/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCompanyStatisticDto } from '../models/ax-company-statistic-dto';

@Injectable({ providedIn: 'root' })
export class AxWebsiteStatisticsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `websiteStatisticsControllerGetCompanyStatistics()` */
  static readonly WebsiteStatisticsControllerGetCompanyStatisticsPath = '/v2/website/statistics/company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteStatisticsControllerGetCompanyStatistics()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteStatisticsControllerGetCompanyStatistics$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxCompanyStatisticDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxWebsiteStatisticsV2Service.WebsiteStatisticsControllerGetCompanyStatisticsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxCompanyStatisticDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteStatisticsControllerGetCompanyStatistics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteStatisticsControllerGetCompanyStatistics(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxCompanyStatisticDto>> {
    return this.websiteStatisticsControllerGetCompanyStatistics$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxCompanyStatisticDto>>): Array<AxCompanyStatisticDto> => r.body)
    );
  }

  /** Path part for operation `websiteStatisticsControllerGetAmountOfBuiltProjects()` */
  static readonly WebsiteStatisticsControllerGetAmountOfBuiltProjectsPath = '/v2/website/statistics/amount-of-built-projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteStatisticsControllerGetAmountOfBuiltProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteStatisticsControllerGetAmountOfBuiltProjects$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxCompanyStatisticDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxWebsiteStatisticsV2Service.WebsiteStatisticsControllerGetAmountOfBuiltProjectsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxCompanyStatisticDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteStatisticsControllerGetAmountOfBuiltProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteStatisticsControllerGetAmountOfBuiltProjects(
    params?: {
    },
    context?: HttpContext
  ): Observable<AxCompanyStatisticDto> {
    return this.websiteStatisticsControllerGetAmountOfBuiltProjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxCompanyStatisticDto>): AxCompanyStatisticDto => r.body)
    );
  }

}
