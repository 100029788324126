import { Component, OnInit } from '@angular/core';

import { ButtonComponent } from '../../../button/button.component';
import { IconComponent } from '../../../icon/icon.component';
import { InputFieldComponent } from '../../../inputs/input-field/input-field.component';
import { TerminassistentState } from '../../ngxs/terminassistent/terminassistent.state';
import { TerminassistentStateSetTimeslot } from '../../ngxs/terminassistent/terminassistent.actions';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { AxReservationsService } from '@axova-frontend-monorepo/axova-rest-api';
import { environment } from '@axova-frontend-monorepo/axova-environments';
import { LoggerService } from '@axova-frontend-monorepo/axova-commons';
import { lastValueFrom } from 'rxjs';
import { StateClear } from 'ngxs-reset-plugin';
import { TerminassistentWizardStepsNavService } from '../../services/terminassistent-wizard-steps-nav.service';

@Component({
  selector: 'ax-ui-terminassistent-otp-verification',
  standalone: true,
  imports: [
    IconComponent,
    FormsModule,
    InputFieldComponent,
    ReactiveFormsModule,
    ButtonComponent
  ],
  templateUrl: './terminassistent-otp-verification.component.html',
  styleUrl: './terminassistent-otp-verification.component.scss'
})
export class TerminassistentOtpVerificationComponent implements OnInit {
  public form = this.formBuilder.group({
    otp: [
      'otp',
      [
        Validators.required,
        Validators.minLength(6)
      ]
    ]
  });
  public showError = false;
  public showSuccessConfirmation = false;

  constructor(
    private store: Store,
    private router: Router,
    private formBuilder: FormBuilder,
    private axReservationsService: AxReservationsService,
    private wizardStepsNavService: TerminassistentWizardStepsNavService
  ) {
  }

  async ngOnInit() {
    this.form.patchValue({ otp: this.store.selectSnapshot(TerminassistentState.reservation)?.otp });
    if (this.store.selectSnapshot(TerminassistentState.reservation)?.otp) {
      await this.verifyOtp();
    }
  }

  public async verifyOtp() {
    this.wizardStepsNavService.showLoader();
    this.wizardStepsNavService.hideWizardNav();
    try {
      await lastValueFrom(this.axReservationsService.reservationsControllerVerifyOtp({
        body: {
          accessKey: this.store.selectSnapshot(TerminassistentState.reservation)?.accessKey || '',
          otp: this.form.value.otp + '',
          apiAppointmentSchedulerKey: this.store.selectSnapshot(TerminassistentState.apiAppointmentSchedulerKey)
        },
        'x-api-key': environment.apiConfiguration.publicApiKey
      }));
      this.wizardStepsNavService.hideLoader();
      this.wizardStepsNavService.reservationSuccessFull = true;
      this.showSuccessConfirmation = true;
      if (environment.name !== 'localhost') {
        this.store.dispatch(new StateClear());
      }
    } catch (verifyOtpException) {
      this.wizardStepsNavService.hideLoader();
      this.showError = true;
      LoggerService.ERROR('Terminassisstent verify otp', verifyOtpException);
    }
  }

  public retryBooking() {
    try {
      this.store.dispatch(new TerminassistentStateSetTimeslot(null));
      this.router.navigateByUrl('/terminassistent/zeit').then();
    } catch (noException) {
      LoggerService.ERROR(this, 'noException', noException);
    }
  }
}
