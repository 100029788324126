/* tslint:disable */
/* eslint-disable */
export enum AxMontageplanCalculationStatusMessageEnum {
  MontageplanstatusBaubewilligungAufgabeNichtGefunden = 'MONTAGEPLANSTATUS_BAUBEWILLIGUNG_AUFGABE_NICHT_GEFUNDEN',
  MontageplanstatusBaubewilligungDokumentNichtGefunden = 'MONTAGEPLANSTATUS_BAUBEWILLIGUNG_DOKUMENT_NICHT_GEFUNDEN',
  MontageplanstatusBaubewilligungEingereichtNichtBewilligt = 'MONTAGEPLANSTATUS_BAUBEWILLIGUNG_EINGEREICHT_NICHT_BEWILLIGT',
  MontageplanstatusBaubewilligungUnerwarteterFehler = 'MONTAGEPLANSTATUS_BAUBEWILLIGUNG_UNERWARTETER_FEHLER',
  MontageplanstatusElektroinstallationAufgabeNichtGefunden = 'MONTAGEPLANSTATUS_ELEKTROINSTALLATION_AUFGABE_NICHT_GEFUNDEN',
  MontageplanstatusElektroinstallationNichtGeplant = 'MONTAGEPLANSTATUS_ELEKTROINSTALLATION_NICHT_GEPLANT',
  MontageplanstatusElektroinstallationUnbekannterFehler = 'MONTAGEPLANSTATUS_ELEKTROINSTALLATION_UNBEKANNTER_FEHLER',
  MontageplanstatusElektroinstallationVorInstallation = 'MONTAGEPLANSTATUS_ELEKTROINSTALLATION_VOR_INSTALLATION',
  MontageplanstatusElektroinstallationWaehrendInstallation = 'MONTAGEPLANSTATUS_ELEKTROINSTALLATION_WAEHREND_INSTALLATION',
  MontageplanstatusErsteTeilrechnungAufgabeNichtAbgeschlossen = 'MONTAGEPLANSTATUS_ERSTE_TEILRECHNUNG_AUFGABE_NICHT_ABGESCHLOSSEN',
  MontageplanstatusErsteTeilrechnungAufgabeNichtGefunden = 'MONTAGEPLANSTATUS_ERSTE_TEILRECHNUNG_AUFGABE_NICHT_GEFUNDEN',
  MontageplanstatusErsteTeilrechnungBezahltAufgabeNichtAbgeschlossen = 'MONTAGEPLANSTATUS_ERSTE_TEILRECHNUNG_BEZAHLT_AUFGABE_NICHT_ABGESCHLOSSEN',
  MontageplanstatusErsteTeilrechnungErstelltAufgabeNichtAbgeschlossen = 'MONTAGEPLANSTATUS_ERSTE_TEILRECHNUNG_ERSTELLT_AUFGABE_NICHT_ABGESCHLOSSEN',
  MontageplanstatusErsteTeilrechnungKeinDebitor = 'MONTAGEPLANSTATUS_ERSTE_TEILRECHNUNG_KEIN_DEBITOR',
  MontageplanstatusErsteTeilrechnungNichtBezahlt = 'MONTAGEPLANSTATUS_ERSTE_TEILRECHNUNG_NICHT_BEZAHLT',
  MontageplanstatusErsteTeilrechnungUnerwarteterFehler = 'MONTAGEPLANSTATUS_ERSTE_TEILRECHNUNG_UNERWARTETER_FEHLER',
  MontageplanstatusGeruestabbauNichtAkzeptiert = 'MONTAGEPLANSTATUS_GERUESTABBAU_NICHT_AKZEPTIERT',
  MontageplanstatusGeruestabbauNichtDisponiert = 'MONTAGEPLANSTATUS_GERUESTABBAU_NICHT_DISPONIERT',
  MontageplanstatusGeruestabbauNichtErledigt = 'MONTAGEPLANSTATUS_GERUESTABBAU_NICHT_ERLEDIGT',
  MontageplanstatusGeruestabbauUnbekannterFehler = 'MONTAGEPLANSTATUS_GERUESTABBAU_UNBEKANNTER_FEHLER',
  MontageplanstatusGeruestabbauVorProjektende = 'MONTAGEPLANSTATUS_GERUESTABBAU_VOR_PROJEKTENDE',
  MontageplanstatusGeruestaufbauNachProjektstart = 'MONTAGEPLANSTATUS_GERUESTAUFBAU_NACH_PROJEKTSTART',
  MontageplanstatusGeruestaufbauNichtAkzeptiert = 'MONTAGEPLANSTATUS_GERUESTAUFBAU_NICHT_AKZEPTIERT',
  MontageplanstatusGeruestaufbauNichtDisponiert = 'MONTAGEPLANSTATUS_GERUESTAUFBAU_NICHT_DISPONIERT',
  MontageplanstatusGeruestaufbauNichtErledigt = 'MONTAGEPLANSTATUS_GERUESTAUFBAU_NICHT_ERLEDIGT',
  MontageplanstatusGeruestaufbauUnbekannterFehler = 'MONTAGEPLANSTATUS_GERUESTAUFBAU_UNBEKANNTER_FEHLER',
  MontageplanstatusIaAufgabeNichtGefunden = 'MONTAGEPLANSTATUS_IA_AUFGABE_NICHT_GEFUNDEN',
  MontageplanstatusIaEingereichtNichtBewilligt = 'MONTAGEPLANSTATUS_IA_EINGEREICHT_NICHT_BEWILLIGT',
  MontageplanstatusIaNichtEingereicht = 'MONTAGEPLANSTATUS_IA_NICHT_EINGEREICHT',
  MontageplanstatusIaUnerwarteterFehler = 'MONTAGEPLANSTATUS_IA_UNERWARTETER_FEHLER',
  MontageplanstatusMeldungKantonAufgabeNichtGefunden = 'MONTAGEPLANSTATUS_MELDUNG_KANTON_AUFGABE_NICHT_GEFUNDEN',
  MontageplanstatusMeldungKantonDokumentNichtGefunden = 'MONTAGEPLANSTATUS_MELDUNG_KANTON_DOKUMENT_NICHT_GEFUNDEN',
  MontageplanstatusMeldungKantonEingereichtNichtFreigegeben = 'MONTAGEPLANSTATUS_MELDUNG_KANTON_EINGEREICHT_NICHT_FREIGEGEBEN',
  MontageplanstatusMeldungKantonNichtGefunden = 'MONTAGEPLANSTATUS_MELDUNG_KANTON_NICHT_GEFUNDEN',
  MontageplanstatusFoerderbeitraegeGebaeudeprogrammAufgabeNichtGefunden = 'MONTAGEPLANSTATUS_FOERDERBEITRAEGE_GEBAEUDEPROGRAMM_AUFGABE_NICHT_GEFUNDEN',
  MontageplanstatusFoerderbeitraegeGebaeudeprogrammDokumentNichtGefunden = 'MONTAGEPLANSTATUS_FOERDERBEITRAEGE_GEBAEUDEPROGRAMM_DOKUMENT_NICHT_GEFUNDEN',
  MontageplanstatusFoerderbeitraegeGebaeudeprogrammUnbekannterFehler = 'MONTAGEPLANSTATUS_FOERDERBEITRAEGE_GEBAEUDEPROGRAMM_UNBEKANNTER_FEHLER',
  MontageplanstatusNichtBerechnet = 'MONTAGEPLANSTATUS_NICHT_BERECHNET',
  MontageplanstatusNichtGebaut = 'MONTAGEPLANSTATUS_NICHT_GEBAUT',
  MontageplanstatusCommunicationlevelVeraendert = 'MONTAGEPLANSTATUS_COMMUNICATIONLEVEL_VERAENDERT',
  MontageplanstatusTagAufgabeNichtGefunden = 'MONTAGEPLANSTATUS_TAG_AUFGABE_NICHT_GEFUNDEN',
  MontageplanstatusTagEingereichtNichtBewilligt = 'MONTAGEPLANSTATUS_TAG_EINGEREICHT_NICHT_BEWILLIGT',
  MontageplanstatusTagNichtErledigt = 'MONTAGEPLANSTATUS_TAG_NICHT_ERLEDIGT',
  MontageplanstatusTagUnerwarteterFehler = 'MONTAGEPLANSTATUS_TAG_UNERWARTETER_FEHLER',
  MontageplanstatusTechnischeFreigabeAufgabeNichtGefunden = 'MONTAGEPLANSTATUS_TECHNISCHE_FREIGABE_AUFGABE_NICHT_GEFUNDEN',
  MontageplanstatusTechnischeFreigabeFaellig = 'MONTAGEPLANSTATUS_TECHNISCHE_FREIGABE_FAELLIG',
  MontageplanstatusTechnischeFreigabeNichtErteilt = 'MONTAGEPLANSTATUS_TECHNISCHE_FREIGABE_NICHT_ERTEILT',
  MontageplanstatusTechnischeFreigabeUeberfaellig = 'MONTAGEPLANSTATUS_TECHNISCHE_FREIGABE_UEBERFAELLIG',
  MontageplanstatusTechnischeFreigabeUnbekannterFehler = 'MONTAGEPLANSTATUS_TECHNISCHE_FREIGABE_UNBEKANNTER_FEHLER',
  MontageplanstatusTobTerminNichtGefunden = 'MONTAGEPLANSTATUS_TOB_TERMIN_NICHT_GEFUNDEN',
  MontageplanstatusVerbundeneProjekteAllesOk = 'MONTAGEPLANSTATUS_VERBUNDENE_PROJEKTE_ALLES_OK',
  MontageplanstatusVerbundeneProjekteFalscheReihenfolge = 'MONTAGEPLANSTATUS_VERBUNDENE_PROJEKTE_FALSCHE_REIHENFOLGE',
  MontageplanstatusVerbundeneProjekteNichtParallel = 'MONTAGEPLANSTATUS_VERBUNDENE_PROJEKTE_NICHT_PARALLEL',
  MontageplanstatusVerbundeneProjekteNichtParallelerStart = 'MONTAGEPLANSTATUS_VERBUNDENE_PROJEKTE_NICHT_PARALLELER_START',
  MontageplanstatusVerbundeneProjekteNichtParallelesEnde = 'MONTAGEPLANSTATUS_VERBUNDENE_PROJEKTE_NICHT_PARALLELES_ENDE',
  MontageplanstatusVerbundeneProjekteUeberschneidung = 'MONTAGEPLANSTATUS_VERBUNDENE_PROJEKTE_UEBERSCHNEIDUNG',
  MontageplanstatusVerbundeneProjekteUnbekannterFehler = 'MONTAGEPLANSTATUS_VERBUNDENE_PROJEKTE_UNBEKANNTER_FEHLER',
  MontageplanstatusVerkaufsstatus = 'MONTAGEPLANSTATUS_VERKAUFSSTATUS'
}
