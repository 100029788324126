
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AxCreateEventRegistrationSelectedOptionDto } from '../models/ax-create-event-registration-selected-option-dto';



export function getUpdateEventInvitationDtoFormGroup() {
return new FormGroup({
    gender: new FormControl<string | undefined>(undefined),
    company: new FormControl<string | undefined>(undefined),
    firstName: new FormControl<string | undefined>(undefined),
    lastName: new FormControl<string | undefined>(undefined),
    phone: new FormControl<string | undefined>(''),
    comment: new FormControl<string | undefined>(''),
    eventRegistrationSelectedOptions: new FormControl<AxCreateEventRegistrationSelectedOptionDto[]>([]),
})
;
}
      