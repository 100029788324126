import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getStandardtaskIconName',
    standalone: true,
})
export class GetStandardtaskIconNamePipe implements PipeTransform {
  transform(standardtaskId: number | null | undefined): string {
    switch (standardtaskId) {
      case 49:
      case 120:
        return 'bolt';
      case 108:
      case 109:
        return 'wc';
      case 127:
      case 128:
      case 129:
      case 130:
        return 'house_siding';
      default: {
        return 'help';
      }
    }
  }
}
