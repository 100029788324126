<span [style]="{
          fontSize: fontSize + 'px',
          fontVariationSettings: '\'FILL\'' + (filled ? 1 : 0) + ',' + '\'wght\'' + boldness,
          padding: (framed || theme !== undefined) ? paddingSize + 'px' : 0
      }"
      [ngStyle]="{'max-width': maxWidth + 'px'}"
      [class.loaded]="isMaterialSymbolsFontLoaded"
      [class.framed]="framed"
      [class.circle]="theme"
      [attr.title]="title ? title : null"
      class="ax-ui-icon material-symbols-rounded ax-ui-icon--theme-{{theme}} ax-ui-icon--fontcolor-{{fontColor}}" translate="no">
  {{ name }}
</span>
