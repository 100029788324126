import * as dayjs from 'dayjs';

export const isDateInFuture = function(date: string | Date | undefined | null, includeToday = false): boolean {
  if (!date) {
    return false;
  }
  const today = includeToday ? dayjs().subtract(1, 'day') : dayjs();
  const inputDate = dayjs(date);

  return inputDate.isAfter(today);
};
