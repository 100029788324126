<div class="ax-ui-terminasssistent-otp-verification ax-text-prose">
  @if (!showError && !showSuccessConfirmation) {
    <div>
      <h2 class="ax-web-text--large">Bestätigung</h2>
      <div class="ax-icon__container flex flex-row center vertical-center gap-24 width-100">
        <ax-ui-icon name=" mark_chat_unread" [fontSize]="40" [boldness]="400" />
        <ax-ui-icon name=" verified_user" [fontSize]="80" />
        <ax-ui-icon name=" mark_email_unread" [fontSize]="40" [boldness]="400" />
      </div>
      <p>Sie sollten nun einen <b>Bestätigungscode</b> per E-Mail und/oder SMS erhalten
        haben. Geben Sie diesen hier ein, um Ihren Beratungstermin zu reservieren.</p>
      <form (ngSubmit)="verifyOtp()" [formGroup]="form">
        <ax-ui-input-field [required]="true"
                           type="number"
                           formControlName="otp" />
        <ax-ui-button text="Termin reservieren"
                      type="submit"
                      [alignRight]="true"
                      [fullWidthMobile]="true"
                      [disabled]="form.invalid" />
      </form>
    </div>
  }
  @if (showError) {
    <div>
      <h2 class="ax-web-text--large">Reservationsanfrage fehlgeschlagen!</h2>
      <div class="ax-icon__container ax-icon__container--error flex flex-row center vertical-center gap-24 width-100">
        <ax-ui-icon name=" error" [fontSize]="80" />
      </div>
      <ax-ui-button text="Erneut versuchen"
                    (buttonClick)="retryBooking()"
                    [alignRight]="true"
                    [fullWidthMobile]="true" />
    </div>
  }

  @if (showSuccessConfirmation) {
    <div>
      <h2 class="ax-web-text--large">Reservationsanfrage abgeschlossen!</h2>
      <div class="ax-icon__container flex flex-row center vertical-center gap-24 width-100">
        <ax-ui-icon name=" event_available" [fontSize]="80" />
      </div>
      <p>Vielen Dank, wir haben Ihre Reservation erhalten. Wir melden uns in den kommenden Tagen mit der
        definitiven Terminbestätigung bei Ihnen.</p>
      <ax-ui-button text="Zurück zur Startseite"
                    routerLink="/"
                    [alignRight]="true"
                    [fullWidthMobile]="true" />
    </div>
  }
</div>
