import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getBusinessdivisionIconNamePipe',
    standalone: true,
})
export class GetBusinessdivisionIconNamePipe implements PipeTransform {
  transform(businessdivisionId: number | null | undefined): string {
    switch (businessdivisionId) {
      case 1:
        return 'solar_power';
      case 2:
        return 'roofing';
      case 3:
        return 'bolt';
      case 4:
        return 'computer';
      default: {
        return 'workspaces';
      }
    }
  }
}
