/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxDeleteCalendarEntryDto } from '../models/ax-delete-calendar-entry-dto';
import { AxIsAvailableDto } from '../models/ax-is-available-dto';

@Injectable({ providedIn: 'root' })
export class AxGoogleCalendarApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `googleCalendarApiControllerOnEventUpdate()` */
  static readonly GoogleCalendarApiControllerOnEventUpdatePath = '/v1/google-calendar-api/onEventUpdate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `googleCalendarApiControllerOnEventUpdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  googleCalendarApiControllerOnEventUpdate$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxGoogleCalendarApiService.GoogleCalendarApiControllerOnEventUpdatePath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `googleCalendarApiControllerOnEventUpdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  googleCalendarApiControllerOnEventUpdate(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.googleCalendarApiControllerOnEventUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `googleCalendarApiControllerIsUserAvailable()` */
  static readonly GoogleCalendarApiControllerIsUserAvailablePath = '/v1/google-calendar-api/isUserAvailable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `googleCalendarApiControllerIsUserAvailable()` instead.
   *
   * This method doesn't expect any request body.
   */
  googleCalendarApiControllerIsUserAvailable$Response(
    params: {
      userId: number;

    /**
     * This request requires a valid API key.
     */
      'x-api-key'?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxIsAvailableDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxGoogleCalendarApiService.GoogleCalendarApiControllerIsUserAvailablePath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.header('x-api-key', params['x-api-key'], {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxIsAvailableDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `googleCalendarApiControllerIsUserAvailable$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  googleCalendarApiControllerIsUserAvailable(
    params: {
      userId: number;

    /**
     * This request requires a valid API key.
     */
      'x-api-key'?: string;
    },
    context?: HttpContext
  ): Observable<AxIsAvailableDto> {
    return this.googleCalendarApiControllerIsUserAvailable$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxIsAvailableDto>): AxIsAvailableDto => r.body)
    );
  }

  /** Path part for operation `googleCalendarApiControllerDeleteCalendarEntry()` */
  static readonly GoogleCalendarApiControllerDeleteCalendarEntryPath = '/v1/google-calendar-api/deleteCalendarEntry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `googleCalendarApiControllerDeleteCalendarEntry()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  googleCalendarApiControllerDeleteCalendarEntry$Response(
    params: {

    /**
     * This request requires a valid API secret.
     */
      'x-api-secret'?: string;
      body: AxDeleteCalendarEntryDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxIsAvailableDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxGoogleCalendarApiService.GoogleCalendarApiControllerDeleteCalendarEntryPath, 'post');
    if (params) {
      rb.header('x-api-secret', params['x-api-secret'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxIsAvailableDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `googleCalendarApiControllerDeleteCalendarEntry$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  googleCalendarApiControllerDeleteCalendarEntry(
    params: {

    /**
     * This request requires a valid API secret.
     */
      'x-api-secret'?: string;
      body: AxDeleteCalendarEntryDto
    },
    context?: HttpContext
  ): Observable<AxIsAvailableDto> {
    return this.googleCalendarApiControllerDeleteCalendarEntry$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxIsAvailableDto>): AxIsAvailableDto => r.body)
    );
  }

}
