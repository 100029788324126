import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'telHref',
    standalone: true,
})
export class TelHrefPipe implements PipeTransform {
  transform(phoneNumber: string | undefined | null): string {
    if (!phoneNumber) {
      return '';
    }

    // Remove all spaces from the phone number
    let formattedNumber = phoneNumber.replace(/\s+/g, '');

    if (formattedNumber.startsWith('00')) {
      // Replace leading '00' with '+'
      formattedNumber = '+' + formattedNumber.substring(2);
    } else if (formattedNumber.startsWith('0')) {
      // Replace leading '0' with '+41'
      formattedNumber = '+41' + formattedNumber.substring(1);
    }

    return formattedNumber;
  }
}
