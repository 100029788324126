import { Component, OnInit, ViewChild } from '@angular/core';
import { AxNotification } from '@axova-frontend-monorepo/axova-rest-api';
import { NavigationDialogBaseComponent } from '../navigation-dialog-base/navigation-dialog-base.component';
import { FormatDatePipe } from '@axova-frontend-monorepo/axova-commons';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '@axova-frontend-monorepo/axova-ui';

@Component({
  selector: 'ax-office-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss'],
  standalone: true,
  imports: [
    NavigationDialogBaseComponent,
    ButtonComponent,
    RouterLink,
    TranslateModule,
    FormatDatePipe
  ]
})
export class NotificationsDialogComponent implements OnInit {
  @ViewChild('navDialog') navDialog!: NavigationDialogBaseComponent;
  public notifications: AxNotification[] = [];

  ngOnInit() {
    this.loadNotifications();
  }

  goToNotification(id: number) {
    this.navDialog.closeDialog();
    // TODO: route to notification detail view
  }

  private loadNotifications() {
    // TODO: Load notifications
  }
}
