import { Component, Input, OnInit } from '@angular/core';
import { DescriptionDetails } from '../../../models/descriptionDetails.model';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DescriptionDetailsListComponent } from '../../description-details-list/description-details-list.component';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';

@Component({
    selector: 'ax-ui-widget-arbeitszeit',
    templateUrl: './widget-arbeitszeit.component.html',
    styleUrls: ['./widget-arbeitszeit.component.scss'],
    standalone: true,
    imports: [
        WidgetBaseComponent,
        DescriptionDetailsListComponent,
        TranslateModule,
    ],
})
export class WidgetArbeitszeitComponent implements OnInit {
  @Input() route: string | undefined = '';

  public nrOfVacationDaysTotal = 25;
  public nrOfVacationDaysLeft = 18;
  public nrOfSickDays = 3;
  public nrOfAbsenceDays = 0;
  public descriptionDetailList: DescriptionDetails[] = [];

  constructor(
    private translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    this.descriptionDetailList = [
      {
        label: this.translateService.instant('FERIENTAGE'),
        value: this.translateService.instant('VON_UEBRIG', { daysLeft: this.nrOfVacationDaysLeft, daysTotal: this.nrOfVacationDaysTotal }),
      },
      {
        label: this.translateService.instant('KRANKHEIT'),
        value: this.nrOfSickDays > 0 ? this.nrOfSickDays.toString() + ' ' + this.translateService.instant('TAGE') : this.translateService.instant('KEINE'),
      },
      {
        label: this.translateService.instant('ABSENZEN'),
        value: this.nrOfAbsenceDays > 0 ? this.nrOfAbsenceDays.toString() + ' ' + this.translateService.instant('TAGE') : this.translateService.instant('KEINE'),
      },
    ];
  }
}
