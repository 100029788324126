
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateElectricalMeasurementDtoFormGroup() {
return new FormGroup({
    projectId: new FormControl<number | undefined>(undefined),
    electricalMeasurementTypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    electricalMeasurementDeviceId: new FormControl<number | undefined>(undefined, [Validators.required]),
    electricalMeasurementCableId: new FormControl<number | undefined>(undefined, [Validators.required]),
    created: new FormControl<string | undefined>(undefined),
    temperature: new FormControl<number | undefined>(undefined, [Validators.required]),
    description: new FormControl<string | undefined>('', [Validators.required]),
    createdBy: new FormControl<number | undefined>(undefined),
    modified: new FormControl<string | undefined>(undefined),
    modifiedBy: new FormControl<number | undefined>(undefined),
})
;
}
      