
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getAuthDtoFormGroup() {
return new FormGroup({
    username: new FormControl<string | undefined>('', [Validators.required]),
    password: new FormControl<string | undefined>('', [Validators.required]),
    otpMobile: new FormControl<string | undefined>(''),
})
;
}
      