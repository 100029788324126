import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertDialogOptions } from '../../models/alert-dialog-options.model';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../button/button.component';
import { IconComponent } from '../icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'ax-ui-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        IconComponent,
        ButtonComponent,
        TranslateModule,
    ],
})
export class AlertDialogComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) alertDialogOptions!: AlertDialogOptions;

  @Output() beforeClose = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();

  public stateIconName = '';
  public isVisible = true;

  ngOnInit() {
    switch (this.alertDialogOptions.feedbackState) {
      case 'success': {
        this.stateIconName = 'check';
        break;
      }
      case 'warning': {
        this.stateIconName = 'campaign';
        break;
      }
      case 'error': {
        this.stateIconName = 'report';
        break;
      }
    }

    if (!this.alertDialogOptions.alertLifeTimeInMs) {
      this.alertDialogOptions.alertLifeTimeInMs = 4000;
    }
  }

  ngAfterViewInit() {
    // automatically close the alert after a defined time
    setTimeout(() => {
      this.close();
    }, this.alertDialogOptions.alertLifeTimeInMs);
  }

  close(): void {
    this.isVisible = false;
    this.beforeClose.emit();
    // Wait for animation to end
    setTimeout(() => {
      this.closed.emit();
    }, 250);
  }

  executeAction(): void {
    if (this.alertDialogOptions.actionHandler) {
      this.alertDialogOptions.actionHandler();
      this.close();
    }
  }
}
