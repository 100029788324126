import { Pipe, PipeTransform } from '@angular/core';
import { FormatDateType } from '../types/format-date.type';
import { getFormattedDate } from '../functions/get-formatted-date';

@Pipe({
  name: 'formatDate',
  standalone: true
})
export class FormatDatePipe implements PipeTransform {
  transform(date: string | Date | undefined | null, format: FormatDateType = 'DD.MM.YYYY'): string {
    return getFormattedDate(date, format);
  }
}
