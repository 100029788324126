<div class="ax-ui-toggle-switch__wrapper ax-ui-toggle-switch__wrapper--{{labelAlignment}}">
  @if (label) {
    <label>
      <small><b>{{ label | translate }}</b></small>
    </label>
  }
  <div [class.disabled]="disabled"
       class="ax-ui-toggle-switch">
    <input [checked]="checked"
           [disabled]="disabled"
           type="checkbox">
    <span (click)="toggleSwitch()" class="ax-ui-toggle-switch__slider"></span>
  </div>
</div>

