
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateDocumentV2DtoFormGroup() {
return new FormGroup({
    file: new FormControl<string | undefined>('', [Validators.required]),
    documenttypeId: new FormControl<number | undefined>(undefined),
    documentsubtypeId: new FormControl<number | undefined>(undefined),
    contactId: new FormControl<number | undefined>(undefined),
    userId: new FormControl<number | undefined>(undefined),
    projectId: new FormControl<number | undefined>(undefined),
    itemId: new FormControl<number | undefined>(undefined),
    calculationitemId: new FormControl<number | undefined>(undefined),
    orderId: new FormControl<number | undefined>(undefined),
    assignedTo: new FormControl<number | undefined>(undefined),
    prettyName: new FormControl<string | undefined>(undefined),
    folder: new FormControl<string | undefined>(undefined),
})
;
}
      