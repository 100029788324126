import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, filter, map, merge } from 'rxjs';
import { LoggerService } from './logger.service';
import { NavigationItemInterface } from '../models/navigation-item.interface';
import { TranslateService } from '@ngx-translate/core';
import { APPLICATION_NAME } from '../application-name';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public $currentNavigationItem: BehaviorSubject<NavigationItemInterface | undefined>;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly title: Title,
    private readonly translateService: TranslateService,
    @Inject(APPLICATION_NAME) private applicationName: string,
  ) {
    this.$currentNavigationItem = new BehaviorSubject<NavigationItemInterface | undefined>(undefined);
  }

  /**
   * Override the title coming from Angular Route configuration manually if you want.
   *
   * @param title
   */
  public overrideTitleManually(title: string) {
    const currentNavigationitem = this.getCurrentNavigationItem();
    if (currentNavigationitem) {
      currentNavigationitem.label = title;
      this.$currentNavigationItem.next(currentNavigationitem);
    }
    this.setBrowserTitle(title);
  }

  /**
   * Sets the browser title to either the navigationItem.label from "data" attribute on the route,
   * or a generic one.
   */
  public setTitleBasedOnRouting() {
    /**
     * Listen for router events, initial translations set and language changes.
     */
    const combined$ = merge(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => ({ type: 'router', data: event })),
      ),
      this.translateService.onDefaultLangChange.pipe(
        map(event => ({ type: 'lang', data: event })),
      ),
      this.translateService.onLangChange.pipe(
        map(event => ({ type: 'lang', data: event })),
      ),
    );

    /**
     * Set the actual title.
     */
    combined$.subscribe(() => {
      try {
        const child = this.getChild(this.activatedRoute);
        if (child.snapshot.data['navigationItem']) {
          const translatedLabel = this.translateService.instant(child.snapshot.data['navigationItem'].label);
          const currentNavigationItem = this.getCurrentNavigationItem();
          const isIdentical = currentNavigationItem && currentNavigationItem.route === this.getCurrentPath(this.activatedRoute.root);
          if (!isIdentical) {
            this.$currentNavigationItem.next({
              route: this.getCurrentPath(this.activatedRoute.root),
              label: translatedLabel,
              fragment: this.activatedRoute.snapshot.fragment ? this.activatedRoute.snapshot.fragment : undefined,
              icon: child.snapshot.data['navigationItem'].icon,
            });
            this.setBrowserTitle(translatedLabel);
          } else if (currentNavigationItem) {
            currentNavigationItem.fragment = this.activatedRoute.snapshot.fragment ? this.activatedRoute.snapshot.fragment : undefined;
            this.$currentNavigationItem.next(currentNavigationItem);
          }
        } else {
          this.setBrowserTitle();
        }
      } catch (browserTitleNotSetException) {
        LoggerService.ERROR(this, 'browserTitleNotSetException', browserTitleNotSetException);
      }
    });
  }

  private setBrowserTitle(title?: string) {
    let browserTitle = `${this.applicationName}`;
    if (title) {
      browserTitle += `: ${title}`;
    }
    this.title.setTitle(browserTitle);
  }

  /**
   * Returns first child if there is one, otherwise just the provided route.
   *
   * @param route
   * @private
   */
  private getChild(route: ActivatedRoute): ActivatedRoute {
    if (route.firstChild) {
      return this.getChild(route.firstChild);
    } else {
      return route;
    }
  }

  private getCurrentPath(route: ActivatedRoute, path = ''): string {
    if (route.firstChild) {
      if (route.firstChild.snapshot.url.map(segment => segment.path).join('/')) {
        if (route.firstChild.outlet === 'primary') {
          path = path + '/' + route.firstChild.snapshot.url.map(segment => segment.path).join('/');
        } else {
          path = `${path}/(${route.firstChild.outlet}:${route.firstChild.snapshot.url.map(segment => segment.path).join('/')})`;
        }
      }
      return this.getCurrentPath(route.firstChild, path);
    }
    return path;
  }

  private getCurrentNavigationItem(): NavigationItemInterface | undefined {
    if (this.$currentNavigationItem.getValue()) {
      return JSON.parse(JSON.stringify(this.$currentNavigationItem.getValue())) as NavigationItemInterface;
    } else {
      return undefined;
    }
  }
}
