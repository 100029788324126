
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateEquipmenttypeDtoFormGroup() {
return new FormGroup({
    name: new FormControl<string | undefined>('', [Validators.required]),
    description: new FormControl<string | undefined>(undefined),
})
;
}
      