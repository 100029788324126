/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreatePhoneDto } from '../models/ax-create-phone-dto';
import { AxPhone } from '../models/ax-phone';
import { AxUpdatePhoneDto } from '../models/ax-update-phone-dto';

@Injectable({ providedIn: 'root' })
export class AxPhonesV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `phonesControllerCreate()` */
  static readonly PhonesControllerCreatePath = '/v2/phones';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phonesControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phonesControllerCreate$Response(
    params: {
      body: AxCreatePhoneDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPhone>> {
    const rb = new RequestBuilder(this.rootUrl, AxPhonesV2Service.PhonesControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPhone>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phonesControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phonesControllerCreate(
    params: {
      body: AxCreatePhoneDto
    },
    context?: HttpContext
  ): Observable<AxPhone> {
    return this.phonesControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPhone>): AxPhone => r.body)
    );
  }

  /** Path part for operation `phonesControllerDelete()` */
  static readonly PhonesControllerDeletePath = '/v2/phones/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phonesControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  phonesControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxPhonesV2Service.PhonesControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phonesControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  phonesControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.phonesControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `phonesControllerUpdate()` */
  static readonly PhonesControllerUpdatePath = '/v2/phones/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phonesControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phonesControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdatePhoneDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPhone>> {
    const rb = new RequestBuilder(this.rootUrl, AxPhonesV2Service.PhonesControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPhone>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phonesControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phonesControllerUpdate(
    params: {
      id: number;
      body: AxUpdatePhoneDto
    },
    context?: HttpContext
  ): Observable<AxPhone> {
    return this.phonesControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPhone>): AxPhone => r.body)
    );
  }

}
