
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getUpdateFixedDateDtoFormGroup() {
return new FormGroup({
    id: new FormControl<number | undefined>(undefined, [Validators.required]),
    blockType: new FormControl<string | undefined>('', [Validators.required]),
    installationStartFixed: new FormControl<boolean | undefined>(undefined, [requiredBoolean()]),
})
;
}
      