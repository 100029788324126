export const slugify = function(inputString: string): string {
  // Replace Swiss Umlauts with their corresponding replacements
  const umlautMap: { [key: string]: string } = {
    'ä': 'ae',
    'ö': 'oe',
    'ü': 'ue',
    'Ä': 'Ae',
    'Ö': 'Oe',
    'Ü': 'Ue',
    'ß': 'ss',
  };

  // Replace characters based on the umlaut map
  let slug = inputString.replace(/[äöüÄÖÜß]/g, match => umlautMap[match]);

  // Convert the string to lowercase
  slug = slug.toLowerCase();

  // Replace any non-alphanumeric characters (excluding hyphens) with hyphens
  slug = slug.replace(/[^a-z0-9]+/g, '-');

  // Remove leading or trailing hyphens
  slug = slug.replace(/^-+|-+$/g, '');

  return slug;
};
