<div [attr.aria-expanded]="isOpen" class="ax-ui-accordion-item" role="tab">
  <div #heading (click)="toggle()" [class.open]="isOpen" class="flex flex-row vertical-center space-between" role="heading" aria-level="4">
    <ng-content select="[accordion-header]" />
    <ax-ui-icon [fontSize]="40" name="add" />
  </div>
  <div [class.open]="isOpen" role="region">
    <div class="content">
      <ng-content select="[accordion-content]" />
    </div>
  </div>
</div>
