<div class="ax-ui-comment">
  <div class="flex gap-16">
    <ax-ui-avatar
      [imgSrc]="comment.user.userImage ? (comment.user.userImage.fileSrcThumbnail | prependServerToFileSrc) : null"
      size="small" />
    <div>
      <div class="ax-ui-comment__content margin-bottom-4">
        <p><b>{{ comment.user.firstName! + ' ' + comment.user.lastName! || '' }}</b></p>
        <p>{{ comment.comment }}</p>
      </div>
      <div class="flex flex-row gap-4 space-between">
        <small class="color-light margin-top-8">{{ comment.created | formatDate: 'DD.MM.YYYY, HH:mm [Uhr]' }}</small>
        @if (!disableCommenting) {
          <ax-ui-button (buttonClick)="showCommentForm = !showCommentForm"
                        [small]="true"
                        [text]="'ANTWORTEN' | translate"
                        variant="tertiary" />
        }
      </div>
    </div>
  </div>
  @if (showCommentForm) {
    <form [formGroup]="commentForm">
      <ax-ui-input-field [placeholder]="'KOMMENTAR_ANTWORTEN' | translate"
                         [required]="true"
                         formControlName="comment"
                         id="comment-input"
                         name="comment"
                         type="text"
                         variant="textarea">
      </ax-ui-input-field>
      <ax-ui-button (buttonClick)="sendComment()"
                    [disabled]="!commentForm.valid"
                    [text]="'KOMMENTIEREN' | translate">
      </ax-ui-button>
    </form>
  }

  @for (answer of comment.answers; track answer) {
    <div class="ax-ui-comment__answer margin-top-40">
      <ax-ui-comment (createComment)="createComment.emit($event)"
                     [comment]="answer"
                     [disableCommenting]="disableCommenting" />
    </div>
  }
  @if (!comment.parentId && !isLastElement) {
    <hr>
  }
</div>
