import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { WidgetsStateModel } from './widgets-state.model';
import { WidgetsStateSetWidgetsConfig } from './widgets.actions';
import { Widget, WidgetGridConfig, WidgetNamesEnum } from '@axova-frontend-monorepo/axova-commons';

const DEFAULT_STATE: WidgetsStateModel = {
  widgetsConfig: {
    activeWidgets: [
      {
        name: WidgetNamesEnum.NOTIZEN,
        gridPosition: 0,
      },
      {
        name: WidgetNamesEnum.MITTEILUNGEN,
        route: '/mitteilungen',
        gridPosition: 1,
      },
    ],
    inactiveWidgets: [
      {
        name: WidgetNamesEnum.ARBEITSZEIT,
        route: '/profil/zeiterfassung',
      },
      {
        name: WidgetNamesEnum.GEBURTSTAGE,
        route: '/mitarbeiter',
      },
      {
        name: WidgetNamesEnum.VERKAUFTE_ANLAGEN,
      },
    ],
  },
};

@State<WidgetsStateModel>({
  name: 'ax_state__widgets',
  defaults: DEFAULT_STATE,
})
@Injectable()
export class WidgetsState {

  @Selector()
  static widgetsConfig(state: WidgetsStateModel): WidgetGridConfig {
    return state.widgetsConfig;
  }

  @Selector()
  static activeWidgets(state: WidgetsStateModel): Widget[] {
    return state.widgetsConfig.activeWidgets;
  }

  @Selector()
  static inactiveWidgets(state: WidgetsStateModel): Widget[] {
    return state.widgetsConfig.inactiveWidgets;
  }

  @Action(WidgetsStateSetWidgetsConfig)
  WidgetsStateSetWidgetsConfig(ctx: StateContext<WidgetsStateModel>, action: WidgetsStateSetWidgetsConfig) {
    ctx.patchState({ widgetsConfig: action.widgetsConfig });
  }
}
