<div class="ax-ui-filter">
  @if (!withCheckboxes) {
    @for (filter of selectedFilters; track filter) {
      <ax-ui-label (labelRemoveClick)="removeFilter(filter)"
                   [removable]="true"
                   [text]="filter.label | translate"
                   variant="success" />
    }
  }

  <div class="ax-ui-filter__button">
    @if (!withCheckboxes) {
      <ax-ui-button (buttonClick)="filterDropdown.toggleDropDown()"
                    [active]="filterDropdown.dropDownOpen"
                    [disabled]="filterDropdownOptions && !filterDropdownOptions.length || disabled"
                    [iconOnly]="true"
                    iconName="filter_list"
                    variant="secondary" />
    }

    @if (withCheckboxes) {
      <ax-ui-button (buttonClick)="filterDropdown.toggleDropDown()"
                    [active]="filterDropdown.dropDownOpen"
                    [additionalInfoText]="filterDropdown.numberOfSelectedCheckboxes > 1 ? '(+' + (filterDropdown.numberOfSelectedCheckboxes - 1).toString() + ')' : ''"
                    [disabled]="filterDropdownOptions && !filterDropdownOptions.length || disabled"
                    [iconPositionRight]="true"
                    [small]="smallButton"
                    [text]="selectedFiltersButtonText"
                    [width]="200"
                    iconName="filter_list"
                    variant="secondary" />
    }
    <ax-ui-dropdown-list #filterDropdown
                         (optionRemoved)="removeFilter($event)"
                         (optionSelected)="addActiveFilter($event)"
                         (selectedCheckboxOptionsChange)="onSelectedCheckboxOptionsChange()"
                         [alignment]="dropDownAlignment"
                         [listTitle]="filterTitle"
                         [options]="filterDropdownOptions"
                         [preventActiveHighlighting]="true"
                         [withCheckboxes]="withCheckboxes"
                         alignment="right" />
  </div>
</div>
