
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AxCreateEventRegistrationOptionDto } from '../models/ax-create-event-registration-option-dto';



export function getCreateEventRegistrationOptionGroupDtoFormGroup() {
return new FormGroup({
    title: new FormControl<string | undefined>('', [Validators.required]),
    description: new FormControl<string | undefined>(''),
    eventId: new FormControl<number | undefined>(undefined),
    eventRegistrationOptions: new FormControl<AxCreateEventRegistrationOptionDto[]>([]),
})
;
}
      