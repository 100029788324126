
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AxCreatePhoneDto } from '../models/ax-create-phone-dto';

import { AxCreateMailDto } from '../models/ax-create-mail-dto';



export function getUpdateContactDtoFormGroup() {
return new FormGroup({
    userId: new FormControl<number | undefined>(undefined),
    businessunitId: new FormControl<number | undefined>(undefined),
    titleId: new FormControl<number | undefined>(undefined),
    categoryId: new FormControl<number | undefined>(undefined),
    statusId: new FormControl<number | undefined>(undefined),
    leadcategoryId: new FormControl<number | undefined>(undefined),
    leadsubcategoryId: new FormControl<number | undefined>(undefined),
    recomUserId: new FormControl<number | undefined>(undefined),
    recomContactId: new FormControl<number | undefined>(undefined),
    number: new FormControl<number | undefined>(undefined),
    company: new FormControl<string | undefined>(undefined),
    firstName: new FormControl<string | undefined>(undefined),
    lastName: new FormControl<string | undefined>(undefined),
    street: new FormControl<string | undefined>(undefined),
    addressAdditional: new FormControl<string | undefined>(undefined),
    zipcode: new FormControl<string | undefined>(undefined),
    city: new FormControl<string | undefined>(undefined),
    canton: new FormControl<string | undefined>(undefined),
    notes: new FormControl<string | undefined>(undefined),
    postalSalutation: new FormControl<string | undefined>(undefined),
    newsPostal: new FormControl<boolean | undefined>(true),
    newsElectronic: new FormControl<boolean | undefined>(true),
    photoPublished: new FormControl<boolean | undefined>(true),
    bankName: new FormControl<string | undefined>(undefined),
    kontoinhaber: new FormControl<string | undefined>(undefined),
    iban: new FormControl<string | undefined>(undefined),
    archiveboxId: new FormControl<number | undefined>(undefined),
    phones: new FormControl<AxCreatePhoneDto[]>([]),
    mails: new FormControl<AxCreateMailDto[]>([]),
})
;
}
      