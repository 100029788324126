<ax-ui-modal #confirmationModal
             (modalClosedWithNoButton)="declineAction()"
             [hideCloseButton]="modalOptions.hideCloseButton || false"
             [modalWidth]="'small'"
             [titleText]="modalOptions.title! | translate"
             modalId="confirmationModal">
  <ng-container modal-body>
    @if (modalOptions.infoText) {
      <p>{{ modalOptions.infoText | translate }}</p>
    }
  </ng-container>
  <ng-container modal-footer>
    @if (modalOptions.confirmButtonText) {
      <ax-ui-button (click)="confirmAction()"
                    [text]="modalOptions.confirmButtonText | translate" />
    }
    @if (modalOptions.declineButtonText) {
      <ax-ui-button (click)="declineAction()"
                    [text]="modalOptions.declineButtonText | translate"
                    variant="tertiary" />
    }
  </ng-container>
</ax-ui-modal>
