import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterLink } from '@angular/router';
import { filter } from 'rxjs';
import { LoggerService, NavigationItemInterface } from '@axova-frontend-monorepo/axova-commons';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';

@Component({
    selector: 'ax-ui-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        IconComponent,
        TranslateModule,
    ],
})
export class BreadcrumbComponent implements OnInit {
  @Input() rootBreadcrumb?: NavigationItemInterface;
  public breadcrumbs: NavigationItemInterface[] = [];

  constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute) {
  }

  /**
   * Listen for router events and create breadcrumbs.
   */
  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.breadcrumbs = [];
      this.createBreadcrumbs(this.router.routerState.root);
      if (this.rootBreadcrumb && this.breadcrumbs.findIndex(breadcrumb => breadcrumb.label === this.rootBreadcrumb?.label) === -1) {
        this.breadcrumbs.unshift(this.rootBreadcrumb);
      }
    });
  }

  /**
   * Loads the currently activated route, checks for children and builds the breadcrumb items.
   *
   * @param route
   * @private
   */
  private createBreadcrumbs(route: ActivatedRoute) {
    const children: ActivatedRoute[] = route.children || [];

    if (children.length === 0) {
      return;
    }

    for (const child of children) {
      this.getBreadcrumbForRouteUrls(child.snapshot);

      for (const grandChild of child.children) {
        this.getBreadcrumbForRouteUrls(grandChild.snapshot);
      }
    }
  }

  private getBreadcrumbForRouteUrls(snapshot: ActivatedRouteSnapshot) {
    let url = '';
    const routeURL: string = snapshot.url.map(segment => segment.path).join('/');
    if (routeURL !== '') {
      url += `/${routeURL}`;
    }

    try {
      const index = this.breadcrumbs.findIndex(breadcrumb => breadcrumb.route === url);
      if (index === -1 && url !== '') {
        this.breadcrumbs.push({
          label: snapshot.data['navigationItem']?.label || url,
          icon: snapshot.data['navigationItem']?.icon || null,
          route: url,
        });
      }
    } catch (noLabelOrIconForBreadcrumbException) {
      LoggerService.ERROR(this, 'You have to add a breadcrumb label and icon in the routing in the data attribute.', noLabelOrIconForBreadcrumbException);
      return undefined;
    }
  }
}
