<label [class.removable]="removable"
       [class.small]="small"
       [class.clickable]="clickable"
       [class.disabled]="disabled"
       [class.text-wrap]="textWrap"
       [ngClass]="[variant, borderStyle, iconName ? 'flex gap-8 flew-row vertical-center' : '']"
       class="ax-ui-label">
  <ax-ui-icon [fontSize]="16"
              [name]="iconName" />
  {{ text }}
  @if (removable) {
    <ax-ui-icon (click)="emitRemoveClick()"
                name="close"
    ></ax-ui-icon>
  }
</label>
