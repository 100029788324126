
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateCreditorDtoFormGroup() {
return new FormGroup({
    type: new FormControl<any>(undefined, [Validators.required]),
    roletypeName: new FormControl<string | undefined>(undefined),
    projectId: new FormControl<number | undefined>(undefined, [Validators.required]),
    calculationId: new FormControl<number | undefined>(undefined, [Validators.required]),
    number: new FormControl<number | undefined>(undefined, [Validators.required]),
    invoicing: new FormControl<string | undefined>('', [Validators.required]),
    notes: new FormControl<string | undefined>(undefined),
})
;
}
      