import { ComponentRef, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';
import { AlertDialogsDirective } from '../../directives/alert-dialogs/alert-dialogs.directive';
import { DOCUMENT } from '@angular/common';
import { AlertDialogOptions } from '../../models/alert-dialog-options.model';

@Injectable({
  providedIn: 'root',
})
export class AlertDialogService {
  private alertDialogsHost!: AlertDialogsDirective;
  private renderer!: Renderer2;
  private dialogContainer!: HTMLElement | null;
  private activeDialogs: ComponentRef<AlertDialogComponent>[] = [];

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public setAlertDialogsHost(host: AlertDialogsDirective) {
    this.alertDialogsHost = host;
  }

  public showAlert(alertDialogOptions: AlertDialogOptions): AlertDialogComponent {
    this.ensureDialogContainerExists(alertDialogOptions.showCentered || false);
    // // Create a component reference from the component
    const dialogRef = this.alertDialogsHost.viewContainerRef.createComponent(AlertDialogComponent);
    const dialogRefInstance = dialogRef.instance;

    // Assign properties
    dialogRefInstance.alertDialogOptions = alertDialogOptions;

    // Push the reference into the activeDialogs array
    this.activeDialogs.push(dialogRef);

    // Destroy instance on user close action and remove from active dialogs list
    dialogRefInstance.closed.subscribe(() => {
      this.closeAlert(dialogRef);
    });

    // Append the dialog to dialogContainer
    this.renderer.appendChild(this.dialogContainer, dialogRef.location.nativeElement);

    // return the reference in case we want to do something with it outside the service
    return dialogRefInstance;
  }

  public closeAllAlerts() {
    this.activeDialogs.forEach(dialog => dialog.destroy());
    this.activeDialogs = [];
    this.removeDialogContainerIfEmpty();
  }

  private closeAlert(dialogRef: ComponentRef<AlertDialogComponent>) {
    const index = this.activeDialogs.indexOf(dialogRef);
    if (index > -1) {
      this.activeDialogs.splice(index, 1);
      dialogRef.destroy();
    }
    this.removeDialogContainerIfEmpty();
  }

  /**
   * If there are no more dialogs in dialogContainer, remove it
   * @private
   */
  private removeDialogContainerIfEmpty() {
    if (this.activeDialogs.length === 0) {
      this.renderer.removeChild(this.document.body, this.dialogContainer);
      this.dialogContainer = null;
    }
  }

  private ensureDialogContainerExists(showCentered: boolean) {
    if (!this.dialogContainer) {
      // create the dialog container
      this.dialogContainer = this.renderer.createElement('div');
      this.renderer.addClass(this.dialogContainer, 'ax-dialog-overlay__container');

      // check if top nav is present and place the dialog container below it accordingly
      try {
        const topNavElement = this.renderer.selectRootElement('ax-office-top-navigation', true).childNodes[0];
        if (topNavElement) {
          const topNavHeight = topNavElement.offsetHeight;
          const topPaddingForDialog = 24;
          this.renderer.setStyle(this.dialogContainer, 'padding-top', `${topNavHeight + topPaddingForDialog}px`);
        }
      } catch (error) {
        // no top nav present, nothing to do
      }

      if (showCentered) {
        this.renderer.addClass(this.dialogContainer, 'centered');
      }

      // append the dialog container to the document
      this.renderer.appendChild(this.document.body, this.dialogContainer);
    }
  }
}
