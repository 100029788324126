/* tslint:disable */
/* eslint-disable */
export enum AxTimerecordtypeNameEnum {
  Arbeit = 'Arbeit',
  Ferien = 'Ferien',
  MilitarZivilschutz = 'Milit\xE4r, Zivilschutz',
  BezahlteAbsenz = 'Bezahlte Absenz',
  Kompensation = 'Kompensation',
  Krank = 'Krank',
  Unfall = 'Unfall',
  Feiertag = 'Feiertag',
  Andere = 'Andere',
  Frei = 'Frei',
  Keine = 'Keine'
}
