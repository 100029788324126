/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxComment } from '../models/ax-comment';
import { AxCreateCommentDto } from '../models/ax-create-comment-dto';

@Injectable({ providedIn: 'root' })
export class AxCommentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `commentsControllerCreate()` */
  static readonly CommentsControllerCreatePath = '/v1/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `commentsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  commentsControllerCreate$Response(
    params: {
      body: AxCreateCommentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxComment>> {
    const rb = new RequestBuilder(this.rootUrl, AxCommentsService.CommentsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxComment>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `commentsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  commentsControllerCreate(
    params: {
      body: AxCreateCommentDto
    },
    context?: HttpContext
  ): Observable<AxComment> {
    return this.commentsControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxComment>): AxComment => r.body)
    );
  }

}
