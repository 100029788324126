
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function requiredBoolean(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value === null || control.value === undefined
      ? { 'required': true }
      : null;
  };
}
        