import { afterNextRender, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IconService {
  public isMaterialSymbolsFontLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    afterNextRender(() => {
      const intervalId = setInterval(() => {
        const fontLoaded = document.fonts.check('12px "Material+Symbols+Rounded"');
        if (fontLoaded) {
          this.isMaterialSymbolsFontLoaded.next(true);
          clearInterval(intervalId);
        }
      }, 100);
    });
  }
}
