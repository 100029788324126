
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getUpdateNewsDtoFormGroup() {
return new FormGroup({
    title: new FormControl<string | undefined>('', [Validators.required]),
    text: new FormControl<string | undefined>('', [Validators.required]),
    requestReadConfirmation: new FormControl<boolean | undefined>(undefined),
    allowComments: new FormControl<boolean | undefined>(undefined),
    showFrom: new FormControl<string | undefined>(new Date().toISOString()),
    showUntil: new FormControl<string | undefined>(new Date('2199-12-31').toISOString()),
    headerImageId: new FormControl<number | undefined>(undefined),
    newsImageId: new FormControl<number | undefined>(undefined),
    createdBy: new FormControl<number | undefined>(undefined),
})
;
}
      