import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getUrlFromImageFullPath',
    standalone: true
})
export class GetUrlFromImageFullPathPipe implements PipeTransform {
  transform(fullPath: string | null) {
    if (!fullPath) {
      return '';
    }
    let newPath = fullPath.substring(fullPath.indexOf('files.axova.cloud'));
    if (fullPath.indexOf('files.axova-office.app') > -1) {
      newPath = fullPath.substring(fullPath.indexOf('files.axova-office.app'));
    }
    return `https://${newPath}`;
  }
}
