import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'ax-ui-burger-menu-icon',
  standalone: true,
  imports: [],
  templateUrl: './burger-menu-icon.component.html',
  styleUrls: ['./burger-menu-icon.component.scss']
})
export class BurgerMenuIconComponent {
  @Input() show = false;
  @Output() toggleMenu: EventEmitter<boolean> = new EventEmitter(this.show);

  emitToggle() {
    this.show = !this.show;
    this.toggleMenu.emit(this.show);
  }
}
