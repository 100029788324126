import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[axUiAutoHeightTextarea]',
    standalone: true,
})
export class AutoHeightTextareaDirective implements OnInit {
  public setAutoHeight = false
  @Input() set axUiAutoHeightTextarea(active: boolean) {
    this.setAutoHeight = active;
  }
  constructor(
    private elementRef: ElementRef,
  ) {
  }

  ngOnInit() {
    if (this.setAutoHeight) {
      this.adjustHeight();
    }
  }

  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    this.adjustHeight();
  }

  adjustHeight(): void {
    const textArea = this.elementRef.nativeElement;
    textArea.style.height = 'auto';
    textArea.style.height = (textArea.scrollHeight > 0 ? textArea.scrollHeight : 104) + 'px';
  }
}
