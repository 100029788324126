@if (chartOptions) {
  <div class="ax-ui-chart-line">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [title]="chartOptions.title"
    ></apx-chart>
  </div>
}
