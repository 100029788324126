import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../button/button.component';

export type ModalWidth = 'small' | 'medium' | 'larger' | 'large';

@Component({
  selector: 'ax-ui-modal',
  standalone: true,
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  imports: [
    TranslateModule,
    ButtonComponent
  ]
})
export class ModalComponent implements OnInit, OnDestroy {
  @ViewChild('modalBody') modalBody!: ElementRef;
  @ViewChild('dynamicComponentSlot', { read: ViewContainerRef }) dynamicComponentSlot!: ViewContainerRef;

  @Input({ required: true }) modalId = 'axModal';
  @Input() titleText = '';
  @Input() introText = '';
  @Input() showInlineBorders = false;
  @Input() modalWidth: ModalWidth | undefined;
  @Input() hideCloseButton = false;
  @Input() noBodyPadding = false;
  @Input() noFooter = false;
  @Input() dynamicComponent: ComponentRef<any> | undefined;

  @Output() modalClosedWithNoButton: EventEmitter<void> = new EventEmitter<void>();
  @Output() modalClosed: EventEmitter<void> = new EventEmitter<void>();

  public isOpen = false;
  public closed = false;
  public noBodyContent = false;
  public modalElement!: HTMLElement;
  private lastFocusedElement!: HTMLElement;
  private onlyOneModalOpen = true;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.modalElement) {
        this.modalElement = this.el.nativeElement.querySelector('.ax-ui-modal');
      }
      if (!this.isOpen) {
        this.modalElement.style.display = 'none';
      }
    }
  }

  ngOnDestroy() {
    if (this.onlyOneModalOpen && isPlatformBrowser(this.platformId)) {
      this.renderer.setStyle(document.documentElement, 'overflow-y', 'auto');
    }
    if (this.dynamicComponent) {
      this.dynamicComponent.destroy();
    }
  }

  /**
   * Show the modal
   */
  public open(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.modalElement = this.el.nativeElement.querySelector('.ax-ui-modal');
      this.renderer.setStyle(document.documentElement, 'overflow-y', 'hidden');
      this.lastFocusedElement = document.activeElement as HTMLElement;
      this.isOpen = true;
      this.closed = false;
      this.changeDetectorRef.detectChanges();
      this.modalElement.style.display = 'flex';
      this.renderer.listen(this.modalElement, 'keydown', this.trapFocusInsideModal.bind(this));
      this.renderer.listen('body', 'keydown', this.closeModalOnEscape.bind(this));
      if (this.dynamicComponent) {
        this.insertDynamicComponent(this.dynamicComponent);
      }
      this.focusFirstElement();

      // check if body has content
      setTimeout(() => {
        if (this.modalBody.nativeElement.children.length === 0) {
          this.noBodyContent = true;
        }
      }, 0);
    }
  }

  /**
   * close the modal
   */
  public close(notClosedViaButton?: boolean): Promise<void> {
    return new Promise((resolve) => {
      if (isPlatformBrowser(this.platformId)) {
        this.isOpen = false;
        this.closed = true;
        this.onlyOneModalOpen = document.querySelectorAll('.ax-ui-modal.open').length === 1;

        // allow document scrolling again
        if (this.onlyOneModalOpen) {
          this.renderer.removeStyle(document.body, 'overflow');
          if (notClosedViaButton) {
            this.modalClosedWithNoButton.emit();
          }
          this.renderer.setStyle(document.documentElement, 'overflow-y', 'auto');
        }

        // delay close to make animation work properly
        setTimeout(() => {
          this.modalElement.style.display = 'none';
          if (this.lastFocusedElement) {
            this.lastFocusedElement.focus();
          }
          this.modalClosed.emit();
          resolve();
        }, 500);
      }
    });
  }


  /**
   * Trap focus inside open modal for accessibility, handle closing on escape key press
   * @param event
   * @private
   */
  private trapFocusInsideModal(event: KeyboardEvent): void {
    if (isPlatformBrowser(this.platformId)) {
      if (event.key === 'Tab') {
        const focusableElements = this.el.nativeElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        if (event.shiftKey && document.activeElement === firstElement) {
          event.preventDefault();
          lastElement.focus();
        } else if (!event.shiftKey && document.activeElement === lastElement) {
          event.preventDefault();
          firstElement.focus();
        }
      }
    }
  }

  private closeModalOnEscape(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.isOpen) {
      event.preventDefault();
      this.close(true).then();
    }
  }

  /**
   * Automatically focus first input element after opening the modal (ignore close button)
   * @private
   */
  private focusFirstElement(): void {
    if (isPlatformBrowser(this.platformId)) {
      const focusableElements = this.el.nativeElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      if (focusableElements.length) {
        // ignore close icon button, therefore [1]
        if (focusableElements[1]) {
          focusableElements[1].focus();
        }
      }
    }
  }

  private insertDynamicComponent(componentRef: ComponentRef<any>): void {
    this.dynamicComponent = componentRef;
    this.dynamicComponentSlot.clear();
    this.dynamicComponentSlot.insert(componentRef.hostView);
  }
}

