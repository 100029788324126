import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ReloadStateModel } from './reload-state.model';
import { ReloadStateTriggerReload } from './reload.actions';

const DEFAULT_STATE: ReloadStateModel = {
  reloadIdentifier: '',
};

@State<ReloadStateModel>({
  name: 'ax_state__reload',
  defaults: DEFAULT_STATE,
})
@Injectable()
export class ReloadState {
  @Selector()
  static reloadIdentifier(state: ReloadStateModel): string {
    return state.reloadIdentifier;
  }

  @Action(ReloadStateTriggerReload)
  reloadStateTriggerReload(ctx: StateContext<ReloadStateModel>, action: ReloadStateTriggerReload) {
    return ctx.patchState({ reloadIdentifier: action.reloadIdentifier });
  }
}
