/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateTimerecorddetailDto } from '../models/ax-create-timerecorddetail-dto';
import { AxDeleteTimerecorddetailDto } from '../models/ax-delete-timerecorddetail-dto';
import { AxHasTimerecorddetailsByUserIdAndDateDto } from '../models/ax-has-timerecorddetails-by-user-id-and-date-dto';
import { AxTimerecorddetail } from '../models/ax-timerecorddetail';
import { AxTimerecorddetailsByDateDto } from '../models/ax-timerecorddetails-by-date-dto';
import { AxTimerecorddetailsByTimerecordtypeDto } from '../models/ax-timerecorddetails-by-timerecordtype-dto';
import { AxUpdateTimerecorddetailDto } from '../models/ax-update-timerecorddetail-dto';

@Injectable({ providedIn: 'root' })
export class AxTimerecorddetailsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `timerecorddetailsControllerHasTimerecorddetailsByUserIdAndDateRange()` */
  static readonly TimerecorddetailsControllerHasTimerecorddetailsByUserIdAndDateRangePath = '/v2/timerecorddetails/byUserId/{userId}/hasEntries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecorddetailsControllerHasTimerecorddetailsByUserIdAndDateRange()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecorddetailsControllerHasTimerecorddetailsByUserIdAndDateRange$Response(
    params: {
      userId: number;
      dateFrom: string;
      dateTo: string;
      withDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxHasTimerecorddetailsByUserIdAndDateDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecorddetailsV2Service.TimerecorddetailsControllerHasTimerecorddetailsByUserIdAndDateRangePath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('withDetails', params.withDetails, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxHasTimerecorddetailsByUserIdAndDateDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecorddetailsControllerHasTimerecorddetailsByUserIdAndDateRange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecorddetailsControllerHasTimerecorddetailsByUserIdAndDateRange(
    params: {
      userId: number;
      dateFrom: string;
      dateTo: string;
      withDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<Array<AxHasTimerecorddetailsByUserIdAndDateDto>> {
    return this.timerecorddetailsControllerHasTimerecorddetailsByUserIdAndDateRange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxHasTimerecorddetailsByUserIdAndDateDto>>): Array<AxHasTimerecorddetailsByUserIdAndDateDto> => r.body)
    );
  }

  /** Path part for operation `timerecorddetailsControllerFindTimerecorddetailsByUserIdAndDate()` */
  static readonly TimerecorddetailsControllerFindTimerecorddetailsByUserIdAndDatePath = '/v2/timerecorddetails/byUserId/{userId}/{date}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecorddetailsControllerFindTimerecorddetailsByUserIdAndDate()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecorddetailsControllerFindTimerecorddetailsByUserIdAndDate$Response(
    params: {
      userId: number;
      date: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTimerecorddetailsByDateDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecorddetailsV2Service.TimerecorddetailsControllerFindTimerecorddetailsByUserIdAndDatePath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('date', params.date, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTimerecorddetailsByDateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecorddetailsControllerFindTimerecorddetailsByUserIdAndDate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecorddetailsControllerFindTimerecorddetailsByUserIdAndDate(
    params: {
      userId: number;
      date: string;
    },
    context?: HttpContext
  ): Observable<AxTimerecorddetailsByDateDto> {
    return this.timerecorddetailsControllerFindTimerecorddetailsByUserIdAndDate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTimerecorddetailsByDateDto>): AxTimerecorddetailsByDateDto => r.body)
    );
  }

  /** Path part for operation `timerecorddetailsControllerFindTimerecorddetailsByUserIdYearAndMonth()` */
  static readonly TimerecorddetailsControllerFindTimerecorddetailsByUserIdYearAndMonthPath = '/v2/timerecorddetails/byUserIdYearAndMonth/{userId}/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecorddetailsControllerFindTimerecorddetailsByUserIdYearAndMonth()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecorddetailsControllerFindTimerecorddetailsByUserIdYearAndMonth$Response(
    params: {
      userId: number;
      year: number;
      month: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxTimerecorddetailsByDateDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecorddetailsV2Service.TimerecorddetailsControllerFindTimerecorddetailsByUserIdYearAndMonthPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('year', params.year, {});
      rb.path('month', params.month, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxTimerecorddetailsByDateDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecorddetailsControllerFindTimerecorddetailsByUserIdYearAndMonth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecorddetailsControllerFindTimerecorddetailsByUserIdYearAndMonth(
    params: {
      userId: number;
      year: number;
      month: number;
    },
    context?: HttpContext
  ): Observable<Array<AxTimerecorddetailsByDateDto>> {
    return this.timerecorddetailsControllerFindTimerecorddetailsByUserIdYearAndMonth$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxTimerecorddetailsByDateDto>>): Array<AxTimerecorddetailsByDateDto> => r.body)
    );
  }

  /** Path part for operation `timerecorddetailsControllerFindTimerecorddetailsByUserIdAndDaterange()` */
  static readonly TimerecorddetailsControllerFindTimerecorddetailsByUserIdAndDaterangePath = '/v2/timerecorddetails/byUserIdAndDaterange/{userId}/{dateFrom}/{dateTo}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecorddetailsControllerFindTimerecorddetailsByUserIdAndDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecorddetailsControllerFindTimerecorddetailsByUserIdAndDaterange$Response(
    params: {
      userId: number;
      dateFrom: string;
      dateTo: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxTimerecorddetailsByDateDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecorddetailsV2Service.TimerecorddetailsControllerFindTimerecorddetailsByUserIdAndDaterangePath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('dateFrom', params.dateFrom, {});
      rb.path('dateTo', params.dateTo, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxTimerecorddetailsByDateDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecorddetailsControllerFindTimerecorddetailsByUserIdAndDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecorddetailsControllerFindTimerecorddetailsByUserIdAndDaterange(
    params: {
      userId: number;
      dateFrom: string;
      dateTo: string;
    },
    context?: HttpContext
  ): Observable<Array<AxTimerecorddetailsByDateDto>> {
    return this.timerecorddetailsControllerFindTimerecorddetailsByUserIdAndDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxTimerecorddetailsByDateDto>>): Array<AxTimerecorddetailsByDateDto> => r.body)
    );
  }

  /** Path part for operation `timerecorddetailsControllerFindTimerecorddetailsByTimerecordtypeYearAndUser()` */
  static readonly TimerecorddetailsControllerFindTimerecorddetailsByTimerecordtypeYearAndUserPath = '/v2/timerecorddetails/byTimerecordtypeYearAndUser/{timerecordtypeId}/{year}/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecorddetailsControllerFindTimerecorddetailsByTimerecordtypeYearAndUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecorddetailsControllerFindTimerecorddetailsByTimerecordtypeYearAndUser$Response(
    params: {
      timerecordtypeId: number;
      year: number;
      userId: number;
      withDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTimerecorddetailsByTimerecordtypeDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecorddetailsV2Service.TimerecorddetailsControllerFindTimerecorddetailsByTimerecordtypeYearAndUserPath, 'get');
    if (params) {
      rb.path('timerecordtypeId', params.timerecordtypeId, {});
      rb.path('year', params.year, {});
      rb.path('userId', params.userId, {});
      rb.query('withDetails', params.withDetails, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTimerecorddetailsByTimerecordtypeDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecorddetailsControllerFindTimerecorddetailsByTimerecordtypeYearAndUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecorddetailsControllerFindTimerecorddetailsByTimerecordtypeYearAndUser(
    params: {
      timerecordtypeId: number;
      year: number;
      userId: number;
      withDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<AxTimerecorddetailsByTimerecordtypeDto> {
    return this.timerecorddetailsControllerFindTimerecorddetailsByTimerecordtypeYearAndUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTimerecorddetailsByTimerecordtypeDto>): AxTimerecorddetailsByTimerecordtypeDto => r.body)
    );
  }

  /** Path part for operation `timerecorddetailsControllerFindOneById()` */
  static readonly TimerecorddetailsControllerFindOneByIdPath = '/v2/timerecorddetails/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecorddetailsControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecorddetailsControllerFindOneById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTimerecorddetail>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecorddetailsV2Service.TimerecorddetailsControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTimerecorddetail>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecorddetailsControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecorddetailsControllerFindOneById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxTimerecorddetail> {
    return this.timerecorddetailsControllerFindOneById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTimerecorddetail>): AxTimerecorddetail => r.body)
    );
  }

  /** Path part for operation `timerecorddetailsControllerDelete()` */
  static readonly TimerecorddetailsControllerDeletePath = '/v2/timerecorddetails/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecorddetailsControllerDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timerecorddetailsControllerDelete$Response(
    params: {
      id: number;
      body: AxDeleteTimerecorddetailDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecorddetailsV2Service.TimerecorddetailsControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecorddetailsControllerDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timerecorddetailsControllerDelete(
    params: {
      id: number;
      body: AxDeleteTimerecorddetailDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.timerecorddetailsControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `timerecorddetailsControllerUpdate()` */
  static readonly TimerecorddetailsControllerUpdatePath = '/v2/timerecorddetails/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecorddetailsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timerecorddetailsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateTimerecorddetailDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTimerecorddetail>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecorddetailsV2Service.TimerecorddetailsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTimerecorddetail>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecorddetailsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timerecorddetailsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateTimerecorddetailDto
    },
    context?: HttpContext
  ): Observable<AxTimerecorddetail> {
    return this.timerecorddetailsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTimerecorddetail>): AxTimerecorddetail => r.body)
    );
  }

  /** Path part for operation `timerecorddetailsControllerCreate()` */
  static readonly TimerecorddetailsControllerCreatePath = '/v2/timerecorddetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecorddetailsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timerecorddetailsControllerCreate$Response(
    params: {
      body: AxCreateTimerecorddetailDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTimerecorddetail>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecorddetailsV2Service.TimerecorddetailsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTimerecorddetail>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecorddetailsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timerecorddetailsControllerCreate(
    params: {
      body: AxCreateTimerecorddetailDto
    },
    context?: HttpContext
  ): Observable<AxTimerecorddetail> {
    return this.timerecorddetailsControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTimerecorddetail>): AxTimerecorddetail => r.body)
    );
  }

}
