/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxInsertMontageplanBlockDto } from '../models/ax-insert-montageplan-block-dto';
import { AxMontageplanBlockDateDto } from '../models/ax-montageplan-block-date-dto';
import { AxMontageplanBlockOrientationTypeEnum } from '../models/ax-montageplan-block-orientation-type-enum';
import { AxMontageplanDayDto } from '../models/ax-montageplan-day-dto';
import { AxMontageplanLockStatusDtoEvent } from '../models/ax-montageplan-lock-status-dto-event';
import { AxMontageplanLog } from '../models/ax-montageplan-log';
import { AxMontageplanSyncStatusDtoEvent } from '../models/ax-montageplan-sync-status-dto-event';
import { AxMontageplanUnplannedBlockDto } from '../models/ax-montageplan-unplanned-block-dto';
import { AxMontageplanUserAvailabilityDto } from '../models/ax-montageplan-user-availability-dto';
import { AxMontageplanUsersDto } from '../models/ax-montageplan-users-dto';
import { AxMoveMontageplanBlockDto } from '../models/ax-move-montageplan-block-dto';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxUpdateFixedDateDto } from '../models/ax-update-fixed-date-dto';
import { AxUpdateMontageplanBlockDatesDto } from '../models/ax-update-montageplan-block-dates-dto';

@Injectable({ providedIn: 'root' })
export class AxMontageplanV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `montageplanControllerGetMontageplanBlocksByTeamIdAndDaterange()` */
  static readonly MontageplanControllerGetMontageplanBlocksByTeamIdAndDaterangePath = '/v2/projects/montageplan/blocks/byTeamsAndDaterange/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerGetMontageplanBlocksByTeamIdAndDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanBlocksByTeamIdAndDaterange$Response(
    params: {
      fromDate: string;
      toDate: string;
      teamIds: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxMontageplanDayDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerGetMontageplanBlocksByTeamIdAndDaterangePath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
      rb.query('teamIds', params.teamIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxMontageplanDayDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerGetMontageplanBlocksByTeamIdAndDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanBlocksByTeamIdAndDaterange(
    params: {
      fromDate: string;
      toDate: string;
      teamIds: Array<string>;
    },
    context?: HttpContext
  ): Observable<Array<AxMontageplanDayDto>> {
    return this.montageplanControllerGetMontageplanBlocksByTeamIdAndDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxMontageplanDayDto>>): Array<AxMontageplanDayDto> => r.body)
    );
  }

  /** Path part for operation `montageplanControllerGetMontageplanBlocksByUserIdAndDaterange()` */
  static readonly MontageplanControllerGetMontageplanBlocksByUserIdAndDaterangePath = '/v2/projects/montageplan/blocks/byUserAndDaterange/{userId}/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerGetMontageplanBlocksByUserIdAndDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanBlocksByUserIdAndDaterange$Response(
    params: {
      userId: number;
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxMontageplanDayDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerGetMontageplanBlocksByUserIdAndDaterangePath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxMontageplanDayDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerGetMontageplanBlocksByUserIdAndDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanBlocksByUserIdAndDaterange(
    params: {
      userId: number;
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<Array<AxMontageplanDayDto>> {
    return this.montageplanControllerGetMontageplanBlocksByUserIdAndDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxMontageplanDayDto>>): Array<AxMontageplanDayDto> => r.body)
    );
  }

  /** Path part for operation `montageplanControllerGetMontageplanUnplannedBlocks()` */
  static readonly MontageplanControllerGetMontageplanUnplannedBlocksPath = '/v2/projects/montageplan/unplannedBlocks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerGetMontageplanUnplannedBlocks()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanUnplannedBlocks$Response(
    params: {
      blockType: AxMontageplanBlockOrientationTypeEnum;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxMontageplanUnplannedBlockDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerGetMontageplanUnplannedBlocksPath, 'get');
    if (params) {
      rb.query('blockType', params.blockType, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxMontageplanUnplannedBlockDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerGetMontageplanUnplannedBlocks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanUnplannedBlocks(
    params: {
      blockType: AxMontageplanBlockOrientationTypeEnum;
    },
    context?: HttpContext
  ): Observable<Array<AxMontageplanUnplannedBlockDto>> {
    return this.montageplanControllerGetMontageplanUnplannedBlocks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxMontageplanUnplannedBlockDto>>): Array<AxMontageplanUnplannedBlockDto> => r.body)
    );
  }

  /** Path part for operation `montageplanControllerMoveBlock()` */
  static readonly MontageplanControllerMoveBlockPath = '/v2/projects/montageplan/blocks/move';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerMoveBlock()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  montageplanControllerMoveBlock$Response(
    params: {
      body: AxMoveMontageplanBlockDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerMoveBlockPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerMoveBlock$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  montageplanControllerMoveBlock(
    params: {
      body: AxMoveMontageplanBlockDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.montageplanControllerMoveBlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerMarkAsBuilt()` */
  static readonly MontageplanControllerMarkAsBuiltPath = '/v2/projects/montageplan/blocks/markAsBuilt/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerMarkAsBuilt()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerMarkAsBuilt$Response(
    params: {
      projectId: number;
      updateInstallationEnd?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerMarkAsBuiltPath, 'post');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.query('updateInstallationEnd', params.updateInstallationEnd, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerMarkAsBuilt$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerMarkAsBuilt(
    params: {
      projectId: number;
      updateInstallationEnd?: boolean;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.montageplanControllerMarkAsBuilt$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerInsertBlock()` */
  static readonly MontageplanControllerInsertBlockPath = '/v2/projects/montageplan/blocks/insert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerInsertBlock()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  montageplanControllerInsertBlock$Response(
    params: {
      body: AxInsertMontageplanBlockDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerInsertBlockPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerInsertBlock$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  montageplanControllerInsertBlock(
    params: {
      body: AxInsertMontageplanBlockDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.montageplanControllerInsertBlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerUpdateFixedDate()` */
  static readonly MontageplanControllerUpdateFixedDatePath = '/v2/projects/montageplan/blocks/updateFixedDate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerUpdateFixedDate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  montageplanControllerUpdateFixedDate$Response(
    params: {
      body: AxUpdateFixedDateDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerUpdateFixedDatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerUpdateFixedDate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  montageplanControllerUpdateFixedDate(
    params: {
      body: AxUpdateFixedDateDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.montageplanControllerUpdateFixedDate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerGetLogsForBlock()` */
  static readonly MontageplanControllerGetLogsForBlockPath = '/v2/projects/montageplan/blocks/logs/{blockType}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerGetLogsForBlock()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetLogsForBlock$Response(
    params: {
      blockType: string;
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxMontageplanLog>>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerGetLogsForBlockPath, 'get');
    if (params) {
      rb.path('blockType', params.blockType, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxMontageplanLog>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerGetLogsForBlock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetLogsForBlock(
    params: {
      blockType: string;
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxMontageplanLog>> {
    return this.montageplanControllerGetLogsForBlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxMontageplanLog>>): Array<AxMontageplanLog> => r.body)
    );
  }

  /** Path part for operation `montageplanControllerGetNewEnddateForBlock()` */
  static readonly MontageplanControllerGetNewEnddateForBlockPath = '/v2/projects/montageplan/blocks/newEnddate/{blockType}/{id}/{newStartDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerGetNewEnddateForBlock()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetNewEnddateForBlock$Response(
    params: {
      blockType: string;
      id: number;
      newStartDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMontageplanBlockDateDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerGetNewEnddateForBlockPath, 'get');
    if (params) {
      rb.path('blockType', params.blockType, {});
      rb.path('id', params.id, {});
      rb.path('newStartDate', params.newStartDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMontageplanBlockDateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerGetNewEnddateForBlock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetNewEnddateForBlock(
    params: {
      blockType: string;
      id: number;
      newStartDate: string;
    },
    context?: HttpContext
  ): Observable<AxMontageplanBlockDateDto> {
    return this.montageplanControllerGetNewEnddateForBlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMontageplanBlockDateDto>): AxMontageplanBlockDateDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerUpdateMontageplanBlockDates()` */
  static readonly MontageplanControllerUpdateMontageplanBlockDatesPath = '/v2/projects/montageplan/blocks/updateDates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerUpdateMontageplanBlockDates()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  montageplanControllerUpdateMontageplanBlockDates$Response(
    params: {
      body: AxUpdateMontageplanBlockDatesDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerUpdateMontageplanBlockDatesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerUpdateMontageplanBlockDates$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  montageplanControllerUpdateMontageplanBlockDates(
    params: {
      body: AxUpdateMontageplanBlockDatesDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.montageplanControllerUpdateMontageplanBlockDates$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerGetMontageplanUsersByTeamIdAndDaterange()` */
  static readonly MontageplanControllerGetMontageplanUsersByTeamIdAndDaterangePath = '/v2/projects/montageplan/users/byTeamsAndDaterange/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerGetMontageplanUsersByTeamIdAndDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanUsersByTeamIdAndDaterange$Response(
    params: {
      fromDate: string;
      toDate: string;
      teamIds: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMontageplanUsersDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerGetMontageplanUsersByTeamIdAndDaterangePath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
      rb.query('teamIds', params.teamIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMontageplanUsersDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerGetMontageplanUsersByTeamIdAndDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanUsersByTeamIdAndDaterange(
    params: {
      fromDate: string;
      toDate: string;
      teamIds: Array<string>;
    },
    context?: HttpContext
  ): Observable<AxMontageplanUsersDto> {
    return this.montageplanControllerGetMontageplanUsersByTeamIdAndDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMontageplanUsersDto>): AxMontageplanUsersDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerGetUserAvailabilityForWorkdate()` */
  static readonly MontageplanControllerGetUserAvailabilityForWorkdatePath = '/v2/projects/montageplan/users/availability/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerGetUserAvailabilityForWorkdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetUserAvailabilityForWorkdate$Response(
    params: {
      fromDate: string;
      toDate: string;
      inMyTeam?: boolean;
      businessdivisionId?: number;
      notBusinessdivisionId?: number;
      userId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxMontageplanUserAvailabilityDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerGetUserAvailabilityForWorkdatePath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
      rb.query('inMyTeam', params.inMyTeam, {});
      rb.query('businessdivisionId', params.businessdivisionId, {});
      rb.query('notBusinessdivisionId', params.notBusinessdivisionId, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxMontageplanUserAvailabilityDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerGetUserAvailabilityForWorkdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetUserAvailabilityForWorkdate(
    params: {
      fromDate: string;
      toDate: string;
      inMyTeam?: boolean;
      businessdivisionId?: number;
      notBusinessdivisionId?: number;
      userId?: number;
    },
    context?: HttpContext
  ): Observable<Array<AxMontageplanUserAvailabilityDto>> {
    return this.montageplanControllerGetUserAvailabilityForWorkdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxMontageplanUserAvailabilityDto>>): Array<AxMontageplanUserAvailabilityDto> => r.body)
    );
  }

  /** Path part for operation `montageplanControllerAssignUserToTeam()` */
  static readonly MontageplanControllerAssignUserToTeamPath = '/v2/projects/montageplan/users/assignToTeam/{userId}/{teamId}/{dateFrom}/{dateTo}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerAssignUserToTeam()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerAssignUserToTeam$Response(
    params: {
      userId: number;
      teamId: number;
      dateFrom: string;
      dateTo: string;
      isTeamlead: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerAssignUserToTeamPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('teamId', params.teamId, {});
      rb.path('dateFrom', params.dateFrom, {});
      rb.path('dateTo', params.dateTo, {});
      rb.query('isTeamlead', params.isTeamlead, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerAssignUserToTeam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerAssignUserToTeam(
    params: {
      userId: number;
      teamId: number;
      dateFrom: string;
      dateTo: string;
      isTeamlead: boolean;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.montageplanControllerAssignUserToTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerCalculateStatusForProject()` */
  static readonly MontageplanControllerCalculateStatusForProjectPath = '/v2/projects/montageplan/projects/calculateStatus/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerCalculateStatusForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerCalculateStatusForProject$Response(
    params: {
      projectId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerCalculateStatusForProjectPath, 'post');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerCalculateStatusForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerCalculateStatusForProject(
    params: {
      projectId: number;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.montageplanControllerCalculateStatusForProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerListenToSyncStatus()` */
  static readonly MontageplanControllerListenToSyncStatusPath = '/v2/projects/montageplan/projects/syncStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerListenToSyncStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerListenToSyncStatus$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMontageplanSyncStatusDtoEvent>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerListenToSyncStatusPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/event-stream', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMontageplanSyncStatusDtoEvent>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerListenToSyncStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerListenToSyncStatus(
    params?: {
    },
    context?: HttpContext
  ): Observable<AxMontageplanSyncStatusDtoEvent> {
    return this.montageplanControllerListenToSyncStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMontageplanSyncStatusDtoEvent>): AxMontageplanSyncStatusDtoEvent => r.body)
    );
  }

  /** Path part for operation `montageplanControllerLockTeam()` */
  static readonly MontageplanControllerLockTeamPath = '/v2/projects/montageplan/teams/lock/{teamId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerLockTeam()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerLockTeam$Response(
    params: {
      teamId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerLockTeamPath, 'post');
    if (params) {
      rb.path('teamId', params.teamId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerLockTeam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerLockTeam(
    params: {
      teamId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.montageplanControllerLockTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `montageplanControllerUnlockTeam()` */
  static readonly MontageplanControllerUnlockTeamPath = '/v2/projects/montageplan/teams/unlock/{teamId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerUnlockTeam()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerUnlockTeam$Response(
    params: {
      teamId: number;
      force?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerUnlockTeamPath, 'post');
    if (params) {
      rb.path('teamId', params.teamId, {});
      rb.query('force', params.force, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerUnlockTeam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerUnlockTeam(
    params: {
      teamId: number;
      force?: boolean;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.montageplanControllerUnlockTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `montageplanControllerListenToLockStatus()` */
  static readonly MontageplanControllerListenToLockStatusPath = '/v2/projects/montageplan/teams/lockStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerListenToLockStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerListenToLockStatus$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMontageplanLockStatusDtoEvent>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerListenToLockStatusPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/event-stream', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMontageplanLockStatusDtoEvent>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerListenToLockStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerListenToLockStatus(
    params?: {
    },
    context?: HttpContext
  ): Observable<AxMontageplanLockStatusDtoEvent> {
    return this.montageplanControllerListenToLockStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMontageplanLockStatusDtoEvent>): AxMontageplanLockStatusDtoEvent => r.body)
    );
  }

}
