import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import * as dayjs from 'dayjs';
import { DateAdapter } from '@angular/material/core';
import * as localData from 'dayjs/plugin/localeData';
import * as LocalizedFormat from 'dayjs/plugin/localizedFormat';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(LocalizedFormat);
dayjs.extend(localData);
dayjs.extend(customParseFormat);

export interface DayJsDateAdapterOptions {
  /**
   * Turns the use of utc dates on or off.
   * Changing this will change how the DateTimePicker output value.
   * {@default false}
   */
  useUtc: boolean;
}

/** InjectionToken for dayjs date adapter to configure options. */
export const DAYJS_DATE_ADAPTER_OPTIONS =
  new InjectionToken<DayJsDateAdapterOptions>(
    'DAYJS_DATE_ADAPTER_OPTIONS',
    {
      providedIn: 'root',
      factory: DAYJS_DATE_ADAPTER_OPTIONS_FACTORY,
    }
  );

/** @docs-private */
export function DAYJS_DATE_ADAPTER_OPTIONS_FACTORY(): DayJsDateAdapterOptions {
  return {
    useUtc: false,
  };
}

/** Creates an array and fills it with values. */
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}

@Injectable()
export class DayjsDateAdapter extends DateAdapter<dayjs.Dayjs> {
  private _localeData!: {
    longMonths: string[];
    shortMonths: string[];
    longDaysOfWeek: string[];
    shortDaysOfWeek: string[];
    narrowDaysOfWeek: string[];
    dates: string[];
  };

  constructor(
    @Optional()
    @Optional()
    @Inject(DAYJS_DATE_ADAPTER_OPTIONS)
    private options?: DayJsDateAdapterOptions
  ) {
    super();
    this.setLocale(dayjs().locale());
  }

  public override setLocale(locale: string) {
    super.setLocale(locale);

    const dayjsLocalData = dayjs().locale(locale).localeData();
    this._localeData = {
      longMonths: dayjsLocalData.months(),
      shortMonths: dayjsLocalData.monthsShort(),
      longDaysOfWeek: dayjsLocalData.weekdaysShort(),
      shortDaysOfWeek: dayjsLocalData.weekdaysShort(),
      narrowDaysOfWeek: dayjsLocalData.weekdaysMin(),
      dates: range(31, (i) =>
        this.createDate(2017, 0, i + 1).format('D')
      ),
    };
  }

  public getYear(date: dayjs.Dayjs): number {
    return this.clone(date).year();
  }

  public getMonth(date: dayjs.Dayjs): number {
    return this.clone(date).month();
  }

  public getDate(date: dayjs.Dayjs): number {
    return this.clone(date).date();
  }

  public getNumDaysInMonth(date: dayjs.Dayjs): number {
    return this.clone(date).daysInMonth();
  }

  public getYearName(date: dayjs.Dayjs): string {
    return date.format('YYYY');
  }

  public getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return style === 'long'
      ? this._localeData.longMonths
      : this._localeData.shortMonths;
  }

  public getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style === 'long') {
      return this._localeData.longDaysOfWeek;
    }
    if (style === 'short') {
      return this._localeData.shortDaysOfWeek;
    }
    return this._localeData.narrowDaysOfWeek;
  }

  public getDateNames(): string[] {
    return this._localeData.dates;
  }

  getDayOfWeek(date: dayjs.Dayjs): number {
    return this.clone(date).day();
  }

  getFirstDayOfWeek(): number {
    return 1;
  }

  today(): dayjs.Dayjs {
    return dayjs();
  }

  public toIso8601(date: dayjs.Dayjs): string {
    return this.clone(date).toISOString();
  }

  public isValid(date: dayjs.Dayjs): boolean {
    return this.clone(date).isValid();
  }

  public invalid(): dayjs.Dayjs {
    return dayjs(NaN);
  }

  public isDateInstance(obj: any): boolean {
    return dayjs.isDayjs(obj);
  }

  /**
   * Attempts to deserialize a value to a valid date object. This is different from parsing in that
   * deserialize should only accept non-ambiguous, locale-independent formats (e.g. a ISO 8601
   * string). The datepicker will call this method on all of it's `@Input()` properties that accept dates.
   * It is therefore possible to support passing values from your backend directly to these properties by
   * overriding this method to also deserialize the format used by your backend.
   * In this dayjs adapter it accepts Dayjs objects or strings that can be input into the dayjs contructor (e.g. ISO 8601).
   */
  public override deserialize(value: dayjs.Dayjs | null | string): dayjs.Dayjs | null {
    if (typeof value === 'string') {
      const constructedDayjs = dayjs(value);
      return this.isValid(constructedDayjs)
        ? constructedDayjs
        : this.invalid();
    } else if (
      value == null ||
      (this.isDateInstance(value) && this.isValid(value))
    ) {
      return value;
    }

    return this.invalid();
  }

  public addCalendarYears(date: dayjs.Dayjs, amount: number): dayjs.Dayjs {
    return this.clone(date).add(amount, 'year');
  }

  public addCalendarMonths(date: dayjs.Dayjs, amount: number): dayjs.Dayjs {
    return this.clone(date).add(amount, 'month');
  }

  public addCalendarDays(date: dayjs.Dayjs, amount: number): dayjs.Dayjs {
    return this.clone(date).add(amount, 'day');
  }

  public createDate(year: number, month: number, date: number): dayjs.Dayjs;
  public createDate(
    year: number,
    month: number,
    date: number,
    hours = 0,
    minutes = 0,
    seconds = 0
  ): dayjs.Dayjs {
    if (month < 0 || month > 11) {
      throw Error(
        `Invalid month index "${month}". Month index has to be between 0 and 11.`
      );
    }

    if (date < 1) {
      throw Error(
        `Invalid date "${date}". Date has to be greater than 0.`
      );
    }

    if (hours < 0 || hours > 23) {
      throw Error(
        `Invalid hours "${hours}". Hours has to be between 0 and 23.`
      );
    }

    if (minutes < 0 || minutes > 59) {
      throw Error(
        `Invalid minutes "${minutes}". Minutes has to between 0 and 59.`
      );
    }

    if (seconds < 0 || seconds > 59) {
      throw Error(
        `Invalid seconds "${seconds}". Seconds has to be between 0 and 59.`
      );
    }

    let result = this.createDayjs(dayjs());

    function trySetUnit(
      date: dayjs.Dayjs,
      unitType: dayjs.UnitType,
      amount: number
    ) {
      if (amount >= 0) {
        return date.set(unitType, amount);
      }
      return date;
    }

    result = trySetUnit(result, 'year', year);
    result = trySetUnit(result, 'month', month);
    result = trySetUnit(result, 'date', date);
    result = trySetUnit(result, 'hour', hours);
    result = trySetUnit(result, 'minute', minutes);
    result = trySetUnit(result, 'second', seconds);
    result.locale(this.locale);

    // If the result isn't valid, the date must have been out of bounds for this month.
    if (!result.isValid()) {
      throw Error(
        `Invalid date "${date}" for month with index "${month}".`
      );
    }

    return result;
  }

  public clone(date: dayjs.Dayjs): dayjs.Dayjs {
    return this.createDayjs(date).clone().locale(this.locale);
  }

  public format(date: dayjs.Dayjs, displayFormat: any): string {
    return this.clone(date).format(displayFormat);
  }

  public parse(value: any, parseFormat: any): dayjs.Dayjs {
    return dayjs(value, parseFormat);
  }

  private createDayjs(date: dayjs.Dayjs): dayjs.Dayjs {
    return date === null
      ? dayjs(null, { utc: this.options?.useUtc })
      : dayjs(date, {
        utc: this.options?.useUtc,
      });
  }
}
