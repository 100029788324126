/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxDocxOutputFormatEnum } from '../models/ax-docx-output-format-enum';
import { AxElektroDrittkontrolleProjectDto } from '../models/ax-elektro-drittkontrolle-project-dto';
import { AxTask } from '../models/ax-task';
import { AxUpdateTaskDto } from '../models/ax-update-task-dto';

@Injectable({ providedIn: 'root' })
export class AxElektroDrittkontrolleV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `elektroDrittkontrolleControllerGetProjectsByInbetriebnahmeDaterange()` */
  static readonly ElektroDrittkontrolleControllerGetProjectsByInbetriebnahmeDaterangePath = '/v2/projects/elektro-drittkontrolle/projects/byInbetriebnahmeDaterange/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elektroDrittkontrolleControllerGetProjectsByInbetriebnahmeDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  elektroDrittkontrolleControllerGetProjectsByInbetriebnahmeDaterange$Response(
    params: {
      fromDate: string;
      toDate: string;
      businessunitId?: number;
      sortByElektroDrittkontrolleStart?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxElektroDrittkontrolleProjectDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxElektroDrittkontrolleV2Service.ElektroDrittkontrolleControllerGetProjectsByInbetriebnahmeDaterangePath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
      rb.query('businessunitId', params.businessunitId, {});
      rb.query('sortByElektroDrittkontrolleStart', params.sortByElektroDrittkontrolleStart, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxElektroDrittkontrolleProjectDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elektroDrittkontrolleControllerGetProjectsByInbetriebnahmeDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elektroDrittkontrolleControllerGetProjectsByInbetriebnahmeDaterange(
    params: {
      fromDate: string;
      toDate: string;
      businessunitId?: number;
      sortByElektroDrittkontrolleStart?: boolean;
    },
    context?: HttpContext
  ): Observable<Array<AxElektroDrittkontrolleProjectDto>> {
    return this.elektroDrittkontrolleControllerGetProjectsByInbetriebnahmeDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxElektroDrittkontrolleProjectDto>>): Array<AxElektroDrittkontrolleProjectDto> => r.body)
    );
  }

  /** Path part for operation `elektroDrittkontrolleControllerUpdateTask()` */
  static readonly ElektroDrittkontrolleControllerUpdateTaskPath = '/v2/projects/elektro-drittkontrolle/tasks/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elektroDrittkontrolleControllerUpdateTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  elektroDrittkontrolleControllerUpdateTask$Response(
    params: {
      id: number;
      body: AxUpdateTaskDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxElektroDrittkontrolleV2Service.ElektroDrittkontrolleControllerUpdateTaskPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elektroDrittkontrolleControllerUpdateTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  elektroDrittkontrolleControllerUpdateTask(
    params: {
      id: number;
      body: AxUpdateTaskDto
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.elektroDrittkontrolleControllerUpdateTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

  /** Path part for operation `elektroDrittkontrolleControllerAcceptTask()` */
  static readonly ElektroDrittkontrolleControllerAcceptTaskPath = '/v2/projects/elektro-drittkontrolle/tasks/{id}/accept';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elektroDrittkontrolleControllerAcceptTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  elektroDrittkontrolleControllerAcceptTask$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxElektroDrittkontrolleV2Service.ElektroDrittkontrolleControllerAcceptTaskPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elektroDrittkontrolleControllerAcceptTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elektroDrittkontrolleControllerAcceptTask(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.elektroDrittkontrolleControllerAcceptTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

  /** Path part for operation `elektroDrittkontrolleControllerDeclineTask()` */
  static readonly ElektroDrittkontrolleControllerDeclineTaskPath = '/v2/projects/elektro-drittkontrolle/tasks/{id}/decline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elektroDrittkontrolleControllerDeclineTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  elektroDrittkontrolleControllerDeclineTask$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxElektroDrittkontrolleV2Service.ElektroDrittkontrolleControllerDeclineTaskPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elektroDrittkontrolleControllerDeclineTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elektroDrittkontrolleControllerDeclineTask(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.elektroDrittkontrolleControllerDeclineTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

  /** Path part for operation `elektroDrittkontrolleControllerCompleteTask()` */
  static readonly ElektroDrittkontrolleControllerCompleteTaskPath = '/v2/projects/elektro-drittkontrolle/tasks/{id}/complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elektroDrittkontrolleControllerCompleteTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  elektroDrittkontrolleControllerCompleteTask$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxElektroDrittkontrolleV2Service.ElektroDrittkontrolleControllerCompleteTaskPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elektroDrittkontrolleControllerCompleteTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elektroDrittkontrolleControllerCompleteTask(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.elektroDrittkontrolleControllerCompleteTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

  /** Path part for operation `elektroDrittkontrolleControllerAssignTaskToTeam()` */
  static readonly ElektroDrittkontrolleControllerAssignTaskToTeamPath = '/v2/projects/elektro-drittkontrolle/tasks/{id}/team/{teamId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elektroDrittkontrolleControllerAssignTaskToTeam()` instead.
   *
   * This method doesn't expect any request body.
   */
  elektroDrittkontrolleControllerAssignTaskToTeam$Response(
    params: {
      id: number;
      teamId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxElektroDrittkontrolleV2Service.ElektroDrittkontrolleControllerAssignTaskToTeamPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('teamId', params.teamId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elektroDrittkontrolleControllerAssignTaskToTeam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elektroDrittkontrolleControllerAssignTaskToTeam(
    params: {
      id: number;
      teamId: number;
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.elektroDrittkontrolleControllerAssignTaskToTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

  /** Path part for operation `elektroDrittkontrolleControllerGenerateInvoiceDocument()` */
  static readonly ElektroDrittkontrolleControllerGenerateInvoiceDocumentPath = '/v2/projects/elektro-drittkontrolle/generateInvoiceDocument/{creditorNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elektroDrittkontrolleControllerGenerateInvoiceDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  elektroDrittkontrolleControllerGenerateInvoiceDocument$Response(
    params: {
      format: AxDocxOutputFormatEnum;
      creditorNumber: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, AxElektroDrittkontrolleV2Service.ElektroDrittkontrolleControllerGenerateInvoiceDocumentPath, 'post');
    if (params) {
      rb.query('format', params.format, {});
      rb.path('creditorNumber', params.creditorNumber, {});
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elektroDrittkontrolleControllerGenerateInvoiceDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elektroDrittkontrolleControllerGenerateInvoiceDocument(
    params: {
      format: AxDocxOutputFormatEnum;
      creditorNumber: number;
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.elektroDrittkontrolleControllerGenerateInvoiceDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
