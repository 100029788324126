import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@axova-frontend-monorepo/axova-environments';

@Directive({
    selector: '[axRouterLink]',
    standalone: true,
})
export class AxRouterlinkDirective {
  @Input({ required: true }) axRouterLink: string | undefined;

  constructor(private el: ElementRef, private router: Router) {
  }

  @HostListener('click', ['$event'])
  async onClick(event: MouseEvent): Promise<void> {
    if (this.axRouterLink) {
      const newUrl = this.getUrlToNavigateTo(this.axRouterLink);
      if (newUrl !== this.axRouterLink) {
        event.preventDefault();
        event.stopPropagation();
        window.open(newUrl, '_blank');
      } else {
        event.preventDefault();
        await this.router.navigateByUrl(this.axRouterLink);
      }
    }
  }

  private getUrlToNavigateTo(url: string): string {
    if (url.indexOf('/projekte/read') > -1) {
      return `${environment.appUrls.axovaOffice}/projects/view/${url.substring(url.lastIndexOf('/') + 1)}`;
    }
    if (url.indexOf('/bestellungen/read') > -1) {
      return `${environment.appUrls.axovaOffice}/orders/view/${url.substring(url.lastIndexOf('/') + 1)}`;
    }
    return url;
  }
}
