import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartComponent, NgApexchartsModule } from 'ng-apexcharts';
import { TranslateService } from '@ngx-translate/core';
import { ChartOptions } from '../../../models/chart-options.type';

@Component({
    selector: 'ax-ui-chart-line',
    templateUrl: './chart-line.component.html',
    styleUrls: ['./chart-line.component.scss'],
    standalone: true,
    imports: [NgApexchartsModule],
})
export class ChartLineComponent implements OnInit {
  @ViewChild('chart') chart!: ChartComponent;
  @Input() title = '';
  @Input() unitName = '';
  @Input() xAxisLabels: string[] = [];
  @Input() xAxisValues: number[] = [];
  public chartOptions: ChartOptions | undefined;

  constructor(
    private readonly translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    this.setChartsOptions();
  }

  private setChartsOptions() {
    this.chartOptions = {
      series: [
        {
          name: this.unitName ? this.translateService.instant(this.unitName) : '',
          data: this.xAxisValues,
        },
      ],
      chart: {
        height: 350,
        type: 'line',
      },
      title: {
        text: this.title ? this.translateService.instant(this.title) : '',
      },
      xaxis: {
        categories: this.xAxisLabels.map(label => label ? this.translateService.instant(label) : ''),
      },
    };
  }
}
