<ax-ui-widget-base title="{{'MITTEILUNGEN' | translate}}"
                   iconName="breaking_news"
                   [route]="route">
  <div class="ax-ui-widget-mitteilungen">
    @for (mitteilung of mitteilungenLatest; track mitteilung) {
      <a
        [routerLink]="'/mitteilungen/read/' + mitteilung.id"
        class="ax-ui-widget-mitteilungen__item ax-action-element">
        @if (mitteilung.newsImage) {
          <img [src]="mitteilung.newsImage.fileSrcThumbnail | prependServerToFileSrc"
               alt="Mitteilungssbild">
        }
        <!-- TODO: remove as soon as all endpoints are finished & all users have the latest version -->
        @if (mitteilung.headerImage && !mitteilung.newsImage) {
          <img [src]="mitteilung.headerImage.fullPath | getUrlFromImageFullPath"
               alt="Mitteilungssbild">
        }
        <div class="ax-ui-widget-mitteilungen__item-info">
          <p><b>{{ mitteilung.title }}</b></p>
          <small class="color-light">{{ mitteilung.created | formatDate: 'dddd, DD.MM.YYYY' }}</small>
        </div>
      </a>
    }
  </div>
</ax-ui-widget-base>
