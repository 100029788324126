<div class="ax-ui-chart-column">
  <apx-chart
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels!"
    [fill]="chartOptions.fill!"
    [legend]="chartOptions.legend!"
    [noData]="chartOptions.noData!"
    [plotOptions]="chartOptions.plotOptions!"
    [series]="chartOptions.series"
    [title]="chartOptions.title"
    [xaxis]="chartOptions.xaxis"
    [yaxis]="chartOptions.yaxis!" />
</div>
