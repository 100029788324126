
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateUploadsDtoFormGroup() {
return new FormGroup({
    files: new FormControl<Array<string> | undefined>([], [Validators.required]),
    note: new FormControl<string | undefined>(''),
})
;
}
      