import { Component, ElementRef, ViewChild } from '@angular/core';
import { NavigationItemInterface } from '@axova-frontend-monorepo/axova-commons';
import { appRoutes } from '../../../app.routes';
import { Route, RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngxs/store';
import { ProfileState } from '@axova-frontend-monorepo/axova-state';
import { environment } from '@axova-frontend-monorepo/axova-environments';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@axova-frontend-monorepo/axova-ui';

@Component({
  selector: 'ax-office-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
  standalone: true,
  imports: [
    IconComponent,
    RouterLinkActive,
    RouterLink,
    TranslateModule
  ]
})
export class SideNavigationComponent {
  public navigationGroups: NavigationItemInterface[][] = [];
  public menuIsOpen = false;

  @ViewChild('axOfficeSideNavigation', { static: true }) axOfficeSideNavigation!: ElementRef;

  constructor(
    private readonly store: Store
  ) {
    this.loadAppRoutesForNavigation();
  }

  public toggleMenu() {
    this.menuIsOpen = !this.menuIsOpen;
  }

  public isTouchDevice(): boolean {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  public scrollToTop() {
    if (this.isTouchDevice() && this.axOfficeSideNavigation) {
      this.axOfficeSideNavigation.nativeElement.scrollTop = 0;
    }
  }

  /**
   * Convert to an object grouped by navigationGroup, convert the object to a 2D array
   * for rendering.
   *
   * @private
   */
  private loadAppRoutesForNavigation() {
    const groupedObject = appRoutes.reduce((acc: any, route: Route) => {
      if (route && route.data && route.data['navigationGroup']) {
        const group = route.data['navigationGroup'];

        // Check permission
        const requiredPermission = route.data['requiredPermission'] || null;
        if (!requiredPermission || (requiredPermission && this.store.selectSnapshot(ProfileState.hasPermission(requiredPermission)))) {
          // Create group if it does not exist yet.
          if (!acc[group]) {
            acc[group] = [];
          }
          const navigationItem: NavigationItemInterface = {
            route: route.path || '/',
            label: route.data['navigationItem']?.label || null,
            icon: route.data['navigationItem']?.icon || null,
            requiredPermission: route.data['requiredPermission'] || null
          };

          // hide unfinished pages on PROD
          // TODO: remove pages from list as soon as usable
          if (environment.name === 'production') {
            const unfinishedPages = [
              'chats',
              'projekte',
              'debitoren',
              'kundensupport',
              'einkauf',
              'artikel'
            ];
            if (!unfinishedPages.find(pageRoute => pageRoute === navigationItem.route)) {
              acc[group].push(navigationItem);
            }
          } else {
            acc[group].push(navigationItem);
          }
        }
      }
      return acc;
    }, {});
    this.navigationGroups = Object.values(groupedObject) as NavigationItemInterface[][];
  }
}
