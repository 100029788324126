import { Pipe, PipeTransform } from '@angular/core';
import { AxEventInvitation } from '@axova-frontend-monorepo/axova-rest-api';

@Pipe({
    name: 'getEventInvitationStatistics',
    standalone: true
})
export class GetEventInvitationStatisticsPipe implements PipeTransform {
  transform(eventInvitations: AxEventInvitation[]): { accepted: number, declined: number, unanswered: number } {
    return {
      accepted: eventInvitations.filter(eventInvitation => eventInvitation.acceptedAt).length,
      declined: eventInvitations.filter(eventInvitation => eventInvitation.declinedAt).length,
      unanswered: eventInvitations.filter(eventInvitation => !eventInvitation.acceptedAt && !eventInvitation.declinedAt).length,
    };
  }
}
