import { ChangeDetectionStrategy, Component, Inject, InjectionToken, TemplateRef } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

export type ModalOverlayData = string | TemplateRef<void>;
export const MODAL_OVERLAY_DATA = new InjectionToken<ModalOverlayData>('Data to display in modal overlay');


@Component({
    selector: 'ax-ui-modal-floating-container',
    templateUrl: './modal-floating-container.component.html',
    styleUrls: ['./modal-floating-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgTemplateOutlet],
})
export class ModalFloatingContainerComponent {

  get asTemplate(): TemplateRef<void> | false {
    return this.modalOverlayData instanceof TemplateRef ? this.modalOverlayData : false;
  }

  constructor(@Inject(MODAL_OVERLAY_DATA) public modalOverlayData: ModalOverlayData) {
  }
}
