<div [class.disable-hint]="disableHint"
     [class.editable]="editable"
     [class.error]="invalid"
     [class.icon-right]="iconRight"
     [class.icon]="iconName"
     [class.max-width-smaller]="maxWidthSmaller"
     [class.table-input]="tableInput"
     [class.with-label]="label && iconName"
     [style.max-width]="maxWidth + 'px'"
     class="ax-ui-input-field ax-input">
  <div class="ax-ui-input-field__header">
    @if (label) {
      <label [for]="id">
        <small><b>{{ label | translate }} @if (required) {
          <b class="ax-ui-required-asterisk"> *</b>
        }</b></small>
      </label>
    }
    @if (maxLength && maxLength !== 10000) {
      <div class="ax-ui-input-field__maxlength-hint">
        <small class="color-light">{{ currentCharLength }}/{{ maxLength }}</small>
      </div>
    }
  </div>
  <div class="input-wrapper">
    @if (variant === 'textField') {
      <input #inputField
             (blur)="onBlur()"
             (input)="updateValue()"
             [axUiAutoInitialWidthInputFieldIsTableEl]="tableInput"
             [disabled]="disabled"
             [id]="id"
             [maxLength]="maxLength"
             [max]="max"
             [min]="min"
             [name]="name"
             [placeholder]="placeholder | translate"
             [required]="required"
             [style.max-width]="maxWidth + 'px'"
             [type]="type"
             [value]="value"
             [attr.autocomplete]="autoComplete ? 'on' : 'off'"
             axUiAutoInitialWidthInputField />
    }
    @if (variant === 'textarea') {
      <textarea #inputArea
                (blur)="onBlur()"
                (input)="updateValue()"
                [attr.rows]="tableInput ? null : rows"
                [axUiAutoHeightTextarea]="setAutoHeight"
                [class.full-height]="!setAutoHeight"
                [disabled]="disabled"
                [id]="id"
                [maxLength]="maxLength"
                [name]="name"
                [placeholder]="placeholder | translate"
                [required]="required"
                [value]="value"
                [attr.autocomplete]="autoComplete ? 'on' : 'off'">
      </textarea>
    }
    <ax-ui-loading-spinner [(loadingState)]="loadingState"
                           [iconSize]="16" />
    @if (iconName) {
      <ax-ui-icon [name]="iconName" />
    }
    @if (showClearButton && value) {
      <div (click)="clear()"
           [class.fade-in]="loadingState === 'none'"
           [class.fade-out]="loadingState !== 'none'"
           class="ax-ui-input-field__clear">
        <ax-ui-icon name="close" />
      </div>
    }
  </div>

  @if (!disableHint) {
    <span
      [class.hide]="hideHint"
      [style.opacity]="invalid ? '1' : '0'" class="ax-input__hint">
      <small><b>{{ hintMessage || '-' | translate }}</b></small>
    </span>
  }
</div>
