/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateEventInvitationDto } from '../models/ax-create-event-invitation-dto';
import { AxEventInvitation } from '../models/ax-event-invitation';
import { AxEventInvitationStatusEnum } from '../models/ax-event-invitation-status-enum';
import { AxUpdateEventInvitationAdminDto } from '../models/ax-update-event-invitation-admin-dto';
import { AxUpdateEventInvitationOrganisatorDto } from '../models/ax-update-event-invitation-organisator-dto';

@Injectable({ providedIn: 'root' })
export class AxEventInvitationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `eventInvitationsControllerFindByEventId()` */
  static readonly EventInvitationsControllerFindByEventIdPath = '/v1/event-invitations/byEvent/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventInvitationsControllerFindByEventId()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventInvitationsControllerFindByEventId$Response(
    params: {
      eventId: number;
      invitationStatus: AxEventInvitationStatusEnum;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxEventInvitation>>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventInvitationsService.EventInvitationsControllerFindByEventIdPath, 'get');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.query('invitationStatus', params.invitationStatus, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxEventInvitation>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventInvitationsControllerFindByEventId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventInvitationsControllerFindByEventId(
    params: {
      eventId: number;
      invitationStatus: AxEventInvitationStatusEnum;
    },
    context?: HttpContext
  ): Observable<Array<AxEventInvitation>> {
    return this.eventInvitationsControllerFindByEventId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxEventInvitation>>): Array<AxEventInvitation> => r.body)
    );
  }

  /** Path part for operation `eventInvitationsControllerFindPublicInvitationsByEventId()` */
  static readonly EventInvitationsControllerFindPublicInvitationsByEventIdPath = '/v1/event-invitations/byEvent/{eventId}/public';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventInvitationsControllerFindPublicInvitationsByEventId()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventInvitationsControllerFindPublicInvitationsByEventId$Response(
    params: {
      eventId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxEventInvitation>>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventInvitationsService.EventInvitationsControllerFindPublicInvitationsByEventIdPath, 'get');
    if (params) {
      rb.path('eventId', params.eventId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxEventInvitation>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventInvitationsControllerFindPublicInvitationsByEventId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventInvitationsControllerFindPublicInvitationsByEventId(
    params: {
      eventId: number;
    },
    context?: HttpContext
  ): Observable<Array<AxEventInvitation>> {
    return this.eventInvitationsControllerFindPublicInvitationsByEventId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxEventInvitation>>): Array<AxEventInvitation> => r.body)
    );
  }

  /** Path part for operation `eventInvitationsControllerCreate()` */
  static readonly EventInvitationsControllerCreatePath = '/v1/event-invitations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventInvitationsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventInvitationsControllerCreate$Response(
    params: {
      body: AxCreateEventInvitationDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventInvitationsService.EventInvitationsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventInvitationsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventInvitationsControllerCreate(
    params: {
      body: AxCreateEventInvitationDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.eventInvitationsControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `eventInvitationsControllerDelete()` */
  static readonly EventInvitationsControllerDeletePath = '/v1/event-invitations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventInvitationsControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventInvitationsControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventInvitationsService.EventInvitationsControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventInvitationsControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventInvitationsControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.eventInvitationsControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `eventInvitationsControllerUpdate()` */
  static readonly EventInvitationsControllerUpdatePath = '/v1/event-invitations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventInvitationsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventInvitationsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateEventInvitationAdminDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventInvitationsService.EventInvitationsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventInvitationsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventInvitationsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateEventInvitationAdminDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.eventInvitationsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `eventInvitationsControllerUpdateAsOrganisator()` */
  static readonly EventInvitationsControllerUpdateAsOrganisatorPath = '/v1/event-invitations/{id}/organisator';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventInvitationsControllerUpdateAsOrganisator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventInvitationsControllerUpdateAsOrganisator$Response(
    params: {
      id: number;
      body: AxUpdateEventInvitationOrganisatorDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventInvitationsService.EventInvitationsControllerUpdateAsOrganisatorPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventInvitationsControllerUpdateAsOrganisator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventInvitationsControllerUpdateAsOrganisator(
    params: {
      id: number;
      body: AxUpdateEventInvitationOrganisatorDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.eventInvitationsControllerUpdateAsOrganisator$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
