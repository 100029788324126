
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getUpdateTimerecorddetailDtoFormGroup() {
return new FormGroup({
    timerecordtypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    timerecordId: new FormControl<number | undefined>(undefined, [Validators.required]),
    timerecord: new FormControl<any>(undefined, [Validators.required]),
    projectId: new FormControl<number | undefined>(undefined),
    hours: new FormControl<number | undefined>(undefined, [Validators.required]),
    lock: new FormControl<boolean | undefined>(false),
    notes: new FormControl<string | undefined>(undefined),
    id: new FormControl<number | undefined>(undefined),
})
;
}
      