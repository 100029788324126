
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateEventRegistrationOptionDtoFormGroup() {
return new FormGroup({
    eventRegistrationOptionGroupId: new FormControl<number | undefined>(undefined),
    title: new FormControl<string | undefined>('', [Validators.required]),
    description: new FormControl<string | undefined>(''),
})
;
}
      