import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationItemInterface } from '@axova-frontend-monorepo/axova-commons';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import { ProfileState } from '@axova-frontend-monorepo/axova-state';
import { Store } from '@ngxs/store';
import { TranslateModule } from '@ngx-translate/core';
import { InputFieldComponent } from '../inputs/input-field/input-field.component';
import { ButtonComponent } from '../button/button.component';

@Component({
    selector: 'ax-ui-inline-nav-list',
    templateUrl: './inline-nav-list.component.html',
    styleUrls: ['./inline-nav-list.component.scss'],
    standalone: true,
    imports: [
        ButtonComponent,
        InputFieldComponent,
        RouterLinkActive,
        RouterLink,
        TranslateModule,
    ],
})
export class InlineNavListComponent implements OnInit {
  @Input({ required: true }) navigationItems: NavigationItemInterface[] = [];
  @Input() showToggleButton = false;
  @Input() automaticallyOpenFirstRoute = true;
  @Input() sortAlphabetically = false;
  @Output() menuClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
  public navigationItemsFiltered: NavigationItemInterface[] = [];
  public closeMenu = false;

  constructor(
    private readonly router: Router,
    private readonly store: Store,
  ) {
  }

  async ngOnInit() {
    this.navigationItemsFiltered = this.navigationItems
      .filter(navigationItem => {
        if (navigationItem.requiredPermission) {
          return this.store.selectSnapshot(ProfileState.hasPermission(navigationItem.requiredPermission));
        }
        return true;
      })
      .sort((a, b) => {
        if (this.sortAlphabetically) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
        }
        return 0;
      });
    if (this.router.url === `/${this.navigationItems[0].outlet}` && this.automaticallyOpenFirstRoute) {
      // open the first list item per default
      await this.router.navigate([[this.navigationItems[0].outlet], { outlets: { [this.navigationItems[0].outlet || '']: this.navigationItems[0].route } }]);
    }
  }

  public getRouterLink(navigationItem: any) {
    return [{ outlets: { [navigationItem.outlet]: [navigationItem.route] } }];
  }

  public async search(searchterm: string) {
    let filterResult: NavigationItemInterface[] = [];
    if (searchterm) {
      filterResult = this.navigationItems.filter(item => item.label.indexOf(searchterm.toUpperCase()) > -1);
    }
    this.navigationItemsFiltered = filterResult.length > 0 ? filterResult : this.navigationItems;
  }

  public toggleMenu() {
    this.closeMenu = !this.closeMenu;
    this.menuClosed.emit(this.closeMenu);
  }
}
