/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateInteractionActivityDto } from '../models/ax-create-interaction-activity-dto';
import { AxCreateInteractionDto } from '../models/ax-create-interaction-dto';
import { AxCreateInteractionNoteDto } from '../models/ax-create-interaction-note-dto';
import { AxCreateInteractionTaskDto } from '../models/ax-create-interaction-task-dto';
import { AxInteraction } from '../models/ax-interaction';
import { AxUpdateInteractionDto } from '../models/ax-update-interaction-dto';

@Injectable({ providedIn: 'root' })
export class AxInteractionsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `interactionsControllerFindByContactId()` */
  static readonly InteractionsControllerFindByContactIdPath = '/v2/interactions/byContactId/{contactId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `interactionsControllerFindByContactId()` instead.
   *
   * This method doesn't expect any request body.
   */
  interactionsControllerFindByContactId$Response(
    params: {
      contactId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxInteraction>>> {
    const rb = new RequestBuilder(this.rootUrl, AxInteractionsV2Service.InteractionsControllerFindByContactIdPath, 'get');
    if (params) {
      rb.path('contactId', params.contactId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxInteraction>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `interactionsControllerFindByContactId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  interactionsControllerFindByContactId(
    params: {
      contactId: number;
    },
    context?: HttpContext
  ): Observable<Array<AxInteraction>> {
    return this.interactionsControllerFindByContactId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxInteraction>>): Array<AxInteraction> => r.body)
    );
  }

  /** Path part for operation `interactionsControllerFindByProjectId()` */
  static readonly InteractionsControllerFindByProjectIdPath = '/v2/interactions/byProjectId/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `interactionsControllerFindByProjectId()` instead.
   *
   * This method doesn't expect any request body.
   */
  interactionsControllerFindByProjectId$Response(
    params: {
      projectId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxInteraction>>> {
    const rb = new RequestBuilder(this.rootUrl, AxInteractionsV2Service.InteractionsControllerFindByProjectIdPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxInteraction>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `interactionsControllerFindByProjectId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  interactionsControllerFindByProjectId(
    params: {
      projectId: number;
    },
    context?: HttpContext
  ): Observable<Array<AxInteraction>> {
    return this.interactionsControllerFindByProjectId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxInteraction>>): Array<AxInteraction> => r.body)
    );
  }

  /** Path part for operation `interactionsControllerCreate()` */
  static readonly InteractionsControllerCreatePath = '/v2/interactions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `interactionsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  interactionsControllerCreate$Response(
    params: {
      body: (AxCreateInteractionDto | AxCreateInteractionActivityDto | AxCreateInteractionNoteDto | AxCreateInteractionTaskDto)
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxInteraction>> {
    const rb = new RequestBuilder(this.rootUrl, AxInteractionsV2Service.InteractionsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxInteraction>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `interactionsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  interactionsControllerCreate(
    params: {
      body: (AxCreateInteractionDto | AxCreateInteractionActivityDto | AxCreateInteractionNoteDto | AxCreateInteractionTaskDto)
    },
    context?: HttpContext
  ): Observable<AxInteraction> {
    return this.interactionsControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxInteraction>): AxInteraction => r.body)
    );
  }

  /** Path part for operation `interactionsControllerDelete()` */
  static readonly InteractionsControllerDeletePath = '/v2/interactions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `interactionsControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  interactionsControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxInteractionsV2Service.InteractionsControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `interactionsControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  interactionsControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.interactionsControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `interactionsControllerUpdate()` */
  static readonly InteractionsControllerUpdatePath = '/v2/interactions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `interactionsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  interactionsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateInteractionDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxInteraction>> {
    const rb = new RequestBuilder(this.rootUrl, AxInteractionsV2Service.InteractionsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxInteraction>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `interactionsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  interactionsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateInteractionDto
    },
    context?: HttpContext
  ): Observable<AxInteraction> {
    return this.interactionsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxInteraction>): AxInteraction => r.body)
    );
  }

}
