import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[axUiConfirmActionModalHost]',
    standalone: true,
})
export class ConfirmActionModalHostDirective {
  constructor(
    public viewContainerRef: ViewContainerRef,
  ) {
  }
}
