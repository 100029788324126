import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import {
  AlertDialogsDirective,
  BreadcrumbComponent,
  ConfirmActionModalHostDirective,
  LoadingSpinnerComponent, ModalDirective
} from '@axova-frontend-monorepo/axova-ui';
import { AppInitializer } from './app.initializer';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import '@angular/common/locales/global/de-CH';
import { ApiModule } from '@axova-frontend-monorepo/axova-rest-api';
import { environment } from '@axova-frontend-monorepo/axova-environments';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LoggingToApiService } from './services/logging-to-api/logging-to-api.service';
import { AxovaStateModule } from '@axova-frontend-monorepo/axova-state';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiTokenInterceptor, AxovaAuthenticationModule } from '@axova-frontend-monorepo/axova-authentication';
import { DEFAULT_LANGUAGE, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  TRANSLATE_FACTORY_MODULES,
  translateHttpModuleLoaderFactory
} from '@axova-frontend-monorepo/axova-commons';
import { SelectDropdownOptionsService } from './services/select-dropdown-options/select-dropdown-options.service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { APPLICATION_NAME } from '@axova-frontend-monorepo/axova-commons';
import { SideNavigationComponent } from './components/navigation/side-navigation/side-navigation.component';
import { TopNavigationComponent } from './components/navigation/top-navigation/top-navigation.component';
import { FavoritesBarComponent } from './components/favorites-bar/favorites-bar.component';

export const initializeApp = (appInitializer: AppInitializer) => (): Promise<void> => appInitializer.init();
const datePipeOptionsFactory = () => {
  return {
    dateFormat: 'shortDate',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  };
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    ApiModule.forRoot({ rootUrl: environment.apiConfiguration.baseUrl }),
    AxovaAuthenticationModule,
    AxovaStateModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpModuleLoaderFactory,
        deps: [
          HttpClient,
          TRANSLATE_FACTORY_MODULES
        ]
      }
    }),
    LoadingSpinnerComponent,
    BreadcrumbComponent,
    AlertDialogsDirective,
    ConfirmActionModalHostDirective,
    ModalDirective,
    SideNavigationComponent,
    TopNavigationComponent,
    FavoritesBarComponent
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de-CH'
    },
    {
      provide: DEFAULT_LANGUAGE,
      useValue: 'de'
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useFactory: datePipeOptionsFactory
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'CHF'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiTokenInterceptor,
      multi: true
    },
    AppInitializer,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitializer],
      multi: true
    },
    {
      provide: TRANSLATE_FACTORY_MODULES,
      useValue: [
        {
          moduleName: 'OFFICE',
          baseTranslateUrl: './assets/i18n'
        }
      ]
    },
    {
      provide: APPLICATION_NAME,
      useValue: 'Axova-Office'
    },
    LoggingToApiService,
    SelectDropdownOptionsService,
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
}
