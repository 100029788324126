
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateAudienceDtoFormGroup() {
return new FormGroup({
    id: new FormControl<number | undefined>(undefined),
    name: new FormControl<string | undefined>('', [Validators.required]),
    description: new FormControl<string | undefined>('', [Validators.required]),
    userIds: new FormControl<Array<number> | undefined>([], [Validators.required]),
    contactIds: new FormControl<Array<number> | undefined>([], [Validators.required]),
    created: new FormControl<string | undefined>(undefined),
    createdBy: new FormControl<number | undefined>(undefined),
    modified: new FormControl<string | undefined>(undefined),
    modifiedBy: new FormControl<number | undefined>(undefined),
})
;
}
      