import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sum',
    pure: false,
    standalone: true,
})
export class SumPipe implements PipeTransform {
  transform(array: Array<any>, propertyPath: string, roundToTwoDecimals?: boolean): number {
    const total = array.reduce((acc, item) => {
      return acc + this.getNestedPropertyValue(item, propertyPath);
    }, 0);
    if (roundToTwoDecimals) {
      return Math.round((total + Number.EPSILON) * 100) / 100;
    } else {
      return total;
    }
  }

  private getNestedPropertyValue(object: any, propertyPath: string): any {
    const properties = propertyPath.split('.');
    let propertyValue = object;

    for (const property of properties) {
      // Return 0 if the property does not exist
      if (propertyValue === null || propertyValue === undefined) {
        return 0;
      }
      propertyValue = propertyValue[property];
    }

    return propertyValue || 0;
  }
}
