
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getCreateTagDtoFormGroup() {
return new FormGroup({
    name: new FormControl<string | undefined>('', [Validators.required]),
    technicalName: new FormControl<string | undefined>('', [Validators.required]),
    isPublic: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
})
;
}
      