/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxProjectrevenue } from '../models/ax-projectrevenue';

@Injectable({ providedIn: 'root' })
export class AxProjectrevenuesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `projectrevenuesControllerFindByProjectId()` */
  static readonly ProjectrevenuesControllerFindByProjectIdPath = '/v1/projectrevenues/byProjectId/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectrevenuesControllerFindByProjectId()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectrevenuesControllerFindByProjectId$Response(
    params: {
      projectId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxProjectrevenue>>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectrevenuesService.ProjectrevenuesControllerFindByProjectIdPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxProjectrevenue>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectrevenuesControllerFindByProjectId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectrevenuesControllerFindByProjectId(
    params: {
      projectId: number;
    },
    context?: HttpContext
  ): Observable<Array<AxProjectrevenue>> {
    return this.projectrevenuesControllerFindByProjectId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxProjectrevenue>>): Array<AxProjectrevenue> => r.body)
    );
  }

}
