
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateCommentDtoFormGroup() {
return new FormGroup({
    comment: new FormControl<string | undefined>('', [Validators.required]),
    newsId: new FormControl<number | undefined>(undefined),
    parentId: new FormControl<number | undefined>(undefined),
    created: new FormControl<string | undefined>(undefined),
    createdBy: new FormControl<number | undefined>(undefined),
    modified: new FormControl<string | undefined>(undefined),
    modifiedBy: new FormControl<number | undefined>(undefined),
})
;
}
      