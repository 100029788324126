
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateUploadDtoFormGroup() {
return new FormGroup({
    file: new FormControl<string | undefined>('', [Validators.required]),
})
;
}
      