import { DropdownOption, FilterNamesEnum } from '@axova-frontend-monorepo/axova-commons';

export interface FilterStateModel {
  filters: Filter[];
}

export interface Filter {
  name: FilterNamesEnum;
  options: DropdownOption[];
}
