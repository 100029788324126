/* tslint:disable */
/* eslint-disable */
export enum AxMontageplanCalculationStatusIdentifierEnum {
  MeldungKanton = 'MELDUNG_KANTON',
  VerknuepfteProjekte = 'VERKNUEPFTE_PROJEKTE',
  ErsteTeilrechnung = 'ERSTE_TEILRECHNUNG',
  KundeFerien = 'KUNDE_FERIEN',
  Gebaut = 'GEBAUT',
  KommunikationsLevel = 'KOMMUNIKATIONS_LEVEL',
  Baugesuch = 'BAUGESUCH',
  TechnischesAnschlussgesuch = 'TECHNISCHES_ANSCHLUSSGESUCH',
  Installationsanzeige = 'INSTALLATIONSANZEIGE',
  Module = 'MODULE',
  Verkauf = 'VERKAUF',
  Geruestabbau = 'GERUESTABBAU',
  Geruestaufbau = 'GERUESTAUFBAU',
  TechnischeObjektbesichtigung = 'TECHNISCHE_OBJEKTBESICHTIGUNG',
  TechnischeFreigabe = 'TECHNISCHE_FREIGABE',
  WechselrichterSpeicherElektromobilitaet = 'WECHSELRICHTER_SPEICHER_ELEKTROMOBILITAET',
  FoerderbeitraegeGebaeudeprogramm = 'FOERDERBEITRAEGE_GEBAEUDEPROGRAMM',
  BaustellenDispo = 'BAUSTELLEN_DISPO'
}
