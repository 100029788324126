import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AxComment } from '@axova-frontend-monorepo/axova-rest-api';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormatDatePipe, PrependServerToFileSrcPipe } from '@axova-frontend-monorepo/axova-commons';
import { TranslateModule } from '@ngx-translate/core';

import { InputFieldComponent } from '../inputs/input-field/input-field.component';
import { ButtonComponent } from '../button/button.component';
import { AvatarComponent } from '../avatar/avatar.component';

@Component({
    selector: 'ax-ui-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss'],
    standalone: true,
  imports: [
    AvatarComponent,
    ButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    InputFieldComponent,
    TranslateModule,
    FormatDatePipe,
    PrependServerToFileSrcPipe
  ]
})
export class CommentComponent {
  @Input({ required: true }) comment!: AxComment;
  @Input() disableCommenting = false;
  @Input() isLastElement = false;

  @Output() createComment: EventEmitter<Partial<AxComment>> = new EventEmitter<Partial<AxComment>>();

  public showCommentForm = false;
  public commentForm: FormGroup = new FormGroup({
    comment: new FormControl(''),
  });

  sendComment() {
    this.createComment.emit({
      comment: this.commentForm.get('comment')?.value || '',
      parentId: this.comment.id,
    });
    this.showCommentForm = false;
  }
}
