import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RequiresPermissionGuard } from '@axova-frontend-monorepo/axova-authentication';
import { ProfilPageComponent } from './profil-page.component';

export const profilPageRoutes: Routes = [
  {
    path: '',
    component: ProfilPageComponent,
    data: {
      navigationItem: {
        label: 'PROFIL',
        icon: 'account_box',
      },
    },
  },
  {
    path: 'zeiterfassung',
    loadChildren: () => import('./zeiterfassung-page/zeiterfassung-page.module').then(m => m.ZeiterfassungPageModule),
    canActivate: [RequiresPermissionGuard],
    data: {
      navigationItem: {
        label: 'ZEITERFASSUNG',
        icon: 'alarm_on',
      },
      requiredPermission: 'timerecorddetails:read',
    },
  },
  {
    path: 'abwesenheiten',
    loadChildren: () => import('./abwesenheiten-page/abwesenheiten-page.module').then(m => m.AbwesenheitenPageModule),
    canActivate: [RequiresPermissionGuard],
    data: {
      navigationItem: {
        label: 'ABWESENHEITEN',
        icon: 'event_busy',
      },
      requiredPermission: 'absences:read',
    },
  },
  {
    path: 'ausbildungen',
    loadChildren: () => import('./ausbildungen-page/ausbildungen-page.module').then(m => m.AusbildungenPageModule),
    data: {
      navigationItem: {
        label: 'AUSBILDUNGEN_KURSE_ZERTIFIKATE',
        icon: 'school',
      },
    },
  },
  {
    path: 'passwort',
    loadChildren: () => import('./passwort-aendern-page/passwort-aendern-page.module').then(m => m.PasswortAendernPageModule),
    data: {
      navigationItem: {
        label: 'PASSWORT_AENDERN',
        icon: 'lock_reset',
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(profilPageRoutes)],
  exports: [RouterModule],
})
export class ProfilPageRoutingModule {
}
