@if (routerLink) {
  <a [routerLink]="routerLink"
    class="ax-link--dark ax-link__animated-icon flex gap-8">
    <ng-container *ngTemplateOutlet="linkContent" />
  </a>
} @else {
  <a [href]="htmlLink"
    [target]="htmlLinkOpenInNewTab ? '_blank' : ''"
    class="ax-link--dark ax-link__animated-icon flex gap-8 margin-top-16">
    <ng-container *ngTemplateOutlet="linkContent" />
  </a>
}


<ng-template #linkContent>
  <span class="ax-link--underlined">{{ text }}</span>
  <ax-ui-icon name="arrow_right_alt" />
</ng-template>
