import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { LoggingToApiService } from './services/logging-to-api/logging-to-api.service';
import { LoggerService, NavigationItemInterface, NavigationService } from '@axova-frontend-monorepo/axova-commons';
import { appRoutes } from './app.routes';
import { Store } from '@ngxs/store';
import { LoadingState, ProfileState } from '@axova-frontend-monorepo/axova-state';
import { AlertDialogsDirective, AlertDialogService, ConfirmActionModalHostDirective, ConfirmActionModalService, ModalDirective, ModalService } from '@axova-frontend-monorepo/axova-ui';

@Component({
  selector: 'ax-office-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild(AlertDialogsDirective, { static: true }) alertDialogsHost!: AlertDialogsDirective;
  @ViewChild(ConfirmActionModalHostDirective, { static: true }) confirmActionModalHost!: ConfirmActionModalHostDirective;
  @ViewChild(ModalDirective, { static: true }) modalHost!: ModalDirective;

  public rootBreadcrumb: NavigationItemInterface | undefined;
  public isLoggedIn$ = this.store.select(ProfileState.profile);
  public showLoading$ = this.store.select(LoadingState.showLoadingState);

  constructor(
    private readonly loggingToApiService: LoggingToApiService,
    private readonly navigationService: NavigationService,
    private readonly alertDialogService: AlertDialogService,
    private readonly confirmActionModalService: ConfirmActionModalService,
    private readonly modalService: ModalService,
    private readonly store: Store,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    this.loggingToApiService.init('axova-office');
  }

  ngOnInit() {
    this.setRootBreadcrumb();
    this.navigationService.setTitleBasedOnRouting();
  }

  ngAfterViewInit() {
    this.showLoading$.subscribe({
      next: () => {
        this.changeDetectorRef.detectChanges();
      },
    });
    this.alertDialogService.setAlertDialogsHost(this.alertDialogsHost);
    this.confirmActionModalService.setModalHost(this.confirmActionModalHost);
    this.modalService.setModalHost(this.modalHost);
  }

  /**
   * Set root breadcrumb.
   *
   * @private
   */
  private setRootBreadcrumb() {
    try {
      const dashboard = appRoutes.find(appRoute => appRoute.path === 'dashboard');
      if (dashboard && dashboard.path && dashboard.data) {
        this.rootBreadcrumb = {
          route: dashboard.path,
          label: dashboard.data['navigationItem']?.label,
          icon: dashboard.data['navigationItem']?.icon,
        };
      }
    } catch (rootBreadcrumbNotFoundException) {
      LoggerService.ERROR(this, 'rootBreadcrumbNotFoundException', rootBreadcrumbNotFoundException);
    }
  }
}
