<nav #axOfficeSideNavigation
     (mouseenter)="isTouchDevice() ? null : toggleMenu()"
     (mouseleave)="menuIsOpen ? toggleMenu() : null"
     [class.open]="menuIsOpen" class="ax-office-side-navigation">
  <button (click)="isTouchDevice() ? toggleMenu() : null" class="ax-navigation-list__item menu">
    <ax-ui-icon name="menu"></ax-ui-icon>
  </button>

  @for (group of navigationGroups; track group; let groupIndex = $index) {
    <ul class="ax-navigation-list" role="list">
      @for (item of group; track item) {
        <li role="listitem">
          <a (click)="menuIsOpen = false;scrollToTop();"
             [routerLinkActive]="'active'"
             [routerLink]="item.route"
             class="ax-navigation-list__item">
            <ax-ui-icon [name]="item.icon"></ax-ui-icon>
            <span class="label hide">{{ item.label | translate }}</span>
          </a>
        </li>
      }
    </ul>
    @if (groupIndex !== navigationGroups.length - 1) {
      <hr>
    }
  }
</nav>
