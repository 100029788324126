<div class="ax-office-profile-options-dialog">
  <ax-office-navigation-dialog-base #navDialog
                                    [title]="'BEGRUESSUNG_INFORMELL' | translate:{ firstname: userProfile?.firstname }">
    <ax-ui-avatar [imgSrc]="(user?.userImage?.fileSrcThumbnail | prependServerToFileSrc) || null"
                  [size]="'small'"
                  button>
    </ax-ui-avatar>

    <ng-container dialog-content>
      <nav>
        <ul class="ax-navigation-list" role="list">
          @for (item of navigationItems; track item) {
            <li role="listitem">
              <a (click)="navDialog.closeDialog()"
                 [routerLinkActiveOptions]="{exact: true}"
                 [routerLinkActive]="'active'"
                 [routerLink]="'profil/' + item.route"
                 class="ax-navigation-list__item">
                <ax-ui-icon [name]="item.icon"></ax-ui-icon>
                <span class="label">{{ item.label | translate }}</span>
              </a>
            </li>
          }
        </ul>
      </nav>

      <ax-ui-button routerLink="/auth/logout"
                    [fullWidth]="true"
                    iconName="logout"
                    text="{{ 'ABMELDEN' | translate }}"
                    variant="secondary">
      </ax-ui-button>
    </ng-container>
  </ax-office-navigation-dialog-base>
</div>
