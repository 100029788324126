@if (dropDownOpen) {
  <div #optionList
       [class.ax-card]="!noParentCard"
       [class.no-parent-card]="noParentCard"
       [class.width-fit-content]="dropdownWidthFitContent"
       [ngClass]="alignment"
       [style.max-width]="maxWidth ? maxWidth + 'px' : null"
       class="ax-ui-dropdown-list flex flex-column no-padding">
    @if (withCheckboxes) {
      <div class="border-bottom"
           [ngClass]="listTitle ? 'padding-24 padding-bottom-16' : 'padding-16' ">
        <div class="flex wrap space-between">
          @if (listTitle) {
            <h3>{{ listTitle | translate }}</h3>
          }
          <div class="flex gap-4">
            <ax-ui-button (buttonClick)="actionForAllCheckboxes('select')"
                          [disabled]="checkboxesSelectedAll"
                          [small]="true"
                          text="AUSWAEHLEN_ALLE"
                          variant="tertiary" />
            <ax-ui-button (buttonClick)="actionForAllCheckboxes('deselect')"
                          [disabled]="checkboxesSelectedNone"
                          [small]="true"
                          text="ZURUECKSETZEN"
                          variant="tertiary" />
          </div>
        </div>
      </div>
    }
    <div #optionElements [class.with-checkboxes]="withCheckboxes" class="ax-ui-dropdown-list__options">
      @if (withCheckboxes) {
        @if (useGroups) {
          <div>
            <div class="groups flex equal-sizing">
              @for (group of getGroupObjectKeys(groupedOptions); track group) {
                <div>
                  <h4 class="ax-ui-dropdown-list__options-element border-none">{{ group }}</h4>
                  <ul>
                    @for (option of groupedOptions[group]; track option) {
                      <li>
                        <ng-container *ngTemplateOutlet="optionTemplate; context: {$implicit: option}"></ng-container>
                      </li>
                    }
                  </ul>
                </div>
              }
            </div>
          </div>
        } @else {
          <ul>
            @for (option of filteredOptions; track option) {
              <li>
                <ng-container *ngTemplateOutlet="optionTemplate; context: {$implicit: option}"></ng-container>
              </li>
            }
          </ul>
        }
        <ng-template #optionTemplate let-option>
          <li>
            <div (click)="selectCheckboxOption(option)"
                 [class.disabled]="option.disabled"
                 class="ax-ui-dropdown-list__options-element ax-action-element">
              <ax-ui-checkbox [checked]="option.selected || false"
                              [label]="option.label"
                              [noMargin]="true" />
            </div>
          </li>
        </ng-template>
      }
      @if (!withCheckboxes) {
        <ul>
          @for (option of filteredOptions; track option) {
            <li>
              @if (!withCheckboxes) {
                <div (click)="selectOption(option)"
                     [class.active]="searchString === option.value && !preventActiveHighlighting"
                     [class.best-match]="option.label === bestMatch?.label  && !preventActiveHighlighting"
                     [class.disabled]="option.disabled"
                     class="ax-ui-dropdown-list__options-element ax-action-element">
                  @if (option.iconName) {
                    <ax-ui-icon [name]="option.iconName" />
                  }
                  <p>{{ option.label  | translate }}</p>
                </div>
              }
            </li>
          }
        </ul>
      }
    </div>
    @if (withCheckboxes) {
      <div class="padding-24 border-top">
        <ax-ui-button (buttonClick)="acceptSelectedCheckboxOptions()"
                      [alignRight]="true"
                      [text]="!hasChanges ? 'SCHLIESSEN' : ('ANWENDEN' | translate) + ' (' + numberOfSelectedCheckboxes + ')'" />
      </div>
    }
  </div>
}

