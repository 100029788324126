import { Routes } from '@angular/router';
import { terminassistentGuard } from './terminassistentGuard';
import { TerminassistentIntroComponent } from './components/terminassistent-intro/terminassistent-intro.component';
import { TerminassistentZipToBusinessUnitComponent } from './components/terminassistent-zip-to-business-unit/terminassistent-zip-to-business-unit.component';
import { TerminassistentChooseContainerTypeComponent } from './components/terminassistent-choose-containertype/terminassistent-choose-container-type.component';
import { TerminassistentAvailableAppointmentsComponent } from './components/terminassistent-available-appointments/terminassistent-available-appointments.component';
import { TerminassistentEnterYourDataComponent } from './components/terminassistent-enter-your-data/terminassistent-enter-your-data.component';
import { TerminassistentOtpVerificationComponent } from './components/terminassistent-otp-verification/terminassistent-otp-verification.component';
import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';

export const terminAssistentRoutes: Routes = [
  {
    path: '',
    redirectTo: 'intro',
    pathMatch: 'full',
  },
  {
    path: 'intro',
    title: 'Terminassistent - Axova',
    component: TerminassistentIntroComponent,
    data: {
      animation: 'intro',
    },
  },
  {
    path: 'ort',
    title: 'Terminassistent - Ort auswählen - Axova',
    component: TerminassistentZipToBusinessUnitComponent,
    data: {
      animation: 'ort',
    },
  },
  {
    path: 'typ',
    title: 'Terminassistent - Typ auswählen - Axova',
    component: TerminassistentChooseContainerTypeComponent,
    canActivate: [terminassistentGuard],
    data: {
      requires: ['ort'],
      animation: 'typ',
    },
  },
  {
    path: 'zeit',
    title: 'Terminassistent - Zeit auswählen - Axova',
    component: TerminassistentAvailableAppointmentsComponent,
    canActivate: [terminassistentGuard],
    data: {
      requires: ['ort', 'typ'],
      animation: 'zeit',
    },
  },
  {
    path: 'daten',
    title: 'Terminassistent - Daten eingeben - Axova',
    component: TerminassistentEnterYourDataComponent,
    canActivate: [terminassistentGuard],
    data: {
      requires: ['ort', 'typ', 'zeit'],
      animation: 'daten',
    },
  },
  {
    path: 'verifizierung',
    title: 'Terminassistent - Bestätigung - Axova',
    component: TerminassistentOtpVerificationComponent,
    canActivate: [terminassistentGuard],
    data: {
      requires: ['ort', 'typ', 'zeit', 'daten'],
      animation: 'verifizierung',
    },
  },
];

export const terminAssistentRoutesAnimations = trigger('terminAssistentRoutesAnimations', [
  transition('intro => ort, ort => typ, typ => zeit, zeit => daten, daten => verifizierung', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
      }),
    ]),
    query(':enter', [style({ right: '-100%', opacity: 0 })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('250ms ease-out', style({ right: '100%', opacity: 0 }))]),
      query(':enter', [animate('250ms ease-out', style({ right: '0%', opacity: 1 }))]),
    ]),
    query(':enter', animateChild()),
  ]),
  transition('verifizierung => daten, daten => zeit, zeit => typ, typ => ort, ort => intro', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        margin: 48,
        width: '100%',
      }),
    ]),
    query(':enter', [style({ left: '-100%', opacity: 0 })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('250ms ease-out', style({ left: '100%', opacity: 0 }))]),
      query(':enter', [animate('250ms ease-out', style({ left: '0%', opacity: 1 }))]),
    ]),
    query(':enter', animateChild()),
  ]),
]);
