/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxAbsence } from '../models/ax-absence';
import { AxCreateAbsenceDto } from '../models/ax-create-absence-dto';
import { AxUpdateAbsenceDto } from '../models/ax-update-absence-dto';

@Injectable({ providedIn: 'root' })
export class AxAbsencesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `absencesControllerCreate()` */
  static readonly AbsencesControllerCreatePath = '/v1/absences';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `absencesControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  absencesControllerCreate$Response(
    params: {
      body: AxCreateAbsenceDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxAbsence>> {
    const rb = new RequestBuilder(this.rootUrl, AxAbsencesService.AbsencesControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxAbsence>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `absencesControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  absencesControllerCreate(
    params: {
      body: AxCreateAbsenceDto
    },
    context?: HttpContext
  ): Observable<AxAbsence> {
    return this.absencesControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxAbsence>): AxAbsence => r.body)
    );
  }

  /** Path part for operation `absencesControllerUpdate()` */
  static readonly AbsencesControllerUpdatePath = '/v1/absences/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `absencesControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  absencesControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateAbsenceDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxAbsencesService.AbsencesControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `absencesControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  absencesControllerUpdate(
    params: {
      id: number;
      body: AxUpdateAbsenceDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.absencesControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
