<div [class.dd-row-padding]="additionalPadding" class="ax-description-details-list">
  <dl>
    @for (item of descriptionDetailList; track item) {
      @if (item.hidden === false || item.hidden === undefined) {
        <dt [class.avatar]="item.avatarSrc !== undefined">
          @if (item.avatarSrc !== undefined) {
            <ax-ui-avatar
              [imgSrc]="item.avatarSrc"
              [size]="'mini'"
              alt="{{ item.label }}" />
          }
          <b>{{ item.label | translate }}</b>
        </dt>
        @if (!item.href && !item.route && !item.valueHTML) {
          <dd
            [class.avatar]="item.avatarSrc !== undefined">
            {{ item.value || '-' | translate }}
          </dd>
        }
        @if (item.valueHTML) {
          <dd
            [class.avatar]="item.avatarSrc !== undefined"
            [innerHTML]="item.valueHTML">
          </dd>
        }
        @if (item.href) {
          <dd [class.avatar]="item.avatarSrc !== undefined">
            <a [href]="item.href" target="_blank">{{ item.value }}</a>
          </dd>
        }
        @if (item.route) {
          <dd [class.avatar]="item.avatarSrc !== undefined">
            <a [routerLink]="item.route">{{ item.value }}</a>
          </dd>
        }
      }
    }
  </dl>
</div>

