import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { ProfileStateModel } from './profile-state.model';
import { AxProfileDto, AxRolesEnum, AxUser } from '@axova-frontend-monorepo/axova-rest-api';
import { ProfileStateSetProfile, ProfileStateSetUser } from './profile.actions';

const DEFAULT_STATE: ProfileStateModel = {
  profile: undefined,
  user: undefined,
};

@State<ProfileStateModel>({
  name: 'ax_state__profile',
  defaults: DEFAULT_STATE,
})
@Injectable()
export class ProfileState {

  @Selector()
  static profile(state: ProfileStateModel): AxProfileDto | undefined {
    return state.profile;
  }

  @Selector()
  static user(state: ProfileStateModel): AxUser | undefined {
    return state.user;
  }

  @Selector()
  static isEmployee(state: ProfileStateModel): boolean {
    try {
      if (state.profile) {
        return state.profile.isUserEntity;
      }
      return false;
    } catch (not) {
      return false;
    }
  }

  @Selector()
  static isCustomer(state: ProfileStateModel): boolean {
    try {
      if (state.profile) {
        return state.profile.isContactEntity;
      }
      return false;
    } catch (not) {
      return false;
    }
  }

  @Selector()
  static roles(state: ProfileStateModel): AxRolesEnum[] {
    return state.profile?.roles || [];
  }

  @Selector()
  static permissions(state: ProfileStateModel): string[] {
    return state.profile?.permissions || [];
  }

  static hasRoles(roleNames: AxRolesEnum[], matchAll = true, ignoreSuperuser = false) {
    return createSelector(
      [ProfileState],
      (state: ProfileStateModel) => {
        try {
          if (!ignoreSuperuser && state.profile?.roles?.find(roleName => roleName === AxRolesEnum.AxSuperuser)) {
            return true;
          }
          if (matchAll) {
            return roleNames.every(roleName => state.profile?.roles?.includes(roleName));
          } else {
            return roleNames.some(roleName => state.profile?.roles?.includes(roleName));
          }
        } catch (noProfileStateException) {
          return false;
        }
      },
    );
  }

  @Selector()
  static hasPermission(permission: string, ignoreGlobalAdmin = false) {
    return createSelector(
      [ProfileState],
      (states: any[]) => {
        const permissionBase = permission.split(':')[0];
        const profileStateKey = Object.keys(states).find(key => key === 'ax_state__profile');
        try {
          if (profileStateKey) {
            const permissions = states[profileStateKey as any].profile.permissions;
            if (!ignoreGlobalAdmin && permissions.includes('all:admin')) {
              return true;
            }
            return permissions.includes(`${permissionBase}:admin`) ? true : permissions.includes(permission);
          }
          return false;
        } catch (noProfileStateException) {
          return false;
        }
      },
    );
  }

  @Action(ProfileStateSetProfile)
  ProfileStateSetProfile(ctx: StateContext<ProfileStateModel>, action: ProfileStateSetProfile) {
    ctx.patchState({ profile: action.profile });
  }

  @Action(ProfileStateSetUser)
  ProfileStateSetUser(ctx: StateContext<ProfileStateModel>, action: ProfileStateSetUser) {
    ctx.patchState({ user: action.user });
  }
}
