@if (widgetsConfig) {
  <div class="ax-ui-widget-grid">
    @for (widget of widgetsConfig.activeWidgets; track widget) {
      @if (widget.name === WidgetNamesEnum.ARBEITSZEIT) {
        <ax-ui-widget-arbeitszeit
          [route]="widget.route" />
      }
      @if (widget.name === WidgetNamesEnum.NOTIZEN) {
        <ax-ui-widget-notizen
          [route]="widget.route" />
      }
      @if (widget.name === WidgetNamesEnum.MITTEILUNGEN) {
        <ax-ui-widget-mitteilungen
          [route]="widget.route" />
      }
      @if (widget.name === WidgetNamesEnum.GEBURTSTAGE) {
        <ax-ui-widget-geburtstage
          [route]="widget.route" />
      }
      @if (widget.name === WidgetNamesEnum.VERKAUFTE_ANLAGEN) {
        <ax-ui-widget-verkaufte-anlagen
          [route]="widget.route"
          class="ax-ui-widget-grid__item-two-columns" />
      }
    }
  </div>
}

<ax-ui-modal #changeWidgetOrderModal
             [introText]="'WIDGET_CONFIG_INTRO' | translate"
             [showInlineBorders]="true"
             [titleText]="'DASHBOARD_ANPASSEN' | translate"
             modalId="changeWidgetOrderModal">
  <ng-container modal-body>
    <div cdkDropListGroup>
      <!-- Active widgets -->
      <h3>{{ 'WIDGETS_AKTIV' | translate }}</h3>
      <div #activeList="cdkDropList"
           (cdkDropListDropped)="onDrop($event)"
           [cdkDropListData]="widgetsConfigChanged.activeWidgets"
           [cdkDropListOrientation]="cdkDropListOrientation"
           cdkDropList
           class="ax-ui-widget-grid">
        @for (widget of widgetsConfigChanged.activeWidgets; track widget) {
          <div
            cdkDrag
            class="ax-ui-widget-grid--draggable">
            <p>{{ widget.name }}</p>
            <div class="drag-placeholder flex center flex-row vertical-center">
              <ax-ui-icon [fontSize]="48" [name]="getWidgetIconName(widget.name)" />
            </div>
          </div>
        }
      </div>

      <!-- Inactive widgets -->
      <h3>{{ 'WIDGETS_WEITERE' | translate }}</h3>
      <div #inactiveList="cdkDropList"
           (cdkDropListDropped)="onDrop($event)"
           [cdkDropListData]="widgetsConfigChanged.inactiveWidgets"
           [cdkDropListOrientation]="cdkDropListOrientation"
           cdkDropList
           class="ax-ui-widget-grid">
        @for (widget of widgetsConfigChanged.inactiveWidgets; track widget) {
          <div
            cdkDrag
            class="ax-ui-widget-grid--draggable">
            <p>{{ widget.name }}</p>
            <div class="drag-placeholder flex center flex-row vertical-center">
              <ax-ui-icon [fontSize]="48" [name]="getWidgetIconName(widget.name)" />
            </div>
          </div>
        }
      </div>
    </div>
  </ng-container>
  <ng-container modal-footer>
    <ax-ui-button (buttonClick)="changeWidgetOrderModal.close(); saveOrderChanges()"
                  [disabled]="false"
                  [text]="'SPEICHERN' | translate" />
    <ax-ui-button (click)="changeWidgetOrderModal.close();"
                  [text]="'SCHLIESSEN' | translate"
                  variant="tertiary" />
  </ng-container>
</ax-ui-modal>
