import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'highlight',
    standalone: true,
})
export class HighlightPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: string | undefined | null, textToHighlight: string | undefined): SafeHtml {
    if (!value) {
      return '-';
    }
    if (!textToHighlight) {
      return value;
    }
    const splitBySpace = textToHighlight.split(' ').filter(word => word !== '');
    let highlighted = value;
    for (const word of splitBySpace) {
      const regex = new RegExp(`(${word})(?![^<]*?>)`, 'gi');
      highlighted = highlighted.replace(regex, match => `<span class="highlighted">${match}</span>`);
    }
    return this.sanitizer.bypassSecurityTrustHtml(highlighted);
  }
}
