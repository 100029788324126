/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateEventRegistrationOptionDto } from '../models/ax-create-event-registration-option-dto';
import { AxCreateEventRegistrationOptionGroupDto } from '../models/ax-create-event-registration-option-group-dto';
import { AxEventRegistrationOption } from '../models/ax-event-registration-option';
import { AxEventRegistrationOptionGroup } from '../models/ax-event-registration-option-group';
import { AxUpdateEventRegistrationOptionDto } from '../models/ax-update-event-registration-option-dto';
import { AxUpdateEventRegistrationOptionGroupDto } from '../models/ax-update-event-registration-option-group-dto';

@Injectable({ providedIn: 'root' })
export class AxEventRegistrationOptionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `eventRegistrationOptionsControllerCreateOption()` */
  static readonly EventRegistrationOptionsControllerCreateOptionPath = '/v1/event-registration-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventRegistrationOptionsControllerCreateOption()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventRegistrationOptionsControllerCreateOption$Response(
    params: {
      body: AxCreateEventRegistrationOptionDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEventRegistrationOption>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventRegistrationOptionsService.EventRegistrationOptionsControllerCreateOptionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEventRegistrationOption>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventRegistrationOptionsControllerCreateOption$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventRegistrationOptionsControllerCreateOption(
    params: {
      body: AxCreateEventRegistrationOptionDto
    },
    context?: HttpContext
  ): Observable<AxEventRegistrationOption> {
    return this.eventRegistrationOptionsControllerCreateOption$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEventRegistrationOption>): AxEventRegistrationOption => r.body)
    );
  }

  /** Path part for operation `eventRegistrationOptionsControllerCreateOptionGroup()` */
  static readonly EventRegistrationOptionsControllerCreateOptionGroupPath = '/v1/event-registration-options/group';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventRegistrationOptionsControllerCreateOptionGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventRegistrationOptionsControllerCreateOptionGroup$Response(
    params: {
      body: AxCreateEventRegistrationOptionGroupDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEventRegistrationOptionGroup>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventRegistrationOptionsService.EventRegistrationOptionsControllerCreateOptionGroupPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEventRegistrationOptionGroup>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventRegistrationOptionsControllerCreateOptionGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventRegistrationOptionsControllerCreateOptionGroup(
    params: {
      body: AxCreateEventRegistrationOptionGroupDto
    },
    context?: HttpContext
  ): Observable<AxEventRegistrationOptionGroup> {
    return this.eventRegistrationOptionsControllerCreateOptionGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEventRegistrationOptionGroup>): AxEventRegistrationOptionGroup => r.body)
    );
  }

  /** Path part for operation `eventRegistrationOptionsControllerDeleteOption()` */
  static readonly EventRegistrationOptionsControllerDeleteOptionPath = '/v1/event-registration-options/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventRegistrationOptionsControllerDeleteOption()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventRegistrationOptionsControllerDeleteOption$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventRegistrationOptionsService.EventRegistrationOptionsControllerDeleteOptionPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventRegistrationOptionsControllerDeleteOption$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventRegistrationOptionsControllerDeleteOption(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.eventRegistrationOptionsControllerDeleteOption$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `eventRegistrationOptionsControllerUpdateOption()` */
  static readonly EventRegistrationOptionsControllerUpdateOptionPath = '/v1/event-registration-options/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventRegistrationOptionsControllerUpdateOption()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventRegistrationOptionsControllerUpdateOption$Response(
    params: {
      id: number;
      body: AxUpdateEventRegistrationOptionDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventRegistrationOptionsService.EventRegistrationOptionsControllerUpdateOptionPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventRegistrationOptionsControllerUpdateOption$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventRegistrationOptionsControllerUpdateOption(
    params: {
      id: number;
      body: AxUpdateEventRegistrationOptionDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.eventRegistrationOptionsControllerUpdateOption$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `eventRegistrationOptionsControllerDeleteOptionGroup()` */
  static readonly EventRegistrationOptionsControllerDeleteOptionGroupPath = '/v1/event-registration-options/group/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventRegistrationOptionsControllerDeleteOptionGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventRegistrationOptionsControllerDeleteOptionGroup$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventRegistrationOptionsService.EventRegistrationOptionsControllerDeleteOptionGroupPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventRegistrationOptionsControllerDeleteOptionGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventRegistrationOptionsControllerDeleteOptionGroup(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.eventRegistrationOptionsControllerDeleteOptionGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `eventRegistrationOptionsControllerUpdateOptionGroup()` */
  static readonly EventRegistrationOptionsControllerUpdateOptionGroupPath = '/v1/event-registration-options/group/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventRegistrationOptionsControllerUpdateOptionGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventRegistrationOptionsControllerUpdateOptionGroup$Response(
    params: {
      id: number;
      body: AxUpdateEventRegistrationOptionGroupDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventRegistrationOptionsService.EventRegistrationOptionsControllerUpdateOptionGroupPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventRegistrationOptionsControllerUpdateOptionGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventRegistrationOptionsControllerUpdateOptionGroup(
    params: {
      id: number;
      body: AxUpdateEventRegistrationOptionGroupDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.eventRegistrationOptionsControllerUpdateOptionGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
