import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FavoritesStateModel } from './favorites-state.model';
import { FavoritesStateToggleFavorite } from './favorites.actions';
import { NavigationItemInterface } from '@axova-frontend-monorepo/axova-commons';

const DEFAULT_STATE: FavoritesStateModel = {
  favorites: [],
};

@State<FavoritesStateModel>({
  name: 'ax_state__favorites',
  defaults: DEFAULT_STATE,
})
@Injectable()
export class FavoritesState {

  @Selector()
  static favorites(state: FavoritesStateModel): NavigationItemInterface[] {
    return state.favorites;
  }

  @Action(FavoritesStateToggleFavorite)
  FavoritesStateToggleFavorite(ctx: StateContext<FavoritesStateModel>, action: FavoritesStateToggleFavorite) {
    const favorites = JSON.parse(JSON.stringify(ctx.getState().favorites)) as NavigationItemInterface[];
    const index = favorites.findIndex(favorite => favorite.route === action.favorite.route);
    if (index === -1) {
      favorites.push(action.favorite);
      ctx.patchState({ favorites: favorites });
    } else {
      favorites.splice(index, 1);
      ctx.patchState({ favorites: favorites });
    }
  }
}
