<ax-ui-widget-base [route]="route"
                   iconName="currency_exchange"
                   title="{{'VERKAUFTE_ANLAGEN' | translate}}">
  <div class="ax-ui-widget-verkaufte-anlagen">
    <div class="fade-in">
      <div class="ax-ui-widget-verkaufte-anlagen__graph">
        @if (showChart) {
          <ax-ui-chart-column
            [chartSeries]="columnChartSeries"
            [xAxisLabels]="columnChartxAxisLabels" />
        }
      </div>
      @if (!showLoading) {
        <div class="ax-ui-widget-verkaufte-anlagen__totals">
          <div class="ax-info-label">
            <small>{{ 'TOTAL' | translate }} {{ dayjs().format('MMMM') }}</small>
            <h3>{{ salesForPv?.amountThisMonth }}</h3>
          </div>
          <div class="ax-info-label">
            <small>{{ 'TOTAL' | translate }} {{ dayjs().year() }}</small>
            <h3>{{ salesForPv?.amountThisYear }}</h3>
          </div>
          <div class="ax-info-label">
            <small>{{ 'TOTAL_ALLZEIT' | translate }}</small>
            <h3>{{ salesForPv?.amountOverall }}</h3>
          </div>
        </div>
      }
    </div>
    @if (showLoading) {
      <ax-ui-loading-spinner
        loadingState="loading" />
    }
  </div>
</ax-ui-widget-base>
