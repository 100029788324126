export type FilterOperator = '$eq' | '$not:$null' | '$not' | '$null' | '$in' | '$gt' | '$gte' | '$lt' | '$lte' | '$btw' | '$ilike' | '$sw' | '$contains';

export interface DropdownOption {
  value: any;
  label: string;
  name?: string;
  iconName?: string;
  operator?: FilterOperator;
  disabled?: boolean;
  selected?: boolean;
  group?: string;
}
