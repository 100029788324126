import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[axUiAlertDialogs]',
    standalone: true,
})
export class AlertDialogsDirective {
  constructor(
    public viewContainerRef: ViewContainerRef,
  ) {
  }
}
