
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getUpdateEventInvitationOrganisatorDtoFormGroup() {
return new FormGroup({
    declinedStatus: new FormControl<any>(undefined),
    examResult: new FormControl<any>(undefined),
})
;
}
      