import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { dropDownAlignment, DropdownListComponent } from '../dropdown-list/dropdown-list.component';
import { DropdownOption, FilterNamesEnum, LoggerService } from '@axova-frontend-monorepo/axova-commons';
import { AxUserconfigurationsV2Service } from '@axova-frontend-monorepo/axova-rest-api';
import { lastValueFrom } from 'rxjs';
import { Store } from '@ngxs/store';
import { FilterState, FilterStateSetFilterOptions } from '@axova-frontend-monorepo/axova-state';

import { LabelComponent } from '../label/label.component';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'ax-ui-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  standalone: true,
  imports: [
    LabelComponent,
    ButtonComponent,
    TranslateModule,
    DropdownListComponent
  ]
})
export class FilterComponent implements OnChanges {
  @ViewChild('filterDropdown') dropdown!: DropdownListComponent;

  @Input({ required: true }) filterDropdownOptions!: DropdownOption[];
  @Input({ required: true }) filterName!: FilterNamesEnum;
  @Input() storageLocation: 'api' | 'state' = 'api';
  @Input() allowOnlyOneActiveFilter = false;
  @Input() withCheckboxes = true;
  @Input() smallButton = false;
  @Input() disabled = false;
  @Input() filterTitle = '';

  @Output() selectedFiltersChange: EventEmitter<DropdownOption[]> = new EventEmitter<DropdownOption[]>();
  @Output() initialFilterEmit: EventEmitter<DropdownOption[]> = new EventEmitter<DropdownOption[]>();

  public selectedValue: any;
  public selectedFilters: DropdownOption[] = [];
  public dropDownAlignment: dropDownAlignment = 'bottom';
  public selectedFiltersButtonText = this.translateService.instant('FILTER');
  private optionsOriginalOrder!: DropdownOption[];
  private selectedFiltersEmpty = false;
  private stateFilterOptions: DropdownOption[] = [];
  private initialFilterEmitDone = false;
  private onSelectedCheckboxOptionsChangeEmitTriggered = false;


  constructor(
    private readonly translateService: TranslateService,
    private readonly elementRef: ElementRef,
    private readonly axUserconfigurationsV2Service: AxUserconfigurationsV2Service,
    private readonly store: Store
  ) {
  }

  @HostListener('document:click', ['$event.target'])
  public onDocumentClick(targetElement: HTMLElement): void {
    if (this.dropdown) {
      const clickedInside = this.elementRef.nativeElement.contains(targetElement);
      if (!clickedInside && this.dropdown.dropDownOpen) {
        this.dropdown.dropDownOpen = false;
        if (this.withCheckboxes) {
          this.dropdown.resetSelection();
          this.filterDropdownOptions = this.dropdown.filteredOptions;
          this.setSelectedFilters();
          this.setSelectedFiltersButtonText();
        }
      }
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['filterDropdownOptions'] && changes['filterDropdownOptions'].currentValue) {
      if (this.onSelectedCheckboxOptionsChangeEmitTriggered) {
        this.onSelectedCheckboxOptionsChangeEmitTriggered = false;
        return;
      }
      let emitChanges = false;
      await this.loadExistingFilterConfiguration();

      // if options from parent are empty, it has most likely to do with the API,
      // therefore state should not be updated
      if (this.filterDropdownOptions.length === 0) {
        return;
      }

      // check if options from parent input are different from the stored options in the state (e.g. introduction of new options or new properties in the DropdownOption model)
      // and if selected values are different to assure the user's selected options are kept correctly in the state
      const comparisonResult = this.deepCompareArrayOptionsForChangesOrSelectedDiffers(this.filterDropdownOptions, this.stateFilterOptions);
      if (this.stateFilterOptions.length === 0 || comparisonResult.hasChanges) {
        // detected options changes from the parent component, update state
        // Merge 'selected' status from state into incoming parent options
        this.mergeSelectedStatus(this.filterDropdownOptions, this.stateFilterOptions);
        this.saveNewFilterConfiguration().catch();
        // make a deep copy to solve object read only problem
        this.filterDropdownOptions = JSON.parse(JSON.stringify(this.filterDropdownOptions));

        if (comparisonResult.hasChanges || comparisonResult.selectedDiffers) {
          emitChanges = true;
        }
      } else if (comparisonResult.selectedDiffers) {
        // no options changes from the parent component, but selected options defer from saved options in state
        this.filterDropdownOptions = JSON.parse(JSON.stringify(this.stateFilterOptions));
        emitChanges = true;
      }
      this.filterDropdownOptions = JSON.parse(JSON.stringify(this.filterDropdownOptions));
      this.optionsOriginalOrder = [...this.filterDropdownOptions];
      this.selectedFilters = [];
      this.filterDropdownOptions.forEach(option => {
        option.selected = option.selected === undefined ? false : option.selected;
        if (option.selected) {
          this.addActiveFilter(option, true);
        }
      });
      if (!this.withCheckboxes) {
        this.selectedFilters.forEach(selectedOption => {
          const index = this.filterDropdownOptions.indexOf(selectedOption);
          if (index > -1) {
            this.filterDropdownOptions.splice(index, 1);
          }
        });
      }
      this.setSelectedFiltersButtonText();

      if (emitChanges || !this.initialFilterEmitDone) {
        // use setTimeout to prevent ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
          this.selectedFiltersChange.emit(this.filterDropdownOptions);
          // initially emit filters at least once to trigger data loading in parent component
          // (necessary, when there are no differences between stateFilterOptions and passed options from parent)
          if (!this.initialFilterEmitDone) {
            this.initialFilterEmitDone = true;
          }
        });
      }
    }
  }

  public addActiveFilter(dropdownOption: DropdownOption, skipEmit = false) {
    if (this.allowOnlyOneActiveFilter) {
      this.selectedFilters.forEach(filter => {
        this.removeFilter(filter);
      });
    }
    const option = this.filterDropdownOptions.find(option => option.label === dropdownOption.label);
    if (option) {
      this.selectedFilters.push(option);

      // don't alter the option array when using checkboxes
      if (this.withCheckboxes) {
        option.selected = true;
        this.setSelectedFiltersButtonText();
        return;
      }

      if (!skipEmit) {
        this.selectedFiltersChange.emit(JSON.parse(JSON.stringify(this.filterDropdownOptions)));
        this.filterDropdownOptions.splice(this.filterDropdownOptions.indexOf(option), 1);
      }
      this.selectedValue = null;

      if (this.filterDropdownOptions.length === 0) {
        this.selectedFiltersEmpty = true;
        this.filterDropdownOptions.push({
          label: this.translateService.instant('KEINE_WEITEREN_FILTER'),
          value: this.translateService.instant('KEINE_WEITEREN_FILTER'),
          disabled: true
        });
      }
      this.saveNewFilterConfiguration().catch();
    }
  }

  public removeFilter(filter: DropdownOption) {
    if (this.selectedFiltersEmpty) {
      this.filterDropdownOptions = [];
      this.selectedFiltersEmpty = false;
    }
    // remove filter
    this.selectedFilters.splice(this.selectedFilters.indexOf(filter), 1);

    // don't alter the option array when using checkboxes
    if (this.withCheckboxes) {
      this.filterDropdownOptions.find(option => option.label === filter.label)!.selected = false;
      this.setSelectedFiltersButtonText();
      return;
    }

    // Find the original index of the filter in optionsOriginalOrder
    const filterOriginalIndex = this.optionsOriginalOrder.indexOf(filter);
    // Re-add the filter to dropdownOptions at its original position
    this.filterDropdownOptions.splice(filterOriginalIndex, 0, filter);
    this.selectedFiltersChange.emit(JSON.parse(JSON.stringify(this.filterDropdownOptions)));
    this.saveNewFilterConfiguration().catch();
  }

  public onSelectedCheckboxOptionsChange() {
    this.saveNewFilterConfiguration().catch();
    this.onSelectedCheckboxOptionsChangeEmitTriggered = true;
    this.selectedFiltersChange.emit(this.filterDropdownOptions);
  }

  private setSelectedFiltersButtonText() {
    if (this.selectedFilters.length > 0) {
      this.selectedFiltersButtonText = this.selectedFilters[0].label;
    } else {
      this.selectedFiltersButtonText = this.translateService.instant(this.filterTitle || 'FILTER');
    }
  }

  private setSelectedFilters() {
    // reset selected filters
    this.selectedFilters = [];
    // populate with new values
    this.filterDropdownOptions.forEach(option => {
      if (option.selected) {
        this.selectedFilters.push(option);
      }
    });
  }

  private async loadExistingFilterConfiguration() {
    if (this.storageLocation === 'api') {
      try {
        const userconfiguration = await lastValueFrom(this.axUserconfigurationsV2Service.userconfigurationsControllerGetMyUseronfigurationByKey({
          configurationKey: this.filterName
        }));
        if (userconfiguration && userconfiguration.configurationValue) {
          this.stateFilterOptions = JSON.parse(userconfiguration.configurationValue);
        }
      } catch (noExistingFilterstateFoundException) {
        LoggerService.ERROR(this, 'noExistingFilterstateFoundException', noExistingFilterstateFoundException);
      }
    } else {
      this.stateFilterOptions = this.store.selectSnapshot(FilterState.singleFilterOptions(this.filterName));
    }
  }

  private async saveNewFilterConfiguration() {
    if (this.storageLocation === 'api') {
      try {
        await lastValueFrom(this.axUserconfigurationsV2Service.userconfigurationsControllerCreateOrUpdate({
          body: {
            configurationKey: this.filterName,
            configurationValue: JSON.stringify(this.filterDropdownOptions)
          }
        }));
      } catch (filterNotStoreException) {
        LoggerService.ERROR(this, 'filterNotStoreException', filterNotStoreException);
      }
    } else {
      this.store.dispatch(new FilterStateSetFilterOptions({
        name: this.filterName,
        options: this.filterDropdownOptions
      }));
    }
  }

  private deepCompareObjects(obj1: any, obj2: any): boolean {
    // Ensure both objects have a 'group' property for comparison
    if (!Object.prototype.hasOwnProperty.call(obj1, 'group')) obj1.group = undefined;
    if (!Object.prototype.hasOwnProperty.call(obj2, 'group')) obj2.group = undefined;

    if (obj1 === obj2) return true;
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) return false;

    const keys1 = Object.keys(obj1).filter(key => key !== 'selected');
    const keys2 = Object.keys(obj2).filter(key => key !== 'selected');

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (!keys2.includes(key)) return false;
      if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        if (!this.deepCompareObjects(obj1[key], obj2[key])) return false;
      } else if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }


  private deepCompareArrayOptionsForChangesOrSelectedDiffers(array1: any[], array2: any[]): {
    hasChanges: boolean;
    selectedDiffers: boolean
  } {
    const result = { hasChanges: false, selectedDiffers: false };

    if (array1.length !== array2.length) {
      result.hasChanges = true;
      return result;
    }

    for (let i = 0; i < array1.length; i++) {
      const obj1 = array1[i];
      const obj2 = array2[i];

      // Check for selected property difference
      const selected1 = obj1.selected === undefined ? false : obj1.selected;
      const selected2 = obj2.selected === undefined ? false : obj2.selected;
      if (selected1 !== selected2) {
        result.selectedDiffers = true;
      }

      // Exclude 'selected' property for deep comparison
      const { selected: _, ...obj1WithoutSelected } = obj1;
      const { selected: __, ...obj2WithoutSelected } = obj2;

      if (!this.deepCompareObjects(obj1WithoutSelected, obj2WithoutSelected)) {
        result.hasChanges = true;
        break; // Found a change, no need to check further
      }
    }

    return result;
  }

  private mergeSelectedStatus(incomingOptions: DropdownOption[], currentOptions: DropdownOption[]) {
    incomingOptions.forEach(option => {
      // Find a matching option in currentOptions based on unique identifier value
      const match = currentOptions.find(currentOption => currentOption.value === option.value);
      if (match) {
        option.selected = match.selected;
      }
      // If there's no matching 'group' property, add it as undefined
      if (!Object.prototype.hasOwnProperty.call(option, 'group')) {
        option.group = undefined;
      }
    });
  }
}
