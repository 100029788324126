
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getUpdateMontageplanBlockDatesDtoFormGroup() {
return new FormGroup({
    id: new FormControl<number | undefined>(undefined, [Validators.required]),
    blockType: new FormControl<any>(undefined, [Validators.required]),
    teamId: new FormControl<number | undefined>(undefined, [Validators.required]),
    installationStart: new FormControl<string | undefined>('', [Validators.required]),
    installationEnd: new FormControl<string | undefined>('', [Validators.required]),
    comment: new FormControl<string | undefined>(''),
})
;
}
      