import { ChangeDetectionStrategy, Component, HostBinding, Inject, InjectionToken, Input, TemplateRef } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

export type TooltipData = string | TemplateRef<void>;
export const TOOLTIP_DATA = new InjectionToken<TooltipData>('Data to display in tooltip');


@Component({
    selector: 'ax-ui-tooltip-container',
    templateUrl: './tooltip-container.component.html',
    styleUrls: ['./tooltip-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgTemplateOutlet],
})
export class TooltipContainerComponent {
  @Input() maxWidth: number | undefined;

  @HostBinding('style.maxWidth')
  get styleMaxWidth(): string | null {
    return this.maxWidth !== undefined ? `${this.maxWidth}px` : null;
  }

  get asString(): string | false {
    return typeof this.tooltipData === 'string' ? this.tooltipData : false;
  }

  get asTemplate(): TemplateRef<void> | false {
    return this.tooltipData instanceof TemplateRef ? this.tooltipData : false;
  }

  constructor(@Inject(TOOLTIP_DATA) public tooltipData: TooltipData) {
  }
}
