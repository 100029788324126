/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateDocumentV2Dto } from '../models/ax-create-document-v-2-dto';
import { AxDocument } from '../models/ax-document';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxSendDocumentsByEmailV2Dto } from '../models/ax-send-documents-by-email-v-2-dto';
import { AxUpdateDocumentV2Dto } from '../models/ax-update-document-v-2-dto';

@Injectable({ providedIn: 'root' })
export class AxDocumentsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `documentsControllerFindAllPaginated()` */
  static readonly DocumentsControllerFindAllPaginatedPath = '/v2/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerFindAllPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by folder query param.
     *           <p>
     *              <b>Format: </b> filter.folder={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.folder=$not:$like:John Doe&filter.folder=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.folder'?: Array<string>;

    /**
     * Filter by assignedTo query param.
     *           <p>
     *              <b>Format: </b> filter.assignedTo={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.assignedTo=$not:$like:John Doe&filter.assignedTo=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.assignedTo'?: Array<string>;

    /**
     * Filter by processingStatus query param.
     *           <p>
     *              <b>Format: </b> filter.processingStatus={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.processingStatus=$not:$like:John Doe&filter.processingStatus=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li>
     * <li>$in</li></ul>
     */
      'filter.processingStatus'?: Array<string>;

    /**
     * Filter by userId query param.
     *           <p>
     *              <b>Format: </b> filter.userId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.userId=$not:$like:John Doe&filter.userId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.userId'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> upload:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      sortBy?: Array<string>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      searchBy?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxDocument>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'folder'?: (string | Array<string>);
'assignedTo'?: (string | Array<string>);
'processingStatus'?: (string | Array<string>);
'userId'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumentsV2Service.DocumentsControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.folder', params['filter.folder'], {});
      rb.query('filter.assignedTo', params['filter.assignedTo'], {});
      rb.query('filter.processingStatus', params['filter.processingStatus'], {});
      rb.query('filter.userId', params['filter.userId'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxDocument>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'folder'?: (string | Array<string>);
        'assignedTo'?: (string | Array<string>);
        'processingStatus'?: (string | Array<string>);
        'userId'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentsControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerFindAllPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by folder query param.
     *           <p>
     *              <b>Format: </b> filter.folder={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.folder=$not:$like:John Doe&filter.folder=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.folder'?: Array<string>;

    /**
     * Filter by assignedTo query param.
     *           <p>
     *              <b>Format: </b> filter.assignedTo={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.assignedTo=$not:$like:John Doe&filter.assignedTo=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.assignedTo'?: Array<string>;

    /**
     * Filter by processingStatus query param.
     *           <p>
     *              <b>Format: </b> filter.processingStatus={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.processingStatus=$not:$like:John Doe&filter.processingStatus=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li>
     * <li>$in</li></ul>
     */
      'filter.processingStatus'?: Array<string>;

    /**
     * Filter by userId query param.
     *           <p>
     *              <b>Format: </b> filter.userId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.userId=$not:$like:John Doe&filter.userId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.userId'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> upload:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      sortBy?: Array<string>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      searchBy?: string;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxDocument>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'folder'?: (string | Array<string>);
'assignedTo'?: (string | Array<string>);
'processingStatus'?: (string | Array<string>);
'userId'?: (string | Array<string>);
};
};
}> {
    return this.documentsControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxDocument>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'folder'?: (string | Array<string>);
'assignedTo'?: (string | Array<string>);
'processingStatus'?: (string | Array<string>);
'userId'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxDocument>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'folder'?: (string | Array<string>);
'assignedTo'?: (string | Array<string>);
'processingStatus'?: (string | Array<string>);
'userId'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `documentsControllerUploadFile_1()` */
  static readonly DocumentsControllerUploadFile_1Path = '/v2/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsControllerUploadFile_1()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  documentsControllerUploadFile_1$Response(
    params: {
      body: AxCreateDocumentV2Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxDocument>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumentsV2Service.DocumentsControllerUploadFile_1Path, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxDocument>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentsControllerUploadFile_1$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  documentsControllerUploadFile_1(
    params: {
      body: AxCreateDocumentV2Dto
    },
    context?: HttpContext
  ): Observable<AxDocument> {
    return this.documentsControllerUploadFile_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxDocument>): AxDocument => r.body)
    );
  }

  /** Path part for operation `documentsControllerFindByUserId()` */
  static readonly DocumentsControllerFindByUserIdPath = '/v2/documents/byUserId/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsControllerFindByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerFindByUserId$Response(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDocument>>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumentsV2Service.DocumentsControllerFindByUserIdPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDocument>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentsControllerFindByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerFindByUserId(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<Array<AxDocument>> {
    return this.documentsControllerFindByUserId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDocument>>): Array<AxDocument> => r.body)
    );
  }

  /** Path part for operation `documentsControllerView()` */
  static readonly DocumentsControllerViewPath = '/v2/documents/document/{fileRequestId}/{fileName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsControllerView()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerView$Response(
    params: {
      fileRequestId: string;
      fileName: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumentsV2Service.DocumentsControllerViewPath, 'get');
    if (params) {
      rb.path('fileRequestId', params.fileRequestId, {});
      rb.path('fileName', params.fileName, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentsControllerView$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerView(
    params: {
      fileRequestId: string;
      fileName: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.documentsControllerView$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `documentsControllerDelete()` */
  static readonly DocumentsControllerDeletePath = '/v2/documents/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumentsV2Service.DocumentsControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentsControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.documentsControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `documentsControllerUpdate()` */
  static readonly DocumentsControllerUpdatePath = '/v2/documents/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateDocumentV2Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxDocument>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumentsV2Service.DocumentsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxDocument>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateDocumentV2Dto
    },
    context?: HttpContext
  ): Observable<AxDocument> {
    return this.documentsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxDocument>): AxDocument => r.body)
    );
  }

  /** Path part for operation `documentsControllerSendDocumentsByEmail()` */
  static readonly DocumentsControllerSendDocumentsByEmailPath = '/v2/documents/sendDocumentsByEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsControllerSendDocumentsByEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsControllerSendDocumentsByEmail$Response(
    params: {
      body: AxSendDocumentsByEmailV2Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumentsV2Service.DocumentsControllerSendDocumentsByEmailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentsControllerSendDocumentsByEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsControllerSendDocumentsByEmail(
    params: {
      body: AxSendDocumentsByEmailV2Dto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.documentsControllerSendDocumentsByEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
