import { HttpClient } from '@angular/common/http';
import { IModuleTranslation, IModuleTranslationOptions, ModuleTranslateLoader } from '@larscom/ngx-translate-module-loader';
import { InjectionToken } from '@angular/core';

export const TRANSLATE_FACTORY_MODULES = new InjectionToken<IModuleTranslation[]>('TRANSLATE_FACTORY_MODULES');


function mergeIntoBase(base: Record<string, any>, newObj: Record<string, any>): Record<string, any> {
  for (const key in newObj) {
    if (newObj.hasOwnProperty(key)) {
      if (key.includes('.')) {
        const parts = key.split('.');
        const lastPart = parts.pop()!;
        let temp = base;

        for (const part of parts) {
          if (!temp[part] || typeof temp[part] !== 'object') {
            temp[part] = {};
          }
          temp = temp[part];
        }

        if (temp[lastPart] && typeof temp[lastPart] === 'object') {
          // Merge if an object already exists
          Object.assign(temp[lastPart], newObj[key]);
        } else {
          temp[lastPart] = newObj[key];
        }

      } else {
        if (typeof base[key] === 'object' && typeof newObj[key] === 'object') {
          // Merge at root level if both are objects
          Object.assign(base[key], newObj[key]);
        } else {
          base[key] = newObj[key];
        }
      }
    }
  }

  return base;
}

export function translateHttpModuleLoaderFactory(http: HttpClient, modules: IModuleTranslation[]) {
  const baseTranslateUrl = './assets/i18n';
  const mergedModules = [
    {
      baseTranslateUrl: baseTranslateUrl,
    },
    ...modules,
  ];
  const options: IModuleTranslationOptions = {
    modules: mergedModules,
    translateMerger: translationModules => {
      let translations = {};
      for (const translationModule of translationModules) {
        translations = mergeIntoBase(translations, translationModule);
      }
      return translations;
    },
  };

  return new ModuleTranslateLoader(http, options);
}
