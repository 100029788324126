import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Widget, WidgetGridConfig, WidgetNamesEnum } from '@axova-frontend-monorepo/axova-commons';
import { ModalComponent } from '../modal/modal.component';
import { CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem, CdkDropListGroup, CdkDrag } from '@angular/cdk/drag-drop';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../button/button.component';
import { IconComponent } from '../icon/icon.component';
import { WidgetVerkaufteAnlagenComponent } from '../widgets/widget-verkaufte-anlagen/widget-verkaufte-anlagen.component';
import { WidgetGeburtstageComponent } from '../widgets/widget-geburtstage/widget-geburtstage.component';
import { WidgetMitteilungenComponent } from '../widgets/widget-mitteilungen/widget-mitteilungen.component';
import { WidgetNotizenComponent } from '../widgets/widget-notizen/widget-notizen.component';
import { WidgetArbeitszeitComponent } from '../widgets/widget-arbeitszeit/widget-arbeitszeit.component';

@Component({
    selector: 'ax-ui-widget-grid',
    templateUrl: './widget-grid.component.html',
    styleUrls: ['./widget-grid.component.scss'],
    standalone: true,
    imports: [
        WidgetArbeitszeitComponent,
        WidgetNotizenComponent,
        WidgetMitteilungenComponent,
        WidgetGeburtstageComponent,
        WidgetVerkaufteAnlagenComponent,
        ModalComponent,
        CdkDropListGroup,
        CdkDropList,
        CdkDrag,
        IconComponent,
        ButtonComponent,
        TranslateModule,
    ],
})
export class WidgetGridComponent implements OnInit, AfterViewInit {
  @ViewChild('changeWidgetOrderModal') modal!: ModalComponent;
  @ViewChild('activeList') activeList!: CdkDropList;
  @ViewChild('inactiveList') inactiveList!: CdkDropList;

  @Input({ required: true }) widgetsConfig!: WidgetGridConfig;
  @Output() widgetOrderChanged: EventEmitter<WidgetGridConfig> = new EventEmitter<WidgetGridConfig>();

  protected readonly WidgetNamesEnum = WidgetNamesEnum;
  public cdkDropListOrientation: 'horizontal' | 'vertical' = 'horizontal';
  public widgetsConfigChanged: WidgetGridConfig = {
    activeWidgets: [],
    inactiveWidgets: [],
  };

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit() {
    this.checkWidgetOrder();
    this.breakpointObserver.observe('(max-width: 767px)').subscribe(result => {
      if (result.matches) {
        this.cdkDropListOrientation = 'vertical';
      } else {
        this.cdkDropListOrientation = 'horizontal';
      }
    });
  }

  ngAfterViewInit() {
    // Connect the two drop lists to allow items to be transferred between them
    this.activeList.connectedTo = [this.inactiveList];
    this.inactiveList.connectedTo = [this.activeList];
  }

  public changeWidgetOrder() {
    // assign deep copy
    this.widgetsConfigChanged = JSON.parse(JSON.stringify(this.widgetsConfig));
    this.modal.open();
  }

  public onDrop(event: CdkDragDrop<Widget[], any>): void {
    if (event.previousContainer === event.container) {
      // Reordering inside the same list
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    } else {
      // Moving item between lists
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  public saveOrderChanges() {
    this.setChangedWidgetOrderPositions();
    this.widgetsConfig = this.widgetsConfigChanged;
    this.widgetOrderChanged.emit(this.widgetsConfig);
  }

  public getWidgetIconName(name: WidgetNamesEnum): string {
    switch (name) {
      case WidgetNamesEnum.ARBEITSZEIT:
        return 'more_time';
      case WidgetNamesEnum.NOTIZEN:
        return 'sticky_note_2';
      case WidgetNamesEnum.MITTEILUNGEN:
        return 'breaking_news';
      case WidgetNamesEnum.GEBURTSTAGE:
        return 'cake';
      case WidgetNamesEnum.VERKAUFTE_ANLAGEN:
        return 'currency_exchange';
    }
  }

  private checkWidgetOrder() {
    this.widgetsConfig.activeWidgets.sort((a, b) => {
      if (a.gridPosition === undefined && b.gridPosition === undefined) {
        return 0; // Both positions are undefined, so they're equal
      }
      if (a.gridPosition === undefined) {
        return 1; // a comes after b
      }
      if (b.gridPosition === undefined) {
        return -1; // b comes after a
      }
      return a.gridPosition - b.gridPosition; // Regular numerical comparison
    });
  }

  private setChangedWidgetOrderPositions() {
    this.widgetsConfigChanged.activeWidgets.forEach((widget, index) => {
      widget.gridPosition = index;
    });
  }
}
