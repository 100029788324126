
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getCreatePhoneDtoFormGroup() {
return new FormGroup({
    contactId: new FormControl<number | undefined>(undefined, [Validators.required]),
    value: new FormControl<string | undefined>('', [Validators.required]),
    note: new FormControl<string | undefined>(undefined),
    phonetypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    standard: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
})
;
}
      