
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getAddOrRemoveDocumentToEventDtoFormGroup() {
return new FormGroup({
    documentId: new FormControl<number | undefined>(undefined, [Validators.required]),
    moveDocumentToVeranstaltungenFolder: new FormControl<boolean | undefined>(false),
})
;
}
      