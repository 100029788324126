
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getRemoveUserDtoFormGroup() {
return new FormGroup({
    userId: new FormControl<number | undefined>(undefined, [Validators.required]),
})
;
}
      