/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxTimerecord } from '../models/ax-timerecord';
import { AxUpdateTimerecordDto } from '../models/ax-update-timerecord-dto';

@Injectable({ providedIn: 'root' })
export class AxTimerecordsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `timerecordsControllerFindById()` */
  static readonly TimerecordsControllerFindByIdPath = '/v2/timerecords/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecordsControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecordsControllerFindById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTimerecord>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecordsV2Service.TimerecordsControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTimerecord>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecordsControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecordsControllerFindById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxTimerecord> {
    return this.timerecordsControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTimerecord>): AxTimerecord => r.body)
    );
  }

  /** Path part for operation `timerecordsControllerUpdate()` */
  static readonly TimerecordsControllerUpdatePath = '/v2/timerecords/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecordsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timerecordsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateTimerecordDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecordsV2Service.TimerecordsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecordsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timerecordsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateTimerecordDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.timerecordsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `timerecordsControllerStartGenerationByUserIdAndYear()` */
  static readonly TimerecordsControllerStartGenerationByUserIdAndYearPath = '/v2/timerecords/startGeneration/byUserId/{userId}/{year}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecordsControllerStartGenerationByUserIdAndYear()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecordsControllerStartGenerationByUserIdAndYear$Response(
    params: {
      userId: number;
      year: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecordsV2Service.TimerecordsControllerStartGenerationByUserIdAndYearPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('year', params.year, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecordsControllerStartGenerationByUserIdAndYear$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecordsControllerStartGenerationByUserIdAndYear(
    params: {
      userId: number;
      year: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.timerecordsControllerStartGenerationByUserIdAndYear$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
