<div class="ax-ui-avatar">
  <div [class]="size"
       [class.full-width]="fullWidth"
       [class.square]="square"
       [class.sunny]="showSunny"
       [ngClass]="status"
       class="ax-ui-avatar__image">
    @if (initials && showText) {
      <div class="ax-ui-avatar__text">
        <h3 class="ax-ui-avatar__text-element">{{ initials }}</h3>
      </div>
    }
    @if (!showText || !initials) {
      <img [alt]="alt || 'Axova Avatar'" [src]="imgSrc">
    }
  </div>
</div>

