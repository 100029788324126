
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateAppDeviceDtoFormGroup() {
return new FormGroup({
    uniqueIdentifier: new FormControl<string | undefined>('', [Validators.required]),
    platform: new FormControl<string | undefined>('', [Validators.required]),
    osVersion: new FormControl<string | undefined>('', [Validators.required]),
    model: new FormControl<string | undefined>('', [Validators.required]),
    appVersion: new FormControl<string | undefined>('', [Validators.required]),
    pushNotificationDto: new FormControl<any>(undefined),
    userId: new FormControl<number | undefined>(undefined),
    contactId: new FormControl<number | undefined>(undefined),
})
;
}
      