<div class="ax-ui-terminassistent-wizard-steps">
  <div class="ax-ui-terminassistent-wizard-steps__content">
    @for (step of wizardStepsNavService.steps; track step; let i = $index) {
      @if (step.isVisible) {
        <div class="step"
             [ngClass]="{'complete': step.isComplete, 'in-progress': step.isActive}">
          <div class="node">
            @if (step.isActive) {
              <ax-ui-icon name="edit" [boldness]="400" />
            }
            @if (step.isComplete) {
              <ax-ui-icon name="check" [boldness]="400" />
            }
          </div>
          <div class="ax-text flex flex-column gap-12">
            <p [class.ax-link--underlined]="step.isActive"><b>{{ step.label }}</b></p>
            <p><small>{{ step.note }}</small></p>
          </div>
        </div>
      }
    }
  </div>
</div>
