/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateCreditorDto } from '../models/ax-create-creditor-dto';
import { AxCreditor } from '../models/ax-creditor';
import { AxUpdateCreditorDto } from '../models/ax-update-creditor-dto';

@Injectable({ providedIn: 'root' })
export class AxCreditorsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `creditorsControllerGetLatest()` */
  static readonly CreditorsControllerGetLatestPath = '/v2/creditors/latest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `creditorsControllerGetLatest()` instead.
   *
   * This method doesn't expect any request body.
   */
  creditorsControllerGetLatest$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxCreditor>> {
    const rb = new RequestBuilder(this.rootUrl, AxCreditorsV2Service.CreditorsControllerGetLatestPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxCreditor>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `creditorsControllerGetLatest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  creditorsControllerGetLatest(
    params?: {
    },
    context?: HttpContext
  ): Observable<AxCreditor> {
    return this.creditorsControllerGetLatest$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxCreditor>): AxCreditor => r.body)
    );
  }

  /** Path part for operation `creditorsControllerGetNewNumber()` */
  static readonly CreditorsControllerGetNewNumberPath = '/v2/creditors/newNumber';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `creditorsControllerGetNewNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  creditorsControllerGetNewNumber$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, AxCreditorsV2Service.CreditorsControllerGetNewNumberPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `creditorsControllerGetNewNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  creditorsControllerGetNewNumber(
    params?: {
    },
    context?: HttpContext
  ): Observable<number> {
    return this.creditorsControllerGetNewNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `creditorsControllerCreate()` */
  static readonly CreditorsControllerCreatePath = '/v2/creditors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `creditorsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  creditorsControllerCreate$Response(
    params: {
      body: AxCreateCreditorDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxCreditor>> {
    const rb = new RequestBuilder(this.rootUrl, AxCreditorsV2Service.CreditorsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxCreditor>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `creditorsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  creditorsControllerCreate(
    params: {
      body: AxCreateCreditorDto
    },
    context?: HttpContext
  ): Observable<AxCreditor> {
    return this.creditorsControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxCreditor>): AxCreditor => r.body)
    );
  }

  /** Path part for operation `creditorsControllerDelete()` */
  static readonly CreditorsControllerDeletePath = '/v2/creditors/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `creditorsControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  creditorsControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxCreditorsV2Service.CreditorsControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `creditorsControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  creditorsControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.creditorsControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `creditorsControllerUpdate()` */
  static readonly CreditorsControllerUpdatePath = '/v2/creditors/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `creditorsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  creditorsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateCreditorDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxCreditor>> {
    const rb = new RequestBuilder(this.rootUrl, AxCreditorsV2Service.CreditorsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxCreditor>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `creditorsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  creditorsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateCreditorDto
    },
    context?: HttpContext
  ): Observable<AxCreditor> {
    return this.creditorsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxCreditor>): AxCreditor => r.body)
    );
  }

}
