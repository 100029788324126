import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AxIsEmployeeService } from './guards/is-employee.guard';
import { AxIsLoggedInService } from './guards/is-logged-in.guard';
import { AxRedirectToDashboardIfIsLoggedInService } from './guards/redirect-to-dashboard-if-is-logged-in.guard';
import { ApiTokenInterceptor } from './services/api-token-interceptor.service';
import { RequiresPermissionService } from './guards/requires-permission.guard';

@NgModule({
  imports: [CommonModule],
  providers: [
    AxIsEmployeeService,
    AxIsLoggedInService,
    AxRedirectToDashboardIfIsLoggedInService,
    ApiTokenInterceptor,
    RequiresPermissionService,
  ],
})
export class AxovaAuthenticationModule {
}
