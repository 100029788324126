
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getCreateTimerecordHolidayDtoFormGroup() {
return new FormGroup({
    name: new FormControl<string | undefined>('', [Validators.required]),
    description: new FormControl<string | undefined>(undefined),
    date: new FormControl<string | undefined>('', [Validators.required]),
    morning: new FormControl<boolean | undefined>(true, [requiredBoolean()]),
    afternoon: new FormControl<boolean | undefined>(true, [requiredBoolean()]),
    businessunitId: new FormControl<number | undefined>(undefined),
    businessdivisionId: new FormControl<number | undefined>(undefined),
    locationId: new FormControl<number | undefined>(undefined),
    timerecordtypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
})
;
}
      