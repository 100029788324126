
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getUpdateUserDtoFormGroup() {
return new FormGroup({
    businessunitId: new FormControl<number | undefined>(undefined, [Validators.required]),
    businessdivisionId: new FormControl<number | undefined>(undefined, [Validators.required]),
    titleId: new FormControl<number | undefined>(undefined),
    firstName: new FormControl<string | undefined>('', [Validators.required]),
    lastName: new FormControl<string | undefined>('', [Validators.required]),
    password: new FormControl<string | undefined>(undefined),
    email: new FormControl<string | undefined>(undefined),
    emailprivate: new FormControl<string | undefined>(undefined),
    mobileprivate: new FormControl<string | undefined>(undefined),
    birthdate: new FormControl<string | undefined>(undefined),
    street: new FormControl<string | undefined>(undefined),
    city: new FormControl<string | undefined>(undefined),
    entrydate: new FormControl<string | undefined>(undefined, [Validators.required]),
    emergencycontact: new FormControl<string | undefined>(undefined),
    phone: new FormControl<string | undefined>(undefined),
    jobtitle: new FormControl<string | undefined>('', [Validators.required]),
    workload: new FormControl<number | undefined>(undefined),
    targetHours: new FormControl<number | undefined>(undefined),
    department: new FormControl<string | undefined>(undefined),
    iaxuser: new FormControl<boolean | undefined>(undefined),
    remarks: new FormControl<string | undefined>(undefined),
    userImageId: new FormControl<number | undefined>(undefined),
    active: new FormControl<boolean | undefined>(true, [requiredBoolean()]),
    supervisorId: new FormControl<number | undefined>(undefined),
    abacusNumber: new FormControl<number | undefined>(undefined),
    showOnWebsite: new FormControl<boolean | undefined>(true, [requiredBoolean()]),
    publicJoblevel: new FormControl<any>(undefined),
    publicJobtitle: new FormControl<string | undefined>(undefined),
    exitdate: new FormControl<string | undefined>(undefined),
    loginPossibleUntil: new FormControl<string | undefined>(undefined),
    dataReviewed: new FormControl<string | undefined>(undefined),
})
;
}
      