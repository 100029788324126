/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxBuildsByContainerIdYearAndMonthDto } from '../models/ax-builds-by-container-id-year-and-month-dto';
import { AxCalculationitem } from '../models/ax-calculationitem';
import { AxDebtor } from '../models/ax-debtor';
import { AxKrankheitUnfallTageDto } from '../models/ax-krankheit-unfall-tage-dto';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxRevenueByContainerIdYearAndMonthDto } from '../models/ax-revenue-by-container-id-year-and-month-dto';
import { AxSalesForPvDto } from '../models/ax-sales-for-pv-dto';
import { AxSpesentageDto } from '../models/ax-spesentage-dto';
import { AxTask } from '../models/ax-task';
import { AxTimerecordBalancesByYearMonthAndUserIdDto } from '../models/ax-timerecord-balances-by-year-month-and-user-id-dto';
import { AxTransformedBankFileDto } from '../models/ax-transformed-bank-file-dto';
import { AxUploadBankFileDto } from '../models/ax-upload-bank-file-dto';
import { AxUseremployment } from '../models/ax-useremployment';
import { AxUseremploymentTypeEnum } from '../models/ax-useremployment-type-enum';
import { AxWegspesenDto } from '../models/ax-wegspesen-dto';

@Injectable({ providedIn: 'root' })
export class AxReportsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `reportsControllerFindSalesByContainerIdYearAndMonth()` */
  static readonly ReportsControllerFindSalesByContainerIdYearAndMonthPath = '/v2/reports/sales/{containertypeId}/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsControllerFindSalesByContainerIdYearAndMonth()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindSalesByContainerIdYearAndMonth$Response(
    params: {
      containertypeId: number;
      year: string;
      month: string;
      withDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxRevenueByContainerIdYearAndMonthDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsControllerFindSalesByContainerIdYearAndMonthPath, 'get');
    if (params) {
      rb.path('containertypeId', params.containertypeId, {});
      rb.path('year', params.year, {});
      rb.path('month', params.month, {});
      rb.query('withDetails', params.withDetails, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxRevenueByContainerIdYearAndMonthDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsControllerFindSalesByContainerIdYearAndMonth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindSalesByContainerIdYearAndMonth(
    params: {
      containertypeId: number;
      year: string;
      month: string;
      withDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<AxRevenueByContainerIdYearAndMonthDto> {
    return this.reportsControllerFindSalesByContainerIdYearAndMonth$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxRevenueByContainerIdYearAndMonthDto>): AxRevenueByContainerIdYearAndMonthDto => r.body)
    );
  }

  /** Path part for operation `reportsControllerFindSalesForPv()` */
  static readonly ReportsControllerFindSalesForPvPath = '/v2/reports/salesForPv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsControllerFindSalesForPv()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindSalesForPv$Response(
    params?: {
      withDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSalesForPvDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsControllerFindSalesForPvPath, 'get');
    if (params) {
      rb.query('withDetails', params.withDetails, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSalesForPvDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsControllerFindSalesForPv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindSalesForPv(
    params?: {
      withDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<AxSalesForPvDto> {
    return this.reportsControllerFindSalesForPv$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSalesForPvDto>): AxSalesForPvDto => r.body)
    );
  }

  /** Path part for operation `reportsControllerFindBuildsByContainerIdYearAndMonth()` */
  static readonly ReportsControllerFindBuildsByContainerIdYearAndMonthPath = '/v2/reports/builds/{containertypeId}/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsControllerFindBuildsByContainerIdYearAndMonth()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindBuildsByContainerIdYearAndMonth$Response(
    params: {
      containertypeId: number;
      year: string;
      month: string;
      withDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxBuildsByContainerIdYearAndMonthDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsControllerFindBuildsByContainerIdYearAndMonthPath, 'get');
    if (params) {
      rb.path('containertypeId', params.containertypeId, {});
      rb.path('year', params.year, {});
      rb.path('month', params.month, {});
      rb.query('withDetails', params.withDetails, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxBuildsByContainerIdYearAndMonthDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsControllerFindBuildsByContainerIdYearAndMonth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindBuildsByContainerIdYearAndMonth(
    params: {
      containertypeId: number;
      year: string;
      month: string;
      withDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<AxBuildsByContainerIdYearAndMonthDto> {
    return this.reportsControllerFindBuildsByContainerIdYearAndMonth$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxBuildsByContainerIdYearAndMonthDto>): AxBuildsByContainerIdYearAndMonthDto => r.body)
    );
  }

  /** Path part for operation `reportsControllerFindCalcutationitemsByStocklocationId()` */
  static readonly ReportsControllerFindCalcutationitemsByStocklocationIdPath = '/v2/reports/calculationitems/byStocklocationId/{stocklocationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsControllerFindCalcutationitemsByStocklocationId()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindCalcutationitemsByStocklocationId$Response(
    params: {
      stocklocationId: number;
      deliveredAt?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxCalculationitem>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsControllerFindCalcutationitemsByStocklocationIdPath, 'get');
    if (params) {
      rb.path('stocklocationId', params.stocklocationId, {});
      rb.query('deliveredAt', params.deliveredAt, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxCalculationitem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsControllerFindCalcutationitemsByStocklocationId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindCalcutationitemsByStocklocationId(
    params: {
      stocklocationId: number;
      deliveredAt?: string;
    },
    context?: HttpContext
  ): Observable<Array<AxCalculationitem>> {
    return this.reportsControllerFindCalcutationitemsByStocklocationId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxCalculationitem>>): Array<AxCalculationitem> => r.body)
    );
  }

  /** Path part for operation `reportsControllerFindMonthlyTimerecordBalancesByDaterangeAndUserId()` */
  static readonly ReportsControllerFindMonthlyTimerecordBalancesByDaterangeAndUserIdPath = '/v2/reports/monthly-timerecord-balances-for-daterange/{fromDate}/{toDate}/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsControllerFindMonthlyTimerecordBalancesByDaterangeAndUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindMonthlyTimerecordBalancesByDaterangeAndUserId$Response(
    params: {
      fromDate: string;
      toDate: string;
      userId: number;
      subtotalDate?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxTimerecordBalancesByYearMonthAndUserIdDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsControllerFindMonthlyTimerecordBalancesByDaterangeAndUserIdPath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
      rb.path('userId', params.userId, {});
      rb.query('subtotalDate', params.subtotalDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxTimerecordBalancesByYearMonthAndUserIdDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsControllerFindMonthlyTimerecordBalancesByDaterangeAndUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindMonthlyTimerecordBalancesByDaterangeAndUserId(
    params: {
      fromDate: string;
      toDate: string;
      userId: number;
      subtotalDate?: string;
    },
    context?: HttpContext
  ): Observable<Array<AxTimerecordBalancesByYearMonthAndUserIdDto>> {
    return this.reportsControllerFindMonthlyTimerecordBalancesByDaterangeAndUserId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxTimerecordBalancesByYearMonthAndUserIdDto>>): Array<AxTimerecordBalancesByYearMonthAndUserIdDto> => r.body)
    );
  }

  /** Path part for operation `reportsLohnlaufControllerFindSpesentageForDaterange()` */
  static readonly ReportsLohnlaufControllerFindSpesentageForDaterangePath = '/v2/reports/lohnlauf/spesentage/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsLohnlaufControllerFindSpesentageForDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsLohnlaufControllerFindSpesentageForDaterange$Response(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxSpesentageDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsLohnlaufControllerFindSpesentageForDaterangePath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxSpesentageDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsLohnlaufControllerFindSpesentageForDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsLohnlaufControllerFindSpesentageForDaterange(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<Array<AxSpesentageDto>> {
    return this.reportsLohnlaufControllerFindSpesentageForDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxSpesentageDto>>): Array<AxSpesentageDto> => r.body)
    );
  }

  /** Path part for operation `reportsLohnlaufControllerFindWegspesenForDaterange()` */
  static readonly ReportsLohnlaufControllerFindWegspesenForDaterangePath = '/v2/reports/lohnlauf/wegspesen/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsLohnlaufControllerFindWegspesenForDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsLohnlaufControllerFindWegspesenForDaterange$Response(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxWegspesenDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsLohnlaufControllerFindWegspesenForDaterangePath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxWegspesenDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsLohnlaufControllerFindWegspesenForDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsLohnlaufControllerFindWegspesenForDaterange(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<Array<AxWegspesenDto>> {
    return this.reportsLohnlaufControllerFindWegspesenForDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxWegspesenDto>>): Array<AxWegspesenDto> => r.body)
    );
  }

  /** Path part for operation `reportsLohnlaufControllerFindKrankheitUnfallTageForDaterange()` */
  static readonly ReportsLohnlaufControllerFindKrankheitUnfallTageForDaterangePath = '/v2/reports/lohnlauf/krankheitunfalltage/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsLohnlaufControllerFindKrankheitUnfallTageForDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsLohnlaufControllerFindKrankheitUnfallTageForDaterange$Response(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxKrankheitUnfallTageDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsLohnlaufControllerFindKrankheitUnfallTageForDaterangePath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxKrankheitUnfallTageDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsLohnlaufControllerFindKrankheitUnfallTageForDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsLohnlaufControllerFindKrankheitUnfallTageForDaterange(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<Array<AxKrankheitUnfallTageDto>> {
    return this.reportsLohnlaufControllerFindKrankheitUnfallTageForDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxKrankheitUnfallTageDto>>): Array<AxKrankheitUnfallTageDto> => r.body)
    );
  }

  /** Path part for operation `reportsLohnlaufControllerFindChangedUseremploymentsForMonth()` */
  static readonly ReportsLohnlaufControllerFindChangedUseremploymentsForMonthPath = '/v2/reports/lohnlauf/changed-useremployments/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsLohnlaufControllerFindChangedUseremploymentsForMonth()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsLohnlaufControllerFindChangedUseremploymentsForMonth$Response(
    params: {
      useremploymentType: AxUseremploymentTypeEnum;
      month: number;
      entriesOnly?: boolean;
      exitsOnly?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxUseremployment>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsLohnlaufControllerFindChangedUseremploymentsForMonthPath, 'get');
    if (params) {
      rb.query('useremploymentType', params.useremploymentType, {});
      rb.path('month', params.month, {});
      rb.query('entriesOnly', params.entriesOnly, {});
      rb.query('exitsOnly', params.exitsOnly, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxUseremployment>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsLohnlaufControllerFindChangedUseremploymentsForMonth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsLohnlaufControllerFindChangedUseremploymentsForMonth(
    params: {
      useremploymentType: AxUseremploymentTypeEnum;
      month: number;
      entriesOnly?: boolean;
      exitsOnly?: boolean;
    },
    context?: HttpContext
  ): Observable<Array<AxUseremployment>> {
    return this.reportsLohnlaufControllerFindChangedUseremploymentsForMonth$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxUseremployment>>): Array<AxUseremployment> => r.body)
    );
  }

  /** Path part for operation `reportsDebitorenabgleichControllerFindDebtorsPaidByDaterange()` */
  static readonly ReportsDebitorenabgleichControllerFindDebtorsPaidByDaterangePath = '/v2/reports/debitorenabgleich/debtors-paid/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsDebitorenabgleichControllerFindDebtorsPaidByDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsDebitorenabgleichControllerFindDebtorsPaidByDaterange$Response(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDebtor>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsDebitorenabgleichControllerFindDebtorsPaidByDaterangePath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDebtor>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsDebitorenabgleichControllerFindDebtorsPaidByDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsDebitorenabgleichControllerFindDebtorsPaidByDaterange(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<Array<AxDebtor>> {
    return this.reportsDebitorenabgleichControllerFindDebtorsPaidByDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDebtor>>): Array<AxDebtor> => r.body)
    );
  }

  /** Path part for operation `reportsDebitorenabgleichControllerFindDebtorsPaidByDaterangeCsv()` */
  static readonly ReportsDebitorenabgleichControllerFindDebtorsPaidByDaterangeCsvPath = '/v2/reports/debitorenabgleich/debtors-paid/{fromDate}/{toDate}/csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsDebitorenabgleichControllerFindDebtorsPaidByDaterangeCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsDebitorenabgleichControllerFindDebtorsPaidByDaterangeCsv$Response(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsDebitorenabgleichControllerFindDebtorsPaidByDaterangeCsvPath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsDebitorenabgleichControllerFindDebtorsPaidByDaterangeCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsDebitorenabgleichControllerFindDebtorsPaidByDaterangeCsv(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.reportsDebitorenabgleichControllerFindDebtorsPaidByDaterangeCsv$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `reportsDebitorenabgleichControllerUploadDebtorsBankImport()` */
  static readonly ReportsDebitorenabgleichControllerUploadDebtorsBankImportPath = '/v2/reports/debitorenabgleich/debtors-bank-import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsDebitorenabgleichControllerUploadDebtorsBankImport()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  reportsDebitorenabgleichControllerUploadDebtorsBankImport$Response(
    params: {
      body: AxUploadBankFileDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxTransformedBankFileDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsDebitorenabgleichControllerUploadDebtorsBankImportPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxTransformedBankFileDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsDebitorenabgleichControllerUploadDebtorsBankImport$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  reportsDebitorenabgleichControllerUploadDebtorsBankImport(
    params: {
      body: AxUploadBankFileDto
    },
    context?: HttpContext
  ): Observable<Array<AxTransformedBankFileDto>> {
    return this.reportsDebitorenabgleichControllerUploadDebtorsBankImport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxTransformedBankFileDto>>): Array<AxTransformedBankFileDto> => r.body)
    );
  }

  /** Path part for operation `reportsJahresendverarbeitungControllerFindKrankheitUnfallTageForYear()` */
  static readonly ReportsJahresendverarbeitungControllerFindKrankheitUnfallTageForYearPath = '/v2/reports/jahresendverarbeitung/krankheitunfalltage/{year}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsJahresendverarbeitungControllerFindKrankheitUnfallTageForYear()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsJahresendverarbeitungControllerFindKrankheitUnfallTageForYear$Response(
    params: {
      year: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxKrankheitUnfallTageDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsJahresendverarbeitungControllerFindKrankheitUnfallTageForYearPath, 'get');
    if (params) {
      rb.path('year', params.year, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxKrankheitUnfallTageDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsJahresendverarbeitungControllerFindKrankheitUnfallTageForYear$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsJahresendverarbeitungControllerFindKrankheitUnfallTageForYear(
    params: {
      year: number;
    },
    context?: HttpContext
  ): Observable<Array<AxKrankheitUnfallTageDto>> {
    return this.reportsJahresendverarbeitungControllerFindKrankheitUnfallTageForYear$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxKrankheitUnfallTageDto>>): Array<AxKrankheitUnfallTageDto> => r.body)
    );
  }

  /** Path part for operation `reportsJahresendverarbeitungControllerFindChangedUseremploymentsForYear()` */
  static readonly ReportsJahresendverarbeitungControllerFindChangedUseremploymentsForYearPath = '/v2/reports/jahresendverarbeitung/changed-useremployments/{year}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsJahresendverarbeitungControllerFindChangedUseremploymentsForYear()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsJahresendverarbeitungControllerFindChangedUseremploymentsForYear$Response(
    params: {
      useremploymentType: AxUseremploymentTypeEnum;
      year: number;
      entriesOnly?: boolean;
      exitsOnly?: boolean;
      holidaysChangeOnly?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxUseremployment>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsJahresendverarbeitungControllerFindChangedUseremploymentsForYearPath, 'get');
    if (params) {
      rb.query('useremploymentType', params.useremploymentType, {});
      rb.path('year', params.year, {});
      rb.query('entriesOnly', params.entriesOnly, {});
      rb.query('exitsOnly', params.exitsOnly, {});
      rb.query('holidaysChangeOnly', params.holidaysChangeOnly, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxUseremployment>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsJahresendverarbeitungControllerFindChangedUseremploymentsForYear$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsJahresendverarbeitungControllerFindChangedUseremploymentsForYear(
    params: {
      useremploymentType: AxUseremploymentTypeEnum;
      year: number;
      entriesOnly?: boolean;
      exitsOnly?: boolean;
      holidaysChangeOnly?: boolean;
    },
    context?: HttpContext
  ): Observable<Array<AxUseremployment>> {
    return this.reportsJahresendverarbeitungControllerFindChangedUseremploymentsForYear$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxUseremployment>>): Array<AxUseremployment> => r.body)
    );
  }

  /** Path part for operation `reportsAusstehendeAufgabenControllerFindAllPaginated()` */
  static readonly ReportsAusstehendeAufgabenControllerFindAllPaginatedPath = '/v2/reports/ausstehende-aufgaben';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsAusstehendeAufgabenControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsAusstehendeAufgabenControllerFindAllPaginated$Response(
    params?: {
      tobNotDoneYet?: boolean;
      tagApproved?: boolean;

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by task.end query param.
     *           <p>
     *              <b>Format: </b> filter.task.end={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.task.end=$not:$like:John Doe&filter.task.end=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.task.end'?: Array<string>;

    /**
     * Filter by task.mandatory query param.
     *           <p>
     *              <b>Format: </b> filter.task.mandatory={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.task.mandatory=$not:$like:John Doe&filter.task.mandatory=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.task.mandatory'?: Array<string>;

    /**
     * Filter by task.standardtaskId query param.
     *           <p>
     *              <b>Format: </b> filter.task.standardtaskId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.task.standardtaskId=$not:$like:John Doe&filter.task.standardtaskId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.task.standardtaskId'?: Array<string>;

    /**
     * Filter by project.sale query param.
     *           <p>
     *              <b>Format: </b> filter.project.sale={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.sale=$not:$like:John Doe&filter.project.sale=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.project.sale'?: Array<string>;

    /**
     * Filter by project.build query param.
     *           <p>
     *              <b>Format: </b> filter.project.build={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.build=$not:$like:John Doe&filter.project.build=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.project.build'?: Array<string>;

    /**
     * Filter by project.implementing query param.
     *           <p>
     *              <b>Format: </b> filter.project.implementing={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.implementing=$not:$like:John Doe&filter.project.implementing=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.project.implementing'?: Array<string>;

    /**
     * Filter by project.buildingpermit query param.
     *           <p>
     *              <b>Format: </b> filter.project.buildingpermit={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.buildingpermit=$not:$like:John Doe&filter.project.buildingpermit=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.project.buildingpermit'?: Array<string>;

    /**
     * Filter by project.container.containertypeId query param.
     *           <p>
     *              <b>Format: </b> filter.project.container.containertypeId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.container.containertypeId=$not:$like:John Doe&filter.project.container.containertypeId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.project.container.containertypeId'?: Array<string>;

    /**
     * Filter by project.container.contact.businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.project.container.contact.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.container.contact.businessunitId=$not:$like:John Doe&filter.project.container.contact.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.project.container.contact.businessunitId'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> id:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>id</li>
     * <li>project.sale</li>
     * <li>project.installationStart</li>
     * <li>project.build</li>
     * <li>project.implementing</li></ul>
     */
      sortBy?: Array<string>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name,standardtask.name
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li>
     * <li>standardtask.name</li></ul>
     */
      searchBy?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxTask>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'task.end'?: (string | Array<string>);
'task.mandatory'?: (string | Array<string>);
'task.standardtaskId'?: (string | Array<string>);
'project.sale'?: (string | Array<string>);
'project.build'?: (string | Array<string>);
'project.implementing'?: (string | Array<string>);
'project.buildingpermit'?: (string | Array<string>);
'project.container.containertypeId'?: (string | Array<string>);
'project.container.contact.businessunitId'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsAusstehendeAufgabenControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('tobNotDoneYet', params.tobNotDoneYet, {});
      rb.query('tagApproved', params.tagApproved, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.task.end', params['filter.task.end'], {});
      rb.query('filter.task.mandatory', params['filter.task.mandatory'], {});
      rb.query('filter.task.standardtaskId', params['filter.task.standardtaskId'], {});
      rb.query('filter.project.sale', params['filter.project.sale'], {});
      rb.query('filter.project.build', params['filter.project.build'], {});
      rb.query('filter.project.implementing', params['filter.project.implementing'], {});
      rb.query('filter.project.buildingpermit', params['filter.project.buildingpermit'], {});
      rb.query('filter.project.container.containertypeId', params['filter.project.container.containertypeId'], {});
      rb.query('filter.project.container.contact.businessunitId', params['filter.project.container.contact.businessunitId'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxTask>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'task.end'?: (string | Array<string>);
        'task.mandatory'?: (string | Array<string>);
        'task.standardtaskId'?: (string | Array<string>);
        'project.sale'?: (string | Array<string>);
        'project.build'?: (string | Array<string>);
        'project.implementing'?: (string | Array<string>);
        'project.buildingpermit'?: (string | Array<string>);
        'project.container.containertypeId'?: (string | Array<string>);
        'project.container.contact.businessunitId'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsAusstehendeAufgabenControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsAusstehendeAufgabenControllerFindAllPaginated(
    params?: {
      tobNotDoneYet?: boolean;
      tagApproved?: boolean;

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by task.end query param.
     *           <p>
     *              <b>Format: </b> filter.task.end={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.task.end=$not:$like:John Doe&filter.task.end=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.task.end'?: Array<string>;

    /**
     * Filter by task.mandatory query param.
     *           <p>
     *              <b>Format: </b> filter.task.mandatory={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.task.mandatory=$not:$like:John Doe&filter.task.mandatory=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.task.mandatory'?: Array<string>;

    /**
     * Filter by task.standardtaskId query param.
     *           <p>
     *              <b>Format: </b> filter.task.standardtaskId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.task.standardtaskId=$not:$like:John Doe&filter.task.standardtaskId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.task.standardtaskId'?: Array<string>;

    /**
     * Filter by project.sale query param.
     *           <p>
     *              <b>Format: </b> filter.project.sale={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.sale=$not:$like:John Doe&filter.project.sale=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.project.sale'?: Array<string>;

    /**
     * Filter by project.build query param.
     *           <p>
     *              <b>Format: </b> filter.project.build={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.build=$not:$like:John Doe&filter.project.build=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.project.build'?: Array<string>;

    /**
     * Filter by project.implementing query param.
     *           <p>
     *              <b>Format: </b> filter.project.implementing={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.implementing=$not:$like:John Doe&filter.project.implementing=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.project.implementing'?: Array<string>;

    /**
     * Filter by project.buildingpermit query param.
     *           <p>
     *              <b>Format: </b> filter.project.buildingpermit={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.buildingpermit=$not:$like:John Doe&filter.project.buildingpermit=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.project.buildingpermit'?: Array<string>;

    /**
     * Filter by project.container.containertypeId query param.
     *           <p>
     *              <b>Format: </b> filter.project.container.containertypeId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.container.containertypeId=$not:$like:John Doe&filter.project.container.containertypeId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.project.container.containertypeId'?: Array<string>;

    /**
     * Filter by project.container.contact.businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.project.container.contact.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.container.contact.businessunitId=$not:$like:John Doe&filter.project.container.contact.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.project.container.contact.businessunitId'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> id:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>id</li>
     * <li>project.sale</li>
     * <li>project.installationStart</li>
     * <li>project.build</li>
     * <li>project.implementing</li></ul>
     */
      sortBy?: Array<string>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name,standardtask.name
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li>
     * <li>standardtask.name</li></ul>
     */
      searchBy?: string;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxTask>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'task.end'?: (string | Array<string>);
'task.mandatory'?: (string | Array<string>);
'task.standardtaskId'?: (string | Array<string>);
'project.sale'?: (string | Array<string>);
'project.build'?: (string | Array<string>);
'project.implementing'?: (string | Array<string>);
'project.buildingpermit'?: (string | Array<string>);
'project.container.containertypeId'?: (string | Array<string>);
'project.container.contact.businessunitId'?: (string | Array<string>);
};
};
}> {
    return this.reportsAusstehendeAufgabenControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxTask>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'task.end'?: (string | Array<string>);
'task.mandatory'?: (string | Array<string>);
'task.standardtaskId'?: (string | Array<string>);
'project.sale'?: (string | Array<string>);
'project.build'?: (string | Array<string>);
'project.implementing'?: (string | Array<string>);
'project.buildingpermit'?: (string | Array<string>);
'project.container.containertypeId'?: (string | Array<string>);
'project.container.contact.businessunitId'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxTask>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'task.end'?: (string | Array<string>);
'task.mandatory'?: (string | Array<string>);
'task.standardtaskId'?: (string | Array<string>);
'project.sale'?: (string | Array<string>);
'project.build'?: (string | Array<string>);
'project.implementing'?: (string | Array<string>);
'project.buildingpermit'?: (string | Array<string>);
'project.container.containertypeId'?: (string | Array<string>);
'project.container.contact.businessunitId'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

}
