import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconComponent } from '../../icon/icon.component';

@Component({
    selector: 'ax-ui-widget-base',
    templateUrl: './widget-base.component.html',
    styleUrls: ['./widget-base.component.scss'],
    standalone: true,
    imports: [IconComponent, RouterLink],
})
export class WidgetBaseComponent {
  @Input({ required: true }) title = '';
  @Input() iconName = '';
  @Input() route: string | undefined = '';
}
