import { AfterContentInit, Component, ContentChildren, Input, QueryList, Renderer2 } from '@angular/core';

import { AccordionItemComponent } from '../accordion-item/accordion-item.component';

@Component({
  selector: 'ax-ui-accordion-list',
  standalone: true,
  imports: [],
  templateUrl: './accordion-list.component.html',
  styleUrls: ['./accordion-list.component.scss']
})
export class AccordionListComponent implements AfterContentInit {
  @ContentChildren(AccordionItemComponent) items: QueryList<AccordionItemComponent> | undefined;
  @Input() closeOtherAccordions = false;

  constructor(
    private renderer: Renderer2) {
  }

  ngAfterContentInit() {
    if (this.items && this.closeOtherAccordions) {
      this.items.forEach(item => {
        const header = item.headingElement?.nativeElement;
        if (header) {
          this.renderer.listen(header, 'click', () => {
            this.toggleItem(item);
          });
        }
      });
    }
  }

  // open the selected accordion and close all other ones
  toggleItem(item: AccordionItemComponent) {
    if (this.items) {
      this.items.forEach(i => {
        if (i !== item) {
          i.isOpen = false;
        }
      });
    }
  }
}
