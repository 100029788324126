import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { ProfileState } from '@axova-frontend-monorepo/axova-state';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AxIsEmployeeService {

  constructor(
    private store: Store,
    private router: Router,
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.store.selectSnapshot(ProfileState.isEmployee)) {
      this.router.navigate(['/']).then();
      return false;
    } else {
      return true;
    }
  }
}

export const IsEmployeeGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return <boolean>inject(AxIsEmployeeService).canActivate(next, state);
};
