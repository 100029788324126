/**
 * This global environment variables are equal to production.
 * The GLOBAL constant shouldn't be imported directly. Only import them via an environment variable.
 */
export const GLOBALS = {
  /**
   * Environment name.
   */
  name: 'production',

  /**
   * Can be used to check if it is the one and only production environment.
   */
  production: true,

  /**
   * API Base URL.
   */
  apiConfiguration: {
    baseUrl: 'https://api.axova.cloud',
    publicApiKey: '7002b21fc8c2a675afd034ec2273ce70375dd83a',
  },

  /**
   * URLs to Apps.
   */
  appUrls: {
    axovaOffice: 'https://office.axova.cloud',
    app: 'https://app.axova.cloud',
    appointmentScheduler: 'https://termin.axova.ch',
    website: 'https://www.axova.ch',
  },

  /**
   * Language configuration, setting the default language and provide a list of available languages in the app.
   */
  languagesDefault: 'de',
  languagesAvailable: ['de'],

  /**
   * Firebase configuration.
   */
  firebase: {
    configuration: {},
  },

  /**
   * Logging settings for NGXS and general.
   */
  isLoggingEnabled: {
    logger: false,
    ngxs: false,
  },
};
