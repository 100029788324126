<fieldset [id]="id" class="ax-ui-radio-group">
  @if (legendText) {
    <legend><small><b>{{ legendText | translate }}</b></small></legend>
  }
  <div [class.horizontal]="directionHorizontal">
    @for (option of options; track option) {
      <ax-ui-radio-button (radioSelected)="selectRadio($event)"
                          [alignHorizontal]="directionHorizontal"
                          [disabled]="option.disabled!"
                          [iconName]="option.iconName || ''"
                          [id]="(id ? id + '-' : null) + option.name!"
                          [label]="option.label!"
                          [name]="option.name"
                          [selected]="option.value === value"
                          [value]="option.value"
                          [visualizerFilePath]="option.visualizerFilePath || ''"
                          [visualizerPlaceholderFilePath]="option.visualizerPlaceholderFilePath || ''">
      </ax-ui-radio-button>
    }
  </div>
</fieldset>
