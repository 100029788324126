import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { LoadingAndFeedbackState } from '../../models/loading-and-feedback-state.type';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'ax-ui-loading-spinner',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnChanges, OnDestroy {
  @Input() loadingState: LoadingAndFeedbackState = 'none';
  @Input() iconSize = 24;
  @Input() pageLoader = false;
  @Input() pageOverlay = false;

  @Output() loadingStateChange: EventEmitter<LoadingAndFeedbackState> = new EventEmitter<LoadingAndFeedbackState>();

  @HostBinding('style.width.px') width = 24;
  @HostBinding('style.height.px') height = 24;

  public showLoading = false;
  public showLoadingSuccess = false;
  public showLoadingError = false;
  public hideOnInit = true;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loadingState'] && changes['loadingState'].currentValue as LoadingAndFeedbackState) {
      switch (changes['loadingState'].currentValue) {
        case 'loading': {
          this.hideOnInit = false;
          this.showLoading = true;
          // prevent ExpressionChangedAfterItHasBeenCheckedError
          this.changeDetectorRef.detectChanges();
          break;
        }
        case 'success': {
          this.showResultAnimation(true);
          break;
        }
        case 'error': {
          this.showResultAnimation(false);
          break;
        }
        case 'none': {
          this.showLoading = false;
          this.showLoadingSuccess = false;
          this.showLoadingError = false;
          break;
        }
      }
    }

    if (changes['iconSize']) {
      this.width = this.iconSize;
      this.height = this.iconSize;
    }
  }

  ngOnDestroy() {
    try {
      this.loadingStateChange.unsubscribe();
    } catch {
      // nothing to catch
    }
  }

  private showResultAnimation(success: boolean) {
    this.showLoading = false;
    if (success) {
      this.showLoadingSuccess = true;
      // set result state display time
      setTimeout(() => {
        this.showLoadingSuccess = false;
        this.loadingStateChange.emit('none');
      }, 1000);
    } else {
      this.showLoadingError = true;
      // set result state display time
      setTimeout(() => {
        this.showLoadingError = false;
        this.loadingStateChange.emit('none');
      }, 1000);
    }
  }
}
