<div class="ax-ui-terminassistent-zip-to-business-unit">
  <h2 class="ax-web-text--large margin-bottom-24">Standort der Immobilie</h2>
  <p>Bitte geben Sie als erstes Postleitzahl oder Ort ein, an welchem sich die zu besichtigende Immobilie befindet.</p>

  <ax-ui-input-field (valueChange)="search($event)"
                     [(value)]="location"
                     placeholder="PLZ oder Ortsname (min. 4 Ziffern)"
                     iconName="location_on"
                     class="margin-top-32" />
  @if (locationResults.length > 0) {
    <p class="margin-bottom-12">Wählen Sie Ihren Ort aus:</p>
    <div class="ax-card no-padding fade-in">
      <ul class="ax-list" role="list">
        @for (result of locationResults; track result; let i = $index) {
          <li (click)="chooseLocation(result, i)"
              [class.active]="selectedLocationIndex === i"
              [class.disabled]="!result.businessUnit && (internalView$ | async) === undefined"
              class="ax-action-element"
              role="listitem">
            <p class="">{{ result.zipcode }} {{ result.name }}</p>
            @if (!result.businessUnit) {
              <ax-ui-label variant="warning"
                           borderStyle="square"
                           [textWrap]="true"
                           [text]="(internalView$ | async) ? 'Eigentlich bedienen wir diesen Ort nicht. Bei Ausnahmen kann man aber trotzdem hier klicken' : 'Dieser Ort wird (aktuell) nicht von uns bedient'" />
            }
          </li>
        }
      </ul>
    </div>
  }
  @if (showTooShortQueryHint) {
    <p><small>Geben Sie in der Suche mindestens 4 Ziffern ein</small></p>
  }
</div>
