import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AxAuthenticationV2Service } from '@axova-frontend-monorepo/axova-rest-api';
import { Store } from '@ngxs/store';
import { AlertDialogService } from '@axova-frontend-monorepo/axova-ui';
import { ProfileState } from '@axova-frontend-monorepo/axova-state';

@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private axAuthenticationV2Service: AxAuthenticationV2Service,
    private store: Store,
    private router: Router,
    private alertDialogService: AlertDialogService,
  ) {
  }

  /**
   * Intercepts every HTTP request. If an access token exists, it will be attached to the request as Authorization Header
   * in the format of "Bearer ${Token}". If a http 401 exception occurs, it will attempt to get new tokens via refresh token
   * mechanism. If this fails, the user will be automatically logged out, because the refresh token is invalid or expired.
   *
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
    });

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401 && this.store.selectSnapshot(ProfileState.profile)) {
          /**
           * Logout the user if the 401 error stems from refresh token endpoints (=> refresh token has expired)
           */
          if (request.url.indexOf(AxAuthenticationV2Service.AuthenticationControllerRefreshToken_1Path) > -1) {
            // Logout
            this.router.navigateByUrl('/auth/logout').then();
            this.alertDialogService.showAlert({
              title: 'Abgemeldet',
              message: 'Aufgrund zu langer Inaktivität wurdest du automatisch abgemeldet.',
              feedbackState: 'warning',
            });
          }
          return this.handle401Error(request, next);
        } else if (error instanceof HttpErrorResponse && (error.status === 502 || error.status === 0)) {
          this.alertDialogService.showAlert({
            title: 'API wird neu gestartet',
            message: 'Bitte in 30 Sekunden die Seite neu laden.',
            feedbackState: 'warning',
          });
          throw error;
        } else {
          throw error;
        }
      }),
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      /**
       * Get new access_token and refresh_token based on the current refresh_token.
       */
      return this.axAuthenticationV2Service.authenticationControllerRefreshToken_1().pipe(
        switchMap(() => {
          this.isRefreshing = false;
          return next.handle(request);
        }),
        catchError(error => {
          throw error;
        }),
      );
    } else {
      return next.handle(request);
    }
  }
}
