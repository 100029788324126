import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

const TIME_FORMAT = 'HH:mm';
const TIME_FORMAT_WITH_SUFFIX = `${TIME_FORMAT} [Uhr]`;

@Pipe({
    name: 'formatTimeRange',
    standalone: true,
})
export class FormatTimeRangePipe implements PipeTransform {
  transform(startDateTime: string | Date | undefined | null, endDateTime: string | Date | undefined | null): string {
    const start = this.convertToDayjs(startDateTime);
    const end = this.convertToDayjs(endDateTime);

    if (!start && !end || start?.hour() === 0 && start?.minute() === 0) {
      return '';
    }

    if (!start) {
      return end?.format(TIME_FORMAT_WITH_SUFFIX) ?? '';
    }

    if (!end || start.isSame(end)) {
      return start.format(TIME_FORMAT_WITH_SUFFIX);
    }

    return `${start.format(TIME_FORMAT)} - ${end.format(TIME_FORMAT_WITH_SUFFIX)}`;
  }

  private convertToDayjs(date: string | Date | undefined | null): dayjs.Dayjs | undefined {
    if (!date) return undefined;
    return dayjs(date);
  }
}
