
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getAuthenticateWithSecretDtoFormGroup() {
return new FormGroup({
    secret: new FormControl<string | undefined>(''),
})
;
}
      