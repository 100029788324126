import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { AxFindFreeTimeslotsDto, AxReservationsService, AxTimeslot } from '@axova-frontend-monorepo/axova-rest-api';
import { LoggerService } from '@axova-frontend-monorepo/axova-commons';
import { environment } from '@axova-frontend-monorepo/axova-environments';
import { TerminassistentState } from '../../ngxs/terminassistent/terminassistent.state';
import { TerminassistentStateSetTimeslot } from '../../ngxs/terminassistent/terminassistent.actions';
import * as dayjs from 'dayjs';
import { lastValueFrom } from 'rxjs';
import { LoadingSpinnerComponent } from '../../../loading-spinner/loading-spinner.component';
import { LabelComponent } from '../../../label/label.component';
import { ButtonComponent } from '../../../button/button.component';

@Component({
  selector: 'ax-ui-terminassistent-available-appointments',
  standalone: true,
  imports: [CommonModule, LoadingSpinnerComponent, ButtonComponent, LabelComponent],
  templateUrl: './terminassistent-available-appointments.component.html',
  styleUrl: './terminassistent-available-appointments.component.scss',
})
export class TerminassistentAvailableAppointmentsComponent implements OnInit {
  public availableTimeslots: AxTimeslot[] = [];
  public availableTimeslotsGroupedByDay: any = {};
  public selectedTimeslot = this.store.select(TerminassistentState.timeslot);
  public objectKeys = Object.keys;
  public currentOffset = 0;
  public isBiggerOffsetAllowed = true;
  public isLoading = true;

  constructor(
    private store: Store,
    private router: Router,
    private axReservationsService: AxReservationsService,
  ) {
  }

  ngOnInit(): void {
    this.currentOffset = 0;
    this.getTimeslots();
  }

  public hasFreeSlotOnDay(timeslots: any[]) {
    return timeslots.filter(timeslot => timeslot.available).length > 0;
  }

  public async chooseTimeslot(availableTimeslot: any) {
    this.store.dispatch(new TerminassistentStateSetTimeslot(availableTimeslot));
    await this.router.navigateByUrl('/terminassistent/daten');
  }

  public async getTimeslots() {
    this.isLoading = true;

    // Get location and reservation type from state.
    const location = this.store.selectSnapshot(TerminassistentState.location);
    const reservationType = this.store.selectSnapshot(TerminassistentState.reservationtype);

    // Actual request.
    if (location && location.id) {
      const bodyDto: AxFindFreeTimeslotsDto = {
        locationId: location.id,
        reservationtypeId: reservationType?.id || -1,
        offset: this.currentOffset,
      };
      // Attach API scheduler key (internal).
      const apiAppointmentSchedulerKey = this.store.selectSnapshot(TerminassistentState.apiAppointmentSchedulerKey);
      if (apiAppointmentSchedulerKey) {
        bodyDto.apiAppointmentSchedulerKey = apiAppointmentSchedulerKey;
      }

      try {
        const timeslotsDto = await lastValueFrom(this.axReservationsService.reservationsControllerFindFreeTimeslots({
          'x-api-key': environment.apiConfiguration.publicApiKey,
          body: bodyDto,
        }));

        this.isLoading = false;
        this.currentOffset++;
        this.isBiggerOffsetAllowed = timeslotsDto.isBiggerOffsetAllowed;
        if (!timeslotsDto || !timeslotsDto.timeslots || !timeslotsDto.timeslots.length) {
          if (timeslotsDto.isBiggerOffsetAllowed) {
            this.getTimeslots();
          }
          return;
        }
        this.availableTimeslots = this.availableTimeslots.concat(timeslotsDto.timeslots);
        for (const availableTimeslot of timeslotsDto.timeslots) {
          const date = dayjs(availableTimeslot.start);
          const dateKey = `${date.year()}-${date.month() + 1}-${date.date()}`;
          if (this.availableTimeslotsGroupedByDay[dateKey]) {
            this.availableTimeslotsGroupedByDay[dateKey].push(availableTimeslot);
          } else {
            this.availableTimeslotsGroupedByDay[dateKey] = [availableTimeslot];
          }
        }
      } catch (findFreeTimeslotsException) {
        this.isLoading = false;
        LoggerService.ERROR('Terminassisstent find free time slots', findFreeTimeslotsException);
      }
    }
  }
}
