import { Pipe, PipeTransform } from '@angular/core';
import { AxTimerecordtypeNameEnum } from '@axova-frontend-monorepo/axova-rest-api';

@Pipe({
    name: 'getTimerecordtypeIconName',
    standalone: true,
})
export class GetTimerecordtypeIconNamePipe implements PipeTransform {
  transform(timerecordtypeName: AxTimerecordtypeNameEnum | null): string {
    switch (timerecordtypeName) {
      case (AxTimerecordtypeNameEnum.Arbeit): {
        return 'check_circle';
      }
      case (AxTimerecordtypeNameEnum.Ferien): {
        return 'beach_access';
      }
      case (AxTimerecordtypeNameEnum.MilitarZivilschutz): {
        return 'military_tech';
      }
      case (AxTimerecordtypeNameEnum.BezahlteAbsenz): {
        return 'arrow_outward';
      }
      case (AxTimerecordtypeNameEnum.Kompensation): {
        return 'remove';
      }
      case (AxTimerecordtypeNameEnum.Krank): {
        return 'medication';
      }
      case (AxTimerecordtypeNameEnum.Unfall): {
        return 'ambulance';
      }
      case (AxTimerecordtypeNameEnum.Feiertag): {
        return 'mood';
      }
      case (AxTimerecordtypeNameEnum.Frei): {
        return 'close';
      }
      default: {
        return 'help';
      }
    }
  }
}
