import { Component, Input } from '@angular/core';

@Component({
    selector: 'ax-ui-chart-progress',
    templateUrl: './chart-progress.component.html',
    styleUrls: ['./chart-progress.component.scss'],
    standalone: true,
})
export class ChartProgressComponent {
  @Input() showNumbers = true;
  @Input() total = 100;
  @Input() reached = 0;

  get percentageReached() {
    return this.reached / this.total * 100;
  }
}
