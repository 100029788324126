export interface RadioButtonOptions {
  id?: string;
  name: string;
  label?: string;
  value: string | boolean | number;
  disabled?: boolean;
  iconName?: string;
  visualizerFilePath?: string
  visualizerPlaceholderFilePath?: string
}
