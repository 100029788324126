<a class="ax-ui-card-modal-opener ax-card ax-action-element" tabindex="0">
  <div>
    <ax-ui-icon [name]="iconName"></ax-ui-icon>
    <p>{{ label }}</p>
  </div>
  <ax-ui-icon [name]="'arrow_circle_right'"
              class="ax-ui-card-modal-opener__arrow">
  </ax-ui-icon>
  @if (actionRequired) {
    <div class="ax-badge ax-badge--notification">!</div>
  }
</a>
