import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { ProfileState } from '@axova-frontend-monorepo/axova-state';
import {
  LoggerService,
  NavigationItemInterface,
  PrependServerToFileSrcPipe
} from '@axova-frontend-monorepo/axova-commons';
import { profilPageRoutes } from '../../../pages/profil-page/profil-page-routing.module';
import {
  AxAuthenticationV2Service,
  AxProfileDto,
  AxUser,
  AxUsersV2Service
} from '@axova-frontend-monorepo/axova-rest-api';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NavigationDialogBaseComponent } from '../navigation-dialog-base/navigation-dialog-base.component';
import { lastValueFrom } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarComponent, ButtonComponent, IconComponent } from '@axova-frontend-monorepo/axova-ui';

@Component({
  selector: 'ax-office-profile-options-dialog',
  templateUrl: './profile-options-dialog.component.html',
  styleUrls: ['./profile-options-dialog.component.scss'],
  standalone: true,
  imports: [
    NavigationDialogBaseComponent,
    AvatarComponent,
    RouterLinkActive,
    RouterLink,
    IconComponent,
    ButtonComponent,
    TranslateModule,
    PrependServerToFileSrcPipe
  ]
})
export class ProfileOptionsDialogComponent implements OnInit {
  @ViewChild('navDialog') navDialog!: NavigationDialogBaseComponent;
  public userProfile!: AxProfileDto | undefined;
  public user!: AxUser | undefined;
  public navigationItems: NavigationItemInterface[] = [];

  constructor(
    private store: Store,
    private axUsersV2Service: AxUsersV2Service,
    private axAuthenticationV2Service: AxAuthenticationV2Service,
    private router: Router
  ) {
    this.userProfile = this.store.selectSnapshot(ProfileState.profile);
    this.loadProfilRoutesForNavigation();
  }

  async ngOnInit() {
    if (this.userProfile) {
      try {
        this.user = await lastValueFrom(this.axUsersV2Service.usersControllerFindOneById({
          id: this.userProfile.id
        }));
      } catch (httpException) {
        LoggerService.ERROR(this, 'httpException', httpException);
      }
    }
  }

  private loadProfilRoutesForNavigation() {
    profilPageRoutes.forEach(route => {
      if (route.data) {
        const requiredPermission = route.data['requiredPermission'] || null;
        if (!requiredPermission || (requiredPermission && this.store.selectSnapshot(ProfileState.hasPermission(requiredPermission)))) {
          this.navigationItems.push({
            route: route.path || '',
            label: route.data['navigationItem'].label || null,
            icon: route.data['navigationItem'].icon || null
          });
        }
      }
    });
  }
}
