import { Pipe, PipeTransform } from '@angular/core';
import { AxDocumentProcessingStatusEnum } from '@axova-frontend-monorepo/axova-rest-api';

@Pipe({
    name: 'getDocumentStateIconName',
    standalone: true,
})
export class GetDocumentStateIconNamePipe implements PipeTransform {
  transform(processingStatus: AxDocumentProcessingStatusEnum | null): string {
    switch (processingStatus) {
      case (AxDocumentProcessingStatusEnum.New):
      case (AxDocumentProcessingStatusEnum.Pending): {
        return 'hourglass';
      }
      case (AxDocumentProcessingStatusEnum.Done):
      case (AxDocumentProcessingStatusEnum.Proposal): {
        return 'check_circle';
      }
      case (AxDocumentProcessingStatusEnum.Failed): {
        return 'error';
      }
      case (AxDocumentProcessingStatusEnum.Skipped): {
        return 'next_plan';
      }
      default: {
        return 'hourglass';
      }
    }
  }
}
