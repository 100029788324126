/* tslint:disable */
/* eslint-disable */
export enum AxDocumentProcessingStatusEnum {
  New = 'NEW',
  Pending = 'PENDING',
  Done = 'DONE',
  Failed = 'FAILED',
  Skipped = 'SKIPPED',
  Proposal = 'PROPOSAL'
}
