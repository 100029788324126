import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TabOptions } from '../../models/tab-options.model';
import { SelectDropdownOptions } from '../../models/select-dropdown-options.model';
import { TranslateModule } from '@ngx-translate/core';
import { NgTemplateOutlet } from '@angular/common';
import { SelectComponent } from '../inputs/select/select.component';
import { IconComponent } from '../icon/icon.component';

@Directive({
    selector: '[axUiTabContent]',
    standalone: true,
})
export class TabContentDirective {
  @Input() tabReference = '';

  constructor(public template: TemplateRef<never>) {
  }
}

@Component({
    selector: 'ax-ui-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
    standalone: true,
    imports: [
        IconComponent,
        SelectComponent,
        NgTemplateOutlet,
        TranslateModule,
    ],
})
export class TabsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('tabGroup') tabGroup!: ElementRef;
  @ViewChildren('tabButton') tabButtons!: QueryList<ElementRef>;
  @ContentChildren(TabContentDirective) tabContentTemplates!: QueryList<TabContentDirective>;

  @Input() tabOptions: TabOptions[] = [];
  @Input() noPadding = false;
  @Input() contentPadding = true;
  @Input() activeTab!: string; // Ensure the activeTab is managed as an input
  @Input() parentTabKey?: string; // Key of the parent tab if this is a nested component

  @Output() activeTabChange: EventEmitter<string> = new EventEmitter<string>();

  public showTabs = false;
  public selectOptions: SelectDropdownOptions[] = [];
  private tabGroupResizeObserver!: ResizeObserver;
  private routingChangeSubscription!: Subscription;
  private tabsComponentInstanceId: number;

  static TabsComponentInstanceId = 0;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
  ) {
    // Use a unique ID for the instanciated tabs component in order to support multiple ones per page.
    // This is important when having multiple "tabs" query params, which must be distinguishable from each other.
    this.tabsComponentInstanceId = TabsComponent.TabsComponentInstanceId;
    TabsComponent.TabsComponentInstanceId++;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.updateActiveTabFromParams(params);
    });

    this.tabOptions.forEach(tab => {
      this.selectOptions.push({
        label: tab.title,
        value: tab.tabReference,
      });
    });

    this.routingChangeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateActiveTabFromParams(this.activatedRoute.snapshot.queryParams);
      }
    });
  }

  ngAfterViewInit() {
    this.tabGroupResizeObserver = new ResizeObserver(() => {
      this.showTabs = this.tabGroupHasOverflow();
      this.changeDetectorRef.detectChanges();
    });

    this.tabGroupResizeObserver.observe(this.tabGroup.nativeElement);
  }

  ngOnDestroy() {
    this.tabGroupResizeObserver?.disconnect();
    this.routingChangeSubscription.unsubscribe();
  }

  private updateActiveTabFromParams(params: Params) {
    const tabKey = this.parentTabKey ? params[this.parentTabKey] : params[`tab${this.tabsComponentInstanceId}`];
    if (tabKey && this.tabOptions.some(tab => tab.tabReference === tabKey)) {
      this.activeTab = tabKey;
      this.activateTab(tabKey, true);
    } else if (!tabKey) {
      this.activeTab = this.tabOptions[0].tabReference;
      this.activateTab(this.activeTab, true);
    }
  }

  public activateTab(tabReference: string, preventRouteChange = false) {
    // prevent changeDetection errors in the parent by wrapping this in setTimeout
    setTimeout(() => {
      this.activeTab = tabReference;
      this.activeTabChange.emit(tabReference);
    });


    if (!preventRouteChange) {
      // Build new query parameters object based on parent or child context
      const queryParams = this.activatedRoute.snapshot.queryParams;
      let newParams: any = {};

      // If it is a parent tab, update or set the 'tab' and reset child tab parameters
      if (!this.parentTabKey) {
        newParams = {
          ...queryParams,
          [`tab${this.tabsComponentInstanceId}`]: tabReference, // Set the parent tab
        };

        // Propagate the current active child tab of the newly activated parent tab, if any
        const childTabComponent = this.elementRef.nativeElement.querySelector('ax-ui-tabs');
        if (childTabComponent) {
          newParams[tabReference] = childTabComponent.activeTab || '';
        }
      } else {
        // If it is a child tab, maintain the parent's state and update only this child's state
        newParams = {
          ...queryParams,
          [this.parentTabKey]: tabReference, // Set the child tab under the parent key
        };
      }

      this.tabOptions.forEach(option => {
        const matchingParameter = Object.keys(newParams).indexOf(option.tabReference) > -1;
        if (matchingParameter && newParams[option.tabReference] === '') {
          delete newParams[option.tabReference];
        }

        if (matchingParameter && newParams[`tab${this.tabsComponentInstanceId}`] !== this.parentTabKey) {
          delete newParams[option.tabReference];
        }
      });

      // Navigate with updated query parameters
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: newParams,
        queryParamsHandling: '',
      });
    }
  }


  public onKeydown(event: KeyboardEvent) {
    const activeTabIndex = this.tabOptions.findIndex(tab => tab.tabReference === this.activeTab);
    if (event.key === 'ArrowRight') {
      const nextTabReference = this.tabOptions[(activeTabIndex + 1) % this.tabOptions.length].tabReference;
      this.activateTab(nextTabReference);
    } else if (event.key === 'ArrowLeft') {
      const previousTabReference = this.tabOptions[(activeTabIndex === 0 ? this.tabOptions.length - 1 : activeTabIndex - 1)].tabReference;
      this.activateTab(previousTabReference);
    }
  }

  private tabGroupHasOverflow() {
    const element = this.tabGroup.nativeElement;
    return element.scrollWidth > element.clientWidth;
  }
}
