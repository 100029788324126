<div [class.no-margins]="noMargins"
     [class.text-overflow-ellipsis]="handleTextOverflow"
     class="ax-office-page-header ax-grid-container">
  @if (!noGridLayout) {
    <div [ngClass]="smallAdditionalContent ? 'ax-grid-row-reversed--tablet' : ''" class="ax-grid-row">
      <div
        [ngClass]="noAdditionalContent ? 'ax-grid-col-desktop-12' : smallAdditionalContent ? 'ax-grid-col-desktop-11': 'ax-grid-col-desktop-5'"
        class="ax-grid-col ax-grid-col-tablet-12 ax-grid-col-mobile-12">
        <div class="flex flex-row vertical-center gap-24 wrap">
          @if (avatarImgSrc || avatarImgSrc === null) {
            <ax-ui-avatar
              [imgSrc]="avatarImgSrc"
              [size]="'bigger'"
              status="available" />
          }
          <div class="flex flex-row vertical-center gap-8">
            @if (titleText) {
              <h1 [title]="titleText | translate">{{ titleText | translate }}</h1>
            }
            @if (subTitleText) {
              <h2 [title]="subTitleText | translate">{{ subTitleText | translate }}</h2>
            }
            @if (tooltipText) {
              <ng-container [ngTemplateOutlet]="tooltip"></ng-container>
            }
          </div>
        </div>
      </div>
      @if (!noAdditionalContent) {
        <div
          [ngClass]="smallAdditionalContent ? 'ax-grid-col-desktop-1': 'ax-grid-col-desktop-7'"
          class="ax-grid-col ax-grid-col-tablet-12 ax-grid-col-mobile-12 ax-grid-col-horizontal-end">
          <div class="ax-office-page-header__content">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
          </div>
        </div>
      }
    </div>
  } @else {
    <div class="flex flex-row vertical-center gap-12 margin-bottom-24">
      @if (avatarImgSrc || avatarImgSrc === null) {
        <ax-ui-avatar
          [imgSrc]="avatarImgSrc"
          [size]="'bigger'"
          status="available" />
      }
      <div class="flex flex-row vertical-center gap-8">
        @if (titleText) {
          <h1 [title]="titleText | translate">{{ titleText | translate }}</h1>
        }
        @if (subTitleText) {
          <h2 [title]="subTitleText | translate">{{ subTitleText | translate }}</h2>
        }
        @if (tooltipText) {
          <ng-container [ngTemplateOutlet]="tooltip"></ng-container>
        }
      </div>
      @if (!noAdditionalContent) {
        <div class="ax-office-page-header__content fit-content">
          <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </div>
      }
    </div>
  }
</div>


<!-- ng-content placed outside and only once to prevent displaying issues -->
<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>

<ng-template #tooltip>
  <div [axUiTooltipMaxWidth]="400" [axUiTooltip]="tooltipTemplate">
    <ax-ui-icon name="info" class="cursor-help" />
    <ng-template #tooltipTemplate>
      @if (tooltipText) {
        <p class="padding-16">{{ tooltipText | translate }}</p>
      }
    </ng-template>
  </div>
</ng-template>
