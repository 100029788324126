/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxAuthDto } from '../models/ax-auth-dto';
import { AxGetMailsAndPhonesDto } from '../models/ax-get-mails-and-phones-dto';
import { AxMailsAndPhonesDto } from '../models/ax-mails-and-phones-dto';
import { AxRefreshtokenDto } from '../models/ax-refreshtoken-dto';
import { AxRequestOtpDto } from '../models/ax-request-otp-dto';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxTokenResponseDto } from '../models/ax-token-response-dto';
import { AxVerifyOtpDto } from '../models/ax-verify-otp-dto';

@Injectable({ providedIn: 'root' })
export class AxAuthenticationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authenticationControllerLogin()` */
  static readonly AuthenticationControllerLoginPath = '/v1/auth/loginWithUsernameAndPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerLogin$Response(
    params: {
      body: AxAuthDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTokenResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxAuthenticationService.AuthenticationControllerLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTokenResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerLogin(
    params: {
      body: AxAuthDto
    },
    context?: HttpContext
  ): Observable<AxTokenResponseDto> {
    return this.authenticationControllerLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTokenResponseDto>): AxTokenResponseDto => r.body)
    );
  }

  /** Path part for operation `authenticationControllerRefreshToken()` */
  static readonly AuthenticationControllerRefreshTokenPath = '/v1/auth/refreshTokens';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerRefreshToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerRefreshToken$Response(
    params: {
      body: AxRefreshtokenDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTokenResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxAuthenticationService.AuthenticationControllerRefreshTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTokenResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerRefreshToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerRefreshToken(
    params: {
      body: AxRefreshtokenDto
    },
    context?: HttpContext
  ): Observable<AxTokenResponseDto> {
    return this.authenticationControllerRefreshToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTokenResponseDto>): AxTokenResponseDto => r.body)
    );
  }

  /** Path part for operation `authenticationControllerLogoutOld()` */
  static readonly AuthenticationControllerLogoutOldPath = '/v1/auth/logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerLogoutOld()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerLogoutOld$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxAuthenticationService.AuthenticationControllerLogoutOldPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerLogoutOld$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerLogoutOld(
    params?: {
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.authenticationControllerLogoutOld$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `authenticationControllerLogoutOldPost()` */
  static readonly AuthenticationControllerLogoutOldPostPath = '/v1/auth/logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerLogoutOldPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerLogoutOldPost$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxAuthenticationService.AuthenticationControllerLogoutOldPostPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerLogoutOldPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerLogoutOldPost(
    params?: {
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.authenticationControllerLogoutOldPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `authenticationControllerGetMailsAndPhones()` */
  static readonly AuthenticationControllerGetMailsAndPhonesPath = '/v1/auth/getMailsAndPhones';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerGetMailsAndPhones()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerGetMailsAndPhones$Response(
    params: {
      body: AxGetMailsAndPhonesDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMailsAndPhonesDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxAuthenticationService.AuthenticationControllerGetMailsAndPhonesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMailsAndPhonesDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerGetMailsAndPhones$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerGetMailsAndPhones(
    params: {
      body: AxGetMailsAndPhonesDto
    },
    context?: HttpContext
  ): Observable<AxMailsAndPhonesDto> {
    return this.authenticationControllerGetMailsAndPhones$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMailsAndPhonesDto>): AxMailsAndPhonesDto => r.body)
    );
  }

  /** Path part for operation `authenticationControllerGetMailsAndPhonesForContact()` */
  static readonly AuthenticationControllerGetMailsAndPhonesForContactPath = '/v1/auth/getMailsAndPhonesForContact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerGetMailsAndPhonesForContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerGetMailsAndPhonesForContact$Response(
    params: {
      body: AxGetMailsAndPhonesDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMailsAndPhonesDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxAuthenticationService.AuthenticationControllerGetMailsAndPhonesForContactPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMailsAndPhonesDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerGetMailsAndPhonesForContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerGetMailsAndPhonesForContact(
    params: {
      body: AxGetMailsAndPhonesDto
    },
    context?: HttpContext
  ): Observable<AxMailsAndPhonesDto> {
    return this.authenticationControllerGetMailsAndPhonesForContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMailsAndPhonesDto>): AxMailsAndPhonesDto => r.body)
    );
  }

  /** Path part for operation `authenticationControllerRequestOtp()` */
  static readonly AuthenticationControllerRequestOtpPath = '/v1/auth/requestOtp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerRequestOtp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerRequestOtp$Response(
    params: {
      body: AxRequestOtpDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxAuthenticationService.AuthenticationControllerRequestOtpPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerRequestOtp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerRequestOtp(
    params: {
      body: AxRequestOtpDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.authenticationControllerRequestOtp$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `authenticationControllerVerifyOtp()` */
  static readonly AuthenticationControllerVerifyOtpPath = '/v1/auth/verifyOtp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerVerifyOtp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerVerifyOtp$Response(
    params: {
      body: AxVerifyOtpDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTokenResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxAuthenticationService.AuthenticationControllerVerifyOtpPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTokenResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerVerifyOtp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerVerifyOtp(
    params: {
      body: AxVerifyOtpDto
    },
    context?: HttpContext
  ): Observable<AxTokenResponseDto> {
    return this.authenticationControllerVerifyOtp$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTokenResponseDto>): AxTokenResponseDto => r.body)
    );
  }

}
