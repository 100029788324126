import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../icon/icon.component';

@Component({
  selector: 'ax-ui-radio-button',
  standalone: true,
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  imports: [
    TranslateModule,
    IconComponent
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true
    }
  ]
})
export class RadioButtonComponent implements OnInit, ControlValueAccessor {
  @Input() id = '';
  @Input({ required: true }) name = '';
  @Input() label = '';
  @Input() value: string | boolean | number = '';
  @Input() disabled = false;
  @Input() selected = false;
  @Input() alignHorizontal = false;
  @Input() iconName = '';
  @Input() visualizerFilePath = '';
  @Input() visualizerPlaceholderFilePath = '';

  @Output() radioSelected: EventEmitter<any> = new EventEmitter();

  public playVisualiser = false;
  public hideVisualizer = true;
  private idCounter = 0;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {
  };

  ngOnInit() {
    if (!this.id) {
      this.id = this.name + this.generateCounterBasedID();
    }
  }

  /**
   * Give the radio button the correct value on click
   */
  public toggleRadio(): void {
    this.onChange(this.value);
    this.onTouched();
    this.radioSelected.emit(this.value);
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private generateCounterBasedID(): string {
    this.idCounter++;
    return this.idCounter.toString();
  }

  startVisualiser() {
    this.playVisualiser = true;
    setTimeout(() => {
      this.hideVisualizer = false;
    }, 400);
  }

  stopVisualiser() {
    this.playVisualiser = false;
    this.hideVisualizer = true;
  }
}
