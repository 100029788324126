<div class="ax-ui-terminassistent-intro ax-text-prose">
  <div class="flex flex-row vertical-center width-100 gap-16 margin-bottom-24">
    <div class="ax-icon__container flex center">
      <ax-ui-icon name="calendar_clock" [fontSize]="40" />
    </div>
    <h1 class="ax-ui-terminassistent-intro__heading ax-web-text--larger">Axova Terminassistent</h1>
  </div>
  <p>Reservieren Sie jetzt ganz bequem <strong>einen kostenlosen Termin</strong> und erhalten Sie fachkundige Beratung zur Installation einer
    Solarstromanlage oder einer energetischen Dachsanierung. <strong>Im Anschluss erstellen wir Ihnen eine kostenlose Offerte!</strong></p>
  <h3>Wie läuft so ein Beratungstermin ab?</h3>
  <ul class="icon-list"
      axUiMotion
      [motionAllChildrenStaggered]="true">
    <li>
      <ax-ui-icon name="check_circle" class="color-green--600" [boldness]="400" [fontSize]="28" />
      <div>Ein Kundenberater kommt zu Ihnen und lernt Sie und Ihre Bedürfnisse kennen</div>
    </li>
    <li>
      <ax-ui-icon name="check_circle" class="color-green--600" [boldness]="400" [fontSize]="28" />
      <div>Wir schauen uns gemeinsam das Gebäude, die Elektroinstallation und das Dach an</div>
    </li>
    <li>
      <ax-ui-icon name="check_circle" class="color-green--600" [boldness]="400" [fontSize]="28" />
      <div>
        Wir erstellen anschliessend kostenlos eine massgeschneiderte Offerte für Sie
        <div [axUiTooltipMaxWidth]="400" [axUiTooltip]="tooltipTemplate" class="width-fit-content ax-ui-terminassistent-intro__tooltip">
          <ax-ui-icon name="info" class="cursor-help" />
          <ng-template #tooltipTemplate>
            <div class="padding-24">
              <p class="margin-bottom-12"><small><strong>Die Offerte beinhaltet:</strong></small></p>
              <div class="ax-text-prose">
                <ul>
                  <li><small>Was Ihr Projekt kostet</small></li>
                  <li><small>Welche Förderbeiträge Sie erhalten</small></li>
                  <li><small>Wie hoch Ihre Steuerersparnisse sein können</small></li>
                  <li><small>Wieviel Strom Ihre Anlage produzieren kann</small></li>
                </ul>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </li>
  </ul>
  <ax-ui-button text="Jetzt Termin vereinbaren"
                class="display-block margin-top-48"
                (buttonClick)="wizardStepsNavService.goToNextStep()"
                [alignRight]="true"
                [fullWidthMobile]="true" />
</div>
