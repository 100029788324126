<div class="ax-ui-widget ax-card">
  <div class="ax-ui-widget__header margin-bottom-24">
    <div class="ax-ui-widget__header-title">
      @if (iconName) {
        <ax-ui-icon
          [framed]="true"
          [name]="iconName" />
      }
      <h3>{{ title }}</h3>
    </div>
    @if (route) {
      <ax-ui-icon
        [routerLink]="route"
        [fontSize]="32"
        name="arrow_circle_right"
        class="ax-action-element" />
    }
  </div>
  <div class="ax-ui-widget__content">
    <ng-content></ng-content>
  </div>
</div>
