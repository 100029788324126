/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateMailDto } from '../models/ax-create-mail-dto';
import { AxMail } from '../models/ax-mail';
import { AxUpdateMailDto } from '../models/ax-update-mail-dto';

@Injectable({ providedIn: 'root' })
export class AxMailsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `mailsControllerCreate()` */
  static readonly MailsControllerCreatePath = '/v2/mails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mailsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mailsControllerCreate$Response(
    params: {
      body: AxCreateMailDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMail>> {
    const rb = new RequestBuilder(this.rootUrl, AxMailsV2Service.MailsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMail>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mailsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mailsControllerCreate(
    params: {
      body: AxCreateMailDto
    },
    context?: HttpContext
  ): Observable<AxMail> {
    return this.mailsControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMail>): AxMail => r.body)
    );
  }

  /** Path part for operation `mailsControllerDelete()` */
  static readonly MailsControllerDeletePath = '/v2/mails/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mailsControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  mailsControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxMailsV2Service.MailsControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mailsControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mailsControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.mailsControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `mailsControllerUpdate()` */
  static readonly MailsControllerUpdatePath = '/v2/mails/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mailsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mailsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateMailDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMail>> {
    const rb = new RequestBuilder(this.rootUrl, AxMailsV2Service.MailsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMail>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mailsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mailsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateMailDto
    },
    context?: HttpContext
  ): Observable<AxMail> {
    return this.mailsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMail>): AxMail => r.body)
    );
  }

}
