
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getUpdateProfileDtoFormGroup() {
return new FormGroup({
    hasChanges: new FormControl<boolean | undefined>(undefined, [requiredBoolean()]),
    message: new FormControl<string | undefined>('', [Validators.required]),
    user: new FormControl<any>(undefined, [Validators.required]),
})
;
}
      