
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AxUpdateEventRegistrationSelectedOptionDto } from '../models/ax-update-event-registration-selected-option-dto';



export function getCreateEventInvitationV2DtoFormGroup() {
return new FormGroup({
    eventId: new FormControl<number | undefined>(undefined, [Validators.required]),
    audienceId: new FormControl<number | undefined>(undefined),
    userId: new FormControl<number | undefined>(undefined),
    contactId: new FormControl<number | undefined>(undefined),
    gender: new FormControl<string | undefined>(undefined),
    company: new FormControl<string | undefined>(undefined),
    firstName: new FormControl<string | undefined>(undefined),
    lastName: new FormControl<string | undefined>(undefined),
    phone: new FormControl<string | undefined>(undefined),
    email: new FormControl<string | undefined>(undefined),
    accept: new FormControl<boolean | undefined>(undefined),
    checkInAt: new FormControl<string | undefined>(undefined),
    comment: new FormControl<string | undefined>(undefined),
    declinedStatus: new FormControl<any>(undefined),
    examResult: new FormControl<any>(undefined),
    eventInvitationTypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    eventRegistrationSelectedOptions: new FormControl<AxUpdateEventRegistrationSelectedOptionDto[]>([]),
})
;
}
      