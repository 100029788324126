import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FilterNamesEnum, FormatDatePipe, PrependServerToFileSrcPipe } from '@axova-frontend-monorepo/axova-commons';
import {
  AxMontageplanTeamDto,
  AxMontageplanUsersDto,
  AxMontageplanUsersTeamPartial,
  AxMontageplanV2Service,
  AxTeam,
  AxTimerecordtypeNameEnum
} from '@axova-frontend-monorepo/axova-rest-api';
import { lastValueFrom } from 'rxjs';
import { ExceptionHandlingService } from '../../services/exception-handling/exception-handling.service';
import * as dayjs from 'dayjs';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../button/button.component';
import { AvatarComponent } from '../avatar/avatar.component';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    ButtonComponent,
    AvatarComponent,
    FormatDatePipe,
    PrependServerToFileSrcPipe
  ],
  selector: 'ax-ui-montageplan-day-overview',
  templateUrl: './montageplan-day-overview.component.html',
  styleUrls: ['./montageplan-day-overview.component.scss']
})
export class MontageplanDayOverviewComponent implements OnChanges {
  @Input({ required: true }) date!: string;
  @Input({ required: true }) teamsActive: AxTeam[] = [];
  public montageplanTeamDtos: AxMontageplanTeamDto[] = [];
  public teamMembers: AxMontageplanUsersDto = { teams: {} };
  public axTimerecordtypeNameEnum = AxTimerecordtypeNameEnum;

  constructor(
    private readonly axMontageplanV2Service: AxMontageplanV2Service,
    private readonly exceptionHandlingService: ExceptionHandlingService
  ) {
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['teamsActive'] && changes['teamsActive'].currentValue && changes['teamsActive'].currentValue !== changes['teamsActive'].previousValue && changes['teamsActive'].currentValue.length) {
      await this.loadDataForSelectedTeams();
    }
  }

  public async loadDataForSelectedTeams() {
    const activeTeamIdsAsString = this.teamsActive.map(team => `${team.id}`);
    await this.loadTeamMembers(activeTeamIdsAsString);
    try {
      const data = await lastValueFrom(this.axMontageplanV2Service.montageplanControllerGetMontageplanBlocksByTeamIdAndDaterange({
        teamIds: activeTeamIdsAsString,
        fromDate: dayjs(this.date).format('YYYY-MM-DD'),
        toDate: dayjs(this.date).format('YYYY-MM-DD')
      }));
      if (data && data.length) {
        this.montageplanTeamDtos = data[0].teams;
      }
    } catch (loadMontageplanDataException) {
      this.exceptionHandlingService.handleExceptionWithAlert('loadMontageplanDataException', loadMontageplanDataException);
    }
  }

  public isEntireTeamAbsent(montageplanUsersTeamPartials: AxMontageplanUsersTeamPartial[] = [], date: string) {
    let isAbsent = true;
    montageplanUsersTeamPartials.forEach(usersTeamPartial => {
      if (usersTeamPartial.timerecords[date].timerecordtypeName === AxTimerecordtypeNameEnum.Arbeit) {
        isAbsent = false;
      }
    });
    return isAbsent;
  }

  private async loadTeamMembers(teamIds: string[]) {
    if (teamIds.length > 0) {
      try {
        const newMembers = (await lastValueFrom(this.axMontageplanV2Service.montageplanControllerGetMontageplanUsersByTeamIdAndDaterange({
          fromDate: dayjs(this.date).format('YYYY-MM-DD'),
          toDate: dayjs(this.date).format('YYYY-MM-DD'),
          teamIds: teamIds
        })));
        teamIds.forEach(teamId => {
          this.teamMembers.teams[teamId] = newMembers.teams[teamId];
        });
      } catch (loadTeamsException) {
        this.exceptionHandlingService.handleExceptionWithAlert('loadTeamsException', loadTeamsException);
      }
    }
  }

  protected readonly FilterNamesEnum = FilterNamesEnum;
}
