<div class="ax-ui-breadcrumb">
  @for (breadcrumb of breadcrumbs; track breadcrumb; let last = $last) {
    <p class="ax-ui-breadcrumb__item">
      @if (!last) {
        <a [routerLink]="breadcrumb.route">
          @if (breadcrumb.icon) {
            <ax-ui-icon [name]="breadcrumb.icon"></ax-ui-icon>
          }
          <span>{{ breadcrumb.label | translate }}</span>
        </a>
      } @else {
        <p class="ax-ui-breadcrumb__item-last">
          @if (breadcrumb.icon) {
            <ax-ui-icon [name]="breadcrumb.icon"></ax-ui-icon>
          }
          <b>{{ breadcrumb.label | translate }}</b>
        </p>
      }
    </p>
    @if (!last) {
      <ax-ui-icon name="arrow_forward_ios"></ax-ui-icon>
    }
  }
</div>
