
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateOrUpdateUserconfigurationDtoFormGroup() {
return new FormGroup({
    configurationKey: new FormControl<string | undefined>('', [Validators.required]),
    configurationValue: new FormControl<string | undefined>('', [Validators.required]),
})
;
}
      