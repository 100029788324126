@if (!clickable) {
  <div
    [class.full-height]="fullHeight"
    [class.list-element]="listElement"
    [class.warning]="warning"
    [ngClass]="{'flex-row vertical-center': textCenteredVertically}"
    class="ax-ui-infoblock flex gap-16"
    tabindex="0">
    <ng-container *ngTemplateOutlet="infoblockContent"></ng-container>
  </div>
} @else {
  <a [routerLink]="route ? route : undefined"
     [class.full-height]="fullHeight"
     [class.list-element]="listElement"
     [class.warning]="warning"
     [href]="href ? href : null"
     [ngClass]="{'flex-row vertical-center': textCenteredVertically}"
     [target]="externalLink ? '_blank' : '_self'"
     class="ax-ui-infoblock flex gap-16 clickable ax-action-element"
     tabindex="0">
    <ng-container *ngTemplateOutlet="infoblockContent"></ng-container>
  </a>
}



<ng-template #infoblockContent>
  <div [class.avatar]="withAvatar"
       class="ax-ui-infoblock__icon">
    @if (!withAvatar) {
      <ax-ui-icon
        [name]="iconName">
      </ax-ui-icon>
    }
    @if (!withAvatar && clickable) {
      <ax-ui-icon
        [fontSize]="20"
        name="info">
      </ax-ui-icon>
    }
    @if (withAvatar) {
      <ax-ui-avatar
        [imgSrc]="avatarImgSrc"
        [size]="avatarSize"
        [square]="avatarSquare"
        [status]="avatarStatus" />
    }
  </div>
  <div class="ax-ui-infoblock__text">
    @if (label) {
      <label><small [innerHTML]="label"></small></label>
    }
    <p><b [innerHTML]="title"></b></p>
    @if (subtitle) {
      <small [innerHTML]="subtitle" class="color-light"></small>
    }
  </div>
</ng-template>
