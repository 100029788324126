import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { AlertDialogService } from '../../services/alert-dialog/alert-dialog.service';

@Directive({
    selector: '[axUiDnd]',
    standalone: true,
})
export class DndDirective {
  @HostBinding('class.file-over') fileOver = false;
  @Input() multiple = false;
  @Input() accept: string[] = [];
  @Output() fileDropped = new EventEmitter<any>();

  constructor(
    private readonly alertDialogService: AlertDialogService,
  ) {
  }

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = [];
    for (const file of evt.dataTransfer.files) {
      if (this.hasValidSuffix(file.name)) {
        files.push(file);
      }
    }
    if (!files.length) {
      this.alertDialogService.showAlert({ title: 'Unerlaubte Dateityp(en).', message: `Erlaubte Dateitypen: ${this.accept.join(', ')}`, feedbackState: 'error' });
      return;
    }
    if (!this.multiple && files.length > 1) {
      this.alertDialogService.showAlert({ title: 'Nur eine Datei erlaubt.', feedbackState: 'error' });
    } else if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }

  private hasValidSuffix(filename: string): boolean {
    return this.accept.some(suffix => filename.endsWith(suffix));
  }
}
