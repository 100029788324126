import { Pipe, PipeTransform } from '@angular/core';
import { AxDocumentProcessingStatusEnum } from '@axova-frontend-monorepo/axova-rest-api';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'getDocumentStateExplanation',
    standalone: true,
})
export class GetDocumentStateExplanationPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {
  }

  transform(processingStatus: AxDocumentProcessingStatusEnum | null): string {
    switch (processingStatus) {
      case (AxDocumentProcessingStatusEnum.New): {
        return this.translateService.instant('DOCUMENT_STATE_EXPLANATION_NEW');
      }
      case (AxDocumentProcessingStatusEnum.Pending): {
        return this.translateService.instant('DOCUMENT_STATE_EXPLANATION_PENDING');
      }
      case (AxDocumentProcessingStatusEnum.Done): {
        return this.translateService.instant('DOCUMENT_STATE_EXPLANATION_DONE');
      }
      case (AxDocumentProcessingStatusEnum.Proposal): {
        return this.translateService.instant('DOCUMENT_STATE_EXPLANATION_PROPOSAL');
      }
      case (AxDocumentProcessingStatusEnum.Failed): {
        return this.translateService.instant('DOCUMENT_STATE_EXPLANATION_FAILED');
      }
      case (AxDocumentProcessingStatusEnum.Skipped): {
        return this.translateService.instant('DOCUMENT_STATE_EXPLANATION_SKIPPED');
      }
      default: {
        return '';
      }
    }
  }
}
