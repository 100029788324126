import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexLegend, ApexNoData, ApexPlotOptions, ApexTitleSubtitle, ApexXAxis, ApexYAxis } from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis?: ApexYAxis;
  title: ApexTitleSubtitle;
  plotOptions?: ApexPlotOptions;
  noData?: ApexNoData;
  dataLabels?: ApexDataLabels,
  legend?: ApexLegend,
  fill?: ApexFill
};

export interface ChartSeries {
  name: string,
  data: Array<number>
}
