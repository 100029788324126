import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'ax-ui-arrow-link',
  standalone: true,
  imports: [CommonModule, IconComponent, RouterLink],
  templateUrl: './arrow-link.component.html',
  styleUrls: ['./arrow-link.component.scss'],
})
export class ArrowLinkComponent {
  @Input() routerLink = '';
  @Input() htmlLink = '';
  @Input() htmlLinkOpenInNewTab = false;
  @Input() text = '';
}
