import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'joinBy',
    standalone: true,
})
export class JoinByPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {
  }

  transform(array: Array<any>, separator = ', ', property?: string): string {
    if (!Array.isArray(array) || array.length === 0) {
      return '';
    }

    // Check if the array is of objects and a property name is provided
    if (property && array[0] && array[0][property]) {
      return array
        .map(item => this.translateService.instant(`${item[property]}`))
        .join(separator);
    } else if (typeof array[0] === 'string') {
      // Handle array of strings
      return array
        .map(item => this.translateService.instant(`${item}`))
        .join(separator);
    } else {
      // Return empty string if none of the conditions match
      return '';
    }
  }
}
