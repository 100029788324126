
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getSendLeadDtoFormGroup() {
return new FormGroup({
    firstname: new FormControl<string | undefined>('', [Validators.required]),
    lastname: new FormControl<string | undefined>('', [Validators.required]),
    street: new FormControl<string | undefined>('', [Validators.required]),
    zip: new FormControl<string | undefined>('', [Validators.required]),
    city: new FormControl<string | undefined>('', [Validators.required]),
    phone: new FormControl<string | undefined>('', [Validators.required]),
    email: new FormControl<string | undefined>('', [Validators.required]),
})
;
}
      