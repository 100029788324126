export enum FilterNamesEnum {
  AxOfficeKontakteTable = 'AxOfficeKontakteTable',
  AxOfficeVeranstaltungenTable = 'AxOfficeVeranstaltungenTable',
  AxOfficeMontageplanTeams = 'AxOfficeMontageplanTeams',
  AxOfficeMontageplanWeekOverviewTeams = 'AxOfficeMontageplanWeekOverviewTeams',
  AxOfficeMitarbeiterTable = 'AxOfficeMitarbeiterTable',
  AxOfficeAuswertungenFoerdergelder = 'AxOfficeAuswertungenFoerdergelder',
  AxOfficeMitarbeiterStundenuebersichtTable = 'AxOfficeMitarbeiterStundenuebersichtTable',
  AxOfficeBetriebsmittelTable = 'AxOfficeBetriebsmittelTable',
  AxOfficeBetriebsmittelLogbuchTable = 'AxOfficeBetriebsmittelLogbuchTable',
  AxOfficeAuswertungenMitarbeiterStundenauszugTable = 'AxOfficeAuswertungenMitarbeiterStundenauszugTable',
  AxOfficeAuswertungenMitarbeiterSaldouebertragTable = 'AxOfficeAuswertungenMitarbeiterSaldouebertragTable',
  AxOfficeAuswertungenMitarbeiterSaldouebersichtTable = 'AxOfficeAuswertungenMitarbeiterSaldouebersichtTable',
  AxOfficeArtikelTable = 'AxOfficeArtikelTable',
  AxOfficeGeruestbauTable = 'AxOfficeGeruestbauTable',
  AxOfficeProjekteTable = 'AxOfficeProjekteTable',
  AxOfficeElektroDrittkontrolleTable = 'AxOfficeElektroDrittkontrolleTable',
}
