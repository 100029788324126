import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { DropdownListComponent } from '../../dropdown-list/dropdown-list.component';
import { LoadingAndFeedbackState } from '../../../models/loading-and-feedback-state.type';
import { DropdownOption } from '@axova-frontend-monorepo/axova-commons';

import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../icon/icon.component';
import { LoadingSpinnerComponent } from '../../loading-spinner/loading-spinner.component';
import {
  AutoInitialWidthInputFieldDirective
} from '../../../directives/auto-initial-width-input-field/auto-initial-width-input-field.directive';

@Component({
  selector: 'ax-ui-input-field-with-options',
  standalone: true,
  templateUrl: './input-field-with-options.component.html',
  styleUrls: ['./input-field-with-options.component.scss'],
  imports: [
    TranslateModule,
    FormsModule,
    LoadingSpinnerComponent,
    IconComponent,
    DropdownListComponent,
    AutoInitialWidthInputFieldDirective
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFieldWithOptionsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputFieldWithOptionsComponent),
      multi: true
    }
  ]
})
export class InputFieldWithOptionsComponent implements OnInit, OnChanges, ControlValueAccessor, Validator, OnDestroy {
  @ViewChild('dropdown') dropdown!: DropdownListComponent;
  @ViewChild('inputFieldSearch') inputFieldSearch!: ElementRef;
  @ViewChild('inputField') inputField!: ElementRef;
  @Input() resetDropdownTrigger = false;

  // General config
  @Input() id = '';
  @Input() name = '';
  @Input() label = '';
  @Input() rows = 6;
  @Input() placeholder = '';
  @Input() value: any = '';
  @Input() valueLabel: any = '';
  @Input() valueDropdownOption!: DropdownOption;
  @Input() disabled = false;
  @Input() iconName = '';
  @Input() iconRight = false;
  @Input() tableInput = false;
  @Input() editable = false;
  @Input() megaSearch = false;
  @Input() maxWidthSmaller = false;
  @Input() maxWidth: number | string | undefined;
  @Input() setAutoHeight = true;
  // Validation
  @Input() required = false;
  @Input() invalid = false;
  @Input() hintMessage = '';
  @Input() hideHint = false;
  @Input() disableHint = false;
  @Input() maxLength: number | undefined;
  @Input() min: number | undefined;
  @Input() max: number | undefined;
  @Input() debounceTimeInMs = 500;
  @Input() formControlName = '';
  // Loading state
  @Input() showLoadingOnValueChange = false;
  @Input() loadingState: LoadingAndFeedbackState = 'none';
  // Dropdown
  @Input() dropdownOptions!: DropdownOption[];
  @Input() dynamicDropdownOptions = false;
  @Input() dropdownShowArrow = false;
  @Input() dropdownWidthFitContent = false;
  @Input() showClearButton = false;

  @Output() valueChange = new EventEmitter<any>();
  @Output() dropDownOptionChange: EventEmitter<DropdownOption> = new EventEmitter<DropdownOption>();
  @Output() loadingStateChange: EventEmitter<LoadingAndFeedbackState> = new EventEmitter<LoadingAndFeedbackState>();

  public currentCharLength = 0;
  private valueChangedSubject = new Subject<any>();
  private inputHintMessage = '';
  private formControl: FormControl | undefined;
  private isDateInput = false;

  constructor(
    private elementRef: ElementRef,
    @Optional() private controlContainer: ControlContainer
  ) {
  }

  @HostListener('document:click', ['$event.target'])
  public onDocumentClick(targetElement: HTMLElement): void {
    if (this.dropdown) {
      const clickedInside = this.elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.dropdown.dropDownOpen = false;
      }
    }
  }

  ngOnInit() {
    if (!this.id && this.name) {
      this.id = this.name;
    }
    this.maxLength = 10000;
    this.setOptionsFromFormControl();

    // save initial hintMessage from parent for validation usage
    this.inputHintMessage = this.hintMessage;

    this.valueChangedSubject
      .pipe(
        debounceTime(this.debounceTimeInMs),
        distinctUntilChanged()
      )
      .subscribe(value => {
        if (!this.required || value !== '') {
          if (this.showLoadingOnValueChange) {
            this.loadingState = 'loading';
            this.loadingStateChange.emit('loading');
          }
          this.valueChange.emit(value);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loadingState'] && changes['loadingState'].currentValue) {
      switch (changes['loadingState'].currentValue as LoadingAndFeedbackState) {
        case 'loading': {
          this.loadingStateChange.emit('loading');
          break;
        }
        case 'success': {
          this.loadingStateChange.emit('success');
          break;
        }
        case 'error': {
          this.loadingStateChange.emit('error');
          break;
        }
        case 'none': {
          this.loadingStateChange.emit('none');
          break;
        }
      }
    }
    if (changes['resetDropdownTrigger'] && this.resetDropdownTrigger) {
      this.resetDropdownSelection();
    }
  }

  ngOnDestroy() {
    try {
      this.valueChangedSubject.unsubscribe();
      this.loadingStateChange.unsubscribe();
    } catch {
      // nothing to catch
    }
  }

  public toggleDropDown() {
    this.dropdown.toggleDropDown();
  }

  public emitOptionChange(selectedOption: DropdownOption) {
    this.dropDownOptionChange.emit(selectedOption);
    this.value = selectedOption.value;
    this.valueLabel = selectedOption.label;
    this.updateValue(this.value);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {
  };

  updateValue(newValue: any) {
    if (newValue && newValue.target) {
      newValue = newValue.target.value;
    }
    this.currentCharLength = newValue.length;

    // prevent writing more characters if maxLength is set
    if (this.maxLength !== undefined && newValue.length > this.maxLength) {
      newValue = newValue.substring(0, this.maxLength);
      // Adjust currentLength if maxLength is exceeded
      this.currentCharLength = this.maxLength;
    }

    if (newValue === '') {
      newValue = null;
    }

    this.value = newValue;
    this.onChange(this.value);
    this.onTouched();
    this.validateNow();
    this.valueChangedSubject.next(this.value);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Used for real-time validation while user is typing rather
   * than only triggering when focus is lost on input
   */
  validateNow() {
    const control = new FormControl(this.value);
    this.validateInput(control);
  }

  validateInput(control: FormControl) {
    const value = control.value;

    // Reset invalid state at the beginning of validation
    this.invalid = false;

    if (control.touched) {
      // Check if the input is required and is empty
      if (value === null || value === undefined || value === '') {
        if (this.required) {
          this.invalid = true;
          this.hintMessage = 'Dieses Feld darf nicht leer sein';
          return { 'required': true };
        }
        return null; // If not required, and value is empty, it's valid
      }

      // Min/Max validation
      if (this.min !== undefined && value < this.min) {
        this.invalid = true;
        this.hintMessage = this.inputHintMessage ? this.inputHintMessage : `Wert muss mindestens ${this.min} sein`;
        return { 'min': { 'min': this.min, 'actual': value } };
      }
      if (this.max !== undefined && value > this.max) {
        this.invalid = true;
        this.hintMessage = this.inputHintMessage ? this.inputHintMessage : `Wert darf maximal ${this.max} sein`;
        return { 'max': { 'max': this.max, 'actual': value } };
      }

      // Maxlength validation
      if (this.maxLength !== undefined && value.length > this.maxLength) {
        this.invalid = true;
        this.hintMessage = `Die maximale Länge von ${this.maxLength} Zeichen ist erreicht`;
        return { 'maxlength': { 'requiredLength': this.maxLength, 'actualLength': value.length } };
      }
    }

    // If the value passes all validations
    return null;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.validateInput(control as FormControl);
  }

  public handleDropDownNavigationOnKeyboardEvent(event: KeyboardEvent) {
    if (this.dropdownOptions) {
      switch (event.key) {
        case ('Enter'): {
          this.dropdown.selectBestMatch();
          break;
        }
        case ('ArrowUp'): {
          this.dropdown.navigateOptionUpOrDown(-1);
          break;
        }
        case ('ArrowDown'): {
          this.dropdown.navigateOptionUpOrDown(1);
          break;
        }
        case ('Escape'): {
          this.dropdown.dropDownOpen = false;
          break;
        }
      }
    }
  }

  public resetDropdownSelection() {
    // Reset the selected option
    this.value = null;
    this.valueLabel = null;
  }

  /**
   * Clear the input.
   */
  public clear() {
    this.value = '';
    this.valueLabel = '';
    this.onChange(this.value);
    this.validateNow();
    this.valueChangedSubject.next(this.value);
  }

  private setOptionsFromFormControl() {
    if (this.controlContainer && this.formControlName) {
      this.formControl = this.controlContainer.control?.get(this.formControlName) as FormControl;
      if (this.formControl) {
        if (this.formControl.validator) {
          const validator = this.formControl.validator({} as AbstractControl);
          this.required = validator && validator['required'];
        }
      }
    }
  }
}
