<ax-office-page-header titleText="PROFIL"></ax-office-page-header>
<h2>{{ (profile$ | async)?.firstname }} {{ (profile$ | async)?.lastname }}</h2>
<h3>Rollen</h3>
<ul>
  @for (role of (profile$ | async)?.roles; track role) {
    <li>{{ role }}</li>
  }
</ul>
<h3>Berechtigungen</h3>
<ul>
  @for (permission of (profile$ | async)?.permissions; track permission) {
    <li>{{ permission }}</li>
  }
</ul>
