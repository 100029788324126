/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateEventDto } from '../models/ax-create-event-dto';
import { AxEvent } from '../models/ax-event';
import { AxUpdateEventDto } from '../models/ax-update-event-dto';

@Injectable({ providedIn: 'root' })
export class AxEventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `eventsControllerFindAll()` */
  static readonly EventsControllerFindAllPath = '/v1/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsControllerFindAll$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxEvent>>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventsService.EventsControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxEvent>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsControllerFindAll(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxEvent>> {
    return this.eventsControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxEvent>>): Array<AxEvent> => r.body)
    );
  }

  /** Path part for operation `eventsControllerCreate()` */
  static readonly EventsControllerCreatePath = '/v1/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsControllerCreate$Response(
    params: {
      body: AxCreateEventDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventsService.EventsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsControllerCreate(
    params: {
      body: AxCreateEventDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.eventsControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `eventsControllerFindById()` */
  static readonly EventsControllerFindByIdPath = '/v1/events/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsControllerFindById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEvent>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventsService.EventsControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEvent>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsControllerFindById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxEvent> {
    return this.eventsControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEvent>): AxEvent => r.body)
    );
  }

  /** Path part for operation `eventsControllerUpdate()` */
  static readonly EventsControllerUpdatePath = '/v1/events/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateEventDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventsService.EventsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateEventDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.eventsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
