/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxNews } from '../models/ax-news';

@Injectable({ providedIn: 'root' })
export class AxNewsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `newsControllerFindAll()` */
  static readonly NewsControllerFindAllPath = '/v1/news';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newsControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerFindAll$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxNews>>> {
    const rb = new RequestBuilder(this.rootUrl, AxNewsService.NewsControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxNews>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newsControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerFindAll(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxNews>> {
    return this.newsControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxNews>>): Array<AxNews> => r.body)
    );
  }

  /** Path part for operation `newsControllerFindOne()` */
  static readonly NewsControllerFindOnePath = '/v1/news/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newsControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxNews>> {
    const rb = new RequestBuilder(this.rootUrl, AxNewsService.NewsControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxNews>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newsControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerFindOne(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<AxNews> {
    return this.newsControllerFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxNews>): AxNews => r.body)
    );
  }

  /** Path part for operation `newsControllerDelete()` */
  static readonly NewsControllerDeletePath = '/v1/news/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newsControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxNewsService.NewsControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newsControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.newsControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
