<form #trixEditorContainer class="ax-input">
  <label [for]="id"><small><b>{{ label | translate }} @if (required) {
    <b class="ax-ui-required-asterisk"> *</b>
  }</b></small></label>
  <input [id]="id" type="hidden" name="content">
  <trix-editor #trixEditor
               class="ax-text-prose"
               placeholder="{{ placeholder | translate }}"
               input="{{id}}"></trix-editor>
</form>

