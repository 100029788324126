import { WidgetNamesEnum } from '../enums/widget-names.enum';

export interface Widget {
  name: WidgetNamesEnum;
  route?: string;
  gridPosition?: number
}

export interface WidgetGridConfig {
  activeWidgets: Widget[];
  inactiveWidgets: Widget[];
}
