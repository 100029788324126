import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmActionModalOptions } from '../../models/confirm-action-modal-options.model';
import { ModalComponent } from '../modal/modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../button/button.component';

@Component({
    selector: 'ax-ui-modal-confirm-action',
    templateUrl: './modal-confirm-action.component.html',
    styleUrls: ['./modal-confirm-action.component.scss'],
    standalone: true,
    imports: [
        ModalComponent,
        ButtonComponent,
        TranslateModule,
    ],
})
export class ModalConfirmActionComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmationModal') modalElement!: ModalComponent;
  @Input({ required: true }) modalOptions!: ConfirmActionModalOptions;
  @Output() userConfirmed: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() {
    if (!this.modalOptions.title && this.modalOptions.title !== '') {
      this.modalOptions.title = 'EINTRAG_LOESCHEN';
    }
    if (!this.modalOptions.infoText && this.modalOptions.infoText !== '') {
      this.modalOptions.infoText = 'EINTRAG_LOESCHEN_BESTAETIGUNGSTEXT';
    }
    if (!this.modalOptions.declineButtonText && this.modalOptions.declineButtonText !== '') {
      this.modalOptions.declineButtonText = 'ABBRECHEN';
    }
    if (!this.modalOptions.confirmButtonText && this.modalOptions.confirmButtonText !== '') {
      this.modalOptions.confirmButtonText = 'LOESCHEN';
    }
    if (this.modalOptions.hideCloseButton !== true && this.modalOptions.hideCloseButton !== false) {
      this.modalOptions.hideCloseButton = true
    }
  }

  ngAfterViewInit() {
    this.modalElement.open();
  }

  public async confirmAction() {
    await this.modalElement.close();
    this.userConfirmed.emit(true);
  }

  public async declineAction() {
    await this.modalElement.close();
    this.userConfirmed.emit(false);
  }
}
