import { Component, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
    selector: 'ax-ui-card-modal-opener',
    templateUrl: './card-modal-opener.component.html',
    styleUrls: ['./card-modal-opener.component.scss'],
    standalone: true,
    imports: [IconComponent],
})
export class CardModalOpenerComponent {
  @Input({ required: true }) label = '';
  @Input({ required: true }) iconName = '';
  @Input() actionRequired = false;
}
