
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateInteractionNoteDtoFormGroup() {
return new FormGroup({
    interactiontypeId: new FormControl<number | undefined>(1, [Validators.required]),
    contactId: new FormControl<number | undefined>(undefined, [Validators.required]),
    containerId: new FormControl<number | undefined>(undefined),
    projectId: new FormControl<number | undefined>(undefined),
    notes: new FormControl<string | undefined>('', [Validators.required]),
})
;
}
      