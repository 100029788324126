
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getCreateTaskDtoFormGroup() {
return new FormGroup({
    standardtaskId: new FormControl<number | undefined>(undefined),
    tasktypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    userId: new FormControl<number | undefined>(undefined),
    projectId: new FormControl<number | undefined>(undefined),
    teamId: new FormControl<number | undefined>(undefined),
    name: new FormControl<string | undefined>('', [Validators.required]),
    start: new FormControl<string | undefined>(undefined),
    end: new FormControl<string | undefined>(undefined),
    due: new FormControl<string | undefined>(undefined),
    status: new FormControl<string | undefined>(undefined),
    notes: new FormControl<string | undefined>(undefined),
    mandatory: new FormControl<boolean | undefined>(true, [requiredBoolean()]),
    active: new FormControl<boolean | undefined>(true, [requiredBoolean()]),
    sortableRank: new FormControl<number | undefined>(undefined),
    visibleForCustomer: new FormControl<boolean | undefined>(undefined),
    confirmationSent: new FormControl<string | undefined>(undefined),
    reminderSent: new FormControl<string | undefined>(undefined),
})
;
}
      