import { Component, Input, OnInit } from '@angular/core';
import { AxUsersV2Service, AxUserV2 } from '@axova-frontend-monorepo/axova-rest-api';
import { ExceptionHandlingService } from '../../../services/exception-handling/exception-handling.service';
import { lastValueFrom } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import { AvatarComponent } from '../../avatar/avatar.component';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
import { LabelComponent } from '../../label/label.component';
import { PrependServerToFileSrcPipe } from '@axova-frontend-monorepo/axova-commons';

@Component({
    selector: 'ax-ui-widget-geburtstage',
    templateUrl: './widget-geburtstage.component.html',
    styleUrls: ['./widget-geburtstage.component.scss'],
    standalone: true,
  imports: [
    WidgetBaseComponent,
    AvatarComponent,
    TranslateModule,
    LabelComponent,
    PrependServerToFileSrcPipe
  ]
})
export class WidgetGeburtstageComponent implements OnInit {
  @Input() route: string | undefined = '';
  public birthdayUsers: AxUserV2[] = [];

  constructor(
    private readonly axUsersV2Service: AxUsersV2Service,
    private readonly exceptionHandlingService: ExceptionHandlingService,
  ) {
  }

  async ngOnInit() {
    await this.loadBirthdays();
  }

  private async loadBirthdays() {
    try {
      this.birthdayUsers = await lastValueFrom(this.axUsersV2Service.usersControllerGetBirthdays({}));
    } catch (loadBirthdaysException) {
      this.exceptionHandlingService.handleExceptionWithAlert('loadBirthdaysException', loadBirthdaysException);
    }
  }
}
