import { GLOBALS } from './global.environment';

export const environment = GLOBALS;

// Overwrite settings for this environment.
environment.name = 'test';
environment.apiConfiguration.baseUrl = 'https://test-api.axova.cloud';
environment.appUrls.axovaOffice = 'https://test-office.axova.cloud';
environment.appUrls.app = 'https://test-app.axova.cloud';
environment.appUrls.website = 'http://localhost:4302';
environment.appUrls.appointmentScheduler = 'https://test-termin.axova.cloud';
environment.isLoggingEnabled.ngxs = true;
environment.isLoggingEnabled.logger = true;
environment.production = false;
