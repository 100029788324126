import { AfterViewInit, Directive, ElementRef, Inject, Input, PLATFORM_ID } from '@angular/core';
import { animate, Easing, EasingFunction, EasingGenerator, ElementOrSelector, inView, timeline, TimelineDefinition } from 'motion';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[axUiMotion]',
  standalone: true,
})
export class MotionDirective implements AfterViewInit {
  @Input() motionOptions: any;
  @Input() motionPreset: 'fadeInLeft' | 'fadeInRight' | 'fadeInBottom' | 'grow' = 'fadeInLeft';
  @Input() motionDurationInSeconds = 0.5;
  @Input() motionDelayInSeconds = 0.1;
  @Input() motionDeEasing: EasingGenerator | Easing | Easing[] | EasingFunction | undefined = [0.17, 0.55, 0.55, 1];
  @Input() motionElementInView = true;
  @Input() motionElementInViewAmount: 'any' | 'all' | number = 0.75;
  // Note: to make this work on angular component hosts, display: block is necessary
  @Input() motionAllChildrenStaggered = false;

  private fadeInLeftPreset = { opacity: [0, 1], translateX: [-40, 0] };
  private fadeInRightPreset = { opacity: [0, 1], translateX: [40, 0] };
  private fadeInBottomPreset = { opacity: [0, 1], translateY: [40, 0] };
  private growPreset = { transform: 'scale(1)' };
  private childrenElements: HTMLElement[] | undefined;

  constructor(
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      const element = this.elementRef.nativeElement;
      element.style.opacity = 0;

      if (this.motionAllChildrenStaggered) {
        this.childrenElements = Array.from(element.children) as HTMLElement[];
        this.childrenElements.forEach(element => {
          element.style.opacity = '0';
        });
      }

      if (!this.motionOptions) {
        switch (this.motionPreset) {
          case 'fadeInLeft':
            this.motionOptions = this.fadeInLeftPreset;
            break;
          case 'fadeInRight':
            this.motionOptions = this.fadeInRightPreset;
            break;
          case 'fadeInBottom':
            this.motionOptions = this.fadeInBottomPreset;
            break;
          case 'grow':
            this.motionOptions = this.growPreset;
            element.style.transform = 'scale(0.9)';
            break;
        }
      }

      const animation = (element: ElementOrSelector) => {
        animate(element, this.motionOptions, {
          duration: this.motionDurationInSeconds,
          delay: this.motionDelayInSeconds,
          easing: this.motionDeEasing,
        });
      };
      // animate the element
      if (this.motionElementInView) {
        inView(element, (info) => {
          if (this.motionAllChildrenStaggered && this.childrenElements) {
            element.style.opacity = 1;
            const sequence: TimelineDefinition = Array.from(this.childrenElements).map((child) => {
              return [
                child,
                {
                  ...this.motionOptions,
                },
                {
                  duration: this.motionDurationInSeconds !== 0.5 ? this.motionDurationInSeconds : 0.2,
                  delay: this.motionDelayInSeconds === 0.2 ? 0.01 : this.motionDelayInSeconds,
                  easing: this.motionDeEasing,
                },
              ];
            });
            timeline(sequence);
          } else {
            animation(info.target);
          }
        }, {
          amount: (this.motionAllChildrenStaggered && this.motionElementInViewAmount === 0.75) ? 0.5 : this.motionElementInViewAmount,
        });
      } else {
        animation(element);
      }
    }
  }
}
