<ax-ui-widget-base [route]="route"
                   iconName="cake"
                   title="{{'GEBURTSTAGE_NAECHSTE' | translate}}">
  <div class="ax-ui-widget-geburtstage">
    @if (birthdayUsers.length > 0) {
      <div class="ax-ui-widget-geburtstage__list">
        <div class="ax-description-details-list dd-row-padding">
          <dl>
            @for (user of birthdayUsers; track user) {
              <dt class="avatar">
                <ax-ui-avatar
                  [imgSrc]="user.userImage ? (user.userImage.fileSrcThumbnail | prependServerToFileSrc) : null"
                  [size]="'mini'"
                  alt="{{user.firstName}} {{user.lastName}}" />
                <b>{{ user.firstName }} {{ user.lastName }}</b>
              </dt>
              <dd class="avatar ax-ui-widget-geburtstage__list-dd">
                {{ user.birthdate }}
                <ax-ui-label [small]="true"
                             [text]="('WIRD' | translate) + ' ' + user.age"
                             variant="success" />
              </dd>
            }
          </dl>
        </div>
      </div>
    }
    @if (birthdayUsers.length === 0) {
      <p class="color-light">{{ 'GEBURTSTAGE_KEINE' | translate }}</p>
    }
  </div>
</ax-ui-widget-base>
