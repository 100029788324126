
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateAbsenceDtoFormGroup() {
return new FormGroup({
    start: new FormControl<string | undefined>(undefined, [Validators.required]),
    end: new FormControl<string | undefined>(undefined, [Validators.required]),
    timerecordtypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    comment: new FormControl<string | undefined>(''),
    created: new FormControl<string | undefined>(undefined),
    createdBy: new FormControl<number | undefined>(undefined),
    modified: new FormControl<string | undefined>(undefined),
    modifiedBy: new FormControl<number | undefined>(undefined),
})
;
}
      