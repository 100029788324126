/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';


@Injectable({ providedIn: 'root' })
export class AxFilesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `filesControllerView()` */
  static readonly FilesControllerViewPath = '/v1/files/view';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesControllerView()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesControllerView$Response(
    params: {
      folder: string;
      filename: string;
      width?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, AxFilesService.FilesControllerViewPath, 'get');
    if (params) {
      rb.query('folder', params.folder, {});
      rb.query('filename', params.filename, {});
      rb.query('width', params.width, {});
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filesControllerView$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesControllerView(
    params: {
      folder: string;
      filename: string;
      width?: number;
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.filesControllerView$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
