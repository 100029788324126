
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateEventDtoFormGroup() {
return new FormGroup({
    title: new FormControl<string | undefined>('', [Validators.required]),
    description: new FormControl<string | undefined>('', [Validators.required]),
    location: new FormControl<string | undefined>('', [Validators.required]),
    address: new FormControl<string | undefined>(''),
    startAt: new FormControl<string | undefined>(undefined, [Validators.required]),
    endAt: new FormControl<string | undefined>(undefined, [Validators.required]),
    registrationPossibleUntil: new FormControl<string | undefined>(undefined, [Validators.required]),
    organizerUserId: new FormControl<number | undefined>(undefined, [Validators.required]),
    eventTypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    eventImageId: new FormControl<number | undefined>(undefined),
    imageId: new FormControl<number | undefined>(undefined),
    active: new FormControl<boolean | undefined>(true),
    showEventInvitations: new FormControl<boolean | undefined>(false),
})
;
}
      