import { Pipe, PipeTransform } from '@angular/core';
import { getGoogleMapsLinkFromLocation } from '../functions/get-google-maps-link-from-location';

@Pipe({
    name: 'getGoogleMapsLinkFromLocation',
    standalone: true,
})
export class GetGoogleMapsLinkFromLocationPipe implements PipeTransform {
  transform(location: string | null): string {
    return getGoogleMapsLinkFromLocation(location);
  }
}
