import { Injectable, Type } from '@angular/core';
import { AlertDialogsDirective } from '../../directives/alert-dialogs/alert-dialogs.directive';
import { ModalDirective } from '../../directives/modal/modal.directive';
import { ModalComponent } from '../../components/modal/modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalHost!: ModalDirective;

  public setModalHost(host: AlertDialogsDirective) {
    this.modalHost = host;
  }

  public create(componentType: Type<any>, componentInputs: { [key: string]: any } = {}, modalInputs: { [key: string]: any } = {}): ModalComponent {
    const dialogRef = this.modalHost.viewContainerRef.createComponent(ModalComponent);
    for (const modalInputKey of Object.keys(modalInputs)) {
      dialogRef.setInput(modalInputKey, modalInputs[modalInputKey]);
    }
    const dialogRefInstance = dialogRef.instance;
    const dynamicComponentRef = this.modalHost.viewContainerRef.createComponent(componentType);
    for (const componentInputKey of Object.keys(componentInputs)) {
      dynamicComponentRef.setInput(componentInputKey, componentInputs[componentInputKey]);
    }
    dialogRefInstance.dynamicComponent = dynamicComponentRef;

    // return the reference in case we want to do something with it outside the service
    return dialogRefInstance;
  }
}
