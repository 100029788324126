import { Injectable } from '@angular/core';
import { AlertDialogService } from '../alert-dialog/alert-dialog.service';
import { AlertDialogOptions } from '../../models/alert-dialog-options.model';
import { LoggerService } from '@axova-frontend-monorepo/axova-commons';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ExceptionHandlingService {

  constructor(
    private readonly alertDialogService: AlertDialogService,
    private readonly router: Router,
  ) {
  }

  public handleExceptionWithAlert(exceptionName: string, exception: any, alertDialogOptions?: AlertDialogOptions) {
    // Log exception
    LoggerService.ERROR(this, exceptionName, exception);
    // Define default strings if no options are given
    if (!alertDialogOptions) {
      alertDialogOptions = {
        title: 'FEHLER_ALLGEMEIN',
        message: '',
        feedbackState: 'error',
      };
      // Add helpful error message from API if present
      try {
        exception.error = JSON.parse(exception.error);
      } catch (probablyNotJson) {
        // Nothing to do here.
      }
      if (exception.error && exception.error.message) {
        if (Array.isArray(exception.error.message)) {
          alertDialogOptions.message = exception.error.message.join(', ');
        } else {
          alertDialogOptions.message = exception.error.message;
        }
      }
      // Define alert title according to exception status
      if (exception.status) {
        alertDialogOptions.title = `FEHLER_${exception.status}`;
        this.alertDialogService.showAlert(alertDialogOptions);
      }
    } else {
      this.alertDialogService.showAlert(alertDialogOptions);
    }
  }

  public async handleExceptionWithErrorPageRedirect(exceptionName: string, exception: any) {
    if (exception.status !== 401) {
      LoggerService.ERROR(this, exceptionName, exception);
      window.history.replaceState(null, '', '/');
      await this.router.navigateByUrl(`fehler/${(exception as HttpErrorResponse).status}?failedRoute=${this.router.url}`);
    }
  }

  public isAccessForbiddenException(exception: any): boolean {
    return exception instanceof HttpErrorResponse && exception.status === 403;
  }
}
