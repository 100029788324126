
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getCreateUsertrainingDtoFormGroup() {
return new FormGroup({
    userId: new FormControl<number | undefined>(undefined, [Validators.required]),
    trainingtypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    documentId: new FormControl<number | undefined>(undefined),
    validFrom: new FormControl<string | undefined>(undefined),
    validTo: new FormControl<string | undefined>(undefined),
    visibleForUser: new FormControl<boolean | undefined>(true, [requiredBoolean()]),
})
;
}
      