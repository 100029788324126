import { Injectable } from '@angular/core';
import { AxLoggingService } from '@axova-frontend-monorepo/axova-rest-api';
import { LoggerService } from '@axova-frontend-monorepo/axova-commons';
import { debounceTime, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoggingToApiService {

  constructor(
    private readonly axLoggingService: AxLoggingService,
  ) {
  }

  /**
   * Send logs every 10 seconds to API.
   */
  public init(applicationName: string) {
    LoggerService.LOG_STREAM
      .pipe(
        debounceTime(10 * 1000),
      )
      .subscribe({
        next: async logEntry => {
          try {
            await lastValueFrom(this.axLoggingService.loggingControllerCreate({
              applicationName: applicationName,
              body: {
                created: new Date().toISOString(),
                severity: logEntry.severity,
                content: logEntry.content,
                class: logEntry.class,
              },
            }));
          } catch (logNotSent) {
            // Nothing to do here.
          }
        },
      });
  }
}
