import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { RadioButtonOptions } from '../../../models/radio-button-options.model';

import { TranslateModule } from '@ngx-translate/core';
import { RadioButtonComponent } from '../radio-button/radio-button.component';

@Component({
  selector: 'ax-ui-radio-group',
  standalone: true,
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  imports: [
    TranslateModule,
    RadioButtonComponent
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true
    }
  ]
})
export class RadioGroupComponent implements ControlValueAccessor, Validator, OnInit, AfterViewInit {
  @Input() options: RadioButtonOptions [] = [];
  @Input() id = '';
  @Input() legendText = '';
  @Input() value: string | boolean | number | undefined = '';
  @Input() required = false;
  @Input() directionHorizontal = false;
  @Input() formControlName = '';

  @Output() valueChange = new EventEmitter<any>();

  private formControl: FormControl | undefined;


  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() private controlContainer: ControlContainer
  ) {
  }

  ngOnInit() {
    this.setOptionsFromFormControl();
  }

  ngAfterViewInit() {
    if (this.value) {
      setTimeout(() => {
        this.onChange(this.value);
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  selectRadio(value: string | boolean | number): void {
    this.value = value;
    this.onChange(value);
    this.onTouched();
    this.valueChange.emit(this.value);
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange = (value: any) => {
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => {
  };

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.required && !this.value) {
      return { required: true };
    }
    return null;
  }

  setOptionsFromFormControl() {
    if (this.controlContainer && this.formControlName) {
      this.formControl = this.controlContainer.control?.get(this.formControlName) as FormControl;
      if (this.formControl) {
        if (this.formControl.value) {
          this.value = this.formControl.value;
        }
        if (this.formControl.validator) {
          const validator = this.formControl.validator({} as AbstractControl);
          this.required = validator && validator['required'];
        }
      }
    }
  }
}
