import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

export type AvatarStatus = 'none' | 'available' | 'busy' | 'absent';
export type AvatarSize = 'mini' | 'small' | 'default' | 'bigger' | 'large' | 'huge';

@Component({
  selector: 'ax-ui-avatar',
  standalone: true,
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  imports: [CommonModule],
})
export class AvatarComponent implements OnChanges {
  @Input() imgSrc!: string | null;
  @Input() status: AvatarStatus = 'none';
  @Input() alt = '';
  @Input() size: AvatarSize = 'default';
  @Input() fullWidth = false;
  @Input() square = false;
  @Input() initials = '';
  public showText = false;
  public showSunny = false;

  ngOnChanges() {
    if (this.imgSrc) {
      this.showText = false;
      this.showSunny = false;
    } else {
      this.imgSrc = 'assets/images/Sunny.webp';
      this.showSunny = true;
      this.showText = true;
    }
  }
}
