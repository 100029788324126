<div [class.close]="closeMenu" class="ax-ui-inline-nav-list">
  @if (showToggleButton) {
    <ax-ui-button (buttonClick)="toggleMenu()"
                  [iconName]="!closeMenu ? 'chevron_left' : 'chevron_right'"
                  [iconOnly]="true"
                  class="ax-ui-inline-nav-list__toggle"
                  variant="secondary" />
  }
  <div class="ax-ui-inline-nav-list__heading">
    <ng-content></ng-content>
  </div>
  <nav>
    <ax-ui-input-field (valueChange)="search($event)"
                       [disableHint]="true"
                       [showClearButton]="true"
                       [class.hide]="closeMenu"
                       iconName="search"
                       placeholder="SUCHEN" />
    <ul class="ax-list standalone margin-top-24 text-overflow-ellipsis" role="list">
      @for (navigationItem of navigationItemsFiltered; track navigationItem) {
        <li class="ax-action-element width-100"
            role="listitem"
            routerLinkActive="active">
          <a [routerLink]="getRouterLink(navigationItem)">
            <p>{{ navigationItem.label | translate }}</p>
          </a>
        </li>
      }
    </ul>
  </nav>
</div>
