/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateUsertrainingDto } from '../models/ax-create-usertraining-dto';
import { AxUpdateUsertrainingDto } from '../models/ax-update-usertraining-dto';
import { AxUsertraining } from '../models/ax-usertraining';

@Injectable({ providedIn: 'root' })
export class AxUsertrainingsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `usertrainingsControllerFindByUserId()` */
  static readonly UsertrainingsControllerFindByUserIdPath = '/v2/usertrainings/byUserId/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usertrainingsControllerFindByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  usertrainingsControllerFindByUserId$Response(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxUsertraining>>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsertrainingsV2Service.UsertrainingsControllerFindByUserIdPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxUsertraining>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usertrainingsControllerFindByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usertrainingsControllerFindByUserId(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<Array<AxUsertraining>> {
    return this.usertrainingsControllerFindByUserId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxUsertraining>>): Array<AxUsertraining> => r.body)
    );
  }

  /** Path part for operation `usertrainingsControllerDelete()` */
  static readonly UsertrainingsControllerDeletePath = '/v2/usertrainings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usertrainingsControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  usertrainingsControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsertrainingsV2Service.UsertrainingsControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usertrainingsControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usertrainingsControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.usertrainingsControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `usertrainingsControllerUpdate()` */
  static readonly UsertrainingsControllerUpdatePath = '/v2/usertrainings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usertrainingsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usertrainingsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateUsertrainingDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsertrainingsV2Service.UsertrainingsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usertrainingsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usertrainingsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateUsertrainingDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.usertrainingsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usertrainingsControllerCreate()` */
  static readonly UsertrainingsControllerCreatePath = '/v2/usertrainings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usertrainingsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usertrainingsControllerCreate$Response(
    params: {
      body: AxCreateUsertrainingDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsertrainingsV2Service.UsertrainingsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usertrainingsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usertrainingsControllerCreate(
    params: {
      body: AxCreateUsertrainingDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.usertrainingsControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
