import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { DropdownOption } from '@axova-frontend-monorepo/axova-commons';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { CheckboxComponent } from '../inputs/checkbox/checkbox.component';
import { IconComponent } from '../icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';

export type dropDownAlignment = 'left' | 'right' | 'bottom';

@Component({
  selector: 'ax-ui-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    CheckboxComponent,
    IconComponent,
    TranslateModule
  ]
})
export class DropdownListComponent implements OnChanges {
  @ViewChildren('optionList') optionList!: QueryList<ElementRef>;
  @ViewChildren('optionElements') optionElements!: QueryList<ElementRef>;

  @Input() options: DropdownOption[] = [];
  @Input() searchString!: string;
  @Input() initialOption: DropdownOption | undefined;
  @Input() dropDownOpen = false;
  @Input() maxWidth: number | string | undefined;
  @Input() alignment: dropDownAlignment = 'bottom';
  @Input() preventActiveHighlighting = false;
  @Input() dynamicDropdownOptions = false;
  @Input() dropdownWidthFitContent = false;
  @Input() noParentCard = false;
  @Input() withCheckboxes = false;
  @Input() listTitle = '';

  @Output() optionSelected: EventEmitter<DropdownOption> = new EventEmitter<DropdownOption>();
  @Output() optionRemoved: EventEmitter<DropdownOption> = new EventEmitter<DropdownOption>();
  @Output() selectedCheckboxOptionsChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() searchStringChange: EventEmitter<string> = new EventEmitter<string>();
  // To keep track of selected index
  public selectedIndex = -1;
  // For highlighting the best match
  public bestMatch: DropdownOption | undefined;
  public checkboxesSelectedAll = false;
  public checkboxesSelectedNone = false;
  public numberOfSelectedCheckboxes = 0;
  public filteredOptions: DropdownOption[] = [];
  public initialOptionsState: DropdownOption[] = [];
  public hasChanges = false;
  public groupedOptions: { [key: string]: DropdownOption[] } = {};
  public useGroups = false;
  private activeIndex = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchString'] && changes['searchString'].currentValue !== changes['searchString'].previousValue) {
      if (!this.dynamicDropdownOptions) {
        this.findBestMatch();
      }
    }
    if (changes['initialOption'] && changes['initialOption'].currentValue !== changes['initialOption'].previousValue) {
      const option = this.options.find(option => option.value === this.initialOption?.value);
      if (option) {
        this.selectOption(option);
        this.dropDownOpen = false;
      }
    }
    if (changes['options'] && changes['options'].currentValue && changes['options'].currentValue !== changes['options'].previousValue) {
      this.filteredOptions = this.options;
      this.initialOptionsState = JSON.parse(JSON.stringify(this.filteredOptions));
      if (this.dynamicDropdownOptions && !this.dropDownOpen) {
        this.toggleDropDown();
      }
      if (this.withCheckboxes) {
        this.groupOptions();
        this.numberOfSelectedCheckboxes = this.options.filter(option => option.selected === true).length;
        this.checkboxesSelectedAll = this.options.every(option => option.selected === true);
        this.checkboxesSelectedNone = this.options.every(option => option.selected === false);
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setDropDownMaxHeight();
  }

  public selectOption(option: DropdownOption) {
    this.searchString = option.label;
    this.optionSelected.emit(option);
    this.activeIndex = this.options.findIndex(arrayOption => arrayOption === option);
    this.selectedIndex = this.activeIndex;
    this.toggleDropDown();
  }

  public toggleDropDown() {
    if (this.options.length > 0) {
      if (!this.dropDownOpen) {
        this.bestMatch = this.options.find(option => option.label.includes(this.searchString));
        if (this.selectedIndex > -1) {
          this.selectedIndex = this.activeIndex;
        }
        // Store a deep copy of the options array to ensure the initial state is not modified later
        this.initialOptionsState = JSON.parse(JSON.stringify(this.filteredOptions));
      }
      this.dropDownOpen = !this.dropDownOpen;
      setTimeout(() => {
        this.setDropDownMaxHeight();
      }, 0);
    }
  }

  public findBestMatch() {
    if (this.searchString) {
      const searchLowerCase = `${this.searchString}`.toLowerCase();
      this.filteredOptions = this.options.filter(option => `${option.label}`.toLowerCase().includes(searchLowerCase));
      if (this.filteredOptions.length === 0) {
        if (this.dropDownOpen) {
          this.toggleDropDown();
        }
        return;
      }
      this.bestMatch = this.options.find(option => `${option.label}`.toLowerCase().includes(searchLowerCase));
      if (this.bestMatch) {
        if (this.searchString !== this.bestMatch.label) {
          this.dropDownOpen = true;
        }
        this.selectedIndex = this.options.findIndex(option => option.value === this.bestMatch?.value);
      }
    } else {
      this.filteredOptions = this.options;
      this.bestMatch = undefined;
      this.dropDownOpen = false;
    }
  }

  public selectBestMatch() {
    if (this.bestMatch) {
      const option = this.options[this.selectedIndex];
      this.selectOption(option);
    }
  }

  public navigateOptionUpOrDown(direction: 1 | -1) {
    if (this.options.length === 0 || !this.dropDownOpen) return;

    // Reset selectedIndex if it's out of bounds
    if (this.selectedIndex < 0 || this.selectedIndex >= this.options.length) {
      this.selectedIndex = (direction === 1) ? 0 : this.options.length - 1;
    } else {
      this.selectedIndex += direction;
      if (this.selectedIndex < 0) this.selectedIndex = this.options.length - 1;
      if (this.selectedIndex >= this.options.length) this.selectedIndex = 0;
    }

    // Update bestMatch
    this.bestMatch = this.options[this.selectedIndex];

    // Get the DOM element for the current option index.
    const optionElement = this.optionElements.toArray()[this.selectedIndex].nativeElement;

    // Scroll the option into view.
    optionElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  public selectCheckboxOption(option: DropdownOption) {
    if (option.selected) {
      this.numberOfSelectedCheckboxes -= 1;
      option.selected = false;
      this.optionRemoved.emit(option);
    } else {
      this.numberOfSelectedCheckboxes += 1;
      option.selected = true;
      this.optionSelected.emit(option);
    }
    this.updateHasChanges();
    this.checkboxesSelectedAll = this.options.every(option => option.selected === true);
    this.checkboxesSelectedNone = this.options.every(option => option.selected === false);
  }

  public actionForAllCheckboxes(action: 'select' | 'deselect') {
    this.numberOfSelectedCheckboxes = action === 'deselect' ? this.options.length : 0;
    this.options.forEach(option => {
      option.selected = action !== 'select';
      this.selectCheckboxOption(option);
    });
  }


  public acceptSelectedCheckboxOptions() {
    this.toggleDropDown();
    if (this.hasChanges) {
      this.selectedCheckboxOptionsChange.emit();
    }
  }

  public resetSelection() {
    this.filteredOptions = JSON.parse(JSON.stringify(this.initialOptionsState));
    this.updateHasChanges();
  }

  private setDropDownMaxHeight() {
    if (this.optionList.first) {
      const optionListElement = this.optionList.first.nativeElement;
      const viewportHeight = window.innerHeight;
      const dropdownPosition = optionListElement.getBoundingClientRect().top;
      const bottomMargin = 24;
      const maxHeight = viewportHeight - dropdownPosition - bottomMargin;
      optionListElement.style.maxHeight = `${maxHeight}px`;
    }
  }

  public getGroupObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  // Method to deeply compare two arrays of DropdownOption objects
  private updateHasChanges() {
    const arr1 = this.filteredOptions;
    const arr2 = this.initialOptionsState;
    if (arr1.length !== arr2.length) {
      this.hasChanges = true;
      return;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].selected !== arr2[i].selected) {
        this.hasChanges = true;
        break;
      } else this.hasChanges = false;
    }
  }

  private groupOptions() {
    // reset options when reopening the dropdown
    this.groupedOptions = {}
    // fill the options container
    this.filteredOptions.forEach(option => {
      const groupName = option.group || 'Weitere';
      if (!this.groupedOptions[groupName]) {
        this.groupedOptions[groupName] = [];
      }
      this.groupedOptions[groupName].push(option);
    });

    // Determine if we should use groups or not (based on the presence of any group other than 'none')
    this.useGroups = Object.keys(this.groupedOptions).some(groupName => groupName !== 'Weitere');
  }
}
