import { Component, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ax-ui-table-empty-state',
  templateUrl: './table-empty-state.component.html',
  styleUrls: ['./table-empty-state.component.scss'],
  standalone: true,
  imports: [
    TranslateModule
  ]
})
export class TableEmptyStateComponent {
  @Input({ required: true }) data: Array<any> | undefined;
}
