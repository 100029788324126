/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateUseremploymentDto } from '../models/ax-create-useremployment-dto';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxUpdateUseremploymentDto } from '../models/ax-update-useremployment-dto';
import { AxUseremployment } from '../models/ax-useremployment';

@Injectable({ providedIn: 'root' })
export class AxUseremploymentsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `useremploymentsControllerFindByUserId()` */
  static readonly UseremploymentsControllerFindByUserIdPath = '/v2/useremployments/byUserId/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `useremploymentsControllerFindByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  useremploymentsControllerFindByUserId$Response(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxUseremployment>>> {
    const rb = new RequestBuilder(this.rootUrl, AxUseremploymentsV2Service.UseremploymentsControllerFindByUserIdPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxUseremployment>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `useremploymentsControllerFindByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  useremploymentsControllerFindByUserId(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<Array<AxUseremployment>> {
    return this.useremploymentsControllerFindByUserId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxUseremployment>>): Array<AxUseremployment> => r.body)
    );
  }

  /** Path part for operation `useremploymentsControllerDelete()` */
  static readonly UseremploymentsControllerDeletePath = '/v2/useremployments/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `useremploymentsControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  useremploymentsControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxUseremploymentsV2Service.UseremploymentsControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `useremploymentsControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  useremploymentsControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.useremploymentsControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `useremploymentsControllerUpdate()` */
  static readonly UseremploymentsControllerUpdatePath = '/v2/useremployments/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `useremploymentsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  useremploymentsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateUseremploymentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxUseremployment>> {
    const rb = new RequestBuilder(this.rootUrl, AxUseremploymentsV2Service.UseremploymentsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxUseremployment>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `useremploymentsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  useremploymentsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateUseremploymentDto
    },
    context?: HttpContext
  ): Observable<AxUseremployment> {
    return this.useremploymentsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxUseremployment>): AxUseremployment => r.body)
    );
  }

  /** Path part for operation `useremploymentsControllerCreate()` */
  static readonly UseremploymentsControllerCreatePath = '/v2/useremployments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `useremploymentsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  useremploymentsControllerCreate$Response(
    params: {
      body: AxCreateUseremploymentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxUseremploymentsV2Service.UseremploymentsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `useremploymentsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  useremploymentsControllerCreate(
    params: {
      body: AxCreateUseremploymentDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.useremploymentsControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

}
