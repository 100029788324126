import { Pipe, PipeTransform } from '@angular/core';
import { AxEquipment } from '@axova-frontend-monorepo/axova-rest-api';

@Pipe({
    name: 'getEquipmentName',
    standalone: true,
})
export class GetEquipmentName implements PipeTransform {
  transform(equipment: AxEquipment): string {
    let name = ``;
    if (equipment.brand) {
      name += `${equipment.brand} `;
    }
    if (equipment.model) {
      name += `${equipment.model} `;
    }
    if (equipment.identifier) {
      name += `(${equipment.identifier})`;
    }
    return name;
  }
}
