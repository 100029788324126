import { Component } from '@angular/core';

import { IconComponent } from '../../../icon/icon.component';
import { ButtonComponent } from '../../../button/button.component';
import { TerminassistentWizardStepsNavService } from '../../services/terminassistent-wizard-steps-nav.service';
import { TranslateModule } from '@ngx-translate/core';
import { MotionDirective } from '../../../../directives/motion/motion.directive';
import { TooltipDirective } from '../../../../directives/tooltip/tooltip.directive';

@Component({
  selector: 'ax-ui-terminassistent-intro',
  standalone: true,
  imports: [
    IconComponent,
    ButtonComponent,
    MotionDirective,
    TranslateModule,
    TooltipDirective
  ],
  templateUrl: './terminassistent-intro.component.html',
  styleUrl: './terminassistent-intro.component.scss'
})
export class TerminassistentIntroComponent {
  constructor(
    public wizardStepsNavService: TerminassistentWizardStepsNavService
  ) {
  }
}
