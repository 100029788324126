/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxEmployeeGroupWebsiteDto } from '../models/ax-employee-group-website-dto';
import { AxEmployeeWebsiteDto } from '../models/ax-employee-website-dto';

@Injectable({ providedIn: 'root' })
export class AxWebsiteEmployeesV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `websiteEmployeesControllerGetEmployeesAll()` */
  static readonly WebsiteEmployeesControllerGetEmployeesAllPath = '/v2/website/employees/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteEmployeesControllerGetEmployeesAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteEmployeesControllerGetEmployeesAll$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxEmployeeWebsiteDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxWebsiteEmployeesV2Service.WebsiteEmployeesControllerGetEmployeesAllPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxEmployeeWebsiteDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteEmployeesControllerGetEmployeesAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteEmployeesControllerGetEmployeesAll(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxEmployeeWebsiteDto>> {
    return this.websiteEmployeesControllerGetEmployeesAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxEmployeeWebsiteDto>>): Array<AxEmployeeWebsiteDto> => r.body)
    );
  }

  /** Path part for operation `websiteEmployeesControllerGetEmployeesContactAdministration()` */
  static readonly WebsiteEmployeesControllerGetEmployeesContactAdministrationPath = '/v2/website/employees/contact-administration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteEmployeesControllerGetEmployeesContactAdministration()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteEmployeesControllerGetEmployeesContactAdministration$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxEmployeeWebsiteDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxWebsiteEmployeesV2Service.WebsiteEmployeesControllerGetEmployeesContactAdministrationPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxEmployeeWebsiteDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteEmployeesControllerGetEmployeesContactAdministration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteEmployeesControllerGetEmployeesContactAdministration(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxEmployeeWebsiteDto>> {
    return this.websiteEmployeesControllerGetEmployeesContactAdministration$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxEmployeeWebsiteDto>>): Array<AxEmployeeWebsiteDto> => r.body)
    );
  }

  /** Path part for operation `websiteEmployeesControllerGetEmployeesSolar()` */
  static readonly WebsiteEmployeesControllerGetEmployeesSolarPath = '/v2/website/employees/solar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteEmployeesControllerGetEmployeesSolar()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteEmployeesControllerGetEmployeesSolar$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxEmployeeGroupWebsiteDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxWebsiteEmployeesV2Service.WebsiteEmployeesControllerGetEmployeesSolarPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxEmployeeGroupWebsiteDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteEmployeesControllerGetEmployeesSolar$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteEmployeesControllerGetEmployeesSolar(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxEmployeeGroupWebsiteDto>> {
    return this.websiteEmployeesControllerGetEmployeesSolar$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxEmployeeGroupWebsiteDto>>): Array<AxEmployeeGroupWebsiteDto> => r.body)
    );
  }

  /** Path part for operation `websiteEmployeesControllerGetRandomEmployeeForTag()` */
  static readonly WebsiteEmployeesControllerGetRandomEmployeeForTagPath = '/v2/website/employees/random/{tag}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteEmployeesControllerGetRandomEmployeeForTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteEmployeesControllerGetRandomEmployeeForTag$Response(
    params: {
      tag: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEmployeeWebsiteDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxWebsiteEmployeesV2Service.WebsiteEmployeesControllerGetRandomEmployeeForTagPath, 'get');
    if (params) {
      rb.path('tag', params.tag, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEmployeeWebsiteDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteEmployeesControllerGetRandomEmployeeForTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteEmployeesControllerGetRandomEmployeeForTag(
    params: {
      tag: string;
    },
    context?: HttpContext
  ): Observable<AxEmployeeWebsiteDto> {
    return this.websiteEmployeesControllerGetRandomEmployeeForTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEmployeeWebsiteDto>): AxEmployeeWebsiteDto => r.body)
    );
  }

  /** Path part for operation `websiteEmployeesControllerGetEmployeesDach()` */
  static readonly WebsiteEmployeesControllerGetEmployeesDachPath = '/v2/website/employees/dach';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteEmployeesControllerGetEmployeesDach()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteEmployeesControllerGetEmployeesDach$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxEmployeeGroupWebsiteDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxWebsiteEmployeesV2Service.WebsiteEmployeesControllerGetEmployeesDachPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxEmployeeGroupWebsiteDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteEmployeesControllerGetEmployeesDach$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteEmployeesControllerGetEmployeesDach(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxEmployeeGroupWebsiteDto>> {
    return this.websiteEmployeesControllerGetEmployeesDach$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxEmployeeGroupWebsiteDto>>): Array<AxEmployeeGroupWebsiteDto> => r.body)
    );
  }

  /** Path part for operation `websiteEmployeesControllerGetEmployeesZentraleDienste()` */
  static readonly WebsiteEmployeesControllerGetEmployeesZentraleDienstePath = '/v2/website/employees/zentrale-dienste';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteEmployeesControllerGetEmployeesZentraleDienste()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteEmployeesControllerGetEmployeesZentraleDienste$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxEmployeeGroupWebsiteDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxWebsiteEmployeesV2Service.WebsiteEmployeesControllerGetEmployeesZentraleDienstePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxEmployeeGroupWebsiteDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteEmployeesControllerGetEmployeesZentraleDienste$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteEmployeesControllerGetEmployeesZentraleDienste(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxEmployeeGroupWebsiteDto>> {
    return this.websiteEmployeesControllerGetEmployeesZentraleDienste$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxEmployeeGroupWebsiteDto>>): Array<AxEmployeeGroupWebsiteDto> => r.body)
    );
  }

}
