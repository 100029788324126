/* tslint:disable */
/* eslint-disable */
export enum AxLsCharakteristikEnum {
  B = 'B',
  C = 'C',
  D = 'D',
  Dii = 'DII',
  Diii = 'DIII',
  Nhs = 'NHS'
}
