import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Store } from '@ngxs/store';
import { ProfileState, ProfileStateSetProfile, ProfileStateSetUser } from '@axova-frontend-monorepo/axova-state';
import { AxMyService } from '@axova-frontend-monorepo/axova-rest-api';
import { LoggerService } from '@axova-frontend-monorepo/axova-commons';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import { environment } from '@axova-frontend-monorepo/axova-environments';

require('dayjs/locale/de');

@Injectable()
export class AppInitializer {

  constructor(
    private readonly axMyService: AxMyService,
    private readonly store: Store,
    private readonly translateService: TranslateService,
  ) {
  }

  async init() {
    if (environment.name !== 'production') {
      document.querySelector('html')?.setAttribute('theme-test-mode', '');
    }
    try {
      dayjs.locale('de');
      dayjs.extend(isBetween);
      dayjs.extend(isSameOrBefore);
      dayjs.extend(isLeapYear);
      dayjs.extend(isoWeek);
      /**
       * Try to load profile. If not possible / not logged in, go to login page, otherwise go to dashboard afterward.
       */
      try {
        if (this.store.selectSnapshot(ProfileState.profile)) {
          const profile = await lastValueFrom(this.axMyService.myProfileControllerProfile());
          this.store.dispatch(new ProfileStateSetProfile(profile));
          if (profile.isUserEntity) {
            this.store.dispatch(new ProfileStateSetUser((profile as any).meta));
          }
        }
        this.translateService.setDefaultLang('de');
      } catch (profileException) {
        LoggerService.ERROR(this, 'profileException', profileException);
      }
    } catch (appInitializerException) {
      LoggerService.ERROR(this, 'appInitializerException', appInitializerException);
    }
  }
}
