import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LoadingStateModel } from './loading-state.model';
import { LoadingStateHideLoading, LoadingStateShowLoading } from './loading.actions';

const DEFAULT_STATE: LoadingStateModel = {
  showLoading: false,
};

@State<LoadingStateModel>({
  name: 'ax_state__loading',
  defaults: DEFAULT_STATE,
})
@Injectable()
export class LoadingState {

  @Selector()
  static showLoadingState(state: LoadingStateModel): boolean {
    return state.showLoading;
  }

  @Action(LoadingStateShowLoading)
  LoadingStateShowLoading(ctx: StateContext<LoadingStateModel>) {
    ctx.patchState({ showLoading: true });
  }

  @Action(LoadingStateHideLoading)
  LoadingStateHideLoading(ctx: StateContext<LoadingStateModel>) {
    ctx.patchState({ showLoading: false });
  }

  ngxsOnInit(ctx: StateContext<LoadingStateModel>) {
    ctx.patchState({ showLoading: false });
  }
}
