import { Route } from '@angular/router';
import { IsEmployeeGuard, IsLoggedInGuard, RequiresPermissionGuard } from '@axova-frontend-monorepo/axova-authentication';

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/login',
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth-page/auth-page.module').then(m => m.AuthPageModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard-page/dashboard-page.module').then(m => m.DashboardPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard],
    data: {
      navigationGroup: 1,
      navigationItem: {
        label: 'DASHBOARD',
        icon: 'space_dashboard',
      },
    },
  },
  {
    path: 'montageplan',
    loadChildren: () => import('./pages/montageplan-page/montageplan-page.module').then(m => m.MontageplanPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 1,
      navigationItem: {
        label: 'MONTAGEPLAN',
        icon: 'calendar_month',
      },
      requiredPermission: 'montageplan:read',
    },
  },
  {
    path: 'montageplan-week-overview',
    loadChildren: () => import('./pages/montageplan-week-overview-page/montageplan-week-overview-page.module').then(m => m.MontageplanWeekOverviewPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 1,
      navigationItem: {
        label: 'WOCHENUEBERSICHT',
        icon: 'date_range',
      },
      requiredPermission: 'montageplan:read',
    },
  },
  {
    path: 'dokumentenmanager',
    loadChildren: () => import('./pages/dokumentenmanager-page/dokumentenmanager-page.module').then(m => m.DokumentenmanagerPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 1,
      navigationItem: {
        label: 'DOKUMENTENMANAGER',
        icon: 'note_stack',
      },
      requiredPermission: 'documents:create',
    },
  },
  {
    path: 'chats',
    loadChildren: () => import('./pages/chats-page/chats-page.module').then(m => m.ChatsPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 1,
      navigationItem: {
        label: 'CHATS',
        icon: 'mark_unread_chat_alt',
      },
      requiredPermission: 'chats:read',
    },
  },
  {
    path: 'geo-informationen',
    loadChildren: () => import('./pages/geo-informationen-page/geo-informationen-page.module').then(m => m.GeoInformationenPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 1,
      navigationItem: {
        label: 'Geo Informationen',
        icon: 'map',
      },
      requiredPermission: 'geoinformations:read',
    },
  },
  {
    path: 'kontakte',
    loadChildren: () => import('./pages/kontakte-page/kontakte-page.module').then(m => m.KontaktePageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 2,
      navigationItem: {
        label: 'KONTAKTE',
        icon: 'contacts',
      },
      requiredPermission: 'contacts:read',
    },
  },
  {
    path: 'projekte',
    loadChildren: () => import('./pages/projekte-page/projekte-page.module').then(m => m.ProjektePageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 2,
      navigationItem: {
        label: 'PROJEKTE',
        icon: 'folder_copy',
      },
      requiredPermission: 'projects:read',
    },
  },
  {
    path: 'debitoren',
    loadChildren: () => import('./pages/debitoren-page/debitoren-page.module').then(m => m.DebitorenPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 2,
      navigationItem: {
        label: 'DEBITOREN',
        icon: 'savings',
      },
      requiredPermission: 'debtors:read',
    },
  },
  {
    path: 'kundensupport',
    loadChildren: () => import('./pages/kundensupport-page/kundensupport-page.module').then(m => m.KundensupportPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 2,
      navigationItem: {
        label: 'KUNDENSUPPORT',
        icon: 'live_help',
      },
      requiredPermission: 'tickets:read',
    },
  },
  {
    path: 'einkauf',
    loadChildren: () => import('./pages/einkauf-page/einkauf-page.module').then(m => m.EinkaufPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 2,
      navigationItem: {
        label: 'EINKAUF',
        icon: 'shopping_cart',
      },
      requiredPermission: 'orders:read',
    },
  },
  {
    path: 'artikel',
    loadChildren: () => import('./pages/artikel-page/artikel-page.module').then(m => m.ArtikelPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 2,
      navigationItem: {
        label: 'ARTIKEL',
        icon: 'package_2',
      },
      requiredPermission: 'items:read',
    },
  },
  {
    path: 'mitarbeiter',
    loadChildren: () => import('./pages/mitarbeiter-page/mitarbeiter-page.module').then(m => m.MitarbeiterPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 2,
      navigationItem: {
        label: 'MITARBEITER',
        icon: 'frame_person',
      },
      requiredPermission: 'users:read',
    },
  },
  {
    path: 'auswertungen',
    loadChildren: () => import('./pages/auswertungen-page/auswertungen-page.module').then(m => m.AuswertungenPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 2,
      navigationItem: {
        label: 'AUSWERTUNGEN',
        icon: 'query_stats',
      },
      requiredPermission: 'reports:read',
    },
  },
  {
    path: 'mitteilungen',
    loadChildren: () => import('./pages/mitteilungen-page/mitteilungen-page.module').then(m => m.MitteilungenPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 2,
      navigationItem: {
        label: 'MITTEILUNGEN',
        icon: 'newsmode',
      },
      requiredPermission: 'news:read',
    },
  },
  {
    path: 'veranstaltungen',
    loadChildren: () => import('./pages/veranstaltungen-page/veranstaltungen-page.module').then(m => m.VeranstaltungenPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 2,
      navigationItem: {
        label: 'VERANSTALTUNGEN',
        icon: 'calendar_today',
      },
      requiredPermission: 'events:read',
    },
  },
  {
    path: 'betriebsmittel',
    loadChildren: () => import('./pages/betriebsmittel-page/betriebsmittel-page.module').then(m => m.BetriebsmittelPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 2,
      navigationItem: {
        label: 'BETRIEBSMITTEL',
        icon: 'handyman',
      },
      requiredPermission: 'equipments:read',
    },
  },
  {
    path: 'geruestbau',
    loadChildren: () => import('./pages/geruestbau-page/geruestbau-page.module').then(m => m.GeruestbauPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 3,
      navigationItem: {
        label: 'GERUESTBAU',
        icon: 'house_siding',
      },
      requiredPermission: 'geruestbau:read',
    },
  },
  {
    path: 'elektro-drittkontrolle',
    loadChildren: () => import('./pages/elektro-drittkontrolle-page/elektro-drittkontrolle-page.module').then(m => m.ElektroDrittkontrollePageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard, RequiresPermissionGuard],
    data: {
      navigationGroup: 3,
      navigationItem: {
        label: 'ELEKTRO_DRITTKONTROLLE',
        icon: 'bolt',
      },
      requiredPermission: 'elektrodrittkontrolle:read',
    },
  },
  {
    path: 'stammdaten',
    loadChildren: () => import('./pages/stammdaten-page/stammdaten-page.module').then(m => m.StammdatenPageModule),
    canActivate: [IsLoggedInGuard, IsEmployeeGuard],
    data: {
      navigationGroup: 4,
      navigationItem: {
        label: 'STAMMDATEN',
        icon: 'settings',
      },
      requiredPermission: 'all:admin',
    },
  },
  {
    path: 'profil',
    canActivate: [IsLoggedInGuard, IsEmployeeGuard],
    loadChildren: () => import('./pages/profil-page/profil-page.module').then(m => m.ProfilPageModule),
    data: {
      navigationItem: {
        label: 'PROFIL',
        icon: 'account_box',
      },
    },
  },
  {
    path: 'fehler/:errorCode',
    loadChildren: () => import('./pages/fehler-page/fehler-page.module').then(m => m.FehlerPageModule),
    data: {
      navigationItem: {
        label: 'FEHLER',
        icon: 'warning',
      },
    },
  },
  {
    path: '**',
    loadChildren: () => import('./pages/fehler-page/fehler-page.module').then(m => m.FehlerPageModule),
    data: {
      navigationItem: {
        label: 'FEHLER',
        icon: 'warning',
      },
    },
  },
];
