<div [axUiModalFloating]="modalTemplate"
     [axUiModalFloatingPosition]="'right'"
     (axUiModalFloatingOpening)="dropDownOpening.emit()"
     class="ax-ui-actions-dropdown-button">
  <ax-ui-button [disabled]="dropdownOptions && !dropdownOptions.length"
                [iconOnly]="true"
                [small]="!defaultButtonSize"
                [whiteBackground]="buttonWhiteBackground"
                [iconName]="iconName"
                variant="secondary" />
  <ng-template #modalTemplate>
    <ax-ui-dropdown-list #actionsDropdown
                         (optionSelected)="selectAction($event)"
                         [dropDownOpen]="true"
                         [noParentCard]="true"
                         [options]="dropdownOptions"
                         [preventActiveHighlighting]="true"
                         alignment="right" />
  </ng-template>
</div>
