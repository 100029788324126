/* tslint:disable */
/* eslint-disable */
export enum AxRolesEnum {
  AxSuperuser = 'ax_superuser',
  AxBereichsleiter = 'ax_bereichsleiter',
  AxAbteilungsleiter = 'ax_abteilungsleiter',
  AxAdministration = 'ax_administration',
  AxEinkauf = 'ax_einkauf',
  AxExtern = 'ax_extern',
  AxFinanzen = 'ax_finanzen',
  AxGeschaeftsleitung = 'ax_geschaeftsleitung',
  AxIntern = 'ax_intern',
  AxKundenberater = 'ax_kundenberater',
  AxMonteur = 'ax_monteur',
  AxHr = 'ax_hr',
  AxPlaner = 'ax_planer',
  AxProjektleiter = 'ax_projektleiter',
  AxTeamleiter = 'ax_teamleiter',
  AxVerwaltungsrat = 'ax_verwaltungsrat',
  AxKunde = 'ax_kunde',
  AxElektriker = 'ax_elektriker'
}
