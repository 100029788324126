<label (click)="toggleRadio()"
       (mouseenter)="startVisualiser()"
       (mouseleave)="stopVisualiser()"
       [class.align-horizontal]="alignHorizontal"
       [class.disabled]="disabled"
       [class.selected]="selected"
       [class.with-icon]="iconName"
       [for]="id"
       class="ax-ui-radio">
  <p [innerHTML]="label | translate"></p>
  @if (visualizerFilePath) {
    <div class="ax-ui-radio__visualiser">
      <img [src]="visualizerPlaceholderFilePath"
           alt="Aktionsvisualisierung Platzhalter">
      @if (playVisualiser) {
        <img
          class="ax-ui-radio__visualiser-gif"
          [class.ax-ui-radio__visualiser--hidden]="hideVisualizer"
          [src]="visualizerFilePath"
          alt="Aktionsvisualisierung">
      }
    </div>
  }
  <input
    (blur)="onTouched()"
    [checked]="selected"
    [disabled]="disabled"
    [id]="id"
    [name]="name"
    [value]="value"
    type="radio" />
  @if (!iconName) {
    <span class="ax-ui-radio__checkmark"></span>
  }
  @if (iconName) {
    <ax-ui-icon
      [fontSize]="alignHorizontal ? 36 : 28"
      [name]="iconName" />
  }
</label>
