<span [class.hide-on-init]="hideOnInit"
      [class.page-loader]="pageLoader"
      [class.page-overlay]="pageOverlay"
      [class.show]="showLoading || showLoadingSuccess || showLoadingError"
      [ngStyle]="{
      'width' : iconSize.toString() + 'px',
      'height': iconSize.toString() + 'px',
      }"
      class="ax-ui-loading-spinner ax-loading-element">
    <ax-ui-icon [class.hide]="!showLoading"
                [class.loading]="showLoading"
                [fontSize]="iconSize"
                class="ax-ui-loading-spinner__loader ax-loading-element"
                name="progress_activity">
    </ax-ui-icon>
    <ax-ui-icon [class.hide]="!showLoadingSuccess"
                [class.show]="showLoadingSuccess"
                [fontSize]="iconSize"
                class="ax-ui-loading-spinner__success ax-loading-element"
                name="check">
    </ax-ui-icon>
   <ax-ui-icon [class.hide]="!showLoadingError"
               [class.show]="showLoadingError"
               [fontSize]="iconSize"
               class="ax-ui-loading-spinner__error ax-loading-element"
               name="report">
    </ax-ui-icon>
  </span>
