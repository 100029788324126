
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateEventInvitationDtoFormGroup() {
return new FormGroup({
    eventId: new FormControl<number | undefined>(undefined, [Validators.required]),
    audienceId: new FormControl<number | undefined>(undefined),
    userId: new FormControl<number | undefined>(undefined),
    contactId: new FormControl<number | undefined>(undefined),
    gender: new FormControl<string | undefined>(undefined),
    company: new FormControl<string | undefined>(undefined),
    firstName: new FormControl<string | undefined>(undefined),
    lastName: new FormControl<string | undefined>(undefined),
    phone: new FormControl<string | undefined>(''),
    email: new FormControl<string | undefined>(''),
    eventInvitationTypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
})
;
}
      