
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getRemoveRoletypeDtoFormGroup() {
return new FormGroup({
    roletypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
})
;
}
      