import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { CommonModule } from '@angular/common';

export type LabelVariant = 'neutral' | 'success' | 'warning' | 'error' | 'empty';
export type LabelBorderStyle = 'rounded' | 'square';

@Component({
  selector: 'ax-ui-label',
  standalone: true,
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  imports: [CommonModule, IconComponent],
})
export class LabelComponent {
  @Input() variant: LabelVariant = 'neutral';
  @Input() borderStyle: LabelBorderStyle = 'rounded';
  @Input() text: string | number = '';
  @Input() iconName = '';
  @Input() small = false;
  @Input() removable = false;
  @Input() clickable = false;
  @Input() disabled = false;
  @Input() textWrap = false;

  @Output() labelRemoveClick: EventEmitter<any> = new EventEmitter<any>();

  emitRemoveClick() {
    this.labelRemoveClick.emit();
  }
}
