/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateDocumentDto } from '../models/ax-create-document-dto';
import { AxDocument } from '../models/ax-document';
import { AxDocumentsGroupedByDocumenttypeDto } from '../models/ax-documents-grouped-by-documenttype-dto';

@Injectable({ providedIn: 'root' })
export class AxDocumentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `documentsControllerGetCustomerDocumentsForItem()` */
  static readonly DocumentsControllerGetCustomerDocumentsForItemPath = '/v1/documents/item/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsControllerGetCustomerDocumentsForItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerGetCustomerDocumentsForItem$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDocument>>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumentsService.DocumentsControllerGetCustomerDocumentsForItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDocument>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentsControllerGetCustomerDocumentsForItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerGetCustomerDocumentsForItem(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxDocument>> {
    return this.documentsControllerGetCustomerDocumentsForItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDocument>>): Array<AxDocument> => r.body)
    );
  }

  /** Path part for operation `documentsControllerGetProductImagesForItem()` */
  static readonly DocumentsControllerGetProductImagesForItemPath = '/v1/documents/item/{id}/productImages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsControllerGetProductImagesForItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerGetProductImagesForItem$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDocument>>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumentsService.DocumentsControllerGetProductImagesForItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDocument>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentsControllerGetProductImagesForItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerGetProductImagesForItem(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxDocument>> {
    return this.documentsControllerGetProductImagesForItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDocument>>): Array<AxDocument> => r.body)
    );
  }

  /** Path part for operation `documentsControllerGetDocumentsByDocumenttypeId()` */
  static readonly DocumentsControllerGetDocumentsByDocumenttypeIdPath = '/v1/documents/byDocumenttypeId/{documenttypeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsControllerGetDocumentsByDocumenttypeId()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerGetDocumentsByDocumenttypeId$Response(
    params: {
      documenttypeId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDocument>>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumentsService.DocumentsControllerGetDocumentsByDocumenttypeIdPath, 'get');
    if (params) {
      rb.path('documenttypeId', params.documenttypeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDocument>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentsControllerGetDocumentsByDocumenttypeId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerGetDocumentsByDocumenttypeId(
    params: {
      documenttypeId: number;
    },
    context?: HttpContext
  ): Observable<Array<AxDocument>> {
    return this.documentsControllerGetDocumentsByDocumenttypeId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDocument>>): Array<AxDocument> => r.body)
    );
  }

  /** Path part for operation `documentsControllerGetInternalDocumentsForEmployees()` */
  static readonly DocumentsControllerGetInternalDocumentsForEmployeesPath = '/v1/documents/internalDocumentsForEmployees';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsControllerGetInternalDocumentsForEmployees()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerGetInternalDocumentsForEmployees$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDocumentsGroupedByDocumenttypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumentsService.DocumentsControllerGetInternalDocumentsForEmployeesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDocumentsGroupedByDocumenttypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentsControllerGetInternalDocumentsForEmployees$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerGetInternalDocumentsForEmployees(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxDocumentsGroupedByDocumenttypeDto>> {
    return this.documentsControllerGetInternalDocumentsForEmployees$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDocumentsGroupedByDocumenttypeDto>>): Array<AxDocumentsGroupedByDocumenttypeDto> => r.body)
    );
  }

  /** Path part for operation `documentsControllerUploadFile()` */
  static readonly DocumentsControllerUploadFilePath = '/v1/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsControllerUploadFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  documentsControllerUploadFile$Response(
    params: {
      body: AxCreateDocumentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxDocument>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumentsService.DocumentsControllerUploadFilePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxDocument>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentsControllerUploadFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  documentsControllerUploadFile(
    params: {
      body: AxCreateDocumentDto
    },
    context?: HttpContext
  ): Observable<AxDocument> {
    return this.documentsControllerUploadFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxDocument>): AxDocument => r.body)
    );
  }

  /** Path part for operation `documentsControllerFixPhotosExifData()` */
  static readonly DocumentsControllerFixPhotosExifDataPath = '/v1/documents/fixPhotosExifData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsControllerFixPhotosExifData()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerFixPhotosExifData$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumentsService.DocumentsControllerFixPhotosExifDataPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentsControllerFixPhotosExifData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentsControllerFixPhotosExifData(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.documentsControllerFixPhotosExifData$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
