/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateNotificationDto } from '../models/ax-create-notification-dto';
import { AxNotification } from '../models/ax-notification';
import { AxUpdateNotificationDto } from '../models/ax-update-notification-dto';

@Injectable({ providedIn: 'root' })
export class AxNotificationsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `notificationsControllerFindByNewsId()` */
  static readonly NotificationsControllerFindByNewsIdPath = '/v2/notifications/byNewsId/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsControllerFindByNewsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsControllerFindByNewsId$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxNotification>>> {
    const rb = new RequestBuilder(this.rootUrl, AxNotificationsV2Service.NotificationsControllerFindByNewsIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxNotification>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationsControllerFindByNewsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsControllerFindByNewsId(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxNotification>> {
    return this.notificationsControllerFindByNewsId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxNotification>>): Array<AxNotification> => r.body)
    );
  }

  /** Path part for operation `notificationsControllerFindByEventId()` */
  static readonly NotificationsControllerFindByEventIdPath = '/v2/notifications/byEventId/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsControllerFindByEventId()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsControllerFindByEventId$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxNotification>>> {
    const rb = new RequestBuilder(this.rootUrl, AxNotificationsV2Service.NotificationsControllerFindByEventIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxNotification>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationsControllerFindByEventId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsControllerFindByEventId(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxNotification>> {
    return this.notificationsControllerFindByEventId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxNotification>>): Array<AxNotification> => r.body)
    );
  }

  /** Path part for operation `notificationsControllerCreate()` */
  static readonly NotificationsControllerCreatePath = '/v2/notifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationsControllerCreate$Response(
    params: {
      body: AxCreateNotificationDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxNotification>> {
    const rb = new RequestBuilder(this.rootUrl, AxNotificationsV2Service.NotificationsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxNotification>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationsControllerCreate(
    params: {
      body: AxCreateNotificationDto
    },
    context?: HttpContext
  ): Observable<AxNotification> {
    return this.notificationsControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxNotification>): AxNotification => r.body)
    );
  }

  /** Path part for operation `notificationsControllerDelete()` */
  static readonly NotificationsControllerDeletePath = '/v2/notifications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxNotificationsV2Service.NotificationsControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationsControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.notificationsControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationsControllerUpdate()` */
  static readonly NotificationsControllerUpdatePath = '/v2/notifications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateNotificationDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxNotification>> {
    const rb = new RequestBuilder(this.rootUrl, AxNotificationsV2Service.NotificationsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxNotification>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateNotificationDto
    },
    context?: HttpContext
  ): Observable<AxNotification> {
    return this.notificationsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxNotification>): AxNotification => r.body)
    );
  }

}
