import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { IconComponent } from '@axova-frontend-monorepo/axova-ui';

@Component({
  selector: 'ax-office-navigation-dialog-base',
  templateUrl: './navigation-dialog-base.component.html',
  styleUrls: ['./navigation-dialog-base.component.scss'],
  standalone: true,
  imports: [IconComponent]
})
export class NavigationDialogBaseComponent {
  @Input({ required: true }) title = '';
  public showDialog = false;
  public toggleButtonActive = false;
  public closeAnimation = false;

  constructor(
    private elementRef: ElementRef
  ) {
  }

  @HostListener('document:click', ['$event.target'])
  public onDocumentClick(targetElement: HTMLElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.closeDialog();
    }
  }

  public closeDialog(): void {
    this.closeAnimation = true;
    this.toggleButtonActive = false;
    // wait for
    setTimeout(() => {
      this.showDialog = false;
      this.closeAnimation = false;
    }, 250);
  }

  public toggleDialog() {
    if (this.showDialog) {
      this.closeDialog();
    } else {
      this.showDialog = true;
      this.toggleButtonActive = true;
    }
  }
}
