import { Component, Input } from '@angular/core';
import { AxInteraction } from '@axova-frontend-monorepo/axova-rest-api';
import { FormatDatePipe } from '@axova-frontend-monorepo/axova-commons';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { InfoblockComponent } from '../infoblock/infoblock.component';
import { IconComponent } from '../icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'ax-ui-interactions-timeline',
  templateUrl: './interactions-timeline.component.html',
  styleUrls: ['./interactions-timeline.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    IconComponent,
    InfoblockComponent,
    RouterLink,
    TranslateModule,
    FormatDatePipe
  ]
})
export class InteractionsTimelineComponent {
  @Input({ required: true }) interactions: AxInteraction[] = [];

  public getBadgeIconName(interactiontypeId: number | null, index: number): string {
    switch (interactiontypeId) {
      case 2:
        switch (this.interactions[index].activitytypeId) {
          case 2:
            return 'mail';
          case 3:
            return 'handshake';
          case 4:
            return 'local_post_office';
          case 5:
            return 'calendar_month';
          default: {
            return 'headset_mic';
          }
        }
      case 3:
        return 'task_alt';
      default:
        return 'sticky_note_2';
    }
  }
}
