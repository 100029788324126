
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateEventRegistrationSelectedOptionDtoFormGroup() {
return new FormGroup({
    eventInvitationId: new FormControl<number | undefined>(undefined),
    eventRegistrationOptionId: new FormControl<number | undefined>(undefined),
    eventRegistrationOptionGroupId: new FormControl<number | undefined>(undefined),
})
;
}
      