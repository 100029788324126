import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getCategoryLetterByCategoryIdPipe',
    standalone: true
})
export class GetCategoryLetterByCategoryIdPipe implements PipeTransform {
  transform(categoryId: number | null | undefined) {
    switch (categoryId) {
      case 1:
      case 5:
      case 8:
        return 'L';
      case 2:
        return 'K';
      default:
        return '-';
    }
  }
}
