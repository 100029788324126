
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getUpdateRoletypeDtoFormGroup() {
return new FormGroup({
    name: new FormControl<string | undefined>('', [Validators.required]),
})
;
}
      