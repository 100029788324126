import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { environment } from '@axova-frontend-monorepo/axova-environments';

@Injectable({
  providedIn: 'root'
})
@Pipe({
    name: 'prependServerToFileSrc',
    standalone: true,
})
export class PrependServerToFileSrcPipe implements PipeTransform {
  transform(fileSrc: string | null | undefined): string {
    if (fileSrc) {
      return `${environment.apiConfiguration.baseUrl}${fileSrc}`;
    }
    return '';
  }
}
