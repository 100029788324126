import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AxContact, AxContactAndProjectsResultDto, AxProject } from '@axova-frontend-monorepo/axova-rest-api';
import { GetCategoryLetterByCategoryIdPipe, HighlightPipe } from '@axova-frontend-monorepo/axova-commons';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@axova-frontend-monorepo/axova-ui';
import { RouterLink } from '@angular/router';

export interface MegasearchResultClickedInterface {
  axContactAndProjectsResultDto: AxContactAndProjectsResultDto;
  selectedProject?: AxProject;
}

@Component({
  selector: 'ax-office-megasearch-result-contact-and-projects',
  templateUrl: './megasearch-result-contact-and-projects.component.html',
  styleUrls: ['./megasearch-result-contact-and-projects.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    ButtonComponent,
    TranslateModule,
    HighlightPipe,
    GetCategoryLetterByCategoryIdPipe
  ]
})
export class MegasearchResultContactAndProjectsComponent {
  @Input({ required: true }) searchterm!: string;
  @Input({ required: true }) contactAndProjects!: AxContactAndProjectsResultDto;
  @Input() renderContactAsLink = false;
  @Input() renderProjectAsLink = false;
  @Output() contactClicked = new EventEmitter<MegasearchResultClickedInterface>();
  @Output() projectClicked = new EventEmitter<MegasearchResultClickedInterface>();

  public getPropertyValueFromEntity(property: string, entity: AxContact | AxProject) {
    if ([
      'numberqualified',
      'firstname',
      'lastname',
      'company',
      'street',
      'zipcode',
      'city'
    ].indexOf(property) > -1) {
      return undefined;
    }
    try {
      const objectKeys = Object.keys(entity);
      for (const key of objectKeys) {
        if (key && (key === property || key.toLowerCase() === property)) {
          let propertyValue = (entity as any)[key];
          if (typeof propertyValue === 'object') {
            propertyValue = propertyValue.name || undefined;
          }
          return propertyValue;
        }
      }
    } catch (propertyNotFound) {
      return '-';
    }
  }
}
