
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getUpdateTimerecordDtoFormGroup() {
return new FormGroup({
    plannedHours: new FormControl<number | undefined>(undefined),
    expensesEntitled: new FormControl<boolean | undefined>(undefined),
    expensesPaid: new FormControl<string | undefined>(undefined),
    wayExpensesPaid: new FormControl<string | undefined>(undefined),
})
;
}
      