import { AxProfileDto, AxUser } from '@axova-frontend-monorepo/axova-rest-api';

export class ProfileStateSetProfile {
  static readonly type = '[UserState] Set profile';

  constructor(public profile: AxProfileDto) {
  }
}

export class ProfileStateSetUser {
  static readonly type = '[UserState] Set user';

  constructor(public user: AxUser) {
  }
}
