import { Component, Input } from '@angular/core';
import { DescriptionDetails } from '../../models/descriptionDetails.model';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { AvatarComponent } from '../avatar/avatar.component';

@Component({
    selector: 'ax-ui-description-details-list',
    templateUrl: './description-details-list.component.html',
    styleUrls: ['./description-details-list.component.scss'],
    standalone: true,
    imports: [
        AvatarComponent,
        RouterLink,
        TranslateModule,
    ],
})
export class DescriptionDetailsListComponent {
  @Input({ required: true }) descriptionDetailList: DescriptionDetails[] = [];
  @Input() additionalPadding = false;
}
