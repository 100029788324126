import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

export type LogoVariant = 'default' | 'white';
export type LogoContext = 'default' | 'office';

@Component({
  selector: 'ax-ui-logo',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {
  @Input() variant: LogoVariant = 'default';
  @Input() context: LogoContext = 'default';
}
