/* tslint:disable */
/* eslint-disable */
export enum AxMontageplanLogActionEnum {
  Swapped = 'SWAPPED',
  MovedManually = 'MOVED_MANUALLY',
  MovedAutomatically = 'MOVED_AUTOMATICALLY',
  Shortened = 'SHORTENED',
  Extended = 'EXTENDED',
  Inserted = 'INSERTED'
}
