import { FormGroup } from '@angular/forms';

export const getFormgroupErrors = function(formgroup: FormGroup): any {
  const result: { [key: string]: any } = {};
  Object.keys(formgroup.controls).forEach((key: string) => {
    const controlErrors = formgroup.controls[key].errors;
    if (controlErrors) {
      result[key] = controlErrors;
    }
  });
  return result;
};
