/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCalculationitem } from '../models/ax-calculationitem';
import { AxDocument } from '../models/ax-document';
import { AxItemsForProjectDto } from '../models/ax-items-for-project-dto';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxProject } from '../models/ax-project';
import { AxTask } from '../models/ax-task';

@Injectable({ providedIn: 'root' })
export class AxProjectsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `projectsControllerFindAll()` */
  static readonly ProjectsControllerFindAllPath = '/v1/projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFindAll$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 20
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> id:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>id</li>
     * <li>revenueChf</li></ul>
     */
      sortBy?: Array<string>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> 
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul></ul>
     */
      searchBy?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxProject>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerFindAllPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.active', params['filter.active'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxProject>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'active'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFindAll(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 20
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> id:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>id</li>
     * <li>revenueChf</li></ul>
     */
      sortBy?: Array<string>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> 
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul></ul>
     */
      searchBy?: string;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxProject>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
};
};
}> {
    return this.projectsControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxProject>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxProject>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `projectsControllerFindAllPublic()` */
  static readonly ProjectsControllerFindAllPublicPath = '/v1/projects/public';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerFindAllPublic()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFindAllPublic$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<{
}>>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerFindAllPublicPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{
        }>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerFindAllPublic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFindAllPublic(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<{
}>> {
    return this.projectsControllerFindAllPublic$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<{
}>>): Array<{
}> => r.body)
    );
  }

  /** Path part for operation `projectsControllerFindOne()` */
  static readonly ProjectsControllerFindOnePath = '/v1/projects/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFindOne$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxProject>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxProject>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFindOne(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxProject> {
    return this.projectsControllerFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxProject>): AxProject => r.body)
    );
  }

  /** Path part for operation `projectsControllerGetItemsForProject()` */
  static readonly ProjectsControllerGetItemsForProjectPath = '/v1/projects/{id}/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetItemsForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetItemsForProject$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxItemsForProjectDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerGetItemsForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxItemsForProjectDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetItemsForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetItemsForProject(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxItemsForProjectDto> {
    return this.projectsControllerGetItemsForProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxItemsForProjectDto>): AxItemsForProjectDto => r.body)
    );
  }

  /** Path part for operation `projectsControllerGetCalculationitemsForProject()` */
  static readonly ProjectsControllerGetCalculationitemsForProjectPath = '/v1/projects/{id}/calculationitems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetCalculationitemsForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetCalculationitemsForProject$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxCalculationitem>>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerGetCalculationitemsForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxCalculationitem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetCalculationitemsForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetCalculationitemsForProject(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxCalculationitem>> {
    return this.projectsControllerGetCalculationitemsForProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxCalculationitem>>): Array<AxCalculationitem> => r.body)
    );
  }

  /** Path part for operation `projectsControllerGetTasksForProject()` */
  static readonly ProjectsControllerGetTasksForProjectPath = '/v1/projects/{id}/tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetTasksForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetTasksForProject$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxTask>>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerGetTasksForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxTask>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetTasksForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetTasksForProject(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxTask>> {
    return this.projectsControllerGetTasksForProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxTask>>): Array<AxTask> => r.body)
    );
  }

  /** Path part for operation `projectsControllerGetBaustellenDispoTasksForProject()` */
  static readonly ProjectsControllerGetBaustellenDispoTasksForProjectPath = '/v1/projects/{id}/tasks/baustellendispo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetBaustellenDispoTasksForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetBaustellenDispoTasksForProject$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxTask>>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerGetBaustellenDispoTasksForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxTask>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetBaustellenDispoTasksForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetBaustellenDispoTasksForProject(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxTask>> {
    return this.projectsControllerGetBaustellenDispoTasksForProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxTask>>): Array<AxTask> => r.body)
    );
  }

  /** Path part for operation `projectsControllerGetAppointmentsForProject()` */
  static readonly ProjectsControllerGetAppointmentsForProjectPath = '/v1/projects/{id}/appointments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetAppointmentsForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetAppointmentsForProject$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxTask>>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerGetAppointmentsForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxTask>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetAppointmentsForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetAppointmentsForProject(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxTask>> {
    return this.projectsControllerGetAppointmentsForProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxTask>>): Array<AxTask> => r.body)
    );
  }

  /** Path part for operation `projectsControllerGetDocumentsForProject()` */
  static readonly ProjectsControllerGetDocumentsForProjectPath = '/v1/projects/{id}/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetDocumentsForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetDocumentsForProject$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDocument>>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerGetDocumentsForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDocument>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetDocumentsForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetDocumentsForProject(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxDocument>> {
    return this.projectsControllerGetDocumentsForProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDocument>>): Array<AxDocument> => r.body)
    );
  }

}
