<div class="ax-office-layout__content-headline">
  <h2>{{ 'AKTIVITAETSVERLAUF' | translate }}</h2>
</div>
@if (interactions && interactions.length > 0) {
  <div class="ax-ui-interactions-timeline">
    @for (interaction of interactions; track interaction; let i = $index) {
      <div
        class="ax-ui-interactions-timeline__item">
        <div class="ax-ui-interactions-timeline__item-badge">
          <div
            [ngClass]="{'success' : interaction.endtime && interaction.interactiontypeId === 3, 'todo' : !interaction.endtime && interaction.interactiontypeId === 3}"
            class="ax-ui-interactions-timeline__item-badge-icon">
            <ax-ui-icon [name]="getBadgeIconName(interaction.interactiontypeId, i)" />
          </div>
        </div>
        <div class="ax-ui-interactions-timeline__item-body">
          <div class="note">
            <p>
              <b>{{ interaction.interactiontype.name }}{{ (interaction.interactiontypeId !== 1 && interaction.subject) ? ': ' + interaction.subject : '' }}{{ (interaction.interactiontypeId === 2 && interaction.activitytype) ? ': ' + interaction.activitytype.name + (interaction.outcometype ? ' - ' + interaction.outcometype.name : '') : '' }}</b>
            </p>
            @if (interaction.notes) {
              <p class="margin-top-8">{{ interaction.notes }}</p>
            }
            @if (interaction.assigneeId || interaction.duetime || interaction.endtime) {
              <div
                class="flex flex-row vertical-center margin-top-8">
                @if (interaction.assignee && !interaction.endtime) {
                  <ax-ui-infoblock
                    [clickable]="true"
                    [route]="'/mitarbeiter/read/' + interaction.assigneeId"
                    [textCenteredVertically]="true"
                    [title]="interaction.assignee.firstName + ' ' + interaction.assignee.lastName"
                    [withAvatar]="true"
                    avatarSize="mini"
                    avatarStatus="none" />
                }
                @if (interaction.endtime && interaction.modifiedByUser) {
                  <ax-ui-infoblock
                    [clickable]="true"
                    [route]="'/mitarbeiter/read/' + interaction.modifiedByUser.id"
                    [textCenteredVertically]="true"
                    [title]="interaction.modifiedByUser.firstName + ' ' + interaction.modifiedByUser.lastName"
                    [withAvatar]="true"
                    avatarSize="mini"
                    avatarStatus="none" />
                }
                @if (interaction.duetime && !interaction.endtime) {
                  <small>{{ 'ERLEDIGEN_BIS' | translate }} {{ interaction.duetime | formatDate }}</small>
                }
                @if (interaction.endtime) {
                  <small>{{ 'ERLEDIGT_AM' | translate }} {{ interaction.endtime | formatDate }}</small>
                }
                @if (interaction.interactiontypeId === 3 && !interaction.endtime) {
                  <small>{{ 'ERLEDIGEN' | translate }}</small>
                }
              </div>
            }
          </div>
          <div class="flex gap-48 space-between">
            <small class="color-light">{{ interaction.created | formatDate: 'DD.MM.YYYY, HH:mm [Uhr]' }}</small>
            @if (interaction.createdByUser) {
              <small class="color-light">
                {{ 'ERSTELLT_VON' | translate }} <a [routerLink]="'/mitarbeiter/read/' + interaction.createdBy">
                {{ interaction.createdByUser.firstName }} {{ interaction.createdByUser.lastName }}</a>
              </small>
            }
          </div>
        </div>
      </div>
    }
  </div>
} @else {
  <p class="color-light">{{ 'AKTIVITAET_KEINE' | translate }}<p>
}

