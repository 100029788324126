
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getUpdateInteractionDtoFormGroup() {
return new FormGroup({
    interactiontypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    contactId: new FormControl<number | undefined>(undefined, [Validators.required]),
    containerId: new FormControl<number | undefined>(undefined),
    projectId: new FormControl<number | undefined>(undefined),
    activitytypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    outcometypeId: new FormControl<number | undefined>(undefined),
    assigneeId: new FormControl<number | undefined>(undefined),
    salesworkflowId: new FormControl<number | undefined>(undefined),
    subject: new FormControl<string | undefined>('', [Validators.required]),
    notes: new FormControl<string | undefined>(undefined),
    starttime: new FormControl<string | undefined>(undefined),
    endtime: new FormControl<string | undefined>(undefined),
    duetime: new FormControl<string | undefined>(undefined),
    interactiontime: new FormControl<string | undefined>(undefined),
    duration: new FormControl<number | undefined>(undefined),
    published: new FormControl<boolean | undefined>(true, [requiredBoolean()]),
})
;
}
      