import { Component } from '@angular/core';

import { TerminassistentWizardStepsNavService } from '../../services/terminassistent-wizard-steps-nav.service';
import { IconComponent } from '../../../icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'ax-ui-terminassistent-wizard-steps',
  standalone: true,
  imports: [
    IconComponent,
    NgClass
  ],
  templateUrl: './terminassistent-wizard-steps.component.html',
  styleUrl: './terminassistent-wizard-steps.component.scss'
})
export class TerminassistentWizardStepsComponent {
  constructor(
    public wizardStepsNavService: TerminassistentWizardStepsNavService
  ) {
  }
}
