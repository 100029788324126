import { Pipe, PipeTransform } from '@angular/core';
import { isDateInFuture } from '../functions/is-date-in-future';

@Pipe({
    name: 'isDateInFuture',
    standalone: true,
})
export class IsDateInFuturePipe implements PipeTransform {
  transform(date: string | Date | undefined | null, includeToday = false): boolean {
    return isDateInFuture(date, includeToday);
  }
}
