import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { TableHeaderOptions } from '../../models/table-header-options.model';

import { IconComponent } from '../icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ax-ui-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
  standalone: true,
  imports: [
    IconComponent,
    TranslateModule
  ]
})
export class TableHeaderComponent implements OnInit {
  @HostBinding('style.display') display = 'contents';

  @Input({ required: true }) tableHeaderOptions!: TableHeaderOptions[];
  @Input() hasActionsColumn = false;

  @Output() sort = new EventEmitter<TableHeaderOptions>();

  ngOnInit() {
    this.tableHeaderOptions.forEach(option => {
      if (!option.sortStatus) {
        option.sortStatus = 'NONE';
      }
    });
    if (this.hasActionsColumn) {
      this.tableHeaderOptions.push({
        label: '',
        sortable: false,
        name: ''
      });
    }
  }

  public changeSortStatus(selectedIndex: number) {
    this.tableHeaderOptions.forEach((option, index) => {
      if (index === selectedIndex) {
        switch (option.sortStatus) {
          case 'ASC': {
            option.sortStatus = 'DESC';
            break;
          }
          case 'DESC': {
            option.sortStatus = 'ASC';
            break;
          }
          case 'NONE': {
            option.sortStatus = 'DESC';
            break;
          }
        }
      } else {
        option.sortStatus = 'NONE';
      }
    });
    this.sort.emit(this.tableHeaderOptions[selectedIndex]);
  }
}
