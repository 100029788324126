<div class="ax-office-megasearch-result-contact-and-projects">
  <div class="ax-office-megasearch-result-contact-and-projects-category">
    {{ contactAndProjects.contact.categoryId | getCategoryLetterByCategoryIdPipe }}
  </div>
  <div class="ax-office-megasearch-result-contact-and-projects-right">
    @if (contactAndProjects.contact) {
      <div class="ax-office-megasearch-result-contact-and-projects-contact">
        <a (click)="contactClicked.emit({axContactAndProjectsResultDto: contactAndProjects})"
           [routerLink]="renderContactAsLink ? 'kontakte/read/' + contactAndProjects.contact.id : undefined"
           class="ax-office-megasearch-result-contact-and-projects-names cursor-pointer">
          @if (contactAndProjects.contact.company) {
            <strong [innerHTML]="(contactAndProjects.contact.company | highlight:searchterm)"></strong><br>
          }
          @if (contactAndProjects.contact.firstName || contactAndProjects.contact.lastName) {
            <strong>
              <span [innerHTML]="contactAndProjects.contact.firstName | highlight:searchterm"></span>&nbsp;
              <span [innerHTML]="contactAndProjects.contact.lastName | highlight:searchterm"></span>
            </strong>
          }
        </a>
        <p>
          <span [innerHTML]="contactAndProjects.contact.street | highlight:searchterm"></span>, <span
          [innerHTML]="contactAndProjects.contact.zipcode | highlight:searchterm"></span>&nbsp;<span
          [innerHTML]="contactAndProjects.contact.city | highlight:searchterm"></span>
        </p>
      </div>
    }
    @if (contactAndProjects.projects && contactAndProjects.projects.length) {
      <div class="ax-office-megasearch-result-contact-and-projects-projects">
        @for (project of contactAndProjects.projects; track project) {
          <ax-ui-button
            (buttonClick)="projectClicked.emit({axContactAndProjectsResultDto: contactAndProjects, selectedProject: project})"
            [routerLink]="renderProjectAsLink ? 'projekte/read/' + project.id : undefined"
            [small]="true"
            [variant]="project.statusId === 8 ? 'inactive' : 'secondary'">
            <span [innerHTML]="project?.numberQualified || '-' | highlight:searchterm"></span>
          </ax-ui-button>
        }
      </div>
    }
    <div class="ax-office-megasearch-result-contact-and-projects-foundinproperties">
      @for (property of contactAndProjects.foundInProperty; track property) {
        @if (getPropertyValueFromEntity(property, contactAndProjects.contact)) {
          <small class="color-light">
            {{ property | translate }}: <span
            [innerHTML]="getPropertyValueFromEntity(property, contactAndProjects.contact) | highlight:searchterm">
      </span>
          </small>
        }
        @for (project of contactAndProjects.projects; track project) {
          @if (getPropertyValueFromEntity(property, project)) {
            <small class="color-light">{{ property | translate }}:
              <span [innerHTML]="getPropertyValueFromEntity(property, project) | highlight:searchterm"></span>
            </small>
          }
        }
      }
    </div>
  </div>
</div>
