import { Component, Input } from '@angular/core';
import { AvatarSize, AvatarStatus, AvatarComponent } from '../avatar/avatar.component';
import { SafeHtml } from '@angular/platform-browser';
import { Router, RouterLink } from '@angular/router';
import { IconComponent } from '../icon/icon.component';
import { NgClass, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'ax-ui-infoblock',
    templateUrl: './infoblock.component.html',
    styleUrls: ['./infoblock.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        RouterLink,
        IconComponent,
        AvatarComponent,
    ],
})
export class InfoblockComponent {
  @Input() iconName = 'wb_sunny';
  @Input() label: string | SafeHtml | null | undefined = '';
  @Input({ required: true }) title: string | SafeHtml | null | undefined = '';
  @Input() subtitle: string | SafeHtml | null | undefined = '';
  @Input() clickable = false;
  @Input() textCenteredVertically = false;
  @Input() href = '';
  @Input() route: any = '';
  @Input() externalLink = false;
  @Input() warning = false;
  @Input() fullHeight = false;
  @Input() smallIcon = false;
  @Input() withAvatar = false;
  @Input() avatarImgSrc: string | null = '';
  @Input() avatarStatus: AvatarStatus = 'available';
  @Input() avatarSize: AvatarSize = 'default';
  @Input() avatarSquare = false;
  @Input() listElement = false;

  constructor(
    private readonly router: Router
  ) {
  }

  /**
   * User Router for internal navigation to prevent conflicts between href & routerLink attribute on the same <a> tag
   */
  async navigateToRoute(event: MouseEvent) {
    event.preventDefault();
    await this.router.navigate([this.route])
  }
}
