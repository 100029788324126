import { Component, Input, OnInit } from '@angular/core';
import { AxNews, AxNewsV2Service } from '@axova-frontend-monorepo/axova-rest-api';
import { lastValueFrom } from 'rxjs';
import { ExceptionHandlingService } from '../../../services/exception-handling/exception-handling.service';
import { Store } from '@ngxs/store';
import { ProfileState } from '@axova-frontend-monorepo/axova-state';
import { FormatDatePipe } from '@axova-frontend-monorepo/axova-commons';
import { TranslateModule } from '@ngx-translate/core';

import { RouterLink } from '@angular/router';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
import { GetUrlFromImageFullPathPipe, PrependServerToFileSrcPipe } from '@axova-frontend-monorepo/axova-commons';

@Component({
  selector: 'ax-ui-widget-mitteilungen',
  templateUrl: './widget-mitteilungen.component.html',
  styleUrls: ['./widget-mitteilungen.component.scss'],
  standalone: true,
  imports: [
    WidgetBaseComponent,
    RouterLink,
    TranslateModule,
    FormatDatePipe,
    PrependServerToFileSrcPipe,
    GetUrlFromImageFullPathPipe
  ]
})
export class WidgetMitteilungenComponent implements OnInit {
  @Input() route: string | undefined = '';
  public mitteilungenLatest: AxNews[] = [];

  constructor(
    private readonly axNewsV2Service: AxNewsV2Service,
    private readonly exceptionHandlingService: ExceptionHandlingService,
    private readonly store: Store
  ) {
  }

  async ngOnInit() {
    await this.loadMitteilungen();
  }

  public async loadMitteilungen() {
    try {
      const newsData = await lastValueFrom(this.axNewsV2Service.newsControllerFindAllPaginated({
        limit: 2,
        'filter.audiences.users.id': [`$eq:${this.store.selectSnapshot(ProfileState.user)?.id}`]
      }));
      this.mitteilungenLatest = newsData.data;
    } catch (findPaginatedNewsException) {
      this.exceptionHandlingService.handleExceptionWithAlert('findPaginatedNewsException', findPaginatedNewsException);
    }
  }
}
