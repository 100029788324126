
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getUpdateTimerecordBalanceDtoFormGroup() {
return new FormGroup({
    transferredTimebalanceFromYearBefore: new FormControl<number | undefined>(undefined),
    transferredHolidaybalanceFromYearBefore: new FormControl<number | undefined>(undefined),
})
;
}
      