import { LoadingAndFeedbackState } from './loading-and-feedback-state.type';

export interface AlertDialogOptions {
  title: string;
  message?: string;
  feedbackState?: LoadingAndFeedbackState;
  showCentered?: boolean;
  alertLifeTimeInMs?: number;
  actionLabel?: string;
  actionHandler?: () => void;
}
