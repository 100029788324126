/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateEquipmentlogCheckinDto } from '../models/ax-create-equipmentlog-checkin-dto';
import { AxCreateEquipmentlogCheckoutDto } from '../models/ax-create-equipmentlog-checkout-dto';
import { AxCreateEquipmentlogDefektDto } from '../models/ax-create-equipmentlog-defekt-dto';
import { AxCreateEquipmentlogDto } from '../models/ax-create-equipmentlog-dto';
import { AxCreateEquipmentlogReparaturDto } from '../models/ax-create-equipmentlog-reparatur-dto';
import { AxEquipmentlog } from '../models/ax-equipmentlog';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxUpdateEquipmentlogDto } from '../models/ax-update-equipmentlog-dto';

@Injectable({ providedIn: 'root' })
export class AxEquipmentlogsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `equipmentlogsControllerFindAllPaginated()` */
  static readonly EquipmentlogsControllerFindAllPaginatedPath = '/v2/equipmentlogs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentlogsControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentlogsControllerFindAllPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by equipmentId query param.
     *           <p>
     *              <b>Format: </b> filter.equipmentId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.equipmentId=$not:$like:John Doe&filter.equipmentId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.equipmentId'?: Array<string>;

    /**
     * Filter by equipmentlogtypeId query param.
     *           <p>
     *              <b>Format: </b> filter.equipmentlogtypeId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.equipmentlogtypeId=$not:$like:John Doe&filter.equipmentlogtypeId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.equipmentlogtypeId'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> created:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>equipmentlogtypeId</li>
     * <li>title</li>
     * <li>createdBy</li>
     * <li>created</li></ul>
     */
      sortBy?: Array<string>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> title,text,equipmentlogtype.name,createdByUser.firstName,createdByUser.lastName
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>title</li>
     * <li>text</li>
     * <li>equipmentlogtype.name</li>
     * <li>createdByUser.firstName</li>
     * <li>createdByUser.lastName</li>
     * <li>created</li></ul>
     */
      searchBy?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxEquipmentlog>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'equipmentId'?: (string | Array<string>);
'equipmentlogtypeId'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentlogsV2Service.EquipmentlogsControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.equipmentId', params['filter.equipmentId'], {});
      rb.query('filter.equipmentlogtypeId', params['filter.equipmentlogtypeId'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxEquipmentlog>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'equipmentId'?: (string | Array<string>);
        'equipmentlogtypeId'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentlogsControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentlogsControllerFindAllPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by equipmentId query param.
     *           <p>
     *              <b>Format: </b> filter.equipmentId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.equipmentId=$not:$like:John Doe&filter.equipmentId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.equipmentId'?: Array<string>;

    /**
     * Filter by equipmentlogtypeId query param.
     *           <p>
     *              <b>Format: </b> filter.equipmentlogtypeId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.equipmentlogtypeId=$not:$like:John Doe&filter.equipmentlogtypeId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.equipmentlogtypeId'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> created:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>equipmentlogtypeId</li>
     * <li>title</li>
     * <li>createdBy</li>
     * <li>created</li></ul>
     */
      sortBy?: Array<string>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> title,text,equipmentlogtype.name,createdByUser.firstName,createdByUser.lastName
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>title</li>
     * <li>text</li>
     * <li>equipmentlogtype.name</li>
     * <li>createdByUser.firstName</li>
     * <li>createdByUser.lastName</li>
     * <li>created</li></ul>
     */
      searchBy?: string;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxEquipmentlog>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'equipmentId'?: (string | Array<string>);
'equipmentlogtypeId'?: (string | Array<string>);
};
};
}> {
    return this.equipmentlogsControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxEquipmentlog>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'equipmentId'?: (string | Array<string>);
'equipmentlogtypeId'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxEquipmentlog>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'equipmentId'?: (string | Array<string>);
'equipmentlogtypeId'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `equipmentlogsControllerCreate()` */
  static readonly EquipmentlogsControllerCreatePath = '/v2/equipmentlogs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentlogsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentlogsControllerCreate$Response(
    params: {
      body: (AxCreateEquipmentlogDto | AxCreateEquipmentlogCheckinDto | AxCreateEquipmentlogCheckoutDto | AxCreateEquipmentlogDefektDto | AxCreateEquipmentlogReparaturDto)
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEquipmentlog>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentlogsV2Service.EquipmentlogsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEquipmentlog>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentlogsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentlogsControllerCreate(
    params: {
      body: (AxCreateEquipmentlogDto | AxCreateEquipmentlogCheckinDto | AxCreateEquipmentlogCheckoutDto | AxCreateEquipmentlogDefektDto | AxCreateEquipmentlogReparaturDto)
    },
    context?: HttpContext
  ): Observable<AxEquipmentlog> {
    return this.equipmentlogsControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEquipmentlog>): AxEquipmentlog => r.body)
    );
  }

  /** Path part for operation `equipmentlogsControllerFindOneById()` */
  static readonly EquipmentlogsControllerFindOneByIdPath = '/v2/equipmentlogs/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentlogsControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentlogsControllerFindOneById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEquipmentlog>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentlogsV2Service.EquipmentlogsControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEquipmentlog>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentlogsControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentlogsControllerFindOneById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxEquipmentlog> {
    return this.equipmentlogsControllerFindOneById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEquipmentlog>): AxEquipmentlog => r.body)
    );
  }

  /** Path part for operation `equipmentlogsControllerDelete()` */
  static readonly EquipmentlogsControllerDeletePath = '/v2/equipmentlogs/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentlogsControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentlogsControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentlogsV2Service.EquipmentlogsControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentlogsControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentlogsControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.equipmentlogsControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `equipmentlogsControllerUpdate()` */
  static readonly EquipmentlogsControllerUpdatePath = '/v2/equipmentlogs/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentlogsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentlogsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateEquipmentlogDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEquipmentlog>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentlogsV2Service.EquipmentlogsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEquipmentlog>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentlogsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentlogsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateEquipmentlogDto
    },
    context?: HttpContext
  ): Observable<AxEquipmentlog> {
    return this.equipmentlogsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEquipmentlog>): AxEquipmentlog => r.body)
    );
  }

}
