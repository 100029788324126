import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { ProfileState } from '@axova-frontend-monorepo/axova-state';

@Component({
  selector: 'ax-office-profil-page',
  templateUrl: './profil-page.component.html',
  styleUrls: ['./profil-page.component.scss'],
})
export class ProfilPageComponent {
  public profile$ = this.store.select(ProfileState.profile);

  constructor(
    private readonly store: Store,
  ) {
  }
}
