import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { IconComponent } from '../../icon/icon.component';

@Component({
  selector: 'ax-ui-accordion-item',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss']
})
export class AccordionItemComponent {
  @ViewChild('heading') headingElement: ElementRef | undefined;
  @Input() isOpen = false;

  toggle() {
    this.isOpen = !this.isOpen;
  }
}
