
import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[axUiModal]',
    standalone: true,
})
export class ModalDirective {
  constructor(
    public viewContainerRef: ViewContainerRef,
  ) {
  }
}
