
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateInteractionActivityDtoFormGroup() {
return new FormGroup({
    interactiontypeId: new FormControl<number | undefined>(2, [Validators.required]),
    contactId: new FormControl<number | undefined>(undefined, [Validators.required]),
    containerId: new FormControl<number | undefined>(undefined),
    projectId: new FormControl<number | undefined>(undefined),
    activitytypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    outcometypeId: new FormControl<number | undefined>(undefined),
    notes: new FormControl<string | undefined>(undefined),
})
;
}
      