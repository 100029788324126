
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateNotificationDtoFormGroup() {
return new FormGroup({
    title: new FormControl<string | undefined>('', [Validators.required]),
    body: new FormControl<string | undefined>('', [Validators.required]),
    audienceId: new FormControl<number | undefined>(undefined),
    userId: new FormControl<number | undefined>(undefined),
    contactId: new FormControl<number | undefined>(undefined),
    scheduled: new FormControl<string | undefined>(undefined, [Validators.required]),
    newsId: new FormControl<number | undefined>(undefined),
    eventId: new FormControl<number | undefined>(undefined),
    absenceId: new FormControl<number | undefined>(undefined),
})
;
}
      