
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getCreateUseremploymentDtoFormGroup() {
return new FormGroup({
    userId: new FormControl<number | undefined>(undefined, [Validators.required]),
    agentId: new FormControl<number | undefined>(undefined),
    businessdivisionId: new FormControl<number | undefined>(undefined),
    businessunitId: new FormControl<number | undefined>(undefined),
    type: new FormControl<string | undefined>('', [Validators.required]),
    workload: new FormControl<number | undefined>(undefined),
    targetHours: new FormControl<number | undefined>(undefined),
    validFrom: new FormControl<string | undefined>(undefined, [Validators.required]),
    validTo: new FormControl<string | undefined>(undefined, [Validators.required]),
    pricePerHour: new FormControl<number | undefined>(undefined),
    holidaysPerYear: new FormControl<number | undefined>(undefined),
    extEmployeenr: new FormControl<string | undefined>(undefined),
    extAssignmentnr: new FormControl<string | undefined>(undefined),
    extAgreementnr: new FormControl<string | undefined>(undefined),
    jobtitle: new FormControl<string | undefined>(undefined),
    welcomeMessage: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    welcomeMessagePhonenumber: new FormControl<string | undefined>(undefined),
    welcomeMessageText: new FormControl<string | undefined>(undefined),
    mondayMorning: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    mondayAfternoon: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    tuesdayMorning: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    tuesdayAfternoon: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    wednesdayMorning: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    wednesdayAfternoon: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    thursdayMorning: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    thursdayAfternoon: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    fridayMorning: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    fridayAfternoon: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    saturdayMorning: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    saturdayAfternoon: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    sundayMorning: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    sundayAfternoon: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    expensesEntitled: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    wayExpensesEntitled: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
})
;
}
      