import { Component, OnInit } from '@angular/core';
import { AxMegasearchResultDto, AxSearchV2Service } from '@axova-frontend-monorepo/axova-rest-api';
import { InputFieldComponent, LoadingAndFeedbackState, LogoComponent } from '@axova-frontend-monorepo/axova-ui';
import { filter, Subject, switchMap, tap } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileOptionsDialogComponent } from '../profile-options-dialog/profile-options-dialog.component';
import { NotificationsDialogComponent } from '../notifications-dialog/notifications-dialog.component';
import { MegasearchResultsComponent } from '../../megasearch/megasearch-results/megasearch-results.component';

@Component({
  selector: 'ax-office-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss'],
  standalone: true,
  imports: [
    InputFieldComponent,
    MegasearchResultsComponent,
    LogoComponent,
    NotificationsDialogComponent,
    ProfileOptionsDialogComponent,
    TranslateModule
  ]
})
export class TopNavigationComponent implements OnInit {
  public axMegasearchResultDto: AxMegasearchResultDto | undefined;
  public searchterm = '';
  public searchLoadingState: LoadingAndFeedbackState = 'none';
  public isSearchVisible = false;
  private searchterm$ = new Subject<string>();

  constructor(
    private readonly axSearchV2Service: AxSearchV2Service
  ) {
  }

  ngOnInit() {
    this.initializeSearchListener();
  }

  /**
   * Search by searchterm.
   *
   * @param searchterm
   */
  async search(searchterm: string) {
    this.searchterm = searchterm.trim();
    if (this.searchterm.length < 3) {
      this.isSearchVisible = false;
    }
    this.searchterm$.next(this.searchterm);
  }

  public resetAndHideSearch() {
    this.searchterm = '';
    this.isSearchVisible = false;
    this.searchterm$.next(this.searchterm);
  }

  private initializeSearchListener() {
    this.searchterm$.pipe(
      filter(term => term.length > 2),
      tap(() => this.searchLoadingState = 'loading'),
      switchMap(term => this.axSearchV2Service.searchControllerMegasearchBySearchterm({ searchterm: term }))
    ).subscribe({
      next: results => {
        this.isSearchVisible = true;
        this.axMegasearchResultDto = results;
        this.searchLoadingState = 'success';
      },
      error: () => {
        this.isSearchVisible = true;
        this.searchLoadingState = 'error';
        this.axMegasearchResultDto = undefined;
      }
    });
  }
}
