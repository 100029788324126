<ax-ui-widget-base [route]="route"
                   iconName="more_time"
                   title="{{'ARBEITSZEIT' | translate}}">
  <div class="ax-ui-widget-arbeitszeit">
    <div class="ax-info-label">
      <small>{{'UEBERZEIT' | translate}}</small>
      <h3>+2h 40min</h3>
    </div>

    <ax-ui-description-details-list [descriptionDetailList]="descriptionDetailList" />
  </div>
</ax-ui-widget-base>
