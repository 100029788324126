import { Component, Input } from '@angular/core';
import { AxContact } from '@axova-frontend-monorepo/axova-rest-api';
import { InfoblockComponent } from '../infoblock/infoblock.component';

@Component({
    selector: 'ax-ui-infoblock-contact',
    templateUrl: './infoblock-contact.component.html',
    styleUrls: ['./infoblock-contact.component.scss'],
    standalone: true,
    imports: [InfoblockComponent],
})
export class InfoblockContactComponent {
  @Input({ required: true }) contact!: AxContact;
  @Input() clickable = false;
}
