import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
    name: 'dateIsoToDatetimeLocalInput',
    standalone: true,
})
export class DateIsoToDatetimeLocalInputPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return 'null';
    return dayjs(value).format('YYYY-MM-DDTHH:mm');
  }
}
