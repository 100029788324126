import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalFloatingInstanceManagementService {
  private activeOverlay: any = null;

  public setActiveOverlay(overlayInstance: any): void {
    if (this.activeOverlay && this.activeOverlay !== overlayInstance) {
      this.activeOverlay.hideModalOverlay();
    }
    this.activeOverlay = overlayInstance;
  }

  public clearActiveOverlay(overlayInstance: any): void {
    if (this.activeOverlay === overlayInstance) {
      this.activeOverlay = null;
    }
  }
}
