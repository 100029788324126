import { BehaviorSubject } from 'rxjs';
import { google } from '@google-cloud/logging/build/protos/protos';
import LogSeverity = google.logging.type.LogSeverity;

export interface LogEntryInterface {
  created?: Date;
  severity?: LogSeverity;
  class?: string;
  content?: any;
}

export class LoggerService {
  static LOG_STREAM: BehaviorSubject<LogEntryInterface> = new BehaviorSubject<LogEntryInterface>({});
  static IS_PRODUCTION = false;

  constructor() {
  }

  /**
   * Creates a log string starting with the current timestamp and component name.
   * If no component (this) is handed over, it will just print the date.
   *
   * @param component
   * @param args
   * @constructor
   */
  static LOG(component: any, ...args: any[]): void {
    const componentClassName = LoggerService.GET_COMPONENT_OR_CLASSNAME(component);
    if (!LoggerService.IS_PRODUCTION) {
      console.info(LoggerService.GET_LOG_STRING(componentClassName), ...args);
    }
  }

  /**
   * Creates a log string starting with the current timestamp and component name.
   * If no component (this) is handed over, it will just print the date.
   *
   * @param component
   * @param args
   * @constructor
   */
  static DEBUG(component: any, ...args: any[]): void {
    const componentClassName = LoggerService.GET_COMPONENT_OR_CLASSNAME(component);
    if (!LoggerService.IS_PRODUCTION) {
      console.debug(LoggerService.GET_LOG_STRING(componentClassName), ...args);
    }
    LoggerService.LOG_STREAM.next({
      created: new Date(),
      severity: LogSeverity.DEBUG,
      class: LoggerService.GET_COMPONENT_OR_CLASSNAME(component),
      content: args,
    });
  }

  /**
   * Creates a log string starting with the current timestamp and component name.
   * If no component (this) is handed over, it will just print the date.
   *
   * @param component
   * @param args
   * @constructor
   */
  static ERROR(component: any, ...args: any[]): void {
    const componentClassName = LoggerService.GET_COMPONENT_OR_CLASSNAME(component);
    if (!LoggerService.IS_PRODUCTION) {
      console.error(LoggerService.GET_LOG_STRING(componentClassName), ...args);
    }
    LoggerService.LOG_STREAM.next({
      created: new Date(),
      severity: LogSeverity.ERROR,
      class: LoggerService.GET_COMPONENT_OR_CLASSNAME(component),
      content: args,
    });
  }

  /**
   * Creates a log string starting with the current timestamp and component name.
   * If no component (this) is handed over, it will just print the date.
   *
   * @param component
   * @param args
   * @constructor
   */
  static CRITICAL(component: any, ...args: any[]): void {
    const componentClassName = LoggerService.GET_COMPONENT_OR_CLASSNAME(component);
    if (!LoggerService.IS_PRODUCTION) {
      console.error(LoggerService.GET_LOG_STRING(componentClassName), ...args);
    }
    LoggerService.LOG_STREAM.next({
      created: new Date(),
      severity: LogSeverity.CRITICAL,
      class: LoggerService.GET_COMPONENT_OR_CLASSNAME(component),
      content: args,
    });
  }

  /**
   * Creates a log string starting with the current timestamp and component name.
   * If no component (this) is handed over, it will just print the date.
   *
   * @param componentName
   * @constructor
   */
  static GET_LOG_STRING(componentName: string): string {
    let logString = '';
    const now = new Date();
    const hours = ('0' + now.getHours()).slice(-2);
    const minutes = ('0' + now.getMinutes()).slice(-2);
    const seconds = ('0' + now.getSeconds()).slice(-2);
    const milliseconds = ('00' + now.getSeconds()).slice(-3);
    logString += hours + ':' + minutes + ':' + seconds + '.' + milliseconds + ' -';
    logString += ' ' + componentName + ' -';
    return logString;
  }

  /**
   * Just returns either the components/class' name or the component string itself.
   *
   * @param component
   * @constructor
   */
  static GET_COMPONENT_OR_CLASSNAME(component: any): string {
    if (component && typeof component === 'object' && component.constructor && component.constructor.name) {
      return component.constructor.name;
    } else {
      return component as string;
    }
  }
}
