import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { FavoritesState, FavoritesStateToggleFavorite } from '@axova-frontend-monorepo/axova-state';
import { NavigationItemInterface, NavigationService } from '@axova-frontend-monorepo/axova-commons';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@axova-frontend-monorepo/axova-ui';

@Component({
  selector: 'ax-office-favorites-bar',
  templateUrl: './favorites-bar.component.html',
  styleUrls: ['./favorites-bar.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    AsyncPipe,
    TranslateModule
  ]
})
export class FavoritesBarComponent implements OnInit {
  public favorites$ = this.store.select(FavoritesState.favorites);
  public currentNavItem: NavigationItemInterface | undefined;
  public isCurrentNavItemFavorite = false;

  constructor(
    private readonly store: Store,
    private readonly navigationService: NavigationService,
    private readonly router: Router
  ) {
  }

  ngOnInit() {
    this.navigationService.$currentNavigationItem.subscribe({
      next: navigationItem => {
        if (navigationItem) {
          this.currentNavItem = navigationItem;
          const index = this.store.selectSnapshot(FavoritesState.favorites).findIndex(favorite => favorite.route === navigationItem.route);
          this.isCurrentNavItemFavorite = index > -1;
        }
      }
    });
    this.favorites$.subscribe({
      next: favorites => {
        if (this.currentNavItem) {
          const index = favorites.findIndex(favorite => favorite.route === this.currentNavItem?.route);
          this.isCurrentNavItemFavorite = index > -1;
        }
      }
    });
  }

  public toggle() {
    if (this.currentNavItem) {
      this.store.dispatch(new FavoritesStateToggleFavorite(this.currentNavItem));
    }
  }

  public async navigate(navigationItem: NavigationItemInterface) {
    let url = `${navigationItem.route}`;
    if (navigationItem.fragment) {
      url += `#${navigationItem.fragment}`;
    }
    await this.router.navigateByUrl(url);
  }
}
