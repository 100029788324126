import { Directive, ElementRef, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { animate, inView } from 'motion';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[axUiMotionCountUpText]',
  standalone: true
})
export class MotionCountUpTextDirective implements OnInit {
  @Input({ required: true }) motionCountTarget!: number;
  @Input() motionDurationInSeconds = 2;
  @Input() motionElementInView = true;


  constructor(
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const animation = (element: Element) => {
        setTimeout(() => {
          animate(
            (progress) => element.innerHTML = String(Math.round(progress * this.motionCountTarget)) + '+',
            { duration: this.motionDurationInSeconds, easing: 'ease-out' },
          );
        }, 500);
      };


      if (this.motionElementInView) {
        inView(this.elementRef.nativeElement, (info) => {
          animation(info.target);
        }, { amount: 'all' });
      } else {
        animation(this.elementRef.nativeElement);
      }
    }
  }
}
