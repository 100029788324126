/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateUploadDto } from '../models/ax-create-upload-dto';
import { AxCreateUploadsDto } from '../models/ax-create-uploads-dto';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxUpload } from '../models/ax-upload';

@Injectable({ providedIn: 'root' })
export class AxUploadsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `uploadsControllerUploadFile()` */
  static readonly UploadsControllerUploadFilePath = '/v1/uploads/upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadsControllerUploadFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadsControllerUploadFile$Response(
    params: {
      body: AxCreateUploadDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxUpload>> {
    const rb = new RequestBuilder(this.rootUrl, AxUploadsService.UploadsControllerUploadFilePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxUpload>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadsControllerUploadFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadsControllerUploadFile(
    params: {
      body: AxCreateUploadDto
    },
    context?: HttpContext
  ): Observable<AxUpload> {
    return this.uploadsControllerUploadFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxUpload>): AxUpload => r.body)
    );
  }

  /** Path part for operation `uploadsControllerUploadAndSendAsPdfByMail()` */
  static readonly UploadsControllerUploadAndSendAsPdfByMailPath = '/v1/uploads/uploadAndSendAsPdfByMail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadsControllerUploadAndSendAsPdfByMail()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadsControllerUploadAndSendAsPdfByMail$Response(
    params: {
      body: AxCreateUploadsDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxUploadsService.UploadsControllerUploadAndSendAsPdfByMailPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadsControllerUploadAndSendAsPdfByMail$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadsControllerUploadAndSendAsPdfByMail(
    params: {
      body: AxCreateUploadsDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.uploadsControllerUploadAndSendAsPdfByMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `uploadsControllerGetUploadedFileById()` */
  static readonly UploadsControllerGetUploadedFileByIdPath = '/v1/uploads/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadsControllerGetUploadedFileById()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadsControllerGetUploadedFileById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxUploadsService.UploadsControllerGetUploadedFileByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadsControllerGetUploadedFileById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadsControllerGetUploadedFileById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.uploadsControllerGetUploadedFileById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `uploadsControllerDeleteUploadedFileById()` */
  static readonly UploadsControllerDeleteUploadedFileByIdPath = '/v1/uploads/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadsControllerDeleteUploadedFileById()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadsControllerDeleteUploadedFileById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxUploadsService.UploadsControllerDeleteUploadedFileByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadsControllerDeleteUploadedFileById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadsControllerDeleteUploadedFileById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.uploadsControllerDeleteUploadedFileById$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
