import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, ChildrenOutletContexts, RouterOutlet } from '@angular/router';
import { ButtonComponent } from '../button/button.component';
import {
  TerminassistentWizardStepsComponent
} from './components/terminassistent-wizard-steps/terminassistent-wizard-steps.component';
import { TerminassistentWizardStepsNavService } from './services/terminassistent-wizard-steps-nav.service';
import { TerminassistentState } from './ngxs/terminassistent/terminassistent.state';
import * as dayjs from 'dayjs';
import { Store } from '@ngxs/store';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { AxReservation, AxReservationsService } from '@axova-frontend-monorepo/axova-rest-api';
import { LoggerService } from '@axova-frontend-monorepo/axova-commons';
import {
  TerminassistentStateSetApiAppointmentSchedulerExternalKey,
  TerminassistentStateSetApiAppointmentSchedulerKey,
  TerminassistentStateSetReservation
} from './ngxs/terminassistent/terminassistent.actions';
import { terminAssistentRoutesAnimations } from './terminassistent.routes';
import { AvatarComponent } from '../avatar/avatar.component';
import { InputDatepickerComponent } from '../inputs/input-datepicker/input-datepicker.component';
import { InputFieldComponent } from '../inputs/input-field/input-field.component';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { SelectComponent } from '../inputs/select/select.component';
import { ToggleSwitchComponent } from '../inputs/toggle-switch/toggle-switch.component';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from '../modal/modal.component';
import { RadioGroupComponent } from '../inputs/radio-group/radio-group.component';
import { CheckboxComponent } from '../inputs/checkbox/checkbox.component';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'ax-ui-terminassistent',
  standalone: true,
  imports: [
    RouterOutlet,
    ButtonComponent,
    TerminassistentWizardStepsComponent,
    LoadingSpinnerComponent,
    AvatarComponent,
    InputDatepickerComponent,
    InputFieldComponent,
    ReactiveFormsModule,
    SelectComponent,
    ToggleSwitchComponent,
    TranslateModule,
    RadioGroupComponent,
    CheckboxComponent,
    ModalComponent
],
  templateUrl: './terminassistent.component.html',
  styleUrl: './terminassistent.component.scss',
  animations: [terminAssistentRoutesAnimations]
})
export class TerminassistentComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('feedbackFormModal') feedbackFormModal!: ModalComponent;

  public terminAssisstentFeedbackformGroup = new FormGroup(
    {
      dateTime: new FormControl(''),
      email: new FormControl('', [Validators.email]),
      phoneNumber: new FormControl(''),
      feedbackOption1: new FormControl(false),
      feedbackOption2: new FormControl(false),
      feedbackOption3: new FormControl(false),
      additionalComments: new FormControl('')
    },
    { validators: this.atLeastOneControlFilledValidator() }
  );
  public feedbackOption1Value = 'Keinen passenden Termin gefunden';
  public feedbackOption2Value = 'Mein Standort wird nicht bedient';
  public feedbackOption3Value = 'Technische Probleme/Der Assistent ist zu kompliziert';
  public feedbackPopupShowedOnce = false;

  constructor(
    private store: Store,
    public wizardStepsNavService: TerminassistentWizardStepsNavService,
    private activatedRoute: ActivatedRoute,
    private contexts: ChildrenOutletContexts,
    private reservationsService: AxReservationsService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  ngOnInit() {
    this.checkForQueryParameters();
    this.store.select(TerminassistentState.location).subscribe(location => this.wizardStepsNavService.steps[1].note = location?.name || '');
    this.store.select(TerminassistentState.reservationtype).subscribe(reservationtype => this.wizardStepsNavService.steps[2].note = reservationtype?.description || '');
    this.store.select(TerminassistentState.timeslot).subscribe(timeslot => {
      if (timeslot && timeslot?.start) {
        this.wizardStepsNavService.steps[3].note = dayjs(timeslot?.start).format('DD.MM.YYYY, H:mm');
      } else {
        this.wizardStepsNavService.steps[3].note = '';
      }
    });
    this.store.select(TerminassistentState.reservation).subscribe(reservation => this.wizardStepsNavService.steps[3].note = reservation?.street ? 'Komplett' : '');
    this.store.select(TerminassistentState.apiAppointmentSchedulerExternalKey).subscribe(apiKey => {
      if (apiKey) {
        this.wizardStepsNavService.steps[1].isVisible = false;
        this.wizardStepsNavService.steps[4].isVisible = false;
      }
    });

    this.wizardStepsNavService.observeRouterChanges();

    // set mail and phone number to required, if a favorite date is selected
    this.terminAssisstentFeedbackformGroup.controls.dateTime?.valueChanges.subscribe(value => {
      if (value) {
        // Add the required validators to email and phoneNumber controls
        this.terminAssisstentFeedbackformGroup.controls.email.setValidators([
          Validators.required,
          Validators.email
        ]);
        this.terminAssisstentFeedbackformGroup.controls.phoneNumber.setValidators([Validators.required]);
      } else {
        // Remove the required validators from email and phoneNumber controls
        this.terminAssisstentFeedbackformGroup.controls.email.clearValidators();
        this.terminAssisstentFeedbackformGroup.controls.phoneNumber.clearValidators();
      }

      // Update the validity status of the controls
      this.terminAssisstentFeedbackformGroup.controls.email.updateValueAndValidity();
      this.terminAssisstentFeedbackformGroup.controls.phoneNumber.updateValueAndValidity();
    });
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      // delay observe in case the user is entering the URL directly or coming from the browser's address bar
      setTimeout(() => {
        this.initializeExitIntent();
      }, 2000);
    }
  }

  ngOnDestroy() {
    this.wizardStepsNavService.unSubScribeFromRouterChanges();
    this.removeMouseMoveListener();
  }

  public getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  public async submitFeedback() {
    const controls = this.terminAssisstentFeedbackformGroup.controls;
    const selectedFeedbackOptions: string[] = [];

    if (controls.feedbackOption1.value) {
      selectedFeedbackOptions.push(this.feedbackOption1Value);
    }
    if (controls.feedbackOption2.value) {
      selectedFeedbackOptions.push(this.feedbackOption2Value);
    }
    if (controls.feedbackOption3.value) {
      selectedFeedbackOptions.push(this.feedbackOption3Value);
    }

    try {
      await lastValueFrom(this.reservationsService.reservationsControllerSendFeedback({
        body: {
          contactMail: controls.email.value || '',
          contactPhoneNumber: controls.phoneNumber.value || '',
          desiredDate: controls.dateTime.value || '',
          selectedFeedbackOptions: selectedFeedbackOptions,
          additionalComments: controls.additionalComments.value || ''
        }
      }));
    } catch (sendReservationFeedbackException) {
      this.wizardStepsNavService.hideLoader();
      LoggerService.ERROR('Terminassisstent send reservation feedback', sendReservationFeedbackException);
    }
    await this.feedbackFormModal.close();
  }

  private checkForQueryParameters() {
    this.activatedRoute.queryParams
      .subscribe((params: any) => {
          try {
            const reservation: Partial<AxReservation> = {};
            const keys: (keyof Partial<AxReservation>)[] = [
              'company',
              'email',
              'titleId',
              'firstName',
              'lastName',
              'objectStreet',
              'objectZip',
              'objectCity',
              'objectCanton',
              'phone',
              'reservationtypeId'
            ];

            keys.forEach(key => {
              if (params[key]) {
                reservation[key] = params[key];
              }
            });

            type AddressKey = 'street' | 'zipcode' | 'city' | 'canton';
            const addressKeys: AddressKey[] = ['street', 'zipcode', 'city', 'canton'];

            addressKeys.forEach(key => {
              reservation[key] = params[key] || params[`object${key.charAt(0).toUpperCase() + key.slice(1)}` as AddressKey];
            });

            if (Object.keys(reservation).some(key => reservation[key as keyof Partial<AxReservation>] !== undefined)) {
              this.store.dispatch(new TerminassistentStateSetReservation(reservation));
            }
          } catch (noParamsException) {
            LoggerService.LOG(this, 'Keine Einstiegsparameter mitgegeben.');
          }

          // Set API Key.
          if (params.apiAppointmentSchedulerKey) {
            this.store.dispatch(new TerminassistentStateSetApiAppointmentSchedulerKey(params.apiAppointmentSchedulerKey));
          }

          // Set External API Key.
          if (params.apiAppointmentSchedulerExternalKey) {
            this.store.dispatch(new TerminassistentStateSetApiAppointmentSchedulerExternalKey(params.apiAppointmentSchedulerExternalKey));
          }
        }
      );
  }

  public showFeedbackModal() {
    this.feedbackFormModal.open();
    this.feedbackPopupShowedOnce = true;
  }

  // checks if at least one control has a valid value (no empty form submits)
  private atLeastOneControlFilledValidator(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const controls = (formGroup as any).controls;
      const isValid = Object.keys(controls).some(controlName => {
        const control = controls[controlName];
        return control && control.value;
      });
      return isValid ? null : { atLeastOneRequired: true };
    };
  }

  private initializeExitIntent() {
    if (isPlatformBrowser(this.platformId)) {
      document.addEventListener('mousemove', this.handleMouseMove.bind(this));
    }
  }

  private handleMouseMove(event: MouseEvent) {
    if (event.clientY < 100 && !this.feedbackFormModal.isOpen && !this.feedbackPopupShowedOnce) {
      if (!this.wizardStepsNavService.reservationSuccessFull) {
        this.showFeedbackModal();
        this.removeMouseMoveListener();
      }
    }
  }

  private removeMouseMoveListener() {
    if (isPlatformBrowser(this.platformId)) {
      document.removeEventListener('mousemove', this.handleMouseMove.bind(this));
    }
  }
}
