import { TableHeaderOptions } from './table-header-options.model';
import { AxPaginatedDocumented } from '@axova-frontend-monorepo/axova-rest-api';
import { DropdownOption, LoggerService } from '@axova-frontend-monorepo/axova-commons';

export interface TableBase<T> {
  data?: T[];
  dataPaginated?: AxPaginatedDocumented & { data?: Array<T> } | undefined;
  dataSelectedRows?: T[];
  paginationOptions?: TablePaginationOptions;
  filterDropdownOptions: DropdownOption[];
  isLoading?: boolean;
  load?: () => any;
  sort?: (tableHeaderOptions: TableHeaderOptions) => any;
  selectDataRow?: (dataRow: T) => any;
  search?: (searchString: string) => any;
  addFilter?: (tableFilter: DropdownOption) => any;
  removeFilter?: (tableFilter: DropdownOption) => any;
}

export class TableBaseClass<T> implements TableBase<T> {
  public data: T[] = [];
  public dataPaginated: (AxPaginatedDocumented & { data?: Array<T> }) | undefined;
  public dataSelectedRows: T[] = [];
  public isLoading = true;
  public paginationOptions: TablePaginationOptions | undefined;
  public defaultPaginationOptions: Partial<TablePaginationOptions> = {};
  public currentTableHeaderOptions: TableHeaderOptions | undefined;
  public currentSearchTerm: string | undefined;
  public filterDropdownOptions: DropdownOption[] = [];

  /**
   * Main method which is responsible for loading paginated data from the API.
   */
  public async load() {
    LoggerService.LOG(this, 'load() method must be implemented individually per component. e.g. load data from API like Events, Items, Users etc. or similar.');
  };

  /**
   * When sorting was changed, sets the current sort table header options and does
   * a load afterwards.
   *
   * @param tableHeaderOptions
   */
  public async sort(tableHeaderOptions: TableHeaderOptions) {
    this.currentTableHeaderOptions = tableHeaderOptions;
    await this.load();
  }

  /**
   * When pagination was changed by the user (e.g. by selecting a page, or change amount of items per page),
   * this saves these pagination options and does a load with the new settings afterwards.
   *
   * @param tablePaginationOptions
   */
  public async paginationOptionsUpdated(tablePaginationOptions: TablePaginationOptions) {
    this.paginationOptions = tablePaginationOptions;
    await this.load();
  }

  /**
   * Basically just sets the search term which will be sent to the API. Also
   * reset the pagination options, since they'll be overwritten by API after receiving the result.
   *
   * @param searchTerm
   */
  public async search(searchTerm: string) {
    this.currentSearchTerm = searchTerm;
    this.paginationOptions = undefined;
    await this.load();
  }

  /**
   * Returns an array of strings which can be sent to the API.
   * You have to pass the name of the filter to find the correct results.
   *
   * @param filterName
   * @param includeFiltersDespiteSearch
   * @protected
   */
  protected getFilter(filterName: string, includeFiltersDespiteSearch = false) {
    if (this.currentSearchTerm && this.currentSearchTerm !== '' && !includeFiltersDespiteSearch) {
      return [];
    }
    const filterBy = [];
    const currentFilterOptions = this.filterDropdownOptions.filter(option => option.selected === true);
    if (currentFilterOptions && currentFilterOptions.length) {
      const filters = currentFilterOptions.filter(filterOption => filterOption.name === filterName);
      for (const filter of filters) {
        if (filter.value !== undefined) {
          filterBy.push(`${filter.operator}:${filter.value}`);
        } else {
          filterBy.push(`${filter.operator}`);
        }
      }
    }
    return filterBy;
  }

  /**
   * Returns the value of the plain filter without suffix or prefix.
   *
   * @param filterName
   * @param includeFiltersDespiteSearch
   * @protected
   */
  protected getPlainFilter(filterName: string, includeFiltersDespiteSearch = false) {
    if (this.currentSearchTerm && this.currentSearchTerm !== '' && !includeFiltersDespiteSearch) {
      return undefined;
    }
    let filterBy = undefined;
    const currentFilterOptions = this.filterDropdownOptions.filter(option => option.selected === true);
    if (currentFilterOptions && currentFilterOptions.length) {
      const filters = currentFilterOptions.filter(filterOption => filterOption.name === filterName);
      for (const filter of filters) {
        filterBy = filter.value;
      }
    }
    return filterBy;
  }

  /**
   * Returns the amount of items to be displayed per page. Defaults to 25.
   *
   * @protected
   */
  protected getLimit() {
    if (this.paginationOptions) {
      return this.paginationOptions.itemsPerPage;
    } else if (this.defaultPaginationOptions.itemsPerPage) {
      return this.defaultPaginationOptions.itemsPerPage;
    } else {
      return 25;
    }
  }

  /**
   * Returns the current page number. Defaults to 1.
   *
   * @protected
   */
  protected getPage() {
    if (this.currentSearchTerm && this.currentSearchTerm !== '') {
      return 1;
    }
    if (this.paginationOptions) {
      return this.paginationOptions.currentPage;
    } else if (this.defaultPaginationOptions.currentPage) {
      return this.defaultPaginationOptions.currentPage;
    } else {
      return 1;
    }
  }

  /**
   * Returns an array of sort strings which can be sent to the API.
   *
   * @protected
   */
  protected getSortBy() {
    const sortBy = [];
    if (this.currentTableHeaderOptions) {
      sortBy.push(`${this.currentTableHeaderOptions.name}:${this.currentTableHeaderOptions.sortStatus}`);
    }
    return sortBy;
  }

  /**
   * Sets the current pagination options based on the received meta data from the API.
   * Setting some default values as well, if API doesn't deliver them.
   *
   * @protected
   */
  protected setPaginationOptions() {
    if (this.dataPaginated) {
      this.paginationOptions = {
        itemsPerPage: this.dataPaginated.meta.itemsPerPage || 25,
        totalItems: this.dataPaginated.meta.totalItems || 0,
        currentPage: this.dataPaginated.meta.currentPage || 0,
      };
    }
  }
}

export interface TablePaginationOptions {
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
}
