import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { withNgxsResetPlugin } from 'ngxs-reset-plugin';
import { FavoritesState } from './states/favorites/favorites-state';
import { ProfileState } from './states/profile/profile-state';
import { WidgetsState } from './states/widgets/widgets-state';
import { ReloadState } from './states/reload/reload-state';
import { LoadingState } from './states/loading/loading-state';
import { FilterState } from './states/filter/filter-state';
import { environment } from '@axova-frontend-monorepo/axova-environments';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forRoot([
      FavoritesState,
      FilterState,
      LoadingState,
      ProfileState,
      ReloadState,
      WidgetsState,
    ], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      keys: '*',
      storage: StorageOption.LocalStorage,
    }),
  ],
  providers: [
    withNgxsResetPlugin(),
  ],
})
export class AxovaStateModule {
}
