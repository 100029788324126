import { Directive, ElementRef, Inject, Input, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
    selector: '[axUiAutoInitialWidthInputField]',
    standalone: true,
})
export class AutoInitialWidthInputFieldDirective implements OnInit {
  @Input() axUiAutoInitialWidthInputFieldIsTableEl = false;
  private readonly span!: HTMLElement;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      // Create a hidden span element to measure the width of the input content
    this.span = this.renderer.createElement('span');
    this.renderer.setStyle(this.span, 'position', 'absolute');
    this.renderer.setStyle(this.span, 'whiteSpace', 'pre');
    this.renderer.setStyle(this.span, 'visibility', 'hidden');
    this.renderer.setStyle(this.span, 'font', 'inherit');
    this.renderer.appendChild(document.body, this.span);
    }
  }

  ngOnInit() {
    if (this.axUiAutoInitialWidthInputFieldIsTableEl) {
      this.adjustWidth();
    }
  }

  adjustWidth(): void {
    if (isPlatformBrowser(this.platformId)) {
      const input: HTMLInputElement = this.elementRef.nativeElement;

      // Set span content to input value
      this.renderer.setProperty(this.span, 'textContent', input.value);
      const computedStyle = window.getComputedStyle(input, null);

      // Measure the width of the span and set it as the input width
      let newWidth = this.span.offsetWidth
        + parseFloat(computedStyle.getPropertyValue('padding-left'))
        + parseFloat(computedStyle.getPropertyValue('padding-right'))
        + parseFloat(computedStyle.getPropertyValue('border-left-width'))
        + parseFloat(computedStyle.getPropertyValue('border-right-width'));

      // Ensure that newWidth is at least 100px
      let minWidth = 100;
      if (input.type === 'datetime-local' || input.type === 'date') {
        minWidth = 200;
      }
      if (newWidth < minWidth) {
        newWidth = minWidth;
      }

      input.style.width = newWidth + 'px';
    }
  }
}
