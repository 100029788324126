/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxAbsence } from '../models/ax-absence';
import { AxAddDocumentDto } from '../models/ax-add-document-dto';
import { AxCreateAbsenceV2Dto } from '../models/ax-create-absence-v-2-dto';
import { AxRemoveDocumentDto } from '../models/ax-remove-document-dto';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxUpdateAbsenceV2Dto } from '../models/ax-update-absence-v-2-dto';

@Injectable({ providedIn: 'root' })
export class AxAbsencesV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `absencesControllerFindByUserId()` */
  static readonly AbsencesControllerFindByUserIdPath = '/v2/absences/byUserId/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `absencesControllerFindByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  absencesControllerFindByUserId$Response(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxAbsence>>> {
    const rb = new RequestBuilder(this.rootUrl, AxAbsencesV2Service.AbsencesControllerFindByUserIdPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxAbsence>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `absencesControllerFindByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  absencesControllerFindByUserId(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<Array<AxAbsence>> {
    return this.absencesControllerFindByUserId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxAbsence>>): Array<AxAbsence> => r.body)
    );
  }

  /** Path part for operation `absencesControllerDelete()` */
  static readonly AbsencesControllerDeletePath = '/v2/absences/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `absencesControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  absencesControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxAbsencesV2Service.AbsencesControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `absencesControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  absencesControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.absencesControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `absencesControllerUpdate_1()` */
  static readonly AbsencesControllerUpdate_1Path = '/v2/absences/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `absencesControllerUpdate_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  absencesControllerUpdate_1$Response(
    params: {
      id: number;
      body: AxUpdateAbsenceV2Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxAbsence>> {
    const rb = new RequestBuilder(this.rootUrl, AxAbsencesV2Service.AbsencesControllerUpdate_1Path, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxAbsence>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `absencesControllerUpdate_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  absencesControllerUpdate_1(
    params: {
      id: number;
      body: AxUpdateAbsenceV2Dto
    },
    context?: HttpContext
  ): Observable<AxAbsence> {
    return this.absencesControllerUpdate_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxAbsence>): AxAbsence => r.body)
    );
  }

  /** Path part for operation `absencesControllerCreate_1()` */
  static readonly AbsencesControllerCreate_1Path = '/v2/absences';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `absencesControllerCreate_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  absencesControllerCreate_1$Response(
    params: {
      body: AxCreateAbsenceV2Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxAbsence>> {
    const rb = new RequestBuilder(this.rootUrl, AxAbsencesV2Service.AbsencesControllerCreate_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxAbsence>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `absencesControllerCreate_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  absencesControllerCreate_1(
    params: {
      body: AxCreateAbsenceV2Dto
    },
    context?: HttpContext
  ): Observable<AxAbsence> {
    return this.absencesControllerCreate_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxAbsence>): AxAbsence => r.body)
    );
  }

  /** Path part for operation `absencesControllerAddDocumentToAbsence()` */
  static readonly AbsencesControllerAddDocumentToAbsencePath = '/v2/absences/{id}/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `absencesControllerAddDocumentToAbsence()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  absencesControllerAddDocumentToAbsence$Response(
    params: {
      id: number;
      body: AxAddDocumentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxAbsencesV2Service.AbsencesControllerAddDocumentToAbsencePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `absencesControllerAddDocumentToAbsence$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  absencesControllerAddDocumentToAbsence(
    params: {
      id: number;
      body: AxAddDocumentDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.absencesControllerAddDocumentToAbsence$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `absencesControllerRemoveDocumentFromAbsence()` */
  static readonly AbsencesControllerRemoveDocumentFromAbsencePath = '/v2/absences/{id}/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `absencesControllerRemoveDocumentFromAbsence()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  absencesControllerRemoveDocumentFromAbsence$Response(
    params: {
      id: number;
      body: AxRemoveDocumentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxAbsencesV2Service.AbsencesControllerRemoveDocumentFromAbsencePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `absencesControllerRemoveDocumentFromAbsence$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  absencesControllerRemoveDocumentFromAbsence(
    params: {
      id: number;
      body: AxRemoveDocumentDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.absencesControllerRemoveDocumentFromAbsence$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

}
