/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateTaskDto } from '../models/ax-create-task-dto';
import { AxTask } from '../models/ax-task';
import { AxUpdateTaskDto } from '../models/ax-update-task-dto';

@Injectable({ providedIn: 'root' })
export class AxTasksV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tasksControllerCreate()` */
  static readonly TasksControllerCreatePath = '/v2/tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tasksControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tasksControllerCreate$Response(
    params: {
      body: AxCreateTaskDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxTasksV2Service.TasksControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tasksControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tasksControllerCreate(
    params: {
      body: AxCreateTaskDto
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.tasksControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

  /** Path part for operation `tasksControllerDelete()` */
  static readonly TasksControllerDeletePath = '/v2/tasks/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tasksControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  tasksControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxTasksV2Service.TasksControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tasksControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tasksControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.tasksControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

  /** Path part for operation `tasksControllerUpdate()` */
  static readonly TasksControllerUpdatePath = '/v2/tasks/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tasksControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tasksControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateTaskDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxTasksV2Service.TasksControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tasksControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tasksControllerUpdate(
    params: {
      id: number;
      body: AxUpdateTaskDto
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.tasksControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

}
