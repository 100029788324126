import { Pipe, PipeTransform } from '@angular/core';
import { AxProject } from '@axova-frontend-monorepo/axova-rest-api';

@Pipe({
    name: 'getAddressOfProjectPipe',
    standalone: true
})
export class GetAddressOfProjectPipe implements PipeTransform {
  transform(project: AxProject): { street: string; zipcode: string; city: string } {
    if (project.objectStreet || project.objectZip || project.objectCity) {
      return {
        street: project.objectStreet ?? '',
        zipcode: project.objectZip ?? '',
        city: project.objectCity ?? '',
      };
    } else {
      try {
        return {
          street: project.container.contact.street ?? '',
          zipcode: project.container.contact.zipcode ?? '',
          city: project.container.contact.city ?? '',
        };
      } catch (contactDataNotLoaded) {
        return {
          street: 'FEHLER',
          zipcode: 'FEHLER',
          city: 'FEHLER',
        };
      }
    }
  }
}
