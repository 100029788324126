<div class="ax-office-notifications-dialog">
  <ax-office-navigation-dialog-base #navDialog [title]="'BENACHRICHTIGUNGEN' | translate">
    <ax-ui-button [iconName]="'notifications'"
                  [iconOnly]=true
                  [whiteBackground]="true"
                  button
                  variant="secondary">
    </ax-ui-button>

    <ng-container dialog-content>
      <div class="ax-office-notifications-dialog__container">
        @if (notifications && notifications.length > 0) {
          @for (notification of notifications; track notification) {
            <div (click)="goToNotification(notification.id)"
                 class="ax-office-notifications-dialog__item ax-action-element">
              <div class="ax-office-notifications-dialog__item-content">
                <p><b>{{ notification.title }}</b></p>
                <small>{{ notification.body }}</small>
                <div class="ax-office-notifications-dialog__item-created-info">
                  <small>{{ notification.scheduled! | formatDate : 'DD.MM.YYYY' }}</small>
                  <small>{{ notification.scheduled! | formatDate : 'HH:mm' }}</small>
                </div>
              </div>
            </div>
          }
          <hr>
        } @else {
          {{ 'BENACHRICHTIGUNGEN_KEINE' | translate }}
        }

      </div>

      <div class="ax-office-notifications-dialog__actions">
        @if (notifications && notifications.length > 0) {
          <ax-ui-button
            text="Alle als gelesen markieren"
            variant="tertiary">
          </ax-ui-button>
        }

        <ax-ui-button [routerLink]="'/'"
                      [text]="'BENACHRICHTIGUNGEN_ALLE' | translate"
                      variant="primary">
        </ax-ui-button>
      </div>
    </ng-container>
  </ax-office-navigation-dialog-base>
</div>
