import { Component, Input } from '@angular/core';
import { LoggerService } from '@axova-frontend-monorepo/axova-commons';
import { TranslateModule } from '@ngx-translate/core';
import { InputFieldComponent } from '../../inputs/input-field/input-field.component';
import { FormsModule } from '@angular/forms';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';

@Component({
    selector: 'ax-ui-widget-notizen',
    templateUrl: './widget-notizen.component.html',
    styleUrls: ['./widget-notizen.component.scss'],
    standalone: true,
    imports: [
        WidgetBaseComponent,
        FormsModule,
        InputFieldComponent,
        TranslateModule,
    ],
})
export class WidgetNotizenComponent {
    @Input() route: string | undefined = '';

  saveNoteChange(inputValue: any) {
    LoggerService.LOG(this, inputValue);
  }
}
