
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateEquipmentlogDtoFormGroup() {
return new FormGroup({
    equipmentId: new FormControl<number | undefined>(undefined, [Validators.required]),
    equipmentlogtypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    title: new FormControl<string | undefined>(undefined),
    text: new FormControl<string | undefined>(undefined),
    childrenIds: new FormControl<Array<number> | undefined>([]),
})
;
}
      