/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxAuthDto } from '../models/ax-auth-dto';
import { AxLogoutDto } from '../models/ax-logout-dto';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxVerifyOtpV2Dto } from '../models/ax-verify-otp-v-2-dto';

@Injectable({ providedIn: 'root' })
export class AxAuthenticationV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authenticationControllerLogin_1()` */
  static readonly AuthenticationControllerLogin_1Path = '/v2/auth/loginWithUsernameAndPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerLogin_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerLogin_1$Response(
    params: {
      body: AxAuthDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxAuthenticationV2Service.AuthenticationControllerLogin_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerLogin_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerLogin_1(
    params: {
      body: AxAuthDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.authenticationControllerLogin_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `authenticationControllerRefreshToken_1()` */
  static readonly AuthenticationControllerRefreshToken_1Path = '/v2/auth/refreshTokens';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerRefreshToken_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerRefreshToken_1$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxAuthenticationV2Service.AuthenticationControllerRefreshToken_1Path, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerRefreshToken_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerRefreshToken_1(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.authenticationControllerRefreshToken_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authenticationControllerLogout()` */
  static readonly AuthenticationControllerLogoutPath = '/v2/auth/logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerLogout()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerLogout$Response(
    params: {
      body: AxLogoutDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxAuthenticationV2Service.AuthenticationControllerLogoutPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerLogout$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerLogout(
    params: {
      body: AxLogoutDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.authenticationControllerLogout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authenticationControllerLogoutFromAllDevices()` */
  static readonly AuthenticationControllerLogoutFromAllDevicesPath = '/v2/auth/logoutFromAllDevices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerLogoutFromAllDevices()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerLogoutFromAllDevices$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxAuthenticationV2Service.AuthenticationControllerLogoutFromAllDevicesPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerLogoutFromAllDevices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerLogoutFromAllDevices(
    params?: {
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.authenticationControllerLogoutFromAllDevices$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `authenticationControllerVerifyOtp_1()` */
  static readonly AuthenticationControllerVerifyOtp_1Path = '/v2/auth/verifyOtp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerVerifyOtp_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerVerifyOtp_1$Response(
    params: {
      body: AxVerifyOtpV2Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxAuthenticationV2Service.AuthenticationControllerVerifyOtp_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerVerifyOtp_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerVerifyOtp_1(
    params: {
      body: AxVerifyOtpV2Dto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.authenticationControllerVerifyOtp_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
