import { Pipe, PipeTransform } from '@angular/core';
import { AxDocument } from '@axova-frontend-monorepo/axova-rest-api';

@Pipe({
    name: 'getItemImageFromDocumentsPipe',
    standalone: true
})
export class GetItemImageFromDocumentsPipe implements PipeTransform {
  transform(documents: AxDocument[] | undefined) {
    if (documents && documents.length) {
      return documents.find(document => document.documenttypeId === 70);
    }
    return undefined;
  }
}
