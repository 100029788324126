import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { TerminassistentState } from './ngxs/terminassistent/terminassistent.state';

export const terminassistentGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const store: Store = inject(Store);
  let canActivate = true;
  if (route.data['requires'] && route.data['requires'].length) {
    for (const required of route.data['requires']) {
      switch (required) {
        case 'ort':
          if (!store.selectSnapshot(TerminassistentState.location)) {
            canActivate = false;
          }
          break;
        case 'typ':
          if (!store.selectSnapshot(TerminassistentState.reservationtype)) {
            canActivate = false;
          }
          break;
        case 'zeit':
          if (!store.selectSnapshot(TerminassistentState.timeslot)) {
            canActivate = false;
          }
          break;
        case 'daten':
          if (!store.selectSnapshot(TerminassistentState.reservation)) {
            canActivate = false;
          }
          break;
      }
    }
  }
  return canActivate;
};
