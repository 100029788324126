import { Pipe, PipeTransform } from '@angular/core';
import { AxTask, AxTaskStatusEnum } from '@axova-frontend-monorepo/axova-rest-api';

@Pipe({
    name: 'getTaskStatusColorVariantPipe',
    standalone: true,
})
export class GetTaskStatusColorVariantPipe implements PipeTransform {
  transform(task: AxTask): 'neutral' | 'success' | 'warning' | 'error' | 'empty' {
    switch (task.status) {
      case AxTaskStatusEnum.Definitiv:
        return 'success';
      case AxTaskStatusEnum.Erledigt:
        return 'success';
      case AxTaskStatusEnum.Geplant:
        return 'warning';
      case AxTaskStatusEnum.Abgelehnt:
        return 'error';
      default:
        return 'error';
    }
  }
}
