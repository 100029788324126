/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxAppDevice } from '../models/ax-app-device';

@Injectable({ providedIn: 'root' })
export class AxAppDevicesV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `appDevicesControllerFindByUserId()` */
  static readonly AppDevicesControllerFindByUserIdPath = '/v2/app-devices/byUserId/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appDevicesControllerFindByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  appDevicesControllerFindByUserId$Response(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxAppDevice>>> {
    const rb = new RequestBuilder(this.rootUrl, AxAppDevicesV2Service.AppDevicesControllerFindByUserIdPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxAppDevice>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appDevicesControllerFindByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appDevicesControllerFindByUserId(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<Array<AxAppDevice>> {
    return this.appDevicesControllerFindByUserId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxAppDevice>>): Array<AxAppDevice> => r.body)
    );
  }

  /** Path part for operation `appDevicesControllerFindByContactId()` */
  static readonly AppDevicesControllerFindByContactIdPath = '/v2/app-devices/byContactId/{contactId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appDevicesControllerFindByContactId()` instead.
   *
   * This method doesn't expect any request body.
   */
  appDevicesControllerFindByContactId$Response(
    params: {
      contactId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxAppDevice>>> {
    const rb = new RequestBuilder(this.rootUrl, AxAppDevicesV2Service.AppDevicesControllerFindByContactIdPath, 'get');
    if (params) {
      rb.path('contactId', params.contactId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxAppDevice>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appDevicesControllerFindByContactId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appDevicesControllerFindByContactId(
    params: {
      contactId: number;
    },
    context?: HttpContext
  ): Observable<Array<AxAppDevice>> {
    return this.appDevicesControllerFindByContactId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxAppDevice>>): Array<AxAppDevice> => r.body)
    );
  }

}
