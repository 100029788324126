import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { TablePaginationOptions } from '../../models/table-base.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DropdownOption } from '@axova-frontend-monorepo/axova-commons';

import { SelectComponent } from '../inputs/select/select.component';
import { IconComponent } from '../icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ax-ui-table-footer',
  templateUrl: './table-footer.component.html',
  styleUrls: ['./table-footer.component.scss'],
  standalone: true,
  imports: [
    SelectComponent,
    IconComponent,
    TranslateModule
  ]
})
export class TableFooterComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('tableFooter', { static: false }) tableFooterElement!: ElementRef;
  @Input({ required: true }) paginationOptions!: TablePaginationOptions;
  @Output() valueChanged = new EventEmitter<TablePaginationOptions>;

  private activatedRouteFragmentSubscribtion!: Subscription;

  public totalPages!: number;
  public itemsPerPageSelectOption: DropdownOption[] = [
    {
      label: '25 pro Seite',
      value: 25
    },
    {
      label: '50 pro Seite',
      value: 50
    },
    {
      label: '100 pro Seite',
      value: 100
    }
  ];
  private maxPaginationItems = 10;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.calculateTotalNumberOfPages;
    // get active page number from url and set the selected page in the nav accordingly
    this.activatedRouteFragmentSubscribtion = this.activatedRoute.fragment.subscribe(async (fragment: string | null) => {
      if (fragment) {
        const pageNumber = parseInt(fragment, 10);
        if (!isNaN(pageNumber)) {
          await this.setPage(pageNumber);
        } else {
          await this.setPage(1);
        }
      } else {
        await this.setPage(1);
      }
    });
  }

  ngAfterViewInit() {
    this.adjustPaginationItems();
  }

  ngOnChanges() {
    this.calculateTotalNumberOfPages;
  }

  ngOnDestroy() {
    if (this.activatedRouteFragmentSubscribtion) {
      this.activatedRouteFragmentSubscribtion.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.adjustPaginationItems();
  }

  get calculateTotalNumberOfPages(): number {
    this.totalPages = Math.ceil(this.paginationOptions.totalItems / this.paginationOptions.itemsPerPage);
    return this.totalPages;
  }

  get paginationNumbers(): number[] {
    if (this.totalPages <= this.maxPaginationItems) {
      return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    }

    let start = this.paginationOptions.currentPage - Math.floor(this.maxPaginationItems / 2);
    let end = this.paginationOptions.currentPage + Math.floor(this.maxPaginationItems / 2) - 1;

    if (start <= 0) {
      end += Math.abs(start) + 1;
      start = 1;
    }

    if (end > this.totalPages) {
      start -= (end - this.totalPages);
      end = this.totalPages;
    }

    // Ensure the pagination always shows at most 10 buttons (or less if totalPages < 10)
    start = Math.max(start, end - this.maxPaginationItems + 1);

    return Array.from({ length: end - start + 1 }, (_, i) => i + start);
  }

  public async setPage(pageNumber: number) {
    if (pageNumber >= 1 && pageNumber <= this.calculateTotalNumberOfPages && this.paginationOptions.currentPage !== pageNumber) {
      this.paginationOptions.currentPage = pageNumber;
      this.valueChanged.emit(this.paginationOptions);

      await this.router.navigate([], {
        relativeTo: this.activatedRoute,
        fragment: pageNumber.toString()
      });
    }
  }

  public setItemsPerPage(amount: number): void {
    this.paginationOptions.itemsPerPage = amount;
    this.valueChanged.emit(this.paginationOptions);
  }

  private adjustPaginationItems() {
    const width = this.tableFooterElement.nativeElement.offsetWidth;

    // Adjust the number of pagination items based on width
    // For example: for every 50 pixels, we want 1 pagination item.
    this.maxPaginationItems = Math.floor(width / 100);
    this.changeDetectorRef.detectChanges();
  }
}
